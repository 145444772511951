import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  bindcredential: string;
}

const initialState: AuthState = {
    bindcredential: '',
};

const bindcredentialSlice = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setBindcredential: (state, action: PayloadAction<string>) => {
      state.bindcredential = action.payload;
    },
    clearBindcredential: state => {
      state.bindcredential = '';
    },
  },
});

export const { setBindcredential, clearBindcredential } = bindcredentialSlice.actions;
export default bindcredentialSlice.reducer;
