import { useUserContext } from "./userContext/UserContext";

import UserHeader from "./layout/ClientHeader";
import UserSearch from "./adduser/components/UserSearch";
import UsersTable from "./adduser/usertable/UsersTable";
import AddUserModal from "./add-user/layouts/add-user-modal/AddUserModal";

const UserDashBoard = () => {
  const { customerOriginalTableData } = useUserContext();

  return (
    <div className="user_management">
      <UserHeader />
      <UserSearch />
      <div className="pt-8 table-top ">
        <div className="w-full user">
          <div className="user-dashboard">{customerOriginalTableData && <UsersTable />}</div>
        </div>
      </div>
      <AddUserModal />
    </div>
  );
};
export default UserDashBoard;
