import React, { useEffect, useState } from 'react';
import ImageView from '../../../../../layouts/component/ImageView';
import { images } from '../../../../../assets/images/ImagePaths';
import SelectConnector from '../modal/SelectConnector';
import './ManageConnection.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSourceConnector } from '../../../../../redux/slice/provisioning/ProvisioningSlice';
import { useTranslation } from 'react-i18next';
const ConnectionSourceAndTarget = (props: any) => {
  const { t } = useTranslation();

  const { triggerError } = props;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const provisioning = useSelector((state: any) => state?.provisioning);
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleSelectConnector = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    if (provisioning?.isActiveEdit) {
      dispatch(setSelectedSourceConnector('Akku_Connector'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provisioning?.isActiveEdit]);
  return (
    <>
      <div className="flex  px-[30px] manage-connector  items-center">
        <div className="lg:w-[40%] w-[60%]">
          <p className="source"> {t('appManagement.provisioning.selectSource')}</p>
          <div className="browse-card">
            <div
              role="none"
              className="browse  h-full flex flex-col"
              onClick={() => {
                handleSelectConnector();
              }}
            >
              <div className="w-11">
                <ImageView src={images.link} />
              </div>

              <p className="text-center">
                {provisioning?.selectedSourceConnector !== '' ? provisioning?.selectedSourceConnector : 'Browse from connectors'}
              </p>
              {provisioning?.selectedSourceConnector !== '' ? (
                <p className="query">{t('appManagement.provisioning.descriptionAkkuConnector')}</p>
              ) : null}
              {/* <p className="text-center">Browse from connectors</p> */}
            </div>

            <div className="selected-source hidden ">
              <p className="flex items-center  name">
                <div className="w-11 mr-3">
                  <ImageView src={images.link} />
                </div>
                {t('appManagement.provisioning.akkuuConnector')}
              </p>
              <p className="query">{t('appManagement.provisioning.queryDetectEnginePerformanceProblem')}</p>
            </div>
          </div>
          {triggerError && <div className="error-message">{t('appManagement.provisioning.pleaseSelectSourceConnector')}</div>}
        </div>
        <div className="lg:w-[20%]  flex justify-center items-center  mt-11 lg:mt-0">
          <div className="w-20 self-center img-arrow">
            <ImageView src={images.rightArrow} />
          </div>
        </div>
        <div className="lg:w-[40%] w-[60%] mt-7 lg:mt-0">
          <p className="source"> {t('appManagement.provisioning.selectTarget')}</p>
          <div className="browse-card">
            <div className="selected-source flex flex-col justify-center items-center">
              <p className="flex items-center  name capitalize">
                <div className="w-11 mr-3">
                  <ImageView src={images.link} />
                </div>
                {generalDetails?.name} {t('appManagement.provisioning.connector')}
              </p>
              <p className="query">{provisioning?.generalDetails?.description || 'query DetectEngineProblems'}</p>
            </div>
          </div>
        </div>
      </div>

      {openModal && <SelectConnector openModal={openModal} handleClose={handleClose} />}
    </>
  );
};

export default ConnectionSourceAndTarget;
