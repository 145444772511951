 export const handleAuthRedirectNext = (stepper:any) => {
    const newStepper = stepper.map((step: any, index: number) => {
      if (index === 2) {
        return {
          ...step,
          status: "check",
          active: false,
          icon: "check",
        };
      } else if (index === 3) {
        return {
          icon: "",
          ...step,
           active: true,
           status: "",
         
          children: [
            {
              
              title: "Authentication",
              description: "Enter child step 1 details",
              active: false,
            },
            {
              
              title: "Search & Update",
              description: "Enter child step 2 details",
              active: true,
            },
          ],
        };
      } else {
        return {
          active: false,
          ...step,
        };
      }
    });
    return newStepper;
  };
  export const handleAuthRedirectBack= (stepper:any)=>{
     const newStepper = stepper.map((step: any, index: number) => {
      if (index === 2) {
        return  {
         title: "Choose Connector",
         icon: "check",
         description: "Enter your personal details",
         active: true,
         status: "check",
      }
      } else if (index === 3) {
        return {
       
        children: [
          {
            title: "Authentication",
            description: "Enter child step 1 details",
            active: false,
          },
          {
            title: "Search & Update",
            description: "Enter child step 2 details",
            active: false,
          },
        ],
           description: "Enter your security details",
          title: "Configure",
          status: "NotStarted",
          icon: "",
        active: false,
      }
      } else if (index === 4) {
        return {
        
          status: "NotStarted",
          icon: "",
          active: false,
            ...step,
          
          children: [
            {
             
              description: "Enter child step 1 details",
               title: "Attributes",
              active: false,
            },
            {
              
              description: "Enter child step 2 details",
              title: "User Status",
              active: false,
            },
            {
              
              description: "Enter child step 2 details",
              title: "Notifications",
              active: false,
            },
          ],
        };
      } else {
        return {
          active: false,
          ...step,
          
        };
      }
    });
  return newStepper
  }

  export const initState=[
      {
        description: "",
        title: "Channel",
        active: false,
        status: "check",
        icon: "check",
      },
      {
        description: "Enter your personal details",
        title: "Directory Connection",
        status: "check",
        icon: "check",
        active: false,
      },
      {
        description: "Enter your personal details",
        title: "Choose Connector",
        status: "inprogress",
        icon: "",
        active: true,
      },
      {
        description: "Enter your security details",
        title: "Configure",
        status: "NotStarted",
        icon: "",
        active: false,
        children: [
          {
            description: "Enter child step 1 details",
            title: "Authentication",
            active: false,
          },
          {
            active: false,
            title: "Search & Update",
            description: "Enter child step 2 details",
          },
        ],
      },
      {
        description: "Enter your account details",
        title: "Mapping",
        status: "NotStarted",
        icon: "",
        children: [
          {
            description: "Enter child step 1 details",
            title: "Attributes",
            active: false,
          },
          {
            description: "Enter child step 2 details",
            title: "User Status",
            active: false,
          },
        ],
        active: false,
      },
    ];
