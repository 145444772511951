import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CardTitle from "../../component/CardTitle";
import batch from "../../../assets/images/icons/recover.png";
import { DatePicker, Select } from "antd";
import AttemptsIndividualScore from "./AttemptsIndividualScore";
import TotalAttemptScore from "./TotalAttemptScore";
import { useSelector } from "react-redux";
// import { chartOptions } from "./chartOptions";
import { DropDownOption, RecoveryAttemptsRepresentation } from "./attemptsInterface";
import ImageView from "../../component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import "./Attempts.scss";
import { useTranslation } from "react-i18next";
import ChartOptions from "../license-card/ChartOptions";
interface ChatType {
  options: ChartOptions;
}

const RecoveryAttempts = () => {
  const { t } = useTranslation();
  const data = [{ type: t("dashboard.failure") }, { type: t("dashboard.successful") }];
  const chartOptions: any = {
    options: {
      labels: data.map((row: any) => row?.type),
      colors: ["#F64D75", "#95DABE"],
      chart: {
        width: 300,
        type: "pie",
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
          expandOnClick: false,
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: "100%",
            labels: {
              show: false,
            },
          },
          animate: false,
        },
      },
      stroke: {
        width: 0,
      },
      tooltip: {
        y: {
          formatter: function (val: any, { series }: any) {
            if (!series) {
              return `${val} %`;
            }
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "13px",
          offsetX: 40,
          offsetY: -10,
        },
      },
      legend: {
        show: false,
      },
      events: {},
      states: {
        hover: {
          filter: {
            type: "none", // Disables the hover effect
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none", // Optionally, disables the active (click) effect as well
          },
        },
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            chart: {
              width: 150,
            },
            legend: {},
          },
        },
      ],
    },
  };
  const [recoveryAttempts, setRecoveryAttempts] = useState<any>(null);
  const [series, setSeries] = useState<number[]>([0, 0]);

  const userDetails = useSelector((state: any) => state.dashboardDetails?.recoveryAttempts);

  useEffect(() => {
    const seriesSuccess = calculateSuccessRatePercentage(userDetails);
    const seriesFail = calculateFailureRatePercentage(userDetails);
    const storeSeriesValue = [seriesFail, seriesSuccess];
    setSeries(storeSeriesValue);
    setRecoveryAttempts(userDetails);
    setDropDownList([]);
  }, [userDetails]);

  const [dropDownList, setDropDownList] = useState<DropDownOption[]>([{ label: "Password", value: "password" }]);

  const calculateSuccessRatePercentage = (el: RecoveryAttemptsRepresentation) => {
    const successCount = el?.totalSuccessRecoveryAttemptsCount;
    const totalCount = el?.totalRecoveryAttemptsCount;
    const rate = Math.round((successCount / totalCount) * 100);
    return rate;
  };

  const calculateFailureRatePercentage = (el: RecoveryAttemptsRepresentation) => {
    const failureCount = el?.totalFailureRecoveryAttemptsCount;
    const totalCount = el?.totalRecoveryAttemptsCount;
    const rate = Math.round((failureCount / totalCount) * 100);
    return rate;
  };

  const onChange = (date: any, dateString: any) => {
    console.log(date, dateString);
  };
  return (
    <div className="recover-attempts ">
      <div className="_card _card-modify rounded-lg w-full h-full min-h-[397px] flex flex-col gap-y-12">
        <div className="_card-header --no-border mt-4">
          <div className="title">
            <CardTitle title={t("dashboard.recoveryAttempts")} src={batch} />
          </div>
          <div className=" hidden">
            <Select
              suffixIcon={<ImageView className="w-6 custom-chevron" src={images.chevronDown} alt="chevronDown" />}
              className="w-[150px] h-[48px] mr-3"
              onChange={(value) => {}}
              showSearch
              value={"Password"}
            >
              {dropDownList?.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
            {/* <Button className="w-[56px] h-[48px] __common btn flex justify-center items-center mr-3" icon={ <span className="material-symbols-outlined text-[#667085]">download</span>} size={"large"}/> */}
            <div className="_custum-date-picker relative w-[200px] ">
              <DatePicker
                className="__common h-[48px] pl-10 __placeholder"
                placeholder="Today"
                onChange={(date, dateString) => {
                  onChange(date, dateString);
                }}
                suffixIcon={null}
              />
              <span className="material-symbols-outlined text-[#667085] absolute left-3 top-3 pr-3">calendar_today</span>
            </div>
          </div>
        </div>
        <div className="flex w-full pt-4">
          <div className="w-[50%] flex justify-center items-center">
            <div className="w-full flex items-center justify-center">
              <TotalAttemptScore attempt={recoveryAttempts?.totalRecoveryAttemptsCount} />
              <div className="w-1/2">
                <Chart options={chartOptions.options} series={series} type="pie" width="225" />
              </div>
            </div>
          </div>
          <AttemptsIndividualScore failureScore={recoveryAttempts?.totalFailureRecoveryAttemptsCount} successScore={recoveryAttempts?.totalSuccessRecoveryAttemptsCount} />
        </div>
      </div>
    </div>
  );
};

export default RecoveryAttempts;
