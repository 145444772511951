import React from "react";
import "./DirectoryBreadcrumb.scss";
import { useTranslation } from "react-i18next";
const DirectoryBreadcrumb = (props: any) => {
  const { t } = useTranslation();

  const { onClick } = props;
  return (
    <ul className="flex ldab-breadcrumb">
      <li role="none" onClick={onClick} className="cursor-pointer">
        {t("userManagement.connectedDirectory")} <span className="pr-1">/</span>
      </li>
      <li className="active">{t("userManagement.managementDirectory")}</li>
    </ul>
  );
};

export default DirectoryBreadcrumb;
