import React, { useEffect, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";

import {
  connectorDeProvisionSchema,
  groupEndPointDescriptionPayload,
  transformHeaderParameters,
  UseGetEndpointDescription,
  userDeProvisionErrorEvaluatorScroll,
} from "../../../helper/connectorHelper";
import {
  setActiveStep,
  setDeProvisionConnectorEndpointConfigId,
  setDeProvisioning,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import DeProvisionFormFields from "../form/DeprovisionFormFields";
import SuccessMessageModal from "../../../../../../access-manager/create/IpTimeSuccessModal";
import { useTranslation } from "react-i18next";
import { useProvisionContext } from "../../../../../context/ProvisionConfigureContext";
import { useHandleProvisionPrerequisites } from "../../../connector/helper/ProvisioningHelper";
import { setButtonStatus } from "../../../../../../../redux/slice/provisioning/ProvisioningButtonSlice";
import { deProvisioningRootState, provisioningRootState } from "../../../../../../../redux/slice/provisioning/ProvisioningTypes";
import { profileDetailsType } from "../../../../../../../redux/slice/profile/ProfileDetailsSlice";
import { buttonRootState, payloadType } from "../../types/provisioningTypes";
import axios, { AxiosResponse } from "axios";

interface groupDeProvisionProps {
  formRef: React.RefObject<FormikProps<deProvisioningRootState>>;
  setActiveKey: (key: string) => void;
}
interface ApiResponse {
  [key: string]: any;
  status: number;
  data?: {
    [key: string]: any;
  };
}

const GroupDeProvision: React.FC<groupDeProvisionProps> = ({ setActiveKey, formRef }) => {
  const { provisionDetails } = useProvisionContext();
  const { getProvisionDetails } = useHandleProvisionPrerequisites();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const generalDetails = useSelector((state: provisioningRootState) => state?.provisioning?.generalDetails);

  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const akkuDeProvisionConnectorEndpointConfigId = useSelector(
    (state: provisioningRootState) => state.provisioning?.akkuDeProvisionConnectorEndpointConfigId,
  );
  const endPointResponseDtosData = useSelector((state: provisioningRootState) => state?.provisioning?.endPointResponseDtosData);
  const akkuProvisioningConnectorId = useSelector((state: provisioningRootState) => state.provisioning?.akkuProvisioningConnectorId);
  const formInitialValues = useSelector((state: deProvisioningRootState) => state?.provisioning?.deProvisioning?.groupDeProvision);
  const deProvisioningDetails = useSelector((state: deProvisioningRootState) => state?.provisioning?.deProvisioning);

  const isTenantAdmin = useSelector((state: profileDetailsType) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const buttonStatus = useSelector((state: buttonRootState) => state?.buttonStatus?.buttonStatus);
  const groupDeprovisioning = useSelector((state: buttonRootState) => state?.buttonStatus?.buttonStatus?.groupDeprovisioning);

  const deProvisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const deProvisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const realmId = retrieveData("realmId", true);

  const [deProvisionDirty, setDeProvisionDirty] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");

  useEffect(() => {
    if (provisioning?.isActiveEdit && !groupDeprovisioning?.isInitialized) {
      if (provisionDetails) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            groupDeprovisioning: {
              ...groupDeprovisioning,
              isInitialized: true,
            },
          }),
        );
        const groupDeProvisionData = getProvisionDetails(provisionDetails?.endPointResponseDtos, ["delete_group"], "groupDeProvision");
        if (groupDeProvisionData) {
          dispatch(
            setDeProvisioning({
              ...deProvisioningDetails,
              groupDeProvision: groupDeProvisionData,
            }),
          );
        }
      }
    }
  }, [provisionDetails]);

  useEffect(() => {
    // Revalidate the form on language change
    if (formRef.current) {
      formRef.current.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleSubmit = (values: deProvisioningRootState) => {
    if (deProvisionDirty || groupDeprovisioning?.apiError) {
      setLoader(true);
      createPayloadConnectorConfigure(values);
      dispatch(
        setDeProvisioning({
          ...deProvisioningDetails,
          groupDeProvision: values,
        }),
      );
    } else {
      dispatch(setActiveStep(4));
    }
  };

  const createPayloadConnectorConfigure = async (values: deProvisioningRootState) => {
    const deProvisionCreatePayload = {
      apiEndpointUrl: values.apiEndpointURL,
      methodType: values.methodType,
      endpointDescription: groupEndPointDescriptionPayload(values.methodType),
      requestPayload: values?.requestPayload || null,
      sampleResponse: values?.response || null,
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      headerParameters: transformHeaderParameters(values?.headerParam),
      pathVariable: transformHeaderParameters(values?.pathVariable),
      isTenantAdmin: isTenantAdmin,
      akkuProvisioningConnectorEndpointConfigId:
        akkuDeProvisionConnectorEndpointConfigId?.groupDeProvision || values?.akkuProvisioningConnectorEndpointConfigId,
    };
    const deProvisionPayloadUpdate = {
      apiEndpointUrl: values?.apiEndpointURL,
      methodType: values?.methodType,
      endpointDescription: groupEndPointDescriptionPayload(values?.methodType),
      requestPayload: values?.requestPayload || null,
      sampleResponse: values?.response || null,
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      headerParameters: transformHeaderParameters(values?.headerParam),
      pathVariable: transformHeaderParameters(values?.pathVariable),
      akkuProvisioningConnectorEndpointConfigId:
        akkuDeProvisionConnectorEndpointConfigId?.groupDeProvision || values?.akkuProvisioningConnectorEndpointConfigId,
      isTenantAdmin: isTenantAdmin,
    };
    const isCreated = UseGetEndpointDescription("delete_group", endPointResponseDtosData);
    const shouldUpdateDeProvision = (provisioning?.isActiveEdit && isCreated) || buttonStatus?.groupDeprovisioning?.isCreated;

    if (shouldUpdateDeProvision) {
      handleUpdateDeProvisionApiCall(deProvisionPayloadUpdate);
    } else {
      handleCreateDeProvisionApiCall(deProvisionCreatePayload);
    }
  };
  const handleCreateDeProvisionApiCall = async (payload: payloadType) => {
    try {
      const response = (await deProvisioningConnectorConfigure([payload])) as AxiosResponse<deProvisioningRootState>;
      const groupDeProvisionData = getProvisionDetails(response?.data?.data, ["delete_group"], "groupDeProvision");
      if (groupDeProvisionData) {
        dispatch(
          setDeProvisionConnectorEndpointConfigId({
            ...akkuDeProvisionConnectorEndpointConfigId,
            groupDeProvision: groupDeProvisionData?.akkuProvisioningConnectorEndpointConfigId,
          }),
        );
      }
      handleApiResponse(response);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
      dispatch(
        setButtonStatus({
          ...buttonStatus,
          groupDeprovisioning: {
            ...buttonStatus?.groupDeprovisioning,
            apiError: true,
          },
        }),
      );
    }
  };

  const handleUpdateDeProvisionApiCall = async (payload: payloadType) => {
    const param = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId ?? akkuProvisioningConnectorId,
    };
    try {
      const response = (await deProvisioningConnectorConfigureUpdate([payload], {}, param)) as AxiosResponse<deProvisioningRootState>;
      handleApiResponse(response);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleRequestError(err);
      }
      setLoader(false);
      dispatch(
        setButtonStatus({
          ...buttonStatus,
          groupDeprovisioning: {
            ...buttonStatus?.groupDeprovisioning,
            apiError: true,
          },
        }),
      );
    }
  };

  const handleApiResponse = (response: ApiResponse) => {
    const status = response.status;
    if (status === 200) {
      setLoader(false);
      dispatch(
        setButtonStatus({
          ...buttonStatus,
          groupDeprovisioning: {
            ...buttonStatus?.groupDeprovisioning,
            isCreated: true,
            apiError: false,
          },
        }),
      );
      setResponseMessage(response?.data?.message);
      setSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        dispatch(setActiveStep(4));
      }, 3000);
    }
  };

  const handleBack = () => {
    setActiveKey("2");
  };

  const handleSkip = () => {
    dispatch(setActiveStep(4));
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        values={formInitialValues}
        onSubmit={(value: deProvisioningRootState) => handleSubmit(value)}
        validationSchema={connectorDeProvisionSchema(t)}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({ values, setFieldValue, dirty }) => {
          setDeProvisionDirty(dirty);
          return (
            <Form>
              <div className=" w-full flex flex-wrap flex-col gap-2 justify-center items-center">
                <DeProvisionFormFields values={values} setFieldValue={setFieldValue} formName="groupDeProvision" />
              </div>

              <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                <div className="modal-footer w-full mx-auto ">
                  <div className="w-full flex justify-end pr-5">
                    <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                    <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                    <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                      {t("common.next")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default GroupDeProvision;
