import React from "react";
import { useTranslation } from "react-i18next";

const PermissionHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex add-permission-container bg-[#F9FAFB] ">
      <div className="w-1/2">
        <h3 className=" font-Inter"> {t("roleManagement.products")}</h3>
      </div>
      <div className="w-1/2">
        <div className="w-full flex action">
          <p>{t("roleManagement.create")}</p>
          <p>{t("common.edit")}</p>
          <p>{t("roleManagement.view")}</p>
          <p>{t("common.delete")}</p>
        </div>
      </div>
    </div>
  );
};

export default PermissionHeader;
