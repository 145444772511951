import React from "react";
import { Modal } from "antd";
import ImageView from "../../component/ImageView";
import warningImg from "./../../../assets/images/user/warning.svg";
import CustomButtonBack from "../../component/CustomButtonBack";
import CustomButtonNext from "../../component/CustomButton";
import { useTranslation } from "react-i18next";

export default function DeviceTokenPhoneCheck(props: any) {
  const { handleCancel, handleProceed, open } = props;
  const { t } = useTranslation();

  return (
    <div className="closeConfirmationModal">
      <Modal className="p-10 rounded-lg  _suspended show-notification" closable={false} centered open={open} footer={false} onCancel={handleCancel} maskClosable={false}>
        <div className="pt-4 modal-body">
          <div className="flex-col flex justify-center items-center w-full">
            <ImageView src={warningImg} alt="warningIcon" className="h-20 w-20" />
            <p className="pt-4 text-[18px] text-center font-Inter">{t("userManagement.updatePhoneNumberWarning")}</p>
          </div>
        </div>
        <div className="pt-8 modal-footer">
          <div className="flex justify-center w-full">
            <CustomButtonBack onClick={handleCancel} text={t("common.cancel")} />
            <CustomButtonNext onClick={handleProceed} text={t("common.proceed")} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
