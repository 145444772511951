import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IpRestrictionType {
  ipRestrictionPayload: object;
  editRecordDetails: object;
  getParticularIpInfo: any;
  informationFromIpGetApi: any;
  storePayload: any;
}

const initialState: IpRestrictionType = {
  ipRestrictionPayload: {},
  editRecordDetails: {},
  getParticularIpInfo: [],
  informationFromIpGetApi: {},
  storePayload: {},
};

const IpRestrictionSlice = createSlice({
  name: "IpRestrictionSlice",
  initialState,
  reducers: {
    setIpPayload: (state, action: PayloadAction<any>) => {
      state.ipRestrictionPayload = action.payload;
    },
    clearIpPayload: (state) => {
      state.ipRestrictionPayload = {};
    },
    setIpEditRecord: (state, action: PayloadAction<any>) => {
      state.editRecordDetails = action.payload;
    },
    clearIpEditRecord: (state) => {
      state.editRecordDetails = {};
    },
    setParticularIpInfo: (state, action: PayloadAction<any>) => {
      state.getParticularIpInfo = action.payload;
    },
    clearParticularIpInfo: (state) => {
      state.getParticularIpInfo = [];
    },
    setInformationFromIpGet: (state, action: PayloadAction<any>) => {
      state.informationFromIpGetApi = action.payload;
    },
    clearInformationFromIpGet: (state) => {
      state.informationFromIpGetApi = {};
    },
    setPayloadForTimeAndIp: (state, action: PayloadAction<any>) => {
      state.storePayload = action.payload;
    },
    clearPayloadForTimeAndIp: (state) => {
      state.storePayload = {};
    },
  },
});

export const {
  setIpPayload,
  clearIpPayload,
  setIpEditRecord,
  clearIpEditRecord,
  setParticularIpInfo,
  clearParticularIpInfo,
  setInformationFromIpGet,
  clearInformationFromIpGet,
  setPayloadForTimeAndIp,
  clearPayloadForTimeAndIp,
} = IpRestrictionSlice.actions;
export default IpRestrictionSlice.reducer;
