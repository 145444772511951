import React, { useState } from 'react';
import SSOStatusToggle from '../status-toggle/sso-status-toggle/SSOStatusToggle';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissionStyle, hasPermission } from '../../../../../redux/slice/permissions/permissionUtils';
import { modulePermissions } from '../../../../../const/RolePermissions';
import { setAppConfigDetails } from '../../../../../redux/slice/app-store/AppDetailsSlice';
import { storeData } from '../../../../../services/storage/Storage';
import { useNavigate } from 'react-router-dom';
import PermissionsModal from '../../../../../layouts/permissionsModal/PermissionsModal';
import { cursorNotAllowedStyle } from '../../../../../hooks/hooks';
import { useTranslation } from 'react-i18next';

export default function SSOCardOption({ handleToggle, item, index }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasCreateAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, 'create');
  const [permissionModal, setPermissionModal] = useState<boolean>(false);

  const handleConfigure = () => {
    if (hasCreateAppStorePermission) {
      dispatch(setAppConfigDetails(item?.akkuMasterClientDto));
      storeData('configureApp', 'configure', true);
      navigate('/app-store/configure-app');
    } else {
      setPermissionModal(true);
    }
  };
  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };
  return (
    <>
      {item?.akkuClientDto ? (
        <SSOStatusToggle handleToggle={handleToggle} item={item} index={index} />
      ) : (
        <p
          className={`app-configure-btn-sso font-Inter text-[#fff] hover:bg-[#7c6ae6]  ${cursorNotAllowedStyle(hasCreateAppStorePermission)}`}
          onClick={handleConfigure}
          role="none"
        >
          {t('appManagement.configure')}
        </p>
      )}

      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
    </>
  );
}
