import React, { useState } from "react";
import icon from "./../../../../../assets/images/icons/dragIcon.svg";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { useSelector } from "react-redux";
import { retrieveData } from "../../../../../services/storage/Storage";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { ErrorMessages } from "../../../../../const/Messages";
import { useTranslation } from "react-i18next";

const DragAndDrop: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const keycloak = useSelector((state: any) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const realmId = retrieveData("realmId", true);
  const [file, setFile] = useState<any>(null);
  const [errorMessages, setErrorMessages] = useState("");
  const [isActiveUploadContent, setIsActiveUploadContent] = useState(true);

  const userCSVImportApi = useGetApiRequests("userCSVImport", "POST");

  const createPayload = (info: any, drag: boolean = false) => {
    if (info) {
      setFile(info);
      const formData = new FormData();
      formData.append("name", info.name);
      formData.append("file", info);
      requestUpload(formData);
    }
  };

  const requestUpload = async (formData: any) => {
    setIsActiveUploadContent(false);
    const payload = {
      userId: userId,
      realmId: realmId,
    };

    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const response: any = await userCSVImportApi(formData === "" ? {} : formData, { UserCsvRequestDtoStr: payload }, {}, headers);
      const status = response.status;
      if (status === 200) {
        const data = response?.data;
        setTimeout(() => {
          triggerNotification("success", "", data?.message, "topRight");
        }, 2000);
        setTimeout(() => {
          setIsActiveUploadContent(true);
          navigate("/user");
        }, 3000);
      }
    } catch (err: any) {
      setIsActiveUploadContent(true);
    }
  };

  const onDrop = (info: any) => {
    const { size, type } = info[0];
    const isCSV = type === "text/csv";
    const isLt20M = size / 1024 / 1024 < 20;

    if (!isCSV) {
      setErrorMessages(t("userManagement.csvUpload.uploadCsvFile"));
      return;
    }
    if (!isLt20M) {
      setErrorMessages(t("userManagement.csvUpload.csvFileLimit"));
      return;
    }
    createPayload(info[0], false);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div className="dragger">
      <div {...getRootProps()} className="dragAndDrop-container cursor-pointer text-center p-5 justify-center items-center flex">
        <input {...getInputProps()} />
        {isActiveUploadContent ? (
          <div>
            <div className="ant-upload-drag-icon w-full flex justify-center">
              <div>
                <img src={icon} alt="icon" />
              </div>
            </div>
            <p className="ant-upload-text">
              <span>{t("userManagement.csvUpload.clickToUploadOr")} </span> {t("userManagement.csvUpload.dragAndDrop")}
            </p>
            <p className="ant-upload-hint">{t("userManagement.csvUpload.fileFormat")}</p>
            {errorMessages && <p className="error-message text-center left-[50%] translate-x-[-50%]">{errorMessages}</p>}
          </div>
        ) : (
          <div className="processingData">
            <p className="processingDataText">{t("userManagement.csvUpload.processing")}</p>
            <div className="processingDataFile-container flex items-center justify-center">
              <div className="processingDataFile flex items-center mt-2 gap-4">
                <span className="material-symbols-outlined">hourglass_bottom</span> <span className="fileName">{file?.name}</span>
              </div>
            </div>
            <p className="processingDataHint mt-2">{t("userManagement.csvUpload.waitingMessage")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DragAndDrop;
