import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo, useCallback } from "react";
import { UserContextProps } from "./UserContextInterface";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { createTableObject } from "../adduser/usertable/CustomTableUtils";
import { retrieveData, storeData } from "../../../../../services/storage/Storage";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { setCustomerDetailsDefaultPassword } from "../../../../../redux/slice/user-basic-details/UserBasicDetailsSlice";

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const realmId = retrieveData("realmId", true);
  const realmName = retrieveData("realmName", true);
  const dispatch = useDispatch();
  const userManagement = useGetApiRequests("userManagement", "POST");
  const getGroups = useGetApiRequests("getGroupsList", "GET");
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");
  const [customerOriginalTableData, setCustomerOriginalTableData] = useState<any[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const isActive = urlParams.get("isActive");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number | undefined>(10);
  const [search, setSearch] = useState<string>("");
  const [userList, setUserList] = useState<any>(null);
  const [totalRecords, setTotalRecords] = useState<any>(null);
  const [statusFilter, setStatusFilter] = useState<any>(isActive ? "active" : "all");
  const toggleDashboard = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const reloadGroupApi = useSelector((state: any) => state?.UserDashboardUpdateSlice?.groupsFilterReload);
  const userManagementLoader = useSelector((state: any) => state?.userManagementSlice?.userManagementLoader);
  const [loader, setLoader] = useState<boolean>(userManagementLoader);
  const [groupList, setGroupList] = useState<any>([]);

  useEffect(() => {
    getFilterData(currentPage, pageSize);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleDashboard]);
  useEffect(() => {
    getUserGroupForFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadGroupApi]);
  useEffect(() => {
    getCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUserGroupForFilter = async (searchValue?: string, toLoad?: boolean) => {
    const queryParams: any = {
      realmName: retrieveData("realmName", true),
      limit: !reloadGroupApi ? 10 : retrieveData("totalGroupsCount", true),
      start: 0,
    };
    try {
      const response: any = await getGroups("", queryParams);
      const status = response.status;
      if (status === 200) {
        const groupData = response?.data?.data.map((item: any) => ({
          label: item?.name,
          value: item?.name,
        }));
        storeData("totalGroupsCount", response?.data?.meta?.totalRecords, true);
        const noGroup = { label: "All Group", value: "" };
        groupData.push(noGroup);
        setGroupList(groupData);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setSearch(searchValue);
    // Debounce only the API call
    debouncedGetFilterData(searchValue);
  };
  const getCustomerDetails = async () => {
    const pathParams = {
      name: realmName,
    };
    try {
      const response: any = await getCustomerDetailsApi("", {}, pathParams);

      const status = response.status;
      if (status === 200) {
        const defaultPassword = response?.data?.data?.customerGeneralDetailsDto?.defaultPassword;
        dispatch(setCustomerDetailsDefaultPassword(defaultPassword));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Debounced API call function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetFilterData = useCallback(
    debounce((searchValue) => {
      if (searchValue === "" && currentPage !== 0) {
        let searchIndex = currentPage - 1;
        getFilterData(searchIndex * 10, pageSize, "", searchValue, true);
      } else {
        getFilterData(0, pageSize, "", searchValue, true);
      }
    }, 800),
    [],
  );

  const handlePaginationChange = (pageNumber: number, pageSize?: number | undefined) => {
    let currentPage = pageNumber - 1;
    const pageSizeValue: number = pageSize ?? 1;
    let finalPage = 1;
    if (currentPage > 0) {
      finalPage = currentPage * pageSizeValue;
    } else if (currentPage === 0) {
      finalPage = 0;
    }

    setCurrentPage(pageNumber);
    setPageSize(pageSize);
    getFilterData(finalPage, pageSize);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const getFilterData = async (currentPage: number, pageSize: number | undefined, statusFilterParam?: string, searchFilter?: string, syncButton?: boolean) => {
    setLoader(true);
    const payload = {
      realmId: realmId,
      sorting: "name_asc",
      statusFilter: statusFilterParam && statusFilterParam !== "" ? statusFilterParam : statusFilter,
      searchFilter: searchFilter ? searchFilter.toLowerCase() : "",
      startValue: currentPage,
      limitValue: pageSize,
    };

    try {
      const response: any = await userManagement(payload);
      const status = response.status;

      if (status === 200) {
        const data = response?.data?.data;
        const storeTableData: any = createTableObject(data);
        setCustomerOriginalTableData(storeTableData);
        setUserList(data);
        setTotalRecords(response?.data?.meta?.totalRecords);
        storeData("totalUserRecords", response?.data?.meta?.totalRecords, true);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const contextValue = useMemo(
    () => ({
      customerOriginalTableData,
      currentPage,
      pageSize,
      loader,
      search,
      userList,
      statusFilter,
      setCustomerOriginalTableData,
      setCurrentPage,
      setPageSize,
      setLoader,
      setSearch,
      setUserList,
      setStatusFilter,
      handleSearch,
      handlePaginationChange,
      handleChangePage,
      getFilterData,
      totalRecords,
      groupList,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerOriginalTableData, currentPage, pageSize, loader, search, userList, statusFilter, groupList],
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }

  return context;
};
