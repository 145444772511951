import React, { useState } from "react";
import Chart from "react-apexcharts";
import { processData } from "./MFAHelper";
import { images } from "../../../assets/images/ImagePaths";
import ApplicationDetailsModal from "../application/ApplicationDetailsModal";
import { useTranslation } from "react-i18next";

const ChartMFA = ({ data }: any) => {
  const { t } = useTranslation();
  // English labels for setting selected application
  const englishMFAChartData = [{ type: "Touch ID" }, { type: "Authenticator" }, { type: "Face ID" }, { type: "Email" }, { type: "Mobile Push" }, { type: "SMS" }];

  const MFAChartData = [
    { type: t("dashboard.touchId") },

    { type: t("dashboard.authenticator") },

    { type: t("dashboard.faceId") },

    { type: t("dashboard.email") },

    { type: t("dashboard.mobilePush") },

    { type: t("dashboard.sms") },
  ];

  const seriesFromProcessData = processData(data);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = useState<any>();
  const [selectedApplicationName, setSelectedApplicationName] = useState<string>();
  // Mapping for icons
  const additionalText: { [key: string]: string } = {
    [t("dashboard.touchId")]: `<img src=${images.thumbIcon} className="material-symbols-outlined">`,
    [t("dashboard.authenticator")]: `<img src=${images.qrCodeIcon} className="material-symbols-outlined">`,
    [t("dashboard.faceId")]: `<img src=${images.faceIcon} className="material-symbols-outlined">`,
    [t("dashboard.email")]: `<img src=${images.mailIcon} className="material-symbols-outlined">`,
    [t("dashboard.mobilePush")]: `<img src=${images.phoneIcon} className="material-symbols-outlined">`,
    [t("dashboard.sms")]: `<img src=${images.smsIcon} className="material-symbols-outlined">`,
  };
  const chartOptions: any = {
    options: {
      labels: MFAChartData.map((row: any) => row?.type),
      colors: ["#1984A3", "#46D39A", "#6E4F7B", "#867FDA", "#EBD588", "#FC9D5A"],
      chart: {
        width: 300,
        type: "pie",
        events: {
          dataPointSelection: function (event: any, chartContext: any, config: any) {
            const dataPointIndex = config.dataPointIndex;
            const selectedData = MFAChartData[dataPointIndex];
            const selectedDataInEnglish = englishMFAChartData[dataPointIndex]; // Set the selected application in English for payload
            setSelectedApplicationName(selectedDataInEnglish?.type);
            setSelectedApplication(selectedData?.type);
            setIsActive(true);
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: "100%",
            labels: {
              show: false,
            },
          },
          animate: false,
        },
      },
      stroke: {
        width: 0,
      },
      tooltip: {
        y: {
          formatter: function (value: any, { series }: any) {
            if (!series) return `${value} %`;
            const total = series.reduce((acc: any, val: any) => acc + val, 0);
            const percentage = ((value / total) * 100).toFixed(2);
            return `${value} (${percentage}%)`;
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 100,
        offsetY: 100,
        textAnchor: "middle",
        style: {
          fontSize: "13px",
        },
      },
      legend: {
        show: true,
        fontSize: "20px",
        color: "#545263",
        formatter: function (seriesName: any) {
          return (additionalText[seriesName] || "") + seriesName;
        },
      },
      responsive: [
        {
          breakpoint: 1700,
          options: {
            chart: {
              width: 500,
            },
          },
        },
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 550,
            },
          },
        },
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: 550,
            },
          },
        },
        {
          breakpoint: 1250,
          options: {
            chart: {
              width: 500,
            },
            legend: {},
          },
        },
        {
          breakpoint: 767,
          options: {
            chart: {
              width: 350,
            },
          },
        },
      ],
    },
  };
  const handleClose = () => {
    setIsActive(false);
  };
  return (
    <>
      <Chart options={chartOptions?.options} series={seriesFromProcessData} type="pie" width="500" className="_chart-custom" />
      {isActive && <ApplicationDetailsModal isActive={isActive} handleClose={handleClose} selectedApplication={selectedApplication} active selectedApplicationName={selectedApplicationName} />}
    </>
  );
};
export default ChartMFA;
