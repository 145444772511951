import React from "react";
import { ErrorMessage, Field } from "formik";
import { Input, Select, Tooltip } from "antd";
import { helpMessage } from "./sessionIdleFormhelper";
import { useTranslation } from "react-i18next";
const LoginSettingForm = (props: any) => {
  const { handleOnChange, setFieldTouched, errors, handleBlurEvent, setFieldValue } = props;
  const userAttributes = ["Min"];
  const { t } = useTranslation();

  return (
    <div className="w-full ">
      <p className="font-Inter font-semibold text-[24px] pb-7">{t("tenants.loginSettings")} </p>
      <div className="sso-login w-full ">
        <div className="w-[50%] flex pt-[10px] flex-col sso-container">
          <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
            <div className="mb-10 !w-[40%] mr-5 flex  items-center sso-container-field idle relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium min-w-[230px]">
                {t("tenants.loginTimeout")}*
                <Tooltip title={t("tenants.loginTimeoutDescription")}>
                  <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                </Tooltip>
              </p>
            </div>
            <div className="mb-10 w-[50%] minute relative flex gap-x-3">
              <div className="sso-input">
                <Field
                  onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                  as={Input}
                  name="loginTimeout"
                  className="h-[48px] font-medium text-[18px] w-[150px]"
                  type="number"
                  data-testid="loginTimeout-input"
                />
                <ErrorMessage name="loginTimeout" component="div" className="error-message" />
              </div>
              <Field
                onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                as={Select}
                name={`min`}
                value="Min"
                suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                className={" w-[90px] h-[48px]  pointer-events-none "}
                onChange={(value: any) => handleOnChange(setFieldValue, "target", value)}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {userAttributes?.map((option: any) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Field>
            </div>
          </div>
        </div>
        <div className="w-[50%] flex pt-[10px] flex-col sso-container">
          <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
            <div className="mb-10 !w-[40%] mr-5 flex  items-center sso-container-field idle relative">
              <p className="text-[#1D1D24] min-w-[230px] text-[18px] font-Inter font-medium">
                {t("tenants.loginActionTimeout")}*
                <Tooltip title={t("tenants.loginActionTimeoutDescription")}>
                  <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                </Tooltip>
              </p>
            </div>
            <div className="mb-10 flex gap-x-3  minute relative w-[50%]">
              <div className="sso-input">
                <Field
                  onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                  as={Input}
                  type="number"
                  className="h-[48px] font-medium text-[18px] w-[150px]"
                  name="loginActionTimeout"
                  data-testid="loginAction-input"
                />
                <ErrorMessage name="loginActionTimeout" component="div" className="error-message" />
              </div>
              <Field
                onBlur={(e: any) => handleBlurEvent(e, setFieldTouched, errors)}
                as={Select}
                suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                name={`min`}
                className={" w-[90px] h-[48px] pointer-events-none"}
                value="Min"
                onChange={(value: any) => handleOnChange(setFieldValue, value, "target")}
                filterOption={(option: React.ReactElement, input: string) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {userAttributes?.map((option: any) => (
                  <Select.Option value={option} key={option}>
                    {option}
                  </Select.Option>
                ))}
              </Field>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSettingForm;
