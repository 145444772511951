import React, { useEffect, useRef } from "react";
import { Button, DatePicker, Input, Select, Tooltip } from "antd";
import AuditLogTable from "./auditLog-table/AuditLogTable";
import { Formik, Form, Field, FieldProps, ErrorMessage, FormikValues, FormikProps } from "formik";
import { useAuditLogContext } from "./context/AuditLogContext";
import { userTypeList } from "./UserAuditHelper";
import * as Yup from "yup";
import "./userAuditLog.scss";
import { useTranslation } from "react-i18next";
interface AuditLogContextProps {
  logsActionTypes: Array<{ label: string; value: string }>;
  tableDataLists: any[];
  currentPage: number;
  sizeChanger: number;
  loader: boolean;
  handleAuditLogsDownload: () => void;
  handleGetUserAuditDetails: (values: object, currentPage: number, sizeChanger: number) => void;
  setFormValue: (values: object) => void;
  formValue: {};
}

export default function UserAuditLogContainer() {
  const { t, i18n } = useTranslation();
  const auditLogValidationSchema = Yup.object().shape({
    userType: Yup.string().required(t("auditLogs.userTypeValidation")),
  });
  const {
    logsActionTypes,
    tableDataLists,
    currentPage,
    sizeChanger,
    loader,
    handleAuditLogsDownload,
    handleGetUserAuditDetails,
    setFormValue,
    formValue,
  } = useAuditLogContext() as AuditLogContextProps;
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const initialValues = {
    search: "",
    actionType: [],
    dateRange: [] as any[],
    ipAddress: "",
    userType: "user",
  };

  const { RangePicker } = DatePicker;

  const handleSubmit = (values: object) => {
    handleGetUserAuditDetails(values, currentPage, sizeChanger);
  };

  return (
    <div className="akku-container user-auditLog ">
      <div className="main-container auditLog">
        <div className="dashboard-container rounded !p-0">
          <div className="auditLog-title relative flex justify-between items-center">
            <h2 className="flex items-center mt-4"> {t("auditLogs.userAuditLogs")}</h2>

            <Tooltip title={t("auditLogs.downloadUserAuditLogs")}>
              <Button
                disabled={!tableDataLists?.length}
                onClick={handleAuditLogsDownload}
                type="primary"
                className="accessManger-addBtn w-[44px] h-[44px] bg-[#5441DA]"
              >
                <span className="material-symbols-outlined">download </span>
              </Button>
            </Tooltip>
          </div>
          <div className="auditLog-filters">
            <Formik
              initialValues={initialValues}
              validationSchema={auditLogValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              innerRef={formikRef}
            >
              {({ resetForm, setFieldValue, values, errors }) => {
                return (
                  <Form>
                    <div className="flex justify-between w-[100%] gap-10 flex-wrap xl:flex-nowrap">
                      <div className="filter w-full">
                        <p className="label">{t("common.search")}</p>
                        <Field name="search">
                          {({ field }: FieldProps) => <Input {...field} placeholder={t("common.enter")} className="w-[100%] h-[48px]" />}
                        </Field>
                      </div>

                      <div className="filter xl:w-full w-[20%]">
                        <p className="label">{t("auditLogs.actionType")}</p>
                        <Field name="actionType">
                          {({ field }: FieldProps) => (
                            <Select
                              {...field}
                              mode="multiple"
                              className="w-[100%] h-[48px]"
                              allowClear
                              placeholder={t("common.select")}
                              onChange={(value) => setFieldValue("actionType", value)}
                              options={logsActionTypes}
                            />
                          )}
                        </Field>
                      </div>

                      <div className="filter min-w-[330px] xl:w-full w-[20%]">
                        <p className="label">{t("auditLogs.dateRange")}</p>
                        <Field name="dateRange">
                          {({ field }: FieldProps) => (
                            <RangePicker
                              className="w-[100%] h-[48px]"
                              onChange={(value) => setFieldValue("dateRange", value)}
                              value={field.value} // Syncing with Formik state
                            />
                          )}
                        </Field>
                      </div>

                      <div className="filter xl:w-full w-[20%]">
                        <p className="label">{t("auditLogs.ipAddress")}</p>
                        <Field name="ipAddress">
                          {({ field }: FieldProps) => <Input {...field} placeholder={t("common.enter")} className="w-[100%] h-[48px]" />}
                        </Field>
                      </div>

                      <div className="filter xl:w-full w-[20%]">
                        <p className="label">{t("auditLogs.userType")}*</p>
                        <Field name="userType">
                          {({ field }: FieldProps) => (
                            <Select
                              {...field}
                              className="w-[100%] h-[48px]"
                              placeholder={t("common.select")}
                              options={userTypeList}
                              value={values?.userType || undefined}
                              onChange={(value) => {
                                setFieldValue("userType", value);
                                setFieldValue("actionType", []);
                                setFormValue({ ...formValue, userType: value });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="userType" component="div" className="error-message" />
                      </div>
                    </div>

                    <div className={`auditLog-filtersButton ${errors?.userType && "mt-16"}`}>
                      <Button
                        className="clear-btn"
                        size="large"
                        onClick={() => {
                          resetForm();
                          setFieldValue("dateRange", null);
                        }}
                      >
                        {t("common.clear")}
                      </Button>
                      <Button className="submit-btn" size="large" type="primary" htmlType="submit" loading={loader}>
                        {t("common.submit")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="auditLog-table">
            <AuditLogTable />
          </div>
        </div>
      </div>
    </div>
  );
}
