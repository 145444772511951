import React, { useEffect, useState } from "react";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { ErrorMessage, Field, Form, Formik } from "formik";
import "./RegisterForm.scss";
import { Button, Checkbox, Input, CheckboxProps } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { validationSchema, initialValue } from "./schema/ValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData, storeData } from "../../../services/storage/Storage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { apiResponseMessage } from "../../../constant/apiResponseMessage";
import Loader from "../../../layouts/component/Loader";
import { setRegisterResponse } from "../../../redux/slice/RegisterResponse";

const RegisterForm = () => {
  const createCustomer = useGetApiRequests("createCustomer", "POST");
  const initialValues = initialValue;
  const [checked, setChecked] = useState(false);
  const featuredList = useSelector((state: any) => state?.FeatureListSlice?.featuredList);
  const [totalLicense, setTotalLicense] = useState();
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    const totalLicense = retrieveData("totalLicense", true);
    setTotalLicense(totalLicense);
  }, []);

  const onChange: CheckboxProps["onChange"] = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleChange = (field: any, value: string, setFieldValue: any) => {
    setFieldValue(field, value);
  };

  const handleSubmit = async (values: any) => {
    let totalLicenses = {
      totalLicense: totalLicense,
    };
    const number = values.primaryMobileNumber;
    storeData("phoneNumber", number, true);
    let payload = { ...values, ...featuredList, ...totalLicenses };
    try {
      const response: any = await createCustomer(payload);
      const status = response.status;
      if (status === 201) {
        setLoader(false);
        dispatch(setRegisterResponse(response?.data?.data));
        navigate("/verify-email");
      }
    } catch (err: any) {
      setLoader(false);
      triggerNotification("error", "", apiResponseMessage.createCustomerFailed, "topRight");
    }
  };
  const getButtonStatus = (dirty: boolean, checked: boolean) => {
    if (dirty && checked) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="h-full overflow-y-auto">
      {loader && <Loader />}
      <div className="w-full flex-col register pt-10 ">
        <div className="w-full flex flex-col justify-center items-center logo">
          <ImageView src={images.registerLogo} className="w-[100px]" />
          <p className="text-[#777]  pt-5 hidden font-Inter font-medium text-[16px]">No credit card required.</p>
        </div>
        <div className="px-[10px]">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values, dirty, setFieldValue }) => {
              return (
                <Form>
                  <div className="w-[85%]  flex mx-auto justify-between flex-wrap _edit">
                    <div className="w-full mx-auto pb-4">
                      <p className="font-Inter text-[32px] font-semibold">Register</p>
                    </div>
                    <div className="mb-10 w-[50%] pr-4">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">First Name*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" placeholder="Enter your first name" name="firstName" data-testid='fnameId' />
                      <ErrorMessage name="firstName" component="div" className="error-message" />
                    </div>
                    <div className="mb-10 w-[50%] ">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Last Name*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" placeholder="Enter your last name" name="lastName" data-testid='lnameId'  />
                      <ErrorMessage name="lastName" component="div" className="error-message" />
                    </div>

                    <div className="mb-10 w-[100%]">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Work Email*</p>
                      <Field as={Input} type="email" className="h-[56px] font-medium text-[18px]" name="primaryEmail" placeholder="Enter  Email ID" data-testid='emailId'  />
                      <ErrorMessage name="primaryEmail" component="div" className="error-message" />
                    </div>
                    <div className="mb-10 w-[100%]">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid='mobileId' >Mobile Number*</p>
                      <PhoneInput
                        inputClass="ant-input h-[48px] w-[100%]"
                        country={"us"}
                        value={values.primaryMobileNumber}
                        onChange={(value) => handleChange("primaryMobileNumber", value, setFieldValue)}
                
                      />
                      <ErrorMessage name="primaryMobileNumber" component="div" className="error-message" />
                    </div>
                    <div className="mb-0 w-[100%]">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">Create Your Site*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="customerName" placeholder="Enter your domain name" data-testid='dnameId'  />
                      <ErrorMessage name="customerName" component="div" className="error-message" />
                    </div>
                    <p className="text-[#777] pt-8 font-Inter font-medium text-[16px]">Your website will be at https://{values.customerName}.akku.work,you can change this later.</p>
                    <div className="mb-4 pt-10">
                      <div className="checkbox-item w-full flex items-center ju">
                        <Checkbox checked={checked} onChange={(e) => onChange(e)}></Checkbox>
                        <p className="text-[#171717] pl-2 font-Inter font-medium text-[16px]">
                          I agree to the
                          <a className="text-[#5441DA] underline mx-1" href="#">
                            Terms of service
                          </a>
                          and
                          <a href="" className="text-[#5441DA] underline mx-1">
                            Privacy policies.
                          </a>
                        </p>
                      </div>
                    </div>
                    <Button disabled={getButtonStatus(dirty, checked)} type="primary" htmlType="submit" className=" w-full ant-select-btn  bg-[#5441DA] ">
                      Start Your Free Trial
                    </Button>
                  </div>
                  <div></div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
