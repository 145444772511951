import { Avatar, Tooltip } from "antd";
import React from "react";
import SyncButton from "../../../../../../../layouts/component/SyncButton";
import { useDispatch, useSelector } from "react-redux";
import { setDeviceRestrictionList } from "../../../../../../../redux/slice/DeviceRestrictionSlice";
import { setListOfRestrictionModal } from "../../../../../../../redux/slice/Users/UserDashboardUpdateSlice";

import ImageView from "../../../../../../../layouts/component/ImageView";
import { useTranslation } from "react-i18next";

export default function Restrictions({ value, record, setActivateModal, setPermissionModal }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessManagerPermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const accessManagerCreate = accessManagerPermissions?.["Access Manager"]?.create;

  const handleAddRestriction = (value: any) => {
    if (accessManagerCreate) {
      const selectedUserKey: any = [];
      selectedUserKey.push(value);
      dispatch(setDeviceRestrictionList(selectedUserKey));
      dispatch(setListOfRestrictionModal(true));

      setActivateModal(true);
    } else {
      setPermissionModal(true);
    }
  };
  const checkingRestriction = (restrictions: any) => {
    const hasRestriction = restrictions?.userRestrictionsList?.every((item: any) => item.restrictionStatus === false);
    return hasRestriction;
  };
  return (
    <div className="flex justify-center">
      <Tooltip title={t("userManagement.deviceAuthorization.deviceAuthorization")} placement="top">
        {checkingRestriction(value) ? (
          <SyncButton record={value} disabled={accessManagerCreate} icon="add" loadings={null} onClick={() => handleAddRestriction(value)} />
        ) : (
          <Avatar.Group size="large" maxCount={3}>
            {value?.userRestrictionsList?.map(
              (elm: any) =>
                elm.restrictionStatus && (
                  <Tooltip key={elm?.client_name} title={t("userManagement.deviceAuthorization.deviceAuthorization")} placement="top">
                    <Avatar className="user-logo">
                      <ImageView className="" src={elm?.iconPath} alt="avatar.img" />
                    </Avatar>
                  </Tooltip>
                ),
            )}
          </Avatar.Group>
        )}
      </Tooltip>
    </div>
  );
}
