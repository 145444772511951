export const apiResponseMessage = {
  suspendFailed: "Failed to suspend user. Please try again later.",
  deletedFailed: "Failed to delete user. Please try again later.",
  ladapNameAlreadyExists: "Ldap name already exists",
  syncFailed: "The sync process has failed, please contact support for assistance.",
  alreadySynced: "User already synced",
  failedToFetchCertificateDetails: "Failed to fetch certificate details",
  regenerateFailed: "Regenerate secret key is failed ",
  regenerateSuccess: "Secret Key regenerated successfully",
  failedFetchApps: "Failed to fetch application details",
  createCustomerFailed: "Registration failed",
  getPlanFailed: "Failed to fetch plan details",
  uploadFailed: "upload logo failed",
  tryAging: "Please try again or contact support if the issue persists.",
};
