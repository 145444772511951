import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FinalReviewUserDataAccordionRoles = ({ roleAttributeDetails }: any) => {
  const { t } = useTranslation();

  const provisioning = useSelector((state: any) => state?.provisioning);

  return (
    <div className="relative">
      <div className="flex w-full px-5 mx-auto flex-wrap review  ">
        <div className="w-full flex pb-4 pt-8  justify-evenly gap-x-10">
          <div className=" flex text-[20px] w-[30%] ">
            <p className="content-header"> {t("appManagement.provisioning.sourceAkku")}</p>
          </div>
          <div className="w-[40%]">
            <p className="content-header">
              {t("appManagement.provisioning.target")}-{provisioning?.generalDetails?.name}
            </p>
          </div>
        </div>
        <div className="w-full modal-list ">
          {roleAttributeDetails?.map((item: any, index: number) => (
            <div className="w-full flex border-b-2 py-3 justify-evenly gap-x-10  text-[#1D1D24]" key={item?.source}>
              <div className="w-[30%]  ">
                <p className="font-Inter content-description text-[20px] font-medium">{item?.source}</p>
              </div>
              <div className="w-[40%]">
                <p className="font-Inter content-description text-[20px] font-medium">{item?.target}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinalReviewUserDataAccordionRoles;
