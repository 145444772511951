import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import SingleUploadDirectory from "../add-user/layouts/single-upload/directory/SingleUploadDirectory";
import "./UserHeader.scss";
const SingleUserModal = (props: any) => {
  const { handelClose, open } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const handleCloseModal = () => {
    setIsOpen(open);
    handelClose();
  };
  return (
    <Modal className=" add-user single " maskClosable={false} centered open={isOpen} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body rounded-lg">
        <SingleUploadDirectory handleCloseModal={handleCloseModal} />
      </div>
    </Modal>
  );
};

export default SingleUserModal;
