import * as Yup from "yup";

export const createAppSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(t("appManagement.provisioning.errors.requiredConnectorName")),
    description: Yup.string().required(t("appManagement.provisioning.errors.requiredDescription")),
    tokenType: Yup.string().required(t("appManagement.provisioning.errors.requiredTokenType")),
    type: Yup.string().required(t("appManagement.provisioning.errors.requiredConnectorType")),
    authenticationType: Yup.string().when("type", {
      is: (value: any) => value === "RESTAPI",
      then: (schema) => schema.required(t("appManagement.provisioning.errors.requiredAuthType")),
      otherwise: (schema) => schema,
    }),
    // Uncomment and translate additional fields as needed
    // spDomain: Yup.string().required(t("SP Domain type is required")),
    // connectorURL: Yup.string().required(t("Connector URL is required")).url(t("Invalid URL")),
    // userName: Yup.string().required(t("User name is required")),
    // password: Yup.string().required(t("Password is required")),
    // provisionURL: Yup.string().required(t("Provisioning URL is required")).url(t("Invalid URL")),
    // deprovisionURL: Yup.string().required(t("Deprovisioning URL is required")).url(t("Invalid URL")),
  });

export const createAppPublishSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().required(t("appManagement.provisioning.errors.requiredConnectorName")),
    description: Yup.string().required(t("appManagement.provisioning.errors.requiredDescription")),
    tokenType: Yup.string().required(t("appManagement.provisioning.errors.requiredTokenType")),
    type: Yup.string().required(t("appManagement.provisioning.errors.requiredConnectorType")),
    authenticationType: Yup.string().when("type", {
      is: (value: any) => value === "RESTAPI",
      then: (schema) => schema.required(t("appManagement.provisioning.errors.requiredAuthType")),
      otherwise: (schema) => schema,
    }),
    // Uncomment and translate additional fields as needed
    // spDomain: Yup.string().required(t("SP Domain type is required")),
    // connectorURL: Yup.string().required(t("Connector URL is required")).url(t("Invalid URL")),
    // userName: Yup.string().required(t("User name is required")),
    // password: Yup.string().required(t("Password is required")),
    // provisionURL: Yup.string().required(t("Provisioning URL is required")).url(t("Invalid URL")),
    // deprovisionURL: Yup.string().required(t("Deprovisioning URL is required")).url(t("Invalid URL")),
  });

export const createAppPublishSchemaa = Yup.object().shape({
  name: Yup.string().required("Connector name is required"),
  description: Yup.string().required("Description is required"),
  type: Yup.string().required("Connector type is required"),
  tokenType: Yup.string().required("Token Type type is required"),
  authenticationType: Yup.string().when("type", {
    is: (value: string | undefined) => value === "RESTAPI",
    then: (schema) => schema.required("Authentication Type is required"),
    otherwise: (schema) => schema,
  }),
  // connectorURL: Yup.string().required("Connector URL is required").url("Invalid URL"),
  // userName: Yup.string().required("User name is required"),
  // password: Yup.string().required("Password is required"),
  // provisionURL: Yup.string().required("Provisioning URL is required").url("Invalid URL"),
  // deprovisionURL: Yup.string().required("Deprovisioning URL is required").url("Invalid URL"),
});
