import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
  ActionTypeItem,
  ActionTypesInterface,
  AuditLogContextType,
  AuditLogsActionTypesResponseItem,
  DownloadPayloadInterface,
} from '../AuditLogTypes';
import useGetApiRequests from '../../../services/axios/useApiRequests';
import { handleRequestError } from '../../../layouts/toast/ErrorNotificationMessage';
import { retrieveData } from '../../../services/storage/Storage';
import { triggerNotification } from '../../../layouts/toast/ToastBar';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AuditLogPayloadInterface } from '../UserAuditHelper';

const AuditLogContext = createContext<AuditLogContextType | undefined>(undefined);

export const AuditLogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const keycloak = useSelector((state: any) => state?.keycloak);

  const getAuditLogsUserTypesApi = useGetApiRequests('getAuditLogsUserTypes', 'GET');
  const auditLogsDownload = useGetApiRequests('auditLogDownload', 'POST');
  const getUserAuditLogs = useGetApiRequests('getUserAuditLogs', 'POST');

  const realmId = retrieveData('realmId', true);
  const userId: string = keycloak?.KeycloakInfo?.subject || '';
  const initialValues = {
    search: '',
    actionType: [],
    dateRange: [] as any[],
    ipAddress: '',
    userType: 'user',
  };
  const [logsActionTypes, setLogsActionTypes] = useState<ActionTypeItem[]>([]);
  const [tableDataLists, setTableDataLists] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sizeChanger, setSizeChanger] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [formValue, setFormValue] = useState({ ...initialValues });
  const [actionTypes, setActionType] = useState<ActionTypesInterface>({
    user: [],
    admin: [],
  });

  useEffect(() => {
    getActionTypes();
  }, []);

  const getActionTypes = async () => {
    try {
      const response = await getAuditLogsUserTypesApi();
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;

        const userActionList = responseData?.userActionTypes?.map((item: AuditLogsActionTypesResponseItem) => ({
          label: item,
          value: item,
        }));
        const adminActionList = responseData?.adminActionTypes?.map((item: AuditLogsActionTypesResponseItem) => ({
          label: item,
          value: item,
        }));
        const reformedActionList = {
          user: userActionList,
          admin: adminActionList,
        };
        setActionType(reformedActionList);
        setLogsActionTypes(reformedActionList?.user);
      }

      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  useEffect(() => {
    if (formValue.userType === 'user') {
      setLogsActionTypes(actionTypes.user);
    } else {
      setLogsActionTypes(actionTypes?.admin);
    }
  }, [formValue?.userType]);

  const handleAuditLogsDownload = async () => {
    const actionTypes = formValue?.actionType;
    const startDate = formValue?.dateRange?.length > 0 ? dayjs(formValue?.dateRange[0])?.format('MM/DD/YY') : null;
    const endDate = formValue?.dateRange?.length > 0 ? dayjs(formValue?.dateRange[1])?.format('MM/DD/YY') : null;
    const queryParams: object = {
      parentId: userId,
    };
    const payload: DownloadPayloadInterface = {
      realmId: realmId,
      userType: formValue?.userType,

      ...(actionTypes?.length > 0 && { actionTypes }),
      ...(startDate && { fromDate: startDate }),
      ...(endDate && { toDate: endDate }),
      ...(formValue?.search && formValue?.search !== '' && { searchTerm: formValue.search }),
      ...(formValue?.ipAddress && formValue?.ipAddress !== '' && { ipAddress: formValue.ipAddress }),
    };

    try {
      const response = await auditLogsDownload(payload, queryParams);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 201 || status === 200) {
        triggerNotification('success', '', response?.data?.message, 'topRight');
      }
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const handleGetUserAuditDetails = async (values: object | any, currentPage: number, sizeChanger: number) => {
    setFormValue({ ...formValue, ...values });
    const storeActionTypes = values?.actionType ? values?.actionType : [];
    const formattedStartDate = values?.dateRange?.length > 0 ? dayjs(values?.dateRange[0])?.format('MM/DD/YY') : null;
    const formattedEndDate = values?.dateRange?.length > 0 ? dayjs(values?.dateRange[1])?.format('MM/DD/YY') : null;

    setLoader(true);

    try {
      const payload: AuditLogPayloadInterface = {
        startValue: currentPage,
        limitValue: sizeChanger,
        realmId,
        userType: values?.userType,
        ...(storeActionTypes?.length > 0 && { actionTypes: storeActionTypes }),
        ...(formattedStartDate && { fromDate: formattedStartDate }),
        ...(formattedEndDate && { toDate: formattedEndDate }),
        ...(values?.search && { searchTerm: values.search }),
        ...(values?.ipAddress && { ipAddress: values.ipAddress }),
      };

      const response = await getUserAuditLogs(payload);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 201 || status === 200) {
        const responseData = response?.data?.data;
        setTableDataLists(responseData);
        setTotalRecords(response?.data?.meta?.totalRecords ?? 0);
      }
      setLoader(false);
    } catch (err) {
      handleRequestError(err);
      setLoader(false);
    }
  };

  const value = useMemo(
    () => ({
      logsActionTypes,
      tableDataLists,
      currentPage,
      sizeChanger,
      totalRecords,
      loader,
      formValue,
      handleAuditLogsDownload,
      handleGetUserAuditDetails,
      setCurrentPage,
      setSizeChanger,
      setFormValue,
    }),
    [logsActionTypes, tableDataLists, totalRecords, formValue],
  );

  return <AuditLogContext.Provider value={value}>{children}</AuditLogContext.Provider>;
};

export const useAuditLogContext = () => {
  const context = useContext(AuditLogContext);
  if (!context) {
    throw new Error('useAuditLogContext must be used within an AuditLogProvider');
  }
  return context;
};
