import { Button, Input, Radio, Select, TimePicker } from "antd";
import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import CustomButtonBack from "../../../../layouts/component/CustomButtonBack";
import { validationSchemaForTimeRestriction } from "./TimeRestrictionValidationSchema";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TimeParameterAddForm(props: any) {
  const { t, i18n } = useTranslation();
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const savedPayloadForTimeParam = useSelector((state: any) => state?.IpRestrictionSlice?.storePayload);
  const { key, initialValues, onSubmit, onChangingZone, zonesList, handleOnChange, handleRemoveList, timeParamAssigningTo, handleOpenConfirmationModal } = props.props;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const navigatedFrom = params.get("from");

  return (
    <div className=" mt-5 time-parameter ">
      <Formik key={key} initialValues={initialValues} validationSchema={validationSchemaForTimeRestriction(t)} onSubmit={onSubmit} enableReinitialize={false} innerRef={formikRef}>
        {({ handleSubmit, values, setFieldValue, dirty, errors }) => {
          return (
            <Form
              className=" h-full"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="w-full flex selectIP-form pt-0 flex-wrap ">
                <div className="flex flex-wrap   justify-between time-parameter-field-row w-[75%] ml-8">
                  <div className="mb-8 lg:mb-11 w-[100%] lg:w-[48%] relative left-field">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium"> {t("common.name")}*</p>
                    <Field as={Input} type="text" className="font-medium h-[48px] text-[18px]" maxLength={30} name="name" data-testid="timeRestriction-name" />
                    <ErrorMessage name="name" component="div" className="error-message" />
                  </div>
                  <div className="mb-8 lg:mb-11 w-[100%] lg:w-[48%] relative right-field">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.description")}*</p>
                    <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" maxLength={60} name="description" data-testid="timeRestriction-description" />
                    <ErrorMessage component="div" className="error-message" name="description" />
                  </div>

                  {/* new design */}
                  <FieldArray name="timeParameter">
                    {({ push, remove }) => {
                      return (
                        <>
                          {values?.timeParameter?.map((field: any, index: any) => (
                            <div className="w-full relative xl:mt-0 mt-4" key={field.id}>
                              <div className="w-full flex justify-between relative flex-wrap">
                                <div className="mb-4 lg:mb-11 left-field w-[100%] lg:w-[48%] md:w-[100%] zonesList xl:mt-0 mt-4">
                                  <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`} data-testid="timeRestriction-selectZone">
                                    {t("accessManager.selectZone")}*
                                  </p>
                                  <Field
                                    as={Select}
                                    showSearch
                                    onChange={(value: string) => onChangingZone(value, setFieldValue, index)}
                                    name={`timeParameter[${index}].timezone`}
                                    placeholder="Select Zone"
                                    optionFilterProp="children"
                                    filterOption={(input: any, option: any) => option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                    filterSort={(optionA: any, optionB: any) => optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())}
                                    className="w-full h-[48px] font-medium text-[18px]"
                                  >
                                    {zonesList?.map((option: any) => (
                                      <Select.Option key={option?.label} value={option?.label}>
                                        {option?.label}
                                      </Select.Option>
                                    ))}
                                  </Field>
                                  <ErrorMessage name={`timeParameter[${index}].timezone`} component="div" className="error-message" />
                                </div>
                                <div className="mb-4 lg:mb-11 right-field w-[100%] lg:w-[48%] xl:mt-0 mt-4">
                                  <p className={`${index === 0 ? "" : "lg:opacity-0"} text-[#1D1D24] text-[18px] font-Inter font-medium md:opacity-100`}>
                                    {" "}
                                    {t("accessManager.selectRange")}* {t("accessManager.startTime")}
                                  </p>
                                  <div className="rangePicker">
                                    {savedPayloadForTimeParam && Object?.keys(savedPayloadForTimeParam)?.length > 0 ? (
                                      <div className="w-full flex">
                                        <div className="pr-2 w-1/2">
                                          <Field name={`timeParameter[${index}].timeFrom`} className=" mr-2">
                                            {({ field }: FieldProps<any>) => (
                                              <TimePicker
                                                use12Hours
                                                onChange={(value, dateString) => {
                                                  handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                }}
                                                value={field?.value}
                                                format="hh:mm A"
                                                suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                              />
                                            )}
                                          </Field>

                                          <ErrorMessage name={`timeParameter[${index}].timeFrom`} component="div" className="error-message" />
                                        </div>
                                        <div className="w-[50%]">
                                          <Field name={`timeParameter[${index}].timeTo`}>
                                            {({ field }: FieldProps<any>) => (
                                              <TimePicker
                                                use12Hours
                                                onChange={(value, dateString) => {
                                                  handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                }}
                                                value={field?.value}
                                                format="hh:mm A"
                                                suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                              />
                                            )}
                                          </Field>
                                          <ErrorMessage name={`timeParameter[${index}].timeTo`} component="div" className="error-message" />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="flex w-full">
                                        <div className="mr-2 w-1/2">
                                          <Field name={`timeParameter[${index}].timeFrom`} className=" mr-2">
                                            {({ field }: FieldProps<any>) => {
                                              return (
                                                <TimePicker
                                                  onChange={(value, dateString) => {
                                                    handleOnChange("timeFrom", dateString, setFieldValue, value, index);
                                                  }}
                                                  use12Hours
                                                  format="hh:mm A"
                                                  suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                  value={field?.value}
                                                />
                                              );
                                            }}
                                          </Field>

                                          <ErrorMessage name={`timeParameter[${index}].timeFrom`} component="div" className="error-message" />
                                        </div>
                                        <div className="w-1/2">
                                          <Field name={`timeParameter[${index}].timeTo`}>
                                            {({ field }: FieldProps<any>) => (
                                              <TimePicker
                                                onChange={(value, dateString) => {
                                                  handleOnChange("timeTo", dateString, setFieldValue, value, index);
                                                }}
                                                suffixIcon={<span className="material-symbols-outlined pr-1">schedule</span>}
                                                use12Hours
                                                value={field?.value}
                                                format="hh:mm A"
                                              />
                                            )}
                                          </Field>
                                          <ErrorMessage name={`timeParameter[${index}].timeTo`} component="div" className="error-message" />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex absolute right-[-70px] top-7 justify-center items-center xl:mt-0 mt-4">
                                {index !== values.timeParameter.length - 1 && (
                                  <span
                                    role="none"
                                    className={`${values.timeParameter.length === 1 ? "pointer-events-none " : ""} material-symbols-outlined add-custom ip remove cursor-pointer`}
                                    onClick={() => handleRemoveList(index, remove, values.timeParameter[index])}
                                  >
                                    remove
                                  </span>
                                )}

                                {index === values.timeParameter.length - 1 && (
                                  <span className="material-symbols-outlined cursor-pointer add-custom ip" onClick={() => push({ timeTo: "", timeFrom: "", timezone: "", index: index })} role="none">
                                    add
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      );
                    }}
                  </FieldArray>
                  {/* end */}
                  {!navigatedFrom && (
                    <div className="lg:mb-11 lg:w-[98%] w-[100%] relative xl:mt-0 mt-5 mb-9">
                      <div className="lg:w-[46%] md:w-[100%]">
                        <p className="font-Inter text-[#1D1D24] font-medium text-[18px]">{t("accessManager.assignTo")}*</p>
                        <Radio.Group value={values?.assignTo} className="pt-3 flex" name="assignTo" onChange={(e: any) => timeParamAssigningTo(e.target.value, setFieldValue)}>
                          <Radio value={"organization"}>
                            <div className="flex flex-col mr-5">
                              <p className="user-type ">{t("accessManager.organization")}</p>
                            </div>
                          </Radio>
                          <Radio value="groups">
                            <div className="flex  flex-col mr-5">
                              <p className="user-type ">{t("accessManager.groups")}</p>
                            </div>
                          </Radio>
                          <Radio value="users">
                            <div className="flex  flex-col">
                              <p className="user-type ">{t("accessManager.users")}</p>
                            </div>
                          </Radio>
                        </Radio.Group>
                      </div>
                      <ErrorMessage name="assignTo" component="div" className="error-message" />
                    </div>
                  )}
                </div>
                <div className="mt-[7px] pb-[13px] flex justify-end w-full foot mr-4">
                  <CustomButtonBack text={t("common.cancel")} onClick={handleOpenConfirmationModal} />
                  <Button htmlType="submit" type="primary" className="bg-[#5441DA] w-[192px] h-[55px] font-Inter text-[20px] btn ">
                    {values?.assignTo === "organization" || navigatedFrom ? `${t("common.save")}` : `${t("common.next")}`}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
