export const MFAChartData = [{ type: "Touch ID" }, { type: "Authenticator" }, { type: "Face ID" }, { type: "Email" }, { type: "Mobile Push" }, { type: "SMS" }];

export const processData = (mfaData: any) => {
  let seriesData: number[] = [0, 0, 0, 0, 0, 0];

  const setSeriesData = (index: number, value: number) => {
    seriesData[index] = value;
  };

  MFAChartData.forEach((item: any, index: number) => {
    switch (item?.type) {
      case "Touch ID":
        setSeriesData(index, 0);
        break;
      case "Authenticator":
        setSeriesData(index, 0);
        break;
      case "Face ID":
        setSeriesData(index, 0);
        break;
      case "Email":
        const emailMfa = mfaData?.find((itemLocal: any) => itemLocal.mfaType === "EMAIL");
        if (emailMfa) {
          setSeriesData(index, emailMfa?.percentageOfMfaUsed);
        }
        break;
      case "Mobile Push":
        setSeriesData(index, 0);
        break;
      case "SMS":
        const smsMfa = mfaData?.find((itemLocal: any) => itemLocal.mfaType === "SMS");
        if (smsMfa) {
          setSeriesData(index, smsMfa?.percentageOfMfaUsed);
        }
        break;
      default:
        break;
    }
  });

  return seriesData;
};
