// validationMessages.ts

export const validationErrorMessages = {
  required: "This field is required",
  invalidEmail: "Invalid email format",
  numericCharactersNotAllowed: "Numeric characters are not allowed",
  // Field-specific messages
  firstName: {
    required: "Please enter the first name",
    invalidCharacters: "Character restriction - 30. Please enter a valid first name.",
    letterAndSpace: "Only letters and spaces are allowed in the first name",
    minLength: "Minimum length is 4 characters for the first name",
    maxLength: "Maximum length is 16 characters for the first name",
  },
  lastName: {
    required: "Please enter the last name",
    invalidCharacters: "Character restriction - 30. Please enter a valid last name.",
    letterAndSpace: "Only letters and spaces are allowed in the last name",
    minLength: "Minimum length is 1 characters for the last name",
    maxLength: "Maximum length is 16 characters for the last name",
  },
  email: {
    required: "Please enter the personal email ID",
    invalidEmail: "Please enter a valid email ID",
  },
  userType: {
    required: "User Type is required",
  },
  ldapError: {
    connectionURL: "Connection URL is required",
    bindType: "Bind type is required",
    bindDN: "Bind DN is required",
    bindCredentials: "Bind Credentials is required",
  },
  phone: "Mobile Number is required",
  domainName: "Space and symbols not allowed",
  minLength: "Minimum 4 characters for the first name",
  register: {
    required: "Site name is required",
  },

  // Add more messages as needed
};
