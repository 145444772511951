import axios from "axios";
import { Form, Formik, FormikProps } from "formik";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import { buttonRootState, ConnectorConfig, UpdateParams } from "../../types/provisioningTypes";
import { useEffect, useState } from "react";
import {
  setActiveStep,
  setIsBackButtonPressed,
  setIsProvisioningApiCalled,
  setIsProvisioningApiError,
  setProvisioning,
  setUserProvisioningConnectorEndpointConfigId,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import {
  connectorProvisionSchema,
  endPointDescriptionPayload,
  transformHeaderParameters,
  UseGetEndpointDescription,
  userProvisionErrorEvaluatorScroll,
} from "../../../helper/connectorHelper";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import { setCurrentStep } from "../../../../../../../redux/slice/app-store/AppDetailsSlice";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";

import { useTranslation } from "react-i18next";
import ProvisionFormFields from "../form/ProvisionFormFields";
import { useProvisionContext } from "../../../../../context/ProvisionConfigureContext";
import { useHandleProvisionPrerequisites } from "../../../connector/helper/ProvisioningHelper";
import { setButtonStatus } from "../../../../../../../redux/slice/provisioning/ProvisioningButtonSlice";
import { provisioningRootState } from "../../../../../../../redux/slice/provisioning/ProvisioningTypes";
import { ProfileDetailsRootState } from "../../../../../../../redux/slice/profile/ProfileDetailsSlice";

interface UserProvisionProps {
  formRef: React.RefObject<FormikProps<provisioningRootState>>;
  setActiveKey: (key: string) => void;
}

interface ApiResponse {
  status: number;
  data?: {
    [key: string]: any;
  };
}

const UserProvision: React.FC<UserProvisionProps> = ({ setActiveKey, formRef }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { provisionDetails } = useProvisionContext();
  const { getProvisionDetails } = useHandleProvisionPrerequisites();

  const provisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const provisioningDetail = useSelector((state: provisioningRootState) => state?.provisioning?.provisioning);
  const formInitialValues = useSelector((state: provisioningRootState) => state?.provisioning?.provisioning?.userProvision);
  const isApiCalled = useSelector((state: provisioningRootState) => state?.provisioning?.isProvisioningApiCalled);
  const isApiError = useSelector((state: provisioningRootState) => state?.provisioning?.isProvisioningApiError);
  const endPointResponseDtosData = useSelector((state: provisioningRootState) => state?.provisioning?.endPointResponseDtosData);
  const akkuProvisioningConnectorEndpointConfigId = useSelector(
    (state: provisioningRootState) => state.provisioning?.userProvisioningConnectorEndpointConfigId,
  );
  const isTenantAdmin = useSelector((state: ProfileDetailsRootState) => state?.ProfileDetailsSlice?.isTenantAdmin);
  const buttonStatus = useSelector((state: buttonRootState) => state?.buttonStatus?.buttonStatus);
  const userProviosining = useSelector((state: buttonRootState) => state?.buttonStatus?.buttonStatus?.userProvisioning);
  const generalDetails = provisioning?.generalDetails;

  const isCreated =
    UseGetEndpointDescription("create_user", endPointResponseDtosData) || UseGetEndpointDescription("get_user", endPointResponseDtosData);

  const realmId = retrieveData("realmId", true);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [responseAttributes, setResponseAttributes] = useState<string[]>([]);

  useEffect(() => {
    if (provisioning?.isActiveEdit && !userProviosining?.isInitialized) {
      if (provisionDetails) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            userProvisioning: {
              ...userProviosining,
              isInitialized: true,
            },
          }),
        );
        const userProvisionData = getProvisionDetails(provisionDetails?.endPointResponseDtos, ["create_user", "get_user"], "userProvision");
        if (userProvisionData) {
          dispatch(
            setProvisioning({
              ...provisioningDetail,
              userProvision: userProvisionData,
            }),
          );
        }
      }
    }
  }, [provisionDetails]);

  useEffect(() => {
    // Revalidate the form on language change
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [i18n.language]);

  const handleSubmit = (values: provisioningRootState) => {
    if (isDirty || isApiError?.userProvisionApiError) {
      createPayloadConnectorConfigure(values);
      dispatch(
        setProvisioning({
          ...provisioningDetail,
          userProvision: values,
        }),
      );
    } else {
      setActiveKey("2");
    }
  };

  const createPayloadConnectorConfigure = async (values: provisioningRootState) => {
    const payload: ConnectorConfig = createPayload(values);
    const isDuplicateUpdate = provisioning?.isDuplicate ? provisioning?.isDuplicate && isApiCalled?.userProvisionApiCalled : true; // for duplicate
    const shouldUpdate = isDuplicateUpdate && ((provisioning?.isActiveEdit && isCreated) || isApiCalled?.userProvisionApiCalled);
    if (shouldUpdate) {
      payload.akkuProvisioningConnectorEndpointConfigId =
        akkuProvisioningConnectorEndpointConfigId || values?.akkuProvisioningConnectorEndpointConfigId;
      payload.isActive = provisioning?.isActive;
      payload.isTenantAdmin = isTenantAdmin;
      handleUpdateApiCall(payload);
    } else {
      payload.isTenantAdmin = isTenantAdmin;
      handleCreateApiCall(payload);
    }
  };
  const handleCreateApiCall = async (payload: ConnectorConfig) => {
    await handleApiCall(provisioningConnectorConfigure, payload);
  };

  const handleUpdateApiCall = async (payload: ConnectorConfig) => {
    const params: UpdateParams = { akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId };
    await handleApiCall(provisioningConnectorConfigureUpdate, payload, params);
  };
  const handleApiCall = async (
    apiCall: (payload: ConnectorConfig[], config?: unknown, params?: UpdateParams) => Promise<any>,
    payload: ConnectorConfig,
    params?: UpdateParams,
  ) => {
    try {
      const response = await apiCall([payload], {}, params);
      const userProvisionData = getProvisionDetails(response?.data?.data, ["create_user", "get_user"], "userProvision");
      if (userProvisionData) {
        dispatch(setUserProvisioningConnectorEndpointConfigId(userProvisionData?.akkuProvisioningConnectorEndpointConfigId));
      }

      handleApiResponse(response);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        dispatch(setIsProvisioningApiError({ ...isApiError, userProvisionApiError: true }));
        handleRequestError(err);
      }
    }
  };
  const handleApiResponse = (response: ApiResponse) => {
    const status = response?.status;
    if (status === 200) {
      dispatch(setIsProvisioningApiCalled({ ...isApiCalled, userProvisionApiCalled: true })); // If ApiCalled set is true
      setActiveKey("2");
    }
  };

  const createPayload = (values: provisioningRootState): ConnectorConfig => {
    const baseConfig = {
      apiEndpointUrl: values?.apiEndpointURL,
      methodType: values?.methodType,
      endpointDescription: endPointDescriptionPayload(values?.methodType),
      requestPayload: values?.requestPayload || null,
      sampleResponse: values?.response,
      primaryKeyAttributeList: values?.primaryKeyAttributeList,
      serviceProviderAttributesDto: null,
      headerParameters: transformHeaderParameters(values?.headerParam),
      pathVariable: transformHeaderParameters(values?.pathVariable),
      realmId: realmId,
      name: generalDetails?.name,
      type: "request",
      endpointType: "PROVISIONING",
      isUserProvisioningConfig: true,
    };

    if (provisioning?.isActiveEdit) {
      return {
        ...baseConfig,
        akkuProvisioningConnectorEndpointConfigId: formInitialValues?.akkuProvisioningConnectorEndpointConfigId,
      };
    }

    return baseConfig;
  };
  const handleBack = () => {
    dispatch(setIsBackButtonPressed(true));
    const updatedStepper = {
      activeStep: 1,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
    dispatch(setActiveStep(1));
  };

  const handleSkip = () => {
    setActiveKey("2");
  };

  return (
    <Formik
      initialValues={formInitialValues}
      values={formInitialValues}
      onSubmit={(value: provisioningRootState) => handleSubmit(value)}
      validationSchema={connectorProvisionSchema(responseAttributes, t)}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {({ values, setFieldValue, dirty }) => {
        setIsDirty(dirty);

        return (
          <Form>
            <div className="w-full flex flex-wrap flex-col gap-2 justify-center items-center">
              <ProvisionFormFields setFieldValue={setFieldValue} values={values} setResponseAttributes={setResponseAttributes} />
            </div>

            <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]" data-testid="footer">
              <div className="modal-footer w-full mx-auto ">
                <div className="w-full flex justify-end pr-5">
                  <Button
                    onClick={() => handleSkip()}
                    className="skip-btn back-btn !text-[#545263]"
                    size="large"
                    disabled={
                      // Logic for the first scenario
                      (!isApiCalled?.userProvisionApiCalled && !provisioning?.isDuplicate && !provisioning?.isActiveEdit) || // For first and second scenario
                      // Logic for the second scenario
                      (provisioning?.isDuplicate && !isApiCalled?.userProvisionApiCalled) || // For second scenario
                      // Logic for the third scenario
                      (isCreated === false && !isApiCalled?.userProvisionApiCalled) // For third scenario
                    }
                  >
                    {t("common.skip")}
                  </Button>

                  <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                  <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserProvision;
