import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const EditEmailConfigurationEdit = () => {
  const { t } = useTranslation();
  return (
    <div className={`w-full overflow-y-auto general-details-container`}>
      <div className={` overflow-x-hidden mb-2 w-[92%]`}>
        <div className={`w-[90%] flex flex-col edit-basic`}>
          <div className={`w-full  flex justify-between flex-wrap pb-2`}>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.from')}*</p>
              </p>
              <Field
                as={Input}
                type="text"
                className="h-[56px] font-medium text-[18px]"
                name="senderEmail"
                data-testid="editSendMail-config"
                placeholder={t('tenants.senderEmailAddress')}
              />
              <ErrorMessage name="senderEmail" component="div" className="error-message" />
            </div>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.fromDisplayName')}*</p>
              <Field
                as={Input}
                type="text"
                data-testid="editMail-config"
                className="h-[56px] font-medium text-[18px]"
                name="displaySenderEmail"
                placeholder={t('tenants.displayNameForSenderEmailAddress')}
              />
              <ErrorMessage name="displaySenderEmail" component="div" className="error-message" />
            </div>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.replyTo')}*</p>
              <Field
                as={Input}
                type="text"
                className="h-[56px] font-medium text-[18px]"
                name="replyEmail"
                placeholder={t('tenants.replyToEmailAddress')}
              />
              <ErrorMessage name="replyEmail" component="div" className="error-message" />
            </div>
            <div className="mb-10 w-[40%] input-container relative">
              <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.replyToDisplayName')}*</p>
              <Field
                as={Input}
                type="text"
                className="h-[56px] font-medium text-[18px]"
                name="displayReplyEmail"
                placeholder={t('tenants.displayNameForReplyEmailAddress')}
              />
              <ErrorMessage name="displayReplyEmail" component="div" className="error-message" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer hidden ">
        <Button
          htmlType="submit"
          type="primary"
          className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
        >
          {t('common.next')}
        </Button>
      </div>
    </div>
  );
};

export default EditEmailConfigurationEdit;
