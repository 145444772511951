import React, { useEffect, useState } from "react";
import PlansCard from "./PlansCard";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import Loader from "../../../layouts/component/Loader";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";

const OurPlanLists = () => {
  const getPlanList = useGetApiRequests("getPlans", "GET");
  const [loader, setLoader] = useState<boolean>(false);

  const [allPlans, setAllPlans] = useState([]);

  useEffect(() => {
    getAvailablePlans();
  }, []);

  const getAvailablePlans = async() => {
    setLoader(true);
    try {
      const response: any = await getPlanList();
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setAllPlans(response?.data);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err)
    }
  };

  return (
    <>
      { loader&& <Loader/> }
      <div className="w-[60%] mx-auto">
        {allPlans && 
         <PlansCard planList={allPlans} />
        }
      </div>
    </>
  );
};

export default OurPlanLists;
