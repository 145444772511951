import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { emailValidationSchema } from '../schema/ValidationScheme';
import CustomButtonBack from '../../../../component/CustomButtonBack';
import { Button, Input } from 'antd';
import CommonHeader from './CommonHeader';
import useGetApiRequests from '../../../../../services/axios/useApiRequests';
import { triggerNotification } from '../../../../toast/ToastBar';
import { handleRequestError } from '../../../../toast/ErrorNotificationMessage';
import Loader from '../../../../component/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerDetailFromApi, setReloadCustomerDashboard } from '../../../../../redux/slice/CustomerSlice';
import { compareObjects } from './customerHelper';
import { useTranslation } from 'react-i18next';

const EmailSettingForm = (props: any) => {
  const { setCurrentTab, currentTab, isEdit, getCustomerDetails, setGetCustomerDetails, setEmailDataChanges, emailDataChanges } = props;
  const { t } = useTranslation();

  const updateCustomerDetails = useGetApiRequests('createCustomer', 'PUT');

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const customerEmailDetails = useSelector((state: any) => state?.CustomerSlice?.getCustomerDetails?.emailDetails);
  const customerId = useSelector((state: any) => state?.CustomerSlice?.customerId);

  const [initialValues, setInitialValues] = useState<any>({
    senderEmail: '',
    displaySenderEmail: '',
    replyEmail: '',
    displayReplyEmail: '',
  });

  useEffect(() => {
    if (customerEmailDetails && Object?.keys(customerEmailDetails).length > 0) {
      setInitialValues({
        senderEmail: customerEmailDetails?.fromEmailAddress || customerEmailDetails?.senderEmail,
        displaySenderEmail: customerEmailDetails?.fromDisplayName || customerEmailDetails?.displaySenderEmail,
        replyEmail: customerEmailDetails?.replyToEmailAddress || customerEmailDetails?.replyEmail,
        displayReplyEmail: customerEmailDetails?.replyToDisplayName || customerEmailDetails?.displayReplyEmail,
      });
      setEmailDataChanges({
        senderEmail: customerEmailDetails?.fromEmailAddress || customerEmailDetails?.senderEmail,
        displaySenderEmail: customerEmailDetails?.fromDisplayName || customerEmailDetails?.displaySenderEmail,
        replyEmail: customerEmailDetails?.replyToEmailAddress || customerEmailDetails?.replyEmail,
        displayReplyEmail: customerEmailDetails?.replyToDisplayName || customerEmailDetails?.displayReplyEmail,
      });
    }
  }, [currentTab]);

  const createEmailPayload = (values: any) => ({
    fromEmailAddress: values?.senderEmail,
    fromDisplayName: values?.displaySenderEmail,
    replyToEmailAddress: values?.replyEmail,
    replyToDisplayName: values?.displayReplyEmail,
  });

  const handleSuccess = (response: any) => {
    if (response?.status === 201 || response?.status === 200) {
      dispatch(setReloadCustomerDashboard());
      triggerNotification('success', '', response?.data?.message, 'topRight');
    }
    setLoader(false);
    setCurrentTab(3);
  };

  const handleError = (err: any) => {
    setLoader(false);
    if (err?.response?.status === 401 || err?.response?.status === 500) {
      handleRequestError(err);
    } else {
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  };

  const handleSubmit = (values: any) => {
    setLoader(true);

    const payLoad = createEmailPayload(values);
    const finalPayload = { emailConfigurationDto: payLoad };

    dispatch(
      setCustomerDetailFromApi({
        ...getCustomerDetails,
        emailDetails: payLoad,
      }),
    );

    setGetCustomerDetails({
      ...getCustomerDetails,
      emailDetails: payLoad,
    });

    if (!isEdit) {
      setLoader(false);
      setCurrentTab(3);
    } else {
      const valueMatched = compareObjects(emailDataChanges, values);

      if (!valueMatched) {
        const pathParams = { name: customerId };

        updateCustomerDetails(finalPayload, '', pathParams).then(handleSuccess).catch(handleError);
      } else {
        setCurrentTab(3);
        setLoader(false);
      }
    }
  };

  const handleBack = () => {
    setCurrentTab(1);
  };
  return (
    <>
      <CommonHeader text={t('tenants.emailSettings')} onClick={handleBack} />

      <div className="w-full h-full">
        <Formik
          initialValues={initialValues}
          values={initialValues}
          validationSchema={emailValidationSchema(t)}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="w-full h-full">
                  <div className="w-full xl:h-full h-[calc(100vh-300px)] overflow-y-auto">
                    <div className="mb-10 w-[92%] mx-auto">
                      <div className="w-[90%] flex pt-[50px] flex-col edit-basic">
                        <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
                          <div className="mb-10 w-[40%] input-container relative">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.from')}*</p>
                            <Field
                              as={Input}
                              type="text"
                              className="h-[56px] font-medium text-[18px]"
                              name="senderEmail"
                              placeholder={t('tenants.senderEmailAddress')}
                            />
                            <ErrorMessage name="senderEmail" component="div" className="error-message" />
                          </div>
                          <div className="mb-10 w-[40%] relative input-container">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.fromDisplayName')}*</p>
                            <Field
                              as={Input}
                              type="text"
                              className="h-[56px] font-medium text-[18px]"
                              name="displaySenderEmail"
                              placeholder={t('tenants.displayNameForSenderEmailAddress')}
                            />
                            <ErrorMessage name="displaySenderEmail" component="div" className="error-message" />
                          </div>
                          <div className="mb-10 relative w-[40%] input-container">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.replyTo')}*</p>
                            <Field
                              as={Input}
                              type="text"
                              className="h-[56px] font-medium text-[18px]"
                              name="replyEmail"
                              placeholder={t('tenants.replyToEmailAddress')}
                            />
                            <ErrorMessage name="replyEmail" component="div" className="error-message" />
                          </div>
                          <div className="relative mb-10 w-[40%] input-container">
                            <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t('tenants.replyToDisplayName')}*</p>
                            <Field
                              as={Input}
                              type="text"
                              className="h-[56px] font-medium text-[18px]"
                              name="displayReplyEmail"
                              placeholder={t('tenants.displayNameForReplyEmailAddress')}
                            />
                            <ErrorMessage name="displayReplyEmail" component="div" className="error-message" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer ">
                      <div className="modal-footer w-[95%] mx-auto ">
                        <div className="w-full flex justify-end">
                          <CustomButtonBack onClick={handleBack} text={t('common.back')} />

                          <Button
                            htmlType="submit"
                            type="primary"
                            className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                          >
                            {t('common.next')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default EmailSettingForm;
