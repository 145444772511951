import * as Yup from "yup";

export const getStrengthMessage = (t: (key: string) => string, value: number) => {
  switch (value) {
    case 9:
      return t("passwordManager.good");
    case 10:
      return t("passwordManager.strong");
    case 11:
      return t("passwordManager.recommended");
    case 12:
      return t("passwordManager.superStrong");
    case 13:
      return t("passwordManager.unnecessarilyStrong");
    default:
      return "";
  }
};

export const passwordManagerValidationSchema = (t: any) =>
  Yup.object().shape({
    loginAttempts: Yup.number().required(t("passwordManager.loginAttemptIsRequired")),
  });
export const attemptsLists = [
  { label: "3", value: 3 },
  { label: "5", value: 5 },
  { label: "7", value: 7 },
];
