import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  registerResponse: {}
}

const initialState: AuthState = {
    registerResponse: {},
};

const registerData = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setRegisterResponse: (state, action: PayloadAction<boolean>) => {
      state.registerResponse = action.payload;
    },
    clearRegisterResponse: state => {
      state.registerResponse = false;
    },
  },
});

export const { setRegisterResponse, clearRegisterResponse } = registerData.actions;
export default registerData.reducer;
