import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../../../../../../../layouts/component/CustomButton";
import ImageView from "../../../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../../../assets/images/ImagePaths";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import { useTranslation } from "react-i18next";
const DomainTestingModal = (props: any) => {
  const { openModal, handleClose, enableTest, values, setEnableTest, setAvailableButton, isLDAP } = props;
  const { t } = useTranslation();

  const domainVerification = useGetApiRequests("customerOnBoardingDomainVerifiaction", "POST");

  const [open, setOpen] = useState<boolean>(false);
  const handleCloseModal = () => {
    handleClose();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(openModal);
    if (isLDAP !== true) {
      isDomainAvailableOrNot();
    }
  }, []);

  const isDomainAvailableOrNot = async () => {
    const customerGeneralDetailsDto: object = {
      customerId: values?.customerId,
      primaryEmailId: values?.customerEmailId,
      displayName: values?.displayName,
      noOfLicense: values?.noOfLicense,
      loginTheme: values?.loginTheme,
      primaryMobileNumber: values?.phone,
      parentCustomer: "master",
      customerType: "CLIENT",
    };
    const payload: object = {
      customerGeneralDetailsDto: customerGeneralDetailsDto,
    };
    try {
      const response: any = await domainVerification(payload);
      const status = response.status;
      if (status === 200) {
        setAvailableButton(true);
        setEnableTest(false);
      }
    } catch (err: any) {
      setEnableTest(true);
      setAvailableButton(false);
      handleClose();
      handleRequestError(err);
    }
  };
  return (
    <Modal className="p-16  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 min-h-[150px] flex items-center">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="w-12">
            <ImageView src={images.sync} />
          </div>
          <p className="font-Inter text-xl font-medium text-[#000] pt-5">{t("tenants.testingInProgress")}</p>
        </div>
      </div>
      {!enableTest && (
        <>
          <p className="text-center font-Inter text-xl font-medium pb-5 text-[#19A227]">{t("tenants.success")}</p>
          <div className="modal-footer">
            <div className="w-full flex justify-center">
              <CustomButtonNext onClick={handleCloseModal} text={t("common.done")} />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default DomainTestingModal;
