const commonRender = (text: any) => (
  <div>
    <p className={`${text.trim().toLowerCase()} device-restriction`}>
      <span></span>
      {text || "-"}
    </p>
  </div>
);
const commonStatus = (text: any) => (
  <div>
    <p className="resend">{text || ""}</p>
  </div>
);
const commonTitle = (text: any) => {
  return (
    <div>
      <p className="user-restriction-title">{text || "-"}</p>
    </div>
  );
};

export const userRestrictionTableColumns = (t: any) => [
  {
    title: t("userManagement.restrictionType"), // Translated "Restriction type"
    dataIndex: "name",
    key: "name",
    width: "40%",
    render: commonTitle,
  },
  {
    title: t("userManagement.status"), // Translated "Status" (or "HAi" if needed)
    dataIndex: "status",
    key: "status",
    width: "38%",
    render: commonRender,
  },
  {
    title: "", // No title for resend
    dataIndex: "resend",
    key: "resend",
    render: commonStatus,
  },
];

export const CustomExpandIcon = ({ expanded, onExpand, record }: any) => (
  <span className="cursor-pointer material-symbols-outlined" onClick={(e) => onExpand(record, e)}>
    {expanded ? "keyboard_arrow_up" : "keyboard_arrow_down"}
  </span>
);
