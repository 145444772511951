import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomerDetailFromApi } from '../../../../../redux/slice/CustomerSlice';
import { Input, Select, Tooltip } from 'antd';
import { ErrorMessage, Field } from 'formik';
import { helpMessage } from './sessionIdleFormhelper';
import { useTranslation } from 'react-i18next';
const SsoSessionSettingFormEdit = (props: any) => {
  const { t } = useTranslation();
  const { handleOnChange, setFieldValue } = props;

  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<any>({
    ssoSessionIdleTimeout: '120',
    ssoSessionMaxLifespan: '480',
    ssoSessionIdleTimeoutRememberMe: '30',
    ssoSessionMaxLifespanRememberMe: '30',
    loginTimeout: '10',
    loginActionTimeout: '5',
  });

  const [loader, setLoader] = useState(false);

  const timeOutDuration = ['Min'];

  const hasValues = (obj: any) => Object.keys(obj).length > 0;
  const updateCustomerDetailsForApi = (details: any, newValues: any) => ({
    ...details,
    ssoDetails: newValues,
  });

  const defaultToZeroIfFalsy = (value: any) => value ?? 0;

  const handleSubmit = (values: any) => {
    // if (hasValues(values)) {
    //   const updatedDetails = updateCustomerDetailsForApi(getCustomerDetails, values);
    //   dispatch(setCustomerDetailFromApi(updatedDetails));
    //   setGetCustomerDetails(updatedDetails);
    // }

    setLoader(true);
    const payload: any = {
      ssoSessionIdleTimeout: values?.ssoSessionIdleTimeout,
      ssoSessionMaxLifespan: values?.ssoSessionMaxLifespan,
      ssoSessionIdleTimeoutRememberMe: defaultToZeroIfFalsy(values?.ssoSessionIdleTimeoutRememberMe),
      ssoSessionMaxLifespanRememberMe: defaultToZeroIfFalsy(values?.ssoSessionMaxLifespanRememberMe),
      loginTimeout: values?.loginTimeout,
      loginActionTimeout: values?.loginActionTimeout,
    };
    for (const key in payload) {
      if (payload.hasOwnProperty(key) && values?.[key] !== undefined) {
        const value = parseInt(values[key], 10);
        if (!isNaN(value)) {
          payload[key] = value * 60;
        }
      }
    }

    const finalPayload: object = {
      sessionDto: payload,
    };
    // setSsoDetailsPayload(finalPayload);
  };
  const getOptions = (time: any) => {
    return timeOutDuration?.map((option: any) => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    ));
  };

  const userAttributes = ['Min'];

  return (
    <div className="w-full sso-session-setting">
      <div className="w-full">
        <div className="w-full overflow-y-auto sso-setting">
          <div className="mb-10 w-[92%] flex flex-wrap ssoDropdown">
            <div className="w-[50%] flex pt-[50px] flex-col sso-container " data-testid="ssoContainerId">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit" data-testid="editId">
                <div className="mb-10 w-[40%] flex  items-center sso-container-field" data-testid="ssoContainerFieldId">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium relative min-w-[230px]">
                    {t('tenants.ssoSessionIdle')}*
                    <Tooltip title={t('tenants.sessionIdleTimeoutDescription')}>
                      <span className="material-symbols-outlined  absolute ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className="mb-10 w-[50%] minute relative flex gap-x-3" data-testid="minuteId  ">
                  <div className=" sso-input relative">
                    <Field
                      as={Input}
                      type="number"
                      className="h-[48px] font-medium text-[18px] w-[150px]"
                      name="ssoSessionIdleTimeout"
                      data-testid="timeoutId"
                    />
                    <ErrorMessage name="ssoSessionIdleTimeout" component="div" className="error-message" />
                  </div>
                  <Field
                    as={Select}
                    name={`min`}
                    suffixIcon={<span className=" material-symbols-outlined text-[#000]">expand_more</span>}
                    className={' w-[90px]  pointer-events-none h-[48px]'}
                    onChange={(value: any) => handleOnChange(setFieldValue, 'target', value)}
                    value="Min"
                    filterOption={(input: string, option: React.ReactElement) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>
            <div className="w-[50%] flex pt-[50px] flex-col sso-container remember" data-testid="ssoContainerId2">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit" data-testid="editId2">
                <div className="mb-10 !w-[40%] flex  items-center sso-container-field idle" data-testid="ssoContainerFieldId2">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium relative min-w-[230px]">
                    <span className="mr-2 break-words">
                      {t('tenants.ssoSessionIdle')}
                      <br></br>
                      {t('tenants.rememberMe')}
                    </span>

                    <Tooltip title={t('tenants.rememberMeSessionIdleDescription')}>
                      <span className="material-symbols-outlined  absolute ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className=" w-[50%] minute relatives mt-0 2xl:mt-[3px] flex gap-x-3" data-testid="minuteId2">
                  <div className="sso-input relative">
                    <Field
                      as={Input}
                      type="number"
                      className="h-[48px] font-medium text-[18px] w-[150px]"
                      name="ssoSessionIdleTimeoutRememberMe"
                      data-testid="rememberId"
                    />
                    <ErrorMessage name="ssoSessionIdleTimeoutRememberMe" component="div" className="error-message" />
                  </div>
                  <Field
                    as={Select}
                    suffixIcon={<span className=" text-[#000] material-symbols-outlined">expand_more</span>}
                    className={' w-[90px] h-[48px] pointer-events-none'}
                    name={`min`}
                    value="Min"
                    onChange={(value: any) => handleOnChange(setFieldValue, 'target', value)}
                    filterOption={(input: string, option: React.ReactElement) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>
            <div className="w-[50%] flex pt-[10px] flex-col sso-container " data-testid="ssoContainerId3">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit" data-testid="editId3">
                <div className="mb-10 w-[40%] flex  items-center sso-container-field" data-testid="ssoContainerFieldId3">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium relative min-w-[230px]">
                    {t('tenants.ssoSessionMax')}*
                    <Tooltip title={t('tenants.sessionMaxTimeoutDescription')}>
                      <span className="material-symbols-outlined  absolute ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className="mt-0   w-[50%] minute relative flex gap-x-3" data-testid="minuteId3">
                  <div className="sso-input relative">
                    <Field
                      as={Input}
                      type="number"
                      className="h-[48px] font-medium text-[18px] w-[150px]"
                      name="ssoSessionMaxLifespan"
                      data-testid="ssoId"
                    />
                    <ErrorMessage name="ssoSessionMaxLifespan" component="div" className="error-message" />
                  </div>
                  <Field
                    as={Select}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className={'pointer-events-none w-[90px] h-[48px] '}
                    name={`min`}
                    value="Min"
                    onChange={(value: any) => handleOnChange(setFieldValue, 'target', value)}
                    filterOption={(input: string, option: React.ReactElement) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>
            <div className="w-[50%] flex pt-[10px] flex-col sso-container" data-testid="ssoContainerId4">
              <div className="w-full  flex mx-auto justify-between flex-wrap _edit" data-testid="editId4">
                <div className="mb-10 !w-[40%] mr-5 flex  items-center sso-container-field idle" data-testid="ssoContainerFieldId4">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium relative min-w-[230px]">
                    <span className="mr-3">
                      {t('tenants.ssoSessionMax')}
                      <br></br>
                      {t('tenants.rememberMe')}
                    </span>
                    <Tooltip title={t('tenants.rememberMeSessionMaxDescription')}>
                      <span className="material-symbols-outlined  absolute ml-1 cursor-pointer "> help</span>
                    </Tooltip>
                  </p>
                </div>
                <div className="w-[50%] mt-0 2xl:mt-[3px] minute relative flex gap-x-3" data-testid="minuteId4">
                  <div className="sso-input relative">
                    <Field
                      as={Input}
                      type="number"
                      className="h-[48px] font-medium text-[18px] w-[150px]"
                      name="ssoSessionMaxLifespanRememberMe"
                      data-testid="ssoRememberId"
                    />
                    <ErrorMessage name="ssoSessionMaxLifespanRememberMe" component="div" className="error-message" />
                  </div>
                  <Field
                    as={Select}
                    value="Min"
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className={'w-[90px] h-[48px] pointer-events-none  '}
                    onChange={(value: any) => handleOnChange(setFieldValue, 'target', value)}
                    name={`min`}
                    filterOption={(input: string, option: React.ReactElement) =>
                      option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getOptions(timeOutDuration)}
                  </Field>
                </div>
              </div>
            </div>

            <div className="w-full ">
              <p className="font-Inter font-semibold text-[24px] pb-7">{t('tenants.loginSettings')}</p>
              <div className="sso-login w-full ">
                <div className="w-[50%] flex pt-[10px] flex-col sso-container">
                  <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
                    <div className="mb-10 !w-[40%] mr-5 flex  items-center sso-container-field idle relative">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium min-w-[230px]">
                        {t('tenants.loginTimeout')}*
                        <Tooltip title={t('tenants.loginTimeoutDescription')}>
                          <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                        </Tooltip>
                      </p>
                    </div>
                    <div className="mb-10 w-[50%] minute relative flex gap-x-3">
                      <div className="sso-input">
                        <Field
                          as={Input}
                          name="loginTimeout"
                          className="h-[48px] font-medium text-[18px] w-[150px]"
                          type="number"
                          data-testid="loginTimeout-input"
                        />
                        <ErrorMessage name="loginTimeout" component="div" className="error-message" />
                      </div>
                      <Field
                        as={Select}
                        name={`min`}
                        value="Min"
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className={' w-[90px] h-[48px]  pointer-events-none '}
                        onChange={(value: any) => handleOnChange(setFieldValue, 'target', value)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {userAttributes?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="w-[50%] flex pt-[10px] flex-col sso-container">
                  <div className="w-full  flex mx-auto justify-between flex-wrap _edit">
                    <div className="mb-10 !w-[40%] mr-5 flex  items-center sso-container-field idle relative">
                      <p className="text-[#1D1D24] min-w-[230px] text-[18px] font-Inter font-medium">
                        {t('tenants.loginActionTimeout')}*
                        <Tooltip title={t('tenants.loginActionTimeoutDescription')}>
                          <span className="material-symbols-outlined  relative ml-1 cursor-pointer top-1"> help</span>
                        </Tooltip>
                      </p>
                    </div>
                    <div className="mb-10 flex gap-x-3  minute relative w-[50%]">
                      <div className="sso-input">
                        <Field
                          as={Input}
                          type="number"
                          className="h-[48px] font-medium text-[18px] w-[150px]"
                          name="loginActionTimeout"
                          data-testid="loginAction-input"
                        />
                        <ErrorMessage name="loginActionTimeout" component="div" className="error-message" />
                      </div>
                      <Field
                        as={Select}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        name={`min`}
                        className={' w-[90px] h-[48px] pointer-events-none'}
                        value="Min"
                        onChange={(value: any) => handleOnChange(setFieldValue, value, 'target')}
                        filterOption={(option: React.ReactElement, input: string) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {userAttributes?.map((option: any) => (
                          <Select.Option value={option} key={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SsoSessionSettingFormEdit;
