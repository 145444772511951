import React, { useEffect, useState } from "react";
import Loader from "../layouts/component/Loader";
import { storeData } from "../services/storage/Storage";
import { useTranslation } from "react-i18next";

const PasswordStrengthIndicator = ({ password, passwordPolicy }: any) => {
  const { t } = useTranslation();
  const [passwordPolicyList, setPasswordPolicyList] = useState<any>([]);
  const [loader, setLoader] = useState(true);

  const requirements = [
    {
      label: t("userManagement.uppercaseCharacters"),
      condition: (password: string) => /^(?=.*[A-Z])/.test(password),
      key: "uppercaseCharacters",
      id: 1,
    },
    {
      label: t("userManagement.specialCharacters"),
      condition: (password: string) => /^(?=.*[!@#$%^&*()_+\-=[\]{};':\"\\|,.<>/?]).*$/.test(password),
      key: "specialCharacters",
      id: 2,
    },
    {
      label: t("userManagement.lowercaseCharacters"),
      condition: (password: string) => /^(?=.*[a-z])/.test(password),
      key: "lowercaseCharacters",
      id: 3,
    },
    {
      label: t("userManagement.passwordLength", {
        length: passwordPolicy?.passwordLength, // Provide a default value (e.g., 8) if passwordLength is undefined
      }),
      condition: (password: string) => password.length >= (passwordPolicy?.passwordLength || 0),
      key: "passwordLength",
      id: 4,
    },
    {
      label: t("userManagement.digits"),
      condition: (password: string) => /^(?=.*\d)/.test(password),
      key: "digits",
      id: 5,
    },
  ];

  useEffect(() => {
    if (passwordPolicy) {
      storeData("realmId", passwordPolicy?.realmId, true);
      const filteredRequirements = requirements?.filter((req) => {
        if (req.key === "passwordLength") {
          return true; // Always include password length requirement
        }
        return passwordPolicy[req.key];
      });
      setPasswordPolicyList(filteredRequirements);
    }

    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [passwordPolicy, loader]);

  return (
    <>
      <ul>
        {passwordPolicyList?.map((req: any) => (
          <li className={`flex items-center font-Inter ${req?.condition(password) ? "success" : "error"}`} key={req?.id}>
            <span className="material-symbols-outlined">{req?.condition(password) ? "check" : "close"}</span>
            <span className="font-Inter">{req.label}</span>
          </li>
        ))}
      </ul>
      {loader && <Loader data-testid="loader" />}
    </>
  );
};

export default PasswordStrengthIndicator;
