import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import AddCustomerStepper from "./AddCustomerStepper";
import CommonHeader from "./forms/CommonHeader";
import { Form, Formik } from "formik";
import { ValidationSchema } from "./schema/ValidationScheme";
import GeneralDetailsForm from "./forms/GeneralDetailsForm";
import EmailSettingForm from "./forms/EmailSettingForm";
import SsoSessionSettingForm from "./forms/SsoSessionSettingForm";
import ProductsAndPlans from "./forms/products/ProductsAndPlans";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../toast/ToastBar";
import { handleRequestError } from "../../../toast/ErrorNotificationMessage";
import Loader from "../../../component/Loader";
import { useDispatch, useSelector } from "react-redux";
import { clearCustomerDetailsFromApi, setCustomerData, setCustomerDetailFromApi, setCustomerId, setReloadCustomerDashboard } from "../../../../redux/slice/CustomerSlice";
import DomainTestingModal from "../../../../pages/dashboard/user/user-dashboard/add-user/layouts/modal/configure-authentication/DomainTestingModal";
import { retrieveData } from "../../../../services/storage/Storage";
import { compareObjects } from "./forms/customerHelper";
import "./AddCustomer.scss";
import { useTranslation } from "react-i18next";
const AddNewCustomersModal = (props: any) => {
  const { t } = useTranslation();
  const { isOpen, handleClose, isEdit, customerName } = props;
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");
  const updateCustomerDetails = useGetApiRequests("createCustomer", "PUT");
  const realmName = retrieveData("realmName", true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [enableTest, setEnableTest] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [loader, setLoader] = useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const [enableTestButton, setEnableTestButton] = useState(isEdit);
  const [availableButton, setAvailableButton] = useState(false);
  const [generalDataChanges, setGeneralDataChanges] = useState({});
  const [emailDataChanges, setEmailDataChanges] = useState({});
  const [ssoDataChanges, setSsoDataChanges] = useState({});
  const customerId = useSelector((state: any) => state?.CustomerSlice?.customerId);
  const [disableSmsProvider, setDisableSmsProvider] = useState(false);

  const [ssoDetailsPayload, setSsoDetailsPayload] = useState<any>({});
  const dispatch = useDispatch();
  const customerGeneralData = useSelector((state: any) => state?.CustomerSlice?.getCustomerDetails?.generalDetails);
  const [getCustomerDetails, setGetCustomerDetails] = useState<any>({
    generalDetails: {},
    emailDetails: {},
    ssoDetails: {},
    plansDetails: {},
  });
  const [initialValues, setInitialValues] = useState<any>({
    customerId: "",
    displayName: "",
    customerEmailId: "",
    noOfLicense: null,
    phone: "",
    loginTheme: "",
    passwordLogin: false,
    defaultPassword: false,
    smsServiceProvider: "",
  });

  useEffect(() => {
    setModalOpen(isOpen);
    if (Object.keys(customerGeneralData).length > 0) {
      setInitialValues({
        ...initialValues,
        customerId: customerGeneralData?.customerId,
        displayName: customerGeneralData?.displayName,
        customerEmailId: customerGeneralData?.primaryEmailId ?? customerGeneralData?.customerEmailId,
        noOfLicense: customerGeneralData?.noOfLicense,
        phone: customerGeneralData?.primaryMobileNumber ?? customerGeneralData?.phone,
        loginTheme: customerGeneralData?.loginTheme,
        passwordLogin: customerGeneralData?.passwordLogin,
        defaultPassword: customerGeneralData?.defaultPassword,
        smsServiceProvider: customerGeneralData?.smsServiceProvider,
      });
      setGeneralDataChanges({
        customerId: customerGeneralData?.customerId,
        displayName: customerGeneralData?.displayName,
        customerEmailId: customerGeneralData?.primaryEmailId ?? customerGeneralData?.customerEmailId,
        noOfLicense: customerGeneralData?.noOfLicense,
        phone: customerGeneralData?.primaryMobileNumber ?? customerGeneralData?.phone,
        loginTheme: customerGeneralData?.loginTheme,
        passwordLogin: customerGeneralData?.passwordLogin,
        defaultPassword: customerGeneralData?.defaultPassword,
        smsServiceProvider: customerGeneralData?.smsServiceProvider,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  useEffect(() => {
    if (isEdit === true) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getDetails = async () => {
    setLoader(true);
    const pathParams: any = {
      name: customerName,
    };
    try {
      const response: any = await getCustomerDetailsApi("", {}, pathParams);
      const status = response.status;
      if (status === 200) {
        setGetCustomerDetails({
          generalDetails: {
            customerId: response?.data?.data?.customerGeneralDetailsDto?.customerId,
            displayName: response?.data?.data?.customerGeneralDetailsDto?.displayName,
            customerEmailId: response?.data?.data?.customerGeneralDetailsDto?.primaryEmailId,
            noOfLicense: response?.data?.data?.customerGeneralDetailsDto?.noOfLicense,
            phone: response?.data?.data?.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
            loginTheme: response?.data?.data?.customerGeneralDetailsDto?.loginTheme,
            passwordLogin: response?.data?.data?.customerGeneralDetailsDto?.passwordLogin,
            defaultPassword: response?.data?.data?.customerGeneralDetailsDto?.defaultPassword,
            smsServiceProvider: response?.data?.data?.customerGeneralDetailsDto?.smsServiceProvider,
          },
          emailDetails: {
            senderEmail: response?.data?.data?.emailConfigurationDto?.fromEmailAddress,
            displaySenderEmail: response?.data?.data?.emailConfigurationDto?.fromDisplayName,
            replyEmail: response?.data?.data?.emailConfigurationDto?.replyToEmailAddress,
            displayReplyEmail: response?.data?.data?.emailConfigurationDto?.replyToDisplayName,
          },
          ssoDetails: {
            ssoSessionIdleTimeout: response?.data?.data?.sessionDto?.ssoSessionIdleTimeout / 60,
            ssoSessionMaxLifespan: response?.data?.data?.sessionDto?.ssoSessionMaxLifespan / 60,
            ssoSessionIdleTimeoutRememberMe: response?.data?.data?.sessionDto?.ssoSessionIdleTimeoutRememberMe / 60,
            ssoSessionMaxLifespanRememberMe: response?.data?.data?.sessionDto?.ssoSessionMaxLifespanRememberMe / 60,
            loginTimeout: response?.data?.data?.sessionDto?.loginTimeout / 60,
            loginActionTimeout: response?.data?.data?.sessionDto?.loginActionTimeout / 60,
          },
          plansDetails: response?.data?.data?.customerPlanPayloadDto,
        });
        setEmailDataChanges({
          senderEmail: response?.data?.data?.emailConfigurationDto?.fromEmailAddress,
          displaySenderEmail: response?.data?.data?.emailConfigurationDto?.fromDisplayName,
          replyEmail: response?.data?.data?.emailConfigurationDto?.replyToEmailAddress,
          displayReplyEmail: response?.data?.data?.emailConfigurationDto?.replyToDisplayName,
        });
        setSsoDataChanges({
          ssoSessionIdleTimeout: response?.data?.data?.sessionDto?.ssoSessionIdleTimeout / 60,
          ssoSessionMaxLifespan: response?.data?.data?.sessionDto?.ssoSessionMaxLifespan / 60,
          ssoSessionIdleTimeoutRememberMe: response?.data?.data?.sessionDto?.ssoSessionIdleTimeoutRememberMe / 60,
          ssoSessionMaxLifespanRememberMe: response?.data?.data?.sessionDto?.ssoSessionMaxLifespanRememberMe / 60,
          loginTimeout: response?.data?.data?.sessionDto?.loginTimeout / 60,
          loginActionTimeout: response?.data?.data?.sessionDto?.loginActionTimeout / 60,
        });
        setInitialValues({
          customerId: response?.data?.data?.customerGeneralDetailsDto?.customerId,
          displayName: response?.data?.data?.customerGeneralDetailsDto?.displayName,
          customerEmailId: response?.data?.data?.customerGeneralDetailsDto?.primaryEmailId,
          noOfLicense: response?.data?.data?.customerGeneralDetailsDto?.noOfLicense,
          phone: response?.data?.data?.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
          loginTheme: response?.data?.data?.customerGeneralDetailsDto?.loginTheme,
          passwordLogin: response?.data?.data?.customerGeneralDetailsDto?.passwordLogin,
          defaultPassword: response?.data?.data?.customerGeneralDetailsDto?.defaultPassword,
          smsServiceProvider: response?.data?.data?.customerGeneralDetailsDto?.smsServiceProvider,
        });
        if (response?.data?.data?.customerGeneralDetailsDto?.smsServiceProvider) {
          setDisableSmsProvider(true);
        }
        if (Object.keys(customerGeneralData).length === 0) {
          setGeneralDataChanges({
            customerId: response?.data?.data?.customerGeneralDetailsDto?.customerId,
            displayName: response?.data?.data?.customerGeneralDetailsDto?.displayName,
            customerEmailId: response?.data?.data?.customerGeneralDetailsDto?.primaryEmailId,
            noOfLicense: response?.data?.data?.customerGeneralDetailsDto?.noOfLicense,
            phone: response?.data?.data?.customerGeneralDetailsDto?.primaryMobileNumber?.replace(/\D/g, ""),
            loginTheme: response?.data?.data?.customerGeneralDetailsDto?.loginTheme,
            passwordLogin: response?.data?.data?.customerGeneralDetailsDto?.passwordLogin,
            defaultPassword: response?.data?.data?.customerGeneralDetailsDto?.defaultPassword,
            smsServiceProvider: response?.data?.data?.customerGeneralDetailsDto?.smsServiceProvider,
          });
        }
        setLoader(false);
      }
    } catch (err: any) {
      setLoader(false);

      handleRequestError(err);
    }
  };
  const handleCloseModal = () => {
    dispatch(clearCustomerDetailsFromApi());
    handleClose();
  };
  const handleCloseDomain = () => {
    setEnableTestButton(false);
  };

  const handleSubmit = async (values: any) => {
    setInitialValues(values);
    const submittedValues = getSubmittedValues(values);

    if (enableTestButton) {
      handleTestButtonEnabled(submittedValues, values);
    } else {
      setEnableTestButton(true);
    }
  };

  const getSubmittedValues = (values: any) => ({
    customerId: values?.customerId,
    displayName: values?.displayName,
    primaryEmailId: values?.customerEmailId,
    noOfLicense: values?.noOfLicense,
    primaryMobileNumber: values?.phone,
    loginTheme: values?.loginTheme,
    passwordLogin: values?.passwordLogin,
    defaultPassword: values?.defaultPassword,
    smsServiceProvider: values?.smsServiceProvider,
  });

  const handleTestButtonEnabled = (submittedValues: any, values: any) => {
    const customerGeneralDetail = getCustomerGeneralDetail(submittedValues);
    updateCustomerState(customerGeneralDetail, submittedValues);

    if (isEdit) {
      handleEdit(submittedValues, values);
    }
  };

  const getCustomerGeneralDetail = (submittedValues: any) => ({
    customerGeneralDetailsDto: {
      ...submittedValues,
      parentCustomer: realmName,
    },
  });

  const updateCustomerState = (customerGeneralDetail: any, submittedValues: any) => {
    dispatch(setCustomerData(customerGeneralDetail));
    dispatch(
      setCustomerDetailFromApi({
        ...getCustomerDetails,
        generalDetails: submittedValues,
      }),
    );
    setGetCustomerDetails({
      ...getCustomerDetails,
      generalDetails: submittedValues,
    });
  };

  const handleEdit = async (submittedValues: any, values: any) => {
    setLoader(true);
    const valueMatched = compareObjects(generalDataChanges, values);

    if (!valueMatched) {
      try {
        const response = await updateCustomerDetailsApi(values, customerId);

        if (response?.status === 201 || response?.status === 200) {
          handleSuccessfulUpdate(response);
        }
      } catch (err) {
        handleUpdateError(err);
      }
    } else {
      setCurrentTab(2);
      setLoader(false);
    }
  };

  const updateCustomerDetailsApi = (values: any, customerId: string) => {
    const pathParams = { name: customerId };
    const payload = getPayload(values);

    return updateCustomerDetails(payload, "", pathParams);
  };

  const getPayload = (values: any) => ({
    customerGeneralDetailsDto: {
      customerId: values?.customerId,
      primaryEmailId: values?.customerEmailId,
      displayName: values?.displayName,
      noOfLicense: values?.noOfLicense,
      loginTheme: values?.loginTheme,
      primaryMobileNumber: values?.phone,
      parentCustomer: "master",
      customerType: "CLIENT",
      passwordLogin: values?.passwordLogin,
      defaultPassword: values?.defaultPassword,
      smsServiceProvider: values?.smsServiceProvider,
    },
  });

  const handleSuccessfulUpdate = (response: any) => {
    dispatch(setReloadCustomerDashboard());
    dispatch(setCustomerId(response?.data?.data?.customerGeneralDetailsDto?.customerId));
    setLoader(false);
    triggerNotification("success", "", response?.data?.message, "topRight");
    setCurrentTab(2);
  };

  const handleUpdateError = (err: any) => {
    setLoader(false);

    if (err?.response?.status === 401 || err?.response?.status === 500) {
      handleRequestError(err);
    } else {
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
    }
  };

  return (
    <Modal className=" add-new-customer add-customer-modal rounded-lg h-full" maskClosable={false} centered open={modalOpen} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  h-full ">
        <div className="flex w-full h-full justify-center items-center flex-col">
          <div className="w-full flex h-full">
            <div className="modal-stepper w-[24%]">
              <AddCustomerStepper currentTab={currentTab} />
            </div>
            <div className="modal-content w-[76%] p-7 rounded-lg">
              {currentTab === 1 && (
                <>
                  <CommonHeader text={t("tenants.generalDetails")} />
                  <div className="w-full ">
                    <Formik initialValues={initialValues} validationSchema={ValidationSchema(t)} onSubmit={handleSubmit} enableReinitialize={true}>
                      {({ values, setFieldValue, errors, setFieldTouched }) => {
                        return (
                          <Form>
                            <div className="w-full">
                              <GeneralDetailsForm
                                setFieldTouched={setFieldTouched}
                                handleCloseModal={handleCloseModal}
                                isEdit={isEdit}
                                setFieldValue={setFieldValue}
                                enableTest={enableTest}
                                values={values}
                                setCurrentTab={setCurrentTab}
                                setEnableTest={setEnableTest}
                                availableButton={availableButton}
                                enableTestButton={enableTestButton}
                                setEnableTestButton={setEnableTestButton}
                                getCustomerDetails={getCustomerDetails}
                                setGetCustomerDetails={setGetCustomerDetails}
                                errors={errors}
                                disableSmsProvider={disableSmsProvider}
                              />
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </>
              )}
              {currentTab === 2 && (
                <div className="w-full">
                  <EmailSettingForm
                    getCustomerDetails={getCustomerDetails}
                    setGetCustomerDetails={setGetCustomerDetails}
                    customerName={customerName}
                    isEdit={isEdit}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                    handleCloseModal={handleCloseModal}
                    emailDataChanges={emailDataChanges}
                    setEmailDataChanges={setEmailDataChanges}
                  />
                </div>
              )}
              {currentTab === 3 && (
                <div className="w-full">
                  <SsoSessionSettingForm
                    getCustomerDetails={getCustomerDetails}
                    setGetCustomerDetails={setGetCustomerDetails}
                    customerName={customerName}
                    isEdit={isEdit}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                    handleCloseModal={handleCloseModal}
                    ssoDataChanges={ssoDataChanges}
                    setSsoDataChanges={setSsoDataChanges}
                    setSsoDetailsPayload={setSsoDetailsPayload}
                  />
                </div>
              )}
              {currentTab === 4 && (
                <div className="w-full">
                  <ProductsAndPlans
                    getCustomerDetails={getCustomerDetails}
                    setGetCustomerDetails={setGetCustomerDetails}
                    customerName={customerName}
                    isEdit={isEdit}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                    handleCloseModal={handleCloseModal}
                    backButtonClicked={backButtonClicked}
                    setBackButtonClicked={setBackButtonClicked}
                    ssoDetailsPayload={ssoDetailsPayload}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {enableTestButton && !isEdit && (
        <DomainTestingModal
          setAvailableButton={setAvailableButton}
          openModal={enableTestButton}
          handleClose={handleCloseDomain}
          setEnableTest={setEnableTest}
          enableTest={enableTest}
          values={initialValues}
        />
      )}
    </Modal>
  );
};

export default AddNewCustomersModal;
