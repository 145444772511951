import React, { useMemo } from "react";
import { getAllKeys } from "../../../helper/connectorHelper";
import { useTranslation } from "react-i18next";
import { InputField } from "../../../connector/form/connector-authentication/InputField";
import SelectField from "../../../../../../../layouts/form/select/SelectField";
import TextAreaField from "../../../../../../../layouts/form/text-area/TextAreaField";
import DynamicFieldArray from "../../../../../../../layouts/form/dynamic-field-array/DynamicFieldArray";
import DynamicFieldArrayTwo from "../../../../../../../layouts/form/dynamic-field-array-two/DynamicFieldArrayTwo";
import { provisionClassNames } from "../../../ProvisionClassNames";

interface RoleProvisionProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  formName: string;
  setGetResponseAttributes?: any;
  setAssignResponseAttributes?: any;
}

const GetRoleGroupProvisionFormField: React.FC<RoleProvisionProps> = ({
  values,
  setFieldValue,
  formName,
  setGetResponseAttributes,
  setAssignResponseAttributes,
}) => {
  const { t } = useTranslation();
  useMemo(() => {
    if (formName === "getRole" || formName === "getGroup") {
      const response = values?.[formName]?.response;
      if (response) {
        try {
          const parsedObject = JSON.parse(response);
          const flattenedKeys: string[] = getAllKeys(parsedObject) || [];
          setGetResponseAttributes(flattenedKeys);
        } catch (error) {
          if (setGetResponseAttributes) {
            setGetResponseAttributes("");
          }
          console.error("Invalid JSON string:", error);
        }
      } else {
        if (setGetResponseAttributes) {
          setGetResponseAttributes("");
        }
      }
    }
  }, [values?.[formName]?.response]);

  useMemo(() => {
    if (formName === "assignRole" || formName === "assignGroup") {
      const response = values?.[formName]?.response;
      if (response) {
        try {
          const parsedObject = JSON.parse(response);
          const flattenedKeys: string[] = getAllKeys(parsedObject) || [];
          setAssignResponseAttributes(flattenedKeys);
        } catch (error) {
          if (setAssignResponseAttributes) {
            setAssignResponseAttributes("");
          }
          console.error("Invalid JSON string:", error);
        }
      } else {
        if (setAssignResponseAttributes) {
          setAssignResponseAttributes("");
        }
      }
    }
  }, [values?.[formName]?.response]);

  const methodType = [
    {
      label: t("appManagement.provisioning.get"),
      value: "Get",
    },
    {
      label: t("appManagement.provisioning.post"),
      value: "Post",
    },
  ];

  return (
    <>
      {/* Get Role || Get Group */}

      {(formName === "getRole" || formName === "getGroup") && (
        <div className="mt-5 pr-20 ">
          <InputField
            label={t("appManagement.provisioning.apiEndpointUrl")}
            name={`${formName}.apiEndpointURL`}
            type="text"
            placeholder="Enter your name"
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            required={true}
            containerClassName={provisionClassNames?.inputFiledContainer}
          />
          <SelectField
            label={t("appManagement.provisioning.methodType")}
            name={`${formName}.methodType`}
            options={methodType}
            placeholder={t("common.select")}
            labelClassName={provisionClassNames?.fieldLabel}
            required
            containerClassName={provisionClassNames?.inputFiledContainer}
            filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
          <TextAreaField
            label={t("appManagement.provisioning.requestPayload")}
            name={`${formName}.requestPayload`}
            data-testid="apiToken-input" // Test ID for your testing framework
            id="apiToken-input"
            className={provisionClassNames?.textAreaField}
            labelClassName={provisionClassNames?.fieldLabel}
            required={values?.[formName]?.methodType === "Post"}
            containerClassName={provisionClassNames?.inputFiledContainer}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue(`${formName}.requestPayload`, e.target.value)}
          />
          <TextAreaField
            label={t("appManagement.provisioning.response")}
            name={`${formName}.response`}
            data-testid="apiToken-input" // Test ID for your testing framework
            id="apiToken-input"
            className={provisionClassNames?.textAreaField}
            labelClassName={provisionClassNames?.fieldLabel}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setFieldValue(`${formName}.response`, e.target.value);
            }}
          />
          <p className="mt-10 mb-3 font-medium text-[#1D1D24] font-Inter flex text-[20px] items-center response-attribute-mapping-box">
            {t("appManagement.provisioning.responseAttributesMapping")}
          </p>
          <div className="grid grid-cols-1 2xl:grid-cols-2 gap-[6%] ">
            <InputField
              label={t("appManagement.provisioning.id")}
              name={`${formName}.serviceProviderAttributesDto.id`}
              type="text"
              className={provisionClassNames?.inputField}
              labelClassName={provisionClassNames?.fieldLabel}
              containerClassName={provisionClassNames?.inputFiledContainer}
            />
            <InputField
              label={t("common.name")}
              name={`${formName}.serviceProviderAttributesDto.name`}
              type="text"
              className={provisionClassNames?.inputField}
              labelClassName={provisionClassNames?.fieldLabel}
              containerClassName={provisionClassNames?.inputFiledContainer}
            />
          </div>
          <div className="grid col-span-2">
            <DynamicFieldArrayTwo
              name={`${formName}.headerParam`}
              label={t("appManagement.provisioning.requestParameters")}
              labelClassName={provisionClassNames?.fieldLabel}
              className={provisionClassNames?.inputField}
              containerClassName={`${provisionClassNames?.inputFiledContainer} w-[100%]`}
              values={values[formName]?.headerParam}
              keyName="key"
              valueName="value"
            />
          </div>
          <div className="grid  col-span-2">
            <DynamicFieldArrayTwo
              name={`${formName}.pathVariable`}
              label={t("appManagement.provisioning.pathVariable")}
              labelClassName={provisionClassNames?.fieldLabel}
              className={provisionClassNames?.inputField}
              containerClassName={`${provisionClassNames?.inputFiledContainer} w-[100%]`}
              values={values[formName]?.pathVariable}
              keyName="key"
              valueName="value"
            />
          </div>
        </div>
      )}

      {/* Assign Role || Assign Group */}

      {(formName === "assignRole" || formName === "assignGroup") && (
        <div className="mt-5 pr-20">
          <InputField
            label={t("appManagement.provisioning.apiEndpointUrl")}
            name={`${formName}.apiEndpointURL`}
            type="text"
            placeholder="Enter your name"
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            required={true}
            containerClassName={provisionClassNames?.inputFiledContainer}
          />
          <SelectField
            label={t("appManagement.provisioning.methodType")}
            name={`${formName}.methodType`}
            options={methodType}
            filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().includes(input.toLowerCase())}
            placeholder={t("common.select")}
            required={true}
            labelClassName={provisionClassNames?.fieldLabel}
            containerClassName={provisionClassNames?.inputFiledContainer}
          />
          <TextAreaField
            label={t("appManagement.provisioning.requestPayload")}
            name={`${formName}.requestPayload`}
            data-testid="apiToken-input" // Test ID for your testing framework
            id="apiToken-input"
            className={provisionClassNames?.textAreaField}
            labelClassName={provisionClassNames?.fieldLabel}
            required={values?.[formName]?.methodType === "Post"}
            containerClassName={provisionClassNames?.inputFiledContainer}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue(`${formName}.requestPayload`, e.target.value)}
          />
          <TextAreaField
            label={t("appManagement.provisioning.response")}
            name={`${formName}.response`}
            data-testid="apiToken-input" // Test ID for your testing framework
            id="apiToken-input"
            className={provisionClassNames?.textAreaField}
            labelClassName={provisionClassNames?.fieldLabel}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setFieldValue(`${formName}.response`, e.target.value);
            }}
          />
          <div className="grid col-span-2 grid-cols-subgrid mt-16">
            <DynamicFieldArray
              name={`${formName}.primaryKeyAttributeList`}
              label={t("appManagement.provisioning.responseAttributesMapping")}
              labelClassName={provisionClassNames?.fieldLabel}
              placeholder={t("appManagement.provisioning.key")}
              className={provisionClassNames?.inputField}
              containerClassName={`${provisionClassNames?.inputFiledContainer} !mb-0 mt-5 dynamic-field-array`}
            />
          </div>
          <div className="grid col-span-2">
            <DynamicFieldArrayTwo
              name={`${formName}.headerParam`}
              label={t("appManagement.provisioning.requestParameters")}
              labelClassName={provisionClassNames?.fieldLabel}
              className={provisionClassNames?.inputField}
              containerClassName={`${provisionClassNames?.inputFiledContainer} w-[100%]`}
              values={values[formName]?.headerParam}
              keyName="key"
              valueName="value"
            />
          </div>
          <div className="grid  col-span-2">
            <DynamicFieldArrayTwo
              name={`${formName}.pathVariable`}
              label={t("appManagement.provisioning.pathVariable")}
              labelClassName={provisionClassNames?.fieldLabel}
              className={provisionClassNames?.inputField}
              containerClassName={`${provisionClassNames?.inputFiledContainer} w-[100%]`}
              values={values[formName]?.pathVariable}
              keyName="key"
              valueName="value"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GetRoleGroupProvisionFormField;
