import React, { useState, useRef, useEffect } from 'react';
import { hasPermission } from '../../../../../../redux/slice/permissions/permissionUtils';
import { modulePermissions } from '../../../../../../const/RolePermissions';
import { useSelector } from 'react-redux';
import PermissionsModal from '../../../../../../layouts/permissionsModal/PermissionsModal';
import { cursorNotAllowedStyle } from '../../../../../../hooks/hooks';
import { PermissionsState } from '../StatusToggleHelper';
import { useTranslation } from 'react-i18next';
import { images } from '../../../../../../assets/images/ImagePaths';
import ImageView from '../../../../../../layouts/component/ImageView';

interface SSOStatusToggleProps {
  handleToggle: (item: any, index: number, status: boolean) => void;
  item: any;
  index: number;
}

export default function SSOStatusToggle({ handleToggle, item, index }: SSOStatusToggleProps) {
  const { t } = useTranslation();

  const permissions = useSelector((state: PermissionsState) => state?.permissionsSlice?.attributes);

  const hasEditAppStorePermission = permissions && hasPermission(permissions, modulePermissions.appStore, 'update');

  const [showToggleSSOStatus, setShowToggleSSOStatus] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);

  const toggleRef = useRef<HTMLDivElement>(null);
  const appSSOstatus = item?.akkuClientDto?.client?.alwaysDisplayInConsole;

  const handleActiveInActive = () => {
    if (hasEditAppStorePermission) {
      setShowToggleSSOStatus((prev) => !prev);
    } else {
      setPermissionModal(true);
    }
  };

  const handleSelectStatus = (el: boolean) => {
    handleToggle(item, index, el);
    setShowToggleSSOStatus(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (toggleRef.current && !toggleRef?.current?.contains(event.target as Node)) {
      setShowToggleSSOStatus(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };

  return (
    <div className="relative top-[-6px] provisioning-card-option-container">
      {/* {showToggleSSOStatus && (
        <div>
          {appSSOstatus ? (
            <p className="card-inactive-btn sso-card-status absolute">
              <p role="none" className="card-status-inactive card-status-btn-label cursor-pointer " onClick={() => handleSelectStatus(false)}>
                <ImageView src={images?.inActiveIcon} alt="in-active-icon" className=" w-[16px] h-[16px] pr-1" />
                {t("common.inactive")}
              </p>
            </p>
          ) : (
            <p className="card-active-btn absolute sso-card-status">
              <p className="card-status-active card-status-btn-label cursor-pointer" role="none" onClick={() => handleSelectStatus(true)}>
                <ImageView src={images?.activeIcon} alt="active-icon" className=" w-[16px] h-[16px] pr-1" />
                {t("common.active")}
              </p>
            </p>
          )}
        </div>
      )} */}

      {appSSOstatus ? (
        <div className={`sso-toggle sso-card-status ${showToggleSSOStatus ? 'sso-toggle-active' : ''}`} ref={toggleRef}>
          <p
            role="none"
            onClick={() => handleActiveInActive()}
            className={`card-status-active sso-status sso-card-status     ${cursorNotAllowedStyle(hasEditAppStorePermission)} `}
          >
            <ImageView src={images?.activeIcon} alt="active-icon" className=" w-[16px] h-[16px] pr-1" />
            {t('common.active')}
          </p>
          <p className={`sso-card-active-btn sso-card-status ${showToggleSSOStatus ? 'activated' : ''}`}>
            <p
              className="card-status-inactive card-status-btn-label cursor-pointer "
              role="none"
              onClick={showToggleSSOStatus ? () => handleSelectStatus(false) : undefined}
            >
              {showToggleSSOStatus ? (
                <>
                  <ImageView src={images?.inActiveIcon} alt="in-active-icon" className=" w-[16px] h-[16px] pr-1" />
                  {t('common.inactive')}
                </>
              ) : null}
            </p>
          </p>
        </div>
      ) : (
        <div className={`sso-toggle sso-card-status ${showToggleSSOStatus ? 'sso-toggle-active' : ''}`} ref={toggleRef}>
          <p
            role="none"
            onClick={() => handleActiveInActive()}
            className={`card-status-inactive sso-status sso-card-status ${cursorNotAllowedStyle(hasEditAppStorePermission)} `}
          >
            <ImageView src={images?.inActiveIcon} alt="in-active-icon" className="w-[16px] h-[16px] pr-1" />
            {t('common.inactive')}
          </p>

          <p className={`sso-card-active-btn  sso-card-status ${showToggleSSOStatus ? 'activated' : ''}`}>
            <p
              className="card-status-active card-status-btn-label cursor-pointer"
              role="none"
              onClick={showToggleSSOStatus ? () => handleSelectStatus(true) : undefined}
            >
              {showToggleSSOStatus ? (
                <>
                  <ImageView src={images?.activeIcon} alt="active-icon" className="w-[16px] h-[16px] pr-1" />
                  {t('common.active')}
                </>
              ) : null}
            </p>
          </p>
        </div>
      )}

      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
    </div>
  );
}
