import React from "react";
import "../Register.scss";
import UploadImage from "./UploadImage";

const UploadCompanyLogo = () => {
  return (
    <>
      <div className="w-full flex new register " data-testid="new-register-id">
        <div className="w-[60%]">
          <div className="upload-logo" data-testid="upload-logo-id"></div>
        </div>
        <div className="w-[40%]">
          <UploadImage />
        </div>
      </div>
    </>
  );
};

export default UploadCompanyLogo;
