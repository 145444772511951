import { Slider } from "antd";
import React, {useEffect, useState } from "react";
import { storeData } from "../../../services/storage/Storage";
import "./Progress.scss";
const UserProgressBar = () => {
  const [sliderValue, setSliderValue] = useState(3000);
  const handleSliderChange = (value: any) => {
    setSliderValue(value);
    storeData("totalLicense", value || sliderValue, true);
  };
  useEffect(()=>{
    storeData("totalLicense", sliderValue, true);
  },[])

  return (
  
      <div className="progress-bar mt-16">
        <label className="text-[#272727] font-Inter text-[20px] font-normal">No of Users</label>
        <Slider value={sliderValue} max={10000} onChange={handleSliderChange} />
      </div>
    
  );
};

export default UserProgressBar;
