import { Modal, Select, DatePicker, DatePickerProps, Button } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { Formik, Form, Field, ErrorMessage } from "formik";
import type { Dayjs } from "dayjs";
import { hoursDropDownOptions, minutesDropDownOptions } from "./ActivateProvisionModalHelper";
import { useTranslation } from "react-i18next";
const ActivateProvisionModal = (props: any) => {
  const { t } = useTranslation();

  const { open, handleClose, activateConnectorFunction } = props;

  const formInitialValues = {
    timing: `${t("appManagement.immediate")}`,
    hours: "01",
    minutes: "00",
    clockFormat: "AM",
  };
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showDateTimePicker, setShowDateTimePicker] = useState<boolean>(false);
  const dropDownOptions: any = ["Immediate"];
  const clockFormatDropDownOptions: any = ["AM", "PM"];
  useEffect(() => {
    setModalOpen(open);
  }, [setModalOpen]);
  const handleCloseModal = () => {
    handleClose();
  };
  const onSubmit = (values: any) => {
    activateConnectorFunction();
  };
  const handleOnChange = (value: any, setFieldValue: any) => {
    if (value === "Schedule") {
      setShowDateTimePicker(true);
    } else {
      setShowDateTimePicker(false);
    }
    setFieldValue("timing", value);
  };
  const handelDateChange: DatePickerProps<Dayjs[]>["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <Modal width={"800px"} className=" rounded-lg" maskClosable={false} centered open={modalOpen} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 activate-provision-modal">
        <div className="flex w-full justify-center items-start flex-col">
          <h5 className=" font-Inter text-[24px] pt-3 font-semibold"> {t("appManagement.activateProvision")}</h5>
          <p className="text-[16px] font-Inter font-medium text-[#75738A]">{t("appManagement.noteStartProvisioningEngine")}</p>
        </div>

        <div>
          <Formik initialValues={formInitialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <div className="w-full mt-4">
                  <div className="w-[full] flex items-center pb-10">
                    <div className="w-[30%] text-[20px] font-semibold flex flex-col">
                      <span>{t("appManagement.selectTheOption")}</span>
                      {showDateTimePicker && <div className="h-[48px]"></div>}
                    </div>
                    <div className=" w-[30%]">
                      <Field
                        disabled
                        as={Select}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className={" w-[100%] h-[56px] "}
                        name="timing"
                        onChange={(value: string) => handleOnChange(value, setFieldValue)}
                        defaultValue="Immediate"
                      >
                        {dropDownOptions?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      {showDateTimePicker && (
                        <div className="mt-2 flex">
                          <DatePicker className="!min-w-[220px] !h-[56px]" onChange={handelDateChange} />
                          <div className="flex gap-2 items-center ml-2">
                            <Field
                              as={Select}
                              suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                              className={" w-[80px] h-[56px] "}
                              name="hours"
                              onChange={(value: string) => setFieldValue("hours", value)}
                              defaultValue="01"
                            >
                              {hoursDropDownOptions?.map((option: any) => (
                                <Select.Option key={option} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Field>
                            <p className="font-extrabold">:</p>
                            <Field
                              as={Select}
                              suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                              className={" w-[80px] h-[56px] "}
                              name="minutes"
                              onChange={(value: string) => setFieldValue("minutes", value)}
                              defaultValue="00"
                            >
                              {minutesDropDownOptions?.map((option: any) => (
                                <Select.Option key={option} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Field>
                            <Field
                              as={Select}
                              suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                              className={" w-[80px] h-[56px] "}
                              name="clockFormat"
                              onChange={(value: string) => setFieldValue("clockFormat", value)}
                              defaultValue="AM"
                            >
                              {clockFormatDropDownOptions?.map((option: any) => (
                                <Select.Option key={option} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Field>
                          </div>
                        </div>
                      )}
                      <ErrorMessage name="timing" component="div" className="error-message custom-error" />
                    </div>
                  </div>

                  <div className=" pt-14 text-end">
                    <div className="w-full flex justify-end">
                      <CustomButtonBack onClick={handleCloseModal} text={t("common.cancel")} />
                      <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn ">
                        {t("common.save")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ActivateProvisionModal;
