import React from "react";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";
import { useTranslation } from "react-i18next";

const MyAppsEmpty = () => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full justify-center items-center flex-col min-h-[300px]">
      <ImageView src={images.appsConfigure} className="w-[80px] mb-6 empty-app" alt="apps configure" />
      <p className="text-[#444] text-[18px] font-Inter font-medium">{t("appManagement.emptyCardInformation")}</p>
    </div>
  );
};

export default MyAppsEmpty;
