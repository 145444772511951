import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import UserDeProvision from "./user-deProvisioning/UserDeprovision";
import RoleDeprovision from "./role-deprovisioning/RoleDeprovision";
import GroupDeprovision from "./group-deprovisioning/GroupDeprovision";
import { useTranslation } from "react-i18next";

const ConnectorProvision = () => {
  const { t } = useTranslation();
  const userDeProvisionRef = useRef<any>(null);
  const roleDeProvisionRef = useRef<any>(null);
  const groupDeProvisionRef = useRef<any>(null);
  const collapseRef = useRef<any>(null);

  const isApiCalled = useSelector((state: any) => state?.provisioning?.isDeProvisioningApiCalled);

  const [activeKey, setActiveKey] = useState<string>("1");

  const handleStepChange = async (key: string | string[]) => {
    const activePanelKey = key[0]; // Directly access the first element of the array

    if (activeKey === "1") {
      if (activePanelKey === "2") {
        if (!isApiCalled?.userProvisionApiCalled) {
          await userDeProvisionRef.current?.submitForm();
        } else if (userDeProvisionRef.current?.dirty) {
          await userDeProvisionRef.current?.submitForm();
        } else {
          setActiveKey("2");
        }
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.userProvisionApiCalled) {
          await userDeProvisionRef.current?.submitForm();
        } else if (userDeProvisionRef.current?.dirty) {
          await userDeProvisionRef.current?.submitForm();
        } else if (!isApiCalled?.roleProvisionApiCalled) {
          setActiveKey("2");
          await roleDeProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "2") {
      if (activePanelKey === "1") {
        setActiveKey("1");
      }

      if (activePanelKey === "3") {
        if (!isApiCalled?.assignRoleProvisionApiCalled) {
          await roleDeProvisionRef.current?.submitForm();
        } else if (roleDeProvisionRef.current?.dirty) {
          await roleDeProvisionRef.current?.submitForm();
        } else {
          setActiveKey("3");
        }
      }
    }

    if (activeKey === "3") {
      if (activePanelKey === "2") {
        setActiveKey("2");
      }
      if (activePanelKey === "1") {
        setActiveKey("1");
      }
    }
  };

  return (
    <div className="relative h-full provisioning-accordion-wrapper ">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-127px)] connector-provision-box">
        <p className="app-header-title ">{t("appManagement.provisioning.connectorDetailsDeprovisioning")}</p>
        <div>
          <div className="flex items-center mt-7 provisioning-stepper-line">
            <div
              ref={collapseRef}
              className={`w-[120px] provisioning-stepper-label ${Number(activeKey) >= 1 ? "dot-active !text-[#5441da]" : "dot-inactive"}`}
            >
              <span className="cursor-pointer" onClick={() => handleStepChange("1")} role="none">
                {t("appManagement.provisioning.user")}
              </span>
            </div>
            <div
              ref={collapseRef}
              className={`w-[120px] provisioning-stepper-label ${Number(activeKey) >= 2 ? "dot-active !text-[#5441da]" : "dot-inactive"} `}
            >
              <span className={`${Number(activeKey) >= 2 ? "green-line" : ""}`}></span>{" "}
              <span onClick={() => handleStepChange("2")} role="none" className="cursor-pointer">
                {t("appManagement.provisioning.role")}
              </span>
            </div>
            <div
              ref={collapseRef}
              className={`w-[120px] provisioning-stepper-label ${Number(activeKey) >= 3 ? "dot-active !text-[#5441da]" : "dot-inactive"} `}
            >
              <span className={`${Number(activeKey) >= 3 ? "green-line" : ""}`}></span>{" "}
              <span onClick={() => handleStepChange("3")} role="none" className="cursor-pointer">
                {t("appManagement.provisioning.group")}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[100%] mt-12 overflow-y-auto h-auto max-h-[490px] pb-8 provision-form-wrapper de-provision-form-wrapper">
          <div className="w-[90%] de-provision-box">
            {Number(activeKey) === 1 && <UserDeProvision formRef={userDeProvisionRef} setActiveKey={setActiveKey} />}
            {Number(activeKey) === 2 && <RoleDeprovision formRef={roleDeProvisionRef} setActiveKey={setActiveKey} />}
            {Number(activeKey) === 3 && <GroupDeprovision formRef={groupDeProvisionRef} setActiveKey={setActiveKey} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectorProvision;
