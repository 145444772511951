import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, Select, Switch, Tooltip } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ReactComponent as GreenCheckIcon } from "./../../../../../assets/images/icons/greencheck.svg";
import trashcan from "./../../../../../assets/images/icons/trashcan.svg";
import * as Yup from "yup";
import { validationErrorMessages } from "../../../../../constant/validationMessage";
import Loader from "../../../../../layouts/component/Loader";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import PhoneInput from "react-phone-input-2";
import GroupAppList from "./GroupAppList";
import ConnectedApplicationEdit from "../../user-dashboard/groups/layout/ConnectedApplicationEdit";
import { images } from "../../../../../assets/images/ImagePaths";
import ImageView from "../../../../../layouts/component/ImageView";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import UserCreationNotificationModal from "../../user-dashboard/add-user/layouts/single-upload/directory/UserCreationNotificationModal";
import { ErrorMessages } from "../../../../../const/Messages";
import CustomFieldBasic from "./CustomFieldBasic";
import CustomAttributesField from "./CustomAttributesField";
import { useSelector } from "react-redux";
import UserRestriction from "./UserRestrictionTable";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../i18n/locales/config";
import UserInformationHeader from "../../layout/UserInformationHeader";
import UserAppTabs from "../../layout/UserAppTabs";
import UserBehaviour from "../../layout/UserBehaviour";
import GroupsConnectedAppsTab from "../../layout/GroupsConnectedAppsTab";
import InActiveUserModal from "./DeactivateUserModal";
import ReactiveUserModal from "../../user-dashboard/adduser/usertable/ReactiveUserModal";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { retrieveData } from "../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
const UserManagementForm = (props: any) => {
  const { t, i18n } = useTranslation();
  const customerDetailsDefaultPassword = useSelector((state: any) => state?.userBasicDetails?.customerDetailsDefaultPassword);

  const [isDirty, setIsDirty] = useState(false);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("userManagement.errors.firstName.required"))
      .matches(/^[a-zA-Z\s]*$/, t("userManagement.errors.firstName.letterAndSpace"))
      .min(4, t("userManagement.errors.firstName.minLength"))
      .max(16, t("userManagement.errors.firstName.maxLength")),

    lastName: Yup.string()
      .required(t("userManagement.errors.lastName.required"))
      .matches(/^[a-zA-Z\s]*$/, t("userManagement.errors.lastName.letterAndSpace"))
      .min(1, t("userManagement.errors.lastName.minLength"))
      .max(16, t("userManagement.errors.lastName.maxLength")),

    phone: customerDetailsDefaultPassword
      ? Yup.string().min(8, t("userManagement.errors.phone.minLength")).max(13, t("userManagement.errors.phone.maxLength"))
      : Yup.string()
          .required(t("userManagement.errors.phone.required"))
          .min(8, t("userManagement.errors.phone.minLength"))
          .max(13, t("userManagement.errors.phone.maxLength")),

    personalEmail: customerDetailsDefaultPassword
      ? Yup.string().email(t("userManagement.errors.personalEmail.invalidEmail"))
      : Yup.string().email(t("userManagement.errors.personalEmail.invalidEmail")).required(t("userManagement.errors.personalEmail.required")),

    workEmail: Yup.string().email(t("userManagement.errors.workEmail.invalidEmail")).required(t("userManagement.errors.workEmail.required")),

    customField: Yup.array().of(
      Yup.object().shape({
        value: Yup.string()
          .test("is-not-first", t("userManagement.errors.customField.required"), function (value, context: any) {
            const index = context?.options?.index;
            if (index === 0) return true;
            return value !== undefined && value !== "";
          })
          .min(3, t("userManagement.errors.customField.minLength")),

        name: Yup.string()
          .required(t("userManagement.errors.customField.required"))
          .min(3, t("userManagement.errors.customField.minLength"))
          .test("unique-name", t("userManagement.errors.customField.uniqueName"), function (value: any) {
            const { path, options }: any = this;
            const fields: any = options.context.customField;
            const currentFieldIndex = parseInt(path.match(/\d+/)[0]);
            const isDuplicate = fields?.filter((field: any, index: any) => index !== currentFieldIndex).some((field: any) => field.name === value);
            return !isDuplicate;
          }),
      }),
    ),

    customField2: Yup.array().of(
      Yup.object().shape({
        value: Yup.string()
          .test("is-not-first", t("userManagement.errors.customField.required"), function (value, context: any) {
            const index = context?.options?.index;
            if (index === 0) return true;
            return value !== undefined && value !== "";
          })
          .min(3, t("userManagement.errors.customField.minLength")),

        name: Yup.string()
          .required(t("userManagement.errors.customField.required"))
          .min(3, t("userManagement.errors.customField.minLength"))
          .test("unique-name", t("userManagement.errors.customField.uniqueName"), function (value: any) {
            const { path, options }: any = this;
            const fields: any = options.context.customField2;
            const currentFieldIndex = parseInt(path.match(/customField2\[(\d+)\]\.name/)[1]);
            const isDuplicate = fields?.filter((field: any, index: any) => index !== currentFieldIndex).some((field: any) => field.name === value);
            return !isDuplicate;
          }),
      }),
    ),
  });
  const formikRef = useRef<any>(null);
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [i18n.language]);
  const {
    initialValues,
    onSubmit,
    isFormDirty,
    setFormDirty,
    disableFields,
    isEdit,
    roleSelected,
    onChange,
    userTypeList,
    selectedRowObject,
    deleteRestriction,
    handleOnChangePhone,
    userDetails,
    setListOfApps,
    listOfApps,
    individualApps,
    tempListApps,
    setCheckedState,
    checkedState,
    isActiveDeviceRestriction,
    handleToBack,
    loader,
    handleModalClose,
    confirmDeleteRestriction,
    modalOpen,
    setModalOpen,
    restrictionList,
    setUserDetails,
    deleteModal,
    temporaryPasswordEvents,
    handleResetPasswordHistoryModal,
    handleResetPasswordModal,
    listOfProvisioningApps,
    setListOfProvisioningApps,
    tempListProvisioningApps,
    setTempListProvisioningApps,
    individualProvisioningApps,
    enabledStatus,
    statusToggleAccess,
    setEnabled,
    setIndividualProvisioningApps,
    setIndividualApps,
  } = props.props;
  const [userEnabled, setUserEnabled] = useState(enabledStatus);
  const [activeUser, setActivateUser] = useState(false);
  const [deActiveUser, setDeActivateUser] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const currentSelectedRow = retrieveData("currentSelectedRow", true);
  const editUserStatus = useGetApiRequests("editUser", "PUT");

  useEffect(() => {
    setUserEnabled(enabledStatus);
  }, [enabledStatus]);
  const onChangeUserStatus = (checked: boolean) => {
    setUserEnabled(checked);
    if (checked === true) {
      setActivateUser(true);
    } else {
      setDeActivateUser(true);
    }
  };
  const handleActivateUser = () => {
    editUserStatusFunction();
  };
  const editUserStatusFunction = async () => {
    setLoaders(true);
    const pathParams = {
      id: `${currentSelectedRow?.key}/status`,
    };
    const queryParams: object = {
      isEnabled: userEnabled,
      realmId: retrieveData("realmId", true),
    };
    try {
      setActivateUser(false);
      setDeActivateUser(false);
      const response: any = await editUserStatus("", queryParams, pathParams);
      const status = response.status;
      if (status === 200) {
        setLoaders(false);
        const responseData = response?.data;
        triggerNotification("success", "", responseData?.message, "topRight");
        setEnabled(true);
      }
    } catch (err) {
      setLoaders(false);
      handleRequestError(err);
    }
  };
  const handleCloseInActiveModal = () => {
    setUserEnabled(!userEnabled);
    setDeActivateUser(false);
  };
  const handleCancelActivateUser = () => {
    setUserEnabled(!userEnabled);
    setActivateUser(false);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        values={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        isInitialValid={isFormDirty}
      >
        {({ handleSubmit, errors, setFieldValue, dirty, values }) => (
          <Form
            onSubmit={(e) => {
              setFormDirty(true);
              handleSubmit(e);
            }}
          >
            <div className="">
              <div className="flex w-full edit_user_dashboard z-[1] update-edit-user-management justify-between">
                <div className="w-[15%]"></div>
                {(dirty || isDirty) && (
                  <div className="flex gap-x-1 items-center justify-center">
                    <p>{t("userManagement.submitDetailsPrompt")}</p>{" "}
                    <Button
                      type="primary"
                      className="ml-4 mr-2 flex items-center justify-evenly font-semibold text-[18px] font-Inter w-auto min-w-[115px] h-[40px] bg-[#5441DA] text-white rounded-full"
                      size="large"
                      htmlType="submit"
                    >
                      {t("common.update")}
                    </Button>
                    <Button
                      className="flex items-center justify-evenly font-semibold text-[18px] font-Inter w-[115px] h-[40px] !text-[#5441DA] bg-[#FFFFFF] border-[#5441DA] rounded-full"
                      size="large"
                      onClick={() => handleToBack()}
                    >
                      {t("common.cancel")}
                    </Button>
                  </div>
                )}
                <div className="flex justify-end items-center pr-8">
                  <span className={`${userEnabled ? "  text-[#ABABAB]" : "text-[#111]"} pr-3  lg:text-[20px] text-[16px] font-Inter font-medium`}>
                    {t("userManagement.disable")}
                  </span>
                  <Switch disabled={statusToggleAccess} data-testid="subscription-switch" checked={userEnabled} onChange={onChangeUserStatus} />
                  <span className={`${userEnabled ? "  text-[#111]" : "text-[#ABABAB]"} pl-3  lg:text-[20px] text-[16px] font-Inter font-medium`}>
                    {t("userManagement.enable")}
                  </span>
                </div>
              </div>
            </div>
            <div className="userCard wrapper-user-card">
              <div className="pr-4 max-h-[700px] overflow-y-auto">
                <UserInformationHeader
                  temporaryPasswordEvents={temporaryPasswordEvents}
                  handleResetPasswordHistoryModal={handleResetPasswordHistoryModal}
                  handleResetPasswordModal={handleResetPasswordModal}
                />
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
                  <div className="mb-2 relative">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.firstName")}*</p>
                    <Field
                      as={Input}
                      disabled={disableFields}
                      type="text"
                      className="h-[56px] font-medium text-[18px]"
                      name="firstName"
                      maxLength={16}
                      data-testid="fnameId"
                    />
                    <ErrorMessage name="firstName" component="div" className="error-message" />
                  </div>
                  <div className="mb-2 relative">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.lastName")}*</p>
                    <Field
                      as={Input}
                      disabled={disableFields}
                      type="text"
                      className="h-[56px] font-medium text-[18px]"
                      name="lastName"
                      maxLength={16}
                      data-testid="lnameId"
                    />
                    <ErrorMessage name="lastName" component="div" className="error-message" />
                  </div>
                  <div className="mb-2 relative">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.workEmailId")} *</p>
                    <Field
                      as={Input}
                      disabled={disableFields}
                      type="email"
                      readOnly={isEdit || ""}
                      className={`${isEdit ? "cursor-not-allowed" : ""} h-[56px] font-medium text-[18px]`}
                      name="workEmail"
                    />
                    <ErrorMessage name="workEmail" component="div" className="error-message" />
                  </div>
                  <div className="mb-2">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid="UTypeId">
                      {t("userManagement.userType")}*
                    </p>
                    <Field
                      disabled={disableFields}
                      as={Select}
                      suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                      className=" w-[100%] h-[56px]"
                      name="userType"
                      value={roleSelected}
                      onChange={(value: string) => onChange(value, setFieldValue)}
                      filterOption={(input: string, option: React.ReactElement) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {userTypeList?.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Field>
                    <ErrorMessage name="userType" component="div" className="error-message" />
                  </div>
                  {selectedRowObject?.restrictions === "active" && (
                    <div className="mb-2">
                      <p className="text-[#171717] text-[24px] font-inter font-semibold">{t("userManagement.restrictions")}</p>
                      <div className=" w-[40%] -form flex items-center justify-between mt-2">
                        <Input
                          disabled={true}
                          value={"Device Restriction"}
                          type="text"
                          className="h-[46px] flex items-center font-medium text-[18px]"
                          prefix={<GreenCheckIcon />}
                        />
                        <Tooltip title={"Remove Restriction"}>
                          <span>
                            <img role="none" src={trashcan} alt="delete" className="cursor-pointer h-[33px]" onClick={deleteRestriction} />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                  <div className="mb-2 relative">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">
                      {t("userManagement.personalEmailId")} {customerDetailsDefaultPassword ? null : "*"}
                    </p>
                    <Input
                      type="email"
                      className="h-[56px] font-medium"
                      name="personalEmail"
                      onChange={(e) => setFieldValue("personalEmail", e.target.value)}
                      value={values.personalEmail}
                      data-testid="emailId"
                      disabled={disableFields}
                    />
                    <ErrorMessage name="personalEmail" component="div" className="error-message" />
                  </div>
                  <div className="mb-2 relative">
                    <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid="phoneId">
                      {t("userManagement.mobileNumber")} {customerDetailsDefaultPassword ? null : "*"}
                    </p>
                    <PhoneInput
                      disabled={disableFields}
                      inputClass="ant-input h-[56px] w-[100%]"
                      country={"us"}
                      value={values.phone}
                      onChange={(value: any) => handleOnChangePhone(value, setFieldValue)}
                    />
                    <ErrorMessage name="phone" component="div" className="error-message" />
                  </div>
                </div>

                <div className="mt-8">
                  <CustomAttributesField
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    userDetails={userDetails}
                    setUserDetails={setUserDetails}
                    hasValues={true}
                  />
                </div>

                <div className="custom-field w-full mx-auto mb-10">
                  <div className="flex w-full justify-between items-center ">
                    <div className="text w-[75%] mt-5 ">
                      <p className="text-[20px] font-semibold font-Inter">{t("userManagement.enterDetailsOrAddCustomField")}</p>
                    </div>
                  </div>
                </div>
                <CustomFieldBasic
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="w-[65%]">
                <div className="userCard">
                  <UserAppTabs
                    setIsDirty={setIsDirty}
                    setListOfApps={setListOfApps}
                    listOfApps={listOfApps}
                    name={t("userManagement.individualApps")}
                    styleName="individual-apps"
                    individualApps={individualApps}
                    tempListApps={tempListApps}
                    listOfProvisioningApps={listOfProvisioningApps}
                    setListOfProvisioningApps={setListOfProvisioningApps}
                    tempListProvisioningApps={tempListProvisioningApps}
                    setTempListProvisioningApps={setTempListProvisioningApps}
                    individualProvisioningApps={individualProvisioningApps}
                    setIndividualProvisioningApps={setIndividualProvisioningApps}
                    setIndividualApps={setIndividualApps}
                  />
                </div>
                {/* userDetails, setIsDirty, checkedState, setCheckedState  */}

                <div className="userCard groupsConnected">
                  {userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap && (
                    <GroupsConnectedAppsTab
                      userDetails={userDetails}
                      setIsDirty={setIsDirty}
                      checkedState={checkedState}
                      setCheckedState={setCheckedState}
                      name={t("userManagement.groupsConnectedApps")}
                      styleName="individual-apps"
                      individualApps={individualApps}
                      tempListApps={tempListApps}
                    />
                  )}
                </div>

                {/* <div className="userCard groupsConnected">
                {userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap && (
                  <>
                    <h1 className="editUser-title groupsConnected-title">{t("userManagement.groupsConnectedApps")}</h1>
                    <GroupAppList setIsDirty={setIsDirty} data={userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap} setCheckedState={setCheckedState} checkedState={checkedState} />
                  </>
                )}
              </div> */}

                <div className="userCard min-h-[418px]">
                  {isActiveDeviceRestriction && (
                    <div>
                      <p className="text-[#171717] text-[24px] font-inter font-semibold mb-4">{t("userManagement.restrictions")}</p>
                      <UserRestriction restrictionList={restrictionList} />
                    </div>
                  )}
                </div>
              </div>

              <div className="userBehaviour-height">
                <UserBehaviour />
              </div>
            </div>

            {/* <div className={` ${isEdit ? "pb-1 relative bottom-0 left-0 mt-0 " : "pb-0 "} modal-footer p-8 pr-[30px] w-[100%] mx-auto`}>
              <div className="w-full flex justify-end">
                <CustomButtonBack onClick={handleToBack} text={"Cancel"} />
                <Button
                  data-testid="submit-button"
                  type="primary"
                  htmlType="submit"
                  className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                >
                  Update
                </Button>
              </div>
            </div> */}
            {loader && <Loader />}
            <Modal
              className="p-10  show-notification  _suspended rounded-lg"
              maskClosable={false}
              centered
              open={deleteModal}
              footer={false}
              onCancel={handleModalClose}
            >
              <div className="modal-body pt-4 ">
                <div className="flex w-full justify-center items-center flex-col">
                  <ImageView src={images.notificationSuspend} alt="notificationDone" className="w-20 h-20" />
                  <h5 className="text-[24px] font-Inter pt-3 font-semibold">{""}</h5>
                  <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">
                    {" "}
                    {t("userManagement.deleteRestrictionsConfirmation")}
                  </p>
                </div>
              </div>
              <div className="modal-footer pt-8">
                <div className="w-full flex justify-center">
                  <CustomButtonBack text={`${t("common.no")}`} onClick={handleModalClose} />
                  <CustomButtonNext text={`${t("common.yes")}`} onClick={confirmDeleteRestriction} />
                </div>
              </div>
            </Modal>
            {modalOpen && (
              <UserCreationNotificationModal
                type={"userBasicDetails"}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                responseMessage={t("userManagement.confirmationMessage")}
              />
            )}
          </Form>
        )}
      </Formik>
      {deActiveUser && (
        <InActiveUserModal
          openModal={deActiveUser}
          handleProceedFunction={handleActivateUser}
          handleCancelFunction={handleCloseInActiveModal}
          displayMessageContent={t("userManagement.inActiveUser")}
        />
      )}
      {activeUser && (
        <ReactiveUserModal
          active={activeUser}
          handleProceed={handleActivateUser}
          handleCancel={handleCancelActivateUser}
          displayContent={t("userManagement.activeUser")}
        />
      )}
      {loaders && <Loader />}
    </>
  );
};

export default UserManagementForm;
