import React from 'react'
import ImageView from '../../../../../layouts/component/ImageView'
import { images } from '../../../../../assets/images/ImagePaths'

const UserRightSide = () => {
  return (
    <>
    <div className="w-full flex flex-col justify-center items-center">
    <ImageView  src={images.adminIcon} alt="userIcon"  className='w-[70px] h-[70px] mb-3'/>
       <h5 className='text-[32px] text-[#171717] font-semibold  font-Inter pb-2'>User Management</h5>
       <p className='text-[22px] font-Inter text-center text-[#5d5d5d] font-medium pb-8'>Choose any one of the following to add users </p>
    </div>
    
    </>
  )
}

export default UserRightSide