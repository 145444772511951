import { Field, ErrorMessage, FieldAttributes, useFormikContext } from 'formik';
import { Select } from 'antd';
import React from 'react';

interface Option {
  label: string;
  value: string | number;
}

interface SelectFieldProps extends FieldAttributes<any> {
  label: string;
  name: string;
  options: Option[];
  className?: string;
  containerClassName?: string;
  filterOption?: (input: string, option: React.ReactElement) => boolean;
  required?: boolean;
  labelClassName?: string;
}

const SelectField: React.FC<SelectFieldProps> = ({
  label,
  name,
  options,
  className = '',
  containerClassName = '',
  filterOption,
  required = true,
  labelClassName,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className={` relative ${containerClassName}`}>
      {label && (
        <label className={labelClassName}>
          {label}
          {required && <span className=""> *</span>}
        </label>
      )}
      <Field
        as={Select}
        placeholder="Select"
        className={`h-[56px] loginThemeText font-medium text-[18px] w-[100%] ${className}`}
        name={name}
        onChange={(value: string) => setFieldValue(name, value)}
        filterOption={filterOption}
        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
        {...props}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Field>
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};

export default SelectField;
