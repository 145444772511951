import React, { useEffect, useState } from "react";
import ImageView from "../../layouts/component/ImageView";
import { images } from "../../assets/images/ImagePaths";
import { Formik } from "formik";
import PasswordManagerForm from "./PasswordManagerForm";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { retrieveData } from "../../services/storage/Storage";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../layouts/component/Loader";
import { useSelector } from "react-redux";
import { modulePermissions } from "../../const/RolePermissions";
import { hasPermission } from "../../redux/slice/permissions/permissionUtils";
import PermissionsModal from "../../layouts/permissionsModal/PermissionsModal";
import "./PasswordManager.scss";
import { passwordManagerValidationSchema } from "./PasswordManagerHelper";
import { useTranslation } from "react-i18next";
const PasswordManager = () => {
  const { t } = useTranslation();

  const updatePasswordManagerApi = useGetApiRequests("updatePasswordManager", "PUT");
  const getPasswordPolicy = useGetApiRequests("getPasswordPolicy", "GET");

  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const hasUpdatePermission = permissions && hasPermission(permissions, modulePermissions.passwordManager, "update");
  const realmId = retrieveData("realmId", true);
  const realmName = retrieveData("realmName", true);

  const [loader, setLoader] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [passwordExpiry, setPasswordExpiry] = useState(15);
  const [passwordLength, setPasswordLength] = useState(8);
  const [isRestoreDefaults, setIsRestoreDefaults] = useState(false);
  const [passwordLengthAction, setPasswordLengthAction] = useState(false);
  const [lowercasePassword, setLowercasePassword] = useState(false);
  const [uppercasePassword, setUppercasePassword] = useState(false);
  const [specialCharactersPassword, setSpecialCharactersPassword] = useState(false);
  const [digitsPassword, setDigitsPassword] = useState(false);
  const [recentlyUsedPassword, setRecentlyUsedPassword] = useState(false);

  const [initialValues, setInitialValues] = useState({
    passwordExpiry: 90,
    passwordLength: 10,
    uppercase: true,
    lowercase: true,
    specialCharacter: true,
    digit: true,
    recentlyUsed: true,
    loginAttempts: "",
  });
  useEffect(() => {
    getPasswordPolicyList();
  }, [isRestoreDefaults]);

  const getPasswordPolicyList = async () => {
    setLoader(true);
    const pathParams = {
      id: realmName,
    };
    try {
      const response: any = await getPasswordPolicy("", {}, pathParams);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;

        setInitialValues({
          passwordExpiry: data?.passwordExpiry,
          passwordLength: data?.passwordLength,
          uppercase: data?.uppercaseCharacters,
          lowercase: data?.lowercaseCharacters,
          specialCharacter: data?.specialCharacters,
          digit: data?.digits,
          recentlyUsed: data?.notRecentlyUsed,
          loginAttempts: data?.loginAttempts,
        });
        setPasswordExpiry(data?.passwordExpiry);
        setPasswordLength(data?.passwordLength);
        setLowercasePassword(data?.lowercaseCharacters);
        setSpecialCharactersPassword(data?.specialCharacters);
        setUppercasePassword(data?.uppercaseCharacters);
        setDigitsPassword(data?.digits);
        setRecentlyUsedPassword(data?.notRecentlyUsed);

        setLoader(false);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    if (hasUpdatePermission) {
      setLoader(true);
      const payload: object = {
        passwordExpiry: passwordExpiry,
        passwordLength: passwordLength,
        uppercaseCharacters: values?.uppercase,
        lowercaseCharacters: values?.lowercase,
        specialCharacters: values?.specialCharacter,
        digits: values?.digit,
        notRecentlyUsed: values?.recentlyUsed,
        restoreDefault: false,
        realmId: realmId,
        loginAttempts: values?.loginAttempts,
      };
      setSubmitting(false);
      try {
        const response: any = await updatePasswordManagerApi(payload);
        const status = response?.status;
        if (status === 200) {
          setLoader(false);
          setPasswordLengthAction(false);
          const data = response?.data;
          triggerNotification("success", "", data?.message, "topRight");
          setIsRestoreDefaults(!isRestoreDefaults);
        }
      } catch (err: any) {
        setLoader(false);
        handleRequestError(err);
      }
    } else {
      setPermissionModal(true);
    }
  };
  const restoreToDefault = async () => {
    if (hasUpdatePermission) {
      setLoader(true);
      const payloadForDefaults: any = {
        restoreDefault: true,
        realmId: realmId,
      };
      try {
        const response: any = await updatePasswordManagerApi(payloadForDefaults);
        const status = response?.status;
        if (status === 200) {
          const data = response?.data;
          setPasswordLengthAction(false);
          triggerNotification("success", "", data?.message, "topRight");
          setIsRestoreDefaults(!isRestoreDefaults);
        }
      } catch (err: any) {
        setLoader(false);
        handleRequestError(err);
      }
    } else {
      setPermissionModal(true);
    }
  };
  const handleChange = (event: any, type: any, setFieldValue: any) => {
    setFieldValue(type, event);
    switch (type) {
      case "uppercase":
        setUppercasePassword(event);
        break;
      case "lowercase":
        setLowercasePassword(event);
        break;
      case "specialCharacter":
        setSpecialCharactersPassword(event);
        break;
      case "digit":
        setDigitsPassword(event);
        break;
      case "recentlyUsed":
        setRecentlyUsedPassword(event);
        break;
      default:
        break;
    }
  };
  const handleClosePermissionModal = () => {
    setPermissionModal(false);
  };
  return (
    <div className="akku-container">
      <div className="main-container ">
        <div className="dashboard-container password">
          <div className="header-content flex justify-between">
            <h2>{t("passwordManager.passwordManager")}</h2>
          </div>
          <div className="w-full">
            <div className="password-policy pt-6">
              <div className="password-content">
                <div className="password-header flex ">
                  <div className="w-12">
                    <ImageView src={images.passwordLock} alt="passwordLock" />
                  </div>
                  <p>{t("passwordManager.passwordPolicy")}</p>
                </div>

                <div className="w-full flex password-manager">
                  <Formik validationSchema={passwordManagerValidationSchema(t)} initialValues={initialValues} values={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
                    {({ values, handleSubmit, setFieldValue }) => (
                      <PasswordManagerForm
                        passwordExpiry={passwordExpiry}
                        setPasswordExpiry={setPasswordExpiry}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        isSubmitting={restoreToDefault}
                        passwordLength={passwordLength}
                        setPasswordLength={setPasswordLength}
                        setPasswordLengthAction={setPasswordLengthAction}
                        passwordLengthAction={passwordLengthAction}
                        lowercasePassword={lowercasePassword}
                        uppercasePassword={uppercasePassword}
                        specialCharactersPassword={specialCharactersPassword}
                        digitsPassword={digitsPassword}
                        recentlyUsedPassword={recentlyUsedPassword}
                        values={values}
                        hasUpdatePermission={hasUpdatePermission}
                      />
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader data-testid="loader" />}
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
    </div>
  );
};

export default PasswordManager;
