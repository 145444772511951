import React from "react";

const CommonHeader = ({ onClick, text, className }: any) => {
  return (
    <div className="  w-full flex">
      <p className={`title flex items-center onboard-title ${text === "General Details" && "ml-[55px]"} `} onClick={onClick}>
        {text !== "General Details" && <span className={`${"material-symbols-outlined text-3xl mr-5 cursor-pointer"} ${className} `}>arrow_back</span>}
        {text}
      </p>
    </div>
  );
};

export default CommonHeader;
