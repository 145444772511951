import React from "react";
import { Button } from "antd";
import { retrieveData } from "../../services/storage/Storage";
import { useNavigate } from "react-router-dom";
import "./PageNotFound.scss";
import { useTranslation } from "react-i18next";
const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userRole = retrieveData("role", false);
  const handleNavigateDashboard = () => {
    if (userRole === "admin") {
      navigate("/admin-dashboard");
    } else {
      navigate("/dashboard");
    }
  };
  return (
    <div className="page-not-found">
      <div className="page-content">
        <div className="text-center">
          <h1>404</h1>
          <h2>{t("common.pageNotFound")}</h2>
          <p>
            {t("common.wrongAddressOrPageMoved")}
            <br />
            {t("common.tryAgainMessage")}
          </p>
          <div className="mt-12">
            <Button className="bg-[#5441DA] btn" type="primary" onClick={handleNavigateDashboard}>
              {t("common.goToDashboard")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
