import axios from "axios";
import { retrieveData } from "../storage/Storage";

interface routingConfigType {
  ipv4: string;
  ipv6: string;
  deviceType: string;
  operatingSystem: string;
  [key: string]: any;
}

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

axios.interceptors.request.use(
  (config) => {
    const token: string = retrieveData("authToken", true);
    // const realmName: string = retrieveData('realmName', true);
    let realmName: string = retrieveData("realmName", true);
    const routingConfig: routingConfigType = retrieveData("routingConfig", true);
    let suffix = "1";
    const hostName = window.location.hostname;
    if (hostName === "localhost") {
      realmName = `${realmName}${suffix}`;
    }

    //   const isTokenAvailable: boolean = !!token;
    // Check if the URL requires the Authorization header
    //   const requiresAuthHeader: boolean = config.headers?.Authorization !== "exclude";

    if (config.headers?.Authorization !== "exclude") {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (realmName) {
      config.headers["realm-Name"] = realmName; // You can use any key name that makes sense for your API
    }

    if (routingConfig) {
      if (routingConfig?.ipv4 !== "Not Available") {
        config.headers["ip"] = routingConfig?.ipv4;
      } else {
        config.headers["ip"] = routingConfig?.ipv6;
      }
      config.headers["device-Type"] = routingConfig?.deviceType;
      config.headers["operating-System"] = routingConfig?.operatingSystem;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axios;
