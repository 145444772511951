import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthenticationPayload {
  isLogin: boolean;
  loginResponse: {} | null | undefined;
  role: string;
}

interface AuthState {
  authentication: AuthenticationPayload;
}

const initialState: AuthState = {
  authentication: {
    isLogin: false,
    loginResponse: {},
    role: "admin",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<AuthenticationPayload>) => {
      state.authentication = action.payload;
    },

    clearAuthentication: (state) => {
      state.authentication = {
        isLogin: false,
        loginResponse: {},
        role: "admin",
      };
    },
  },
});

export const { setAuthentication, clearAuthentication } = authSlice.actions;
export default authSlice.reducer;
