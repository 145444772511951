import React, { useEffect, useState } from "react";
import UserBehaviorCardContent from "./userBehavior/UserBehaviorCardContent";
import moment from "moment";
import UserBehaviorCardContentSevenDaysAgo from "./userBehavior/UserBehaviorCardContentSevenDaysAgo";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../services/storage/Storage";
import Loader from "../../../../layouts/component/Loader";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ImageView from "../../../../layouts/component/ImageView";
import { images } from "../../../../assets/images/ImagePaths";

export default function UserBehaviour() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentSelectedRow = retrieveData("currentSelectedRow", true);
  const getUserAuditLogsApi = useGetApiRequests("getUserAuditLogsUserManagement", "GET");

  const [categorizedLogs, setCategorizedLogs] = useState({
    today: [],
    yesterday: [],
    sevenDaysAgo: [],
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [yesterdayArrowOpen, setYesterdayArrowOpen] = useState<boolean>(true);
  const [sevenDaysArrowOpen, setSevenDaysArrowOpen] = useState<boolean>(true);

  const [responseMessage, setResponseMessage] = useState<string>("");
  useEffect(() => {
    getUserAuditLogs();
  }, []);

  function convertMillisecondsToISO(millisecond: any) {
    const date = new Date(millisecond);

    // Get the individual components of the date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

    // Construct the ISO 8601 string with the desired format
    const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;

    return isoString;
  }

  const categorizeLogs = async (logs: any) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const formattedLogs: any = {
      today: [],
      yesterday: [],
      sevenDaysAgo: [],
    };
    logs?.forEach((log: any) => {
      const logDate = new Date(convertMillisecondsToISO(log?.eventTime));
      const formattedDate = logDate?.toLocaleDateString("en-US", { month: "long", day: "numeric" });
      const formattedTime = moment(logDate)?.format("hh:mm a"); // Format time using moment.js

      const logWithFormattedTime = { ...log, formattedTime }; // Attach formatted time to each log

      if (logDate.toDateString() === today?.toDateString()) {
        formattedLogs.today.push(logWithFormattedTime);
      } else if (logDate.toDateString() === yesterday?.toDateString()) {
        formattedLogs.yesterday.push(logWithFormattedTime);
      } else if (logDate >= sevenDaysAgo && logDate < today) {
        // Check if this date already exists in the sevenDaysAgo array
        let dateGroup = formattedLogs?.sevenDaysAgo?.find((group: any) => group?.date === formattedDate);

        if (!dateGroup) {
          // If the date group doesn't exist, create a new one

          dateGroup = { date: formattedDate, logs: [] };
          formattedLogs?.sevenDaysAgo?.push(dateGroup);
        }

        // Add the log to the correct date group
        dateGroup?.logs?.push(logWithFormattedTime);
      }
    });

    setCategorizedLogs(formattedLogs);
  };

  const getUserAuditLogs = async () => {
    const pathParams: object = { id: currentSelectedRow?.key };

    try {
      const response: any = await getUserAuditLogsApi("", {}, pathParams);
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;
        if (responseData && responseData?.length > 0) {
          categorizeLogs(responseData);
        } else {
          setResponseMessage(response?.data?.message);
        }
      }

      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const handleNavigateToUserAudit = () => {
    navigate(`/audit-log`);
  };
  const handleYesterdayCollapse = () => {
    setYesterdayArrowOpen(!yesterdayArrowOpen);
  };
  const handleSevenDaysCollapse = () => {
    setSevenDaysArrowOpen(!sevenDaysArrowOpen);
  };
  return (
    <div className="userCard">
      {loader && <Loader />}
      <div className="userBehavior-scroll">
        <div className="userBehavior">
          <div className="userBehavior-title">
            <h2>{t("userManagement.userBehavior")}</h2>
          </div>
        </div>
        <div className="userBehavior-container">
          <div className="userBehavior-body">
            <div className="userBehavior-content">
              {categorizedLogs?.today?.length > 0 ? (
                <div className="userBehavior-card">
                  <div className="userBehavior-card_Header fill">
                    <h2> {t("userManagement.today")}</h2>
                  </div>
                  <div className={`userBehavior-card_content sevenDaysAgo`}>{categorizedLogs?.today.map((log: any) => <UserBehaviorCardContent details={log} key={log?.id} />)}</div>
                </div>
              ) : null}

              <div className="userBehavior-card">
                {categorizedLogs?.yesterday?.length > 0 ? (
                  <div className="userBehavior-card">
                    <div className="userBehavior-card_Header roundOutline flex gap-x-1">
                      <h2>{t("userManagement.yesterday")}</h2>
                      <div className="flex  w-full items-center justify-between">
                        <span className="bg-[#E8E8E8] h-[1px] w-[85%] block ml-2"></span>
                        <div role="none" onClick={handleYesterdayCollapse}>
                          <ImageView src={yesterdayArrowOpen ? images.upArrowBox : images.downArrowBox} className="cursor-pointer" />
                        </div>
                      </div>
                    </div>
                    <div className={`userBehavior-card_content sevenDaysAgo ${!yesterdayArrowOpen && "hidden"}`}>
                      {categorizedLogs?.yesterday.map((log: any) => <UserBehaviorCardContent details={log} key={log?.id} type="yesterday" dots={false} isActiveArrow={true} />)}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="userBehavior-card">
                {categorizedLogs?.sevenDaysAgo?.length > 0 ? (
                  <div className="userBehavior-card">
                    <div className="userBehavior-card_Header roundOutline flex gap-x-1">
                      <h2 className="w-[27%]">{t("userManagement.sevenDaysAgo")}</h2>
                      <div className="flex  w-[80%] items-center justify-between">
                        <span className="bg-[#E8E8E8] h-[1px] w-[85%] block ml-2"></span>
                        <div role="none" onClick={handleSevenDaysCollapse}>
                          <ImageView src={sevenDaysArrowOpen ? images.upArrowBox : images.downArrowBox} className="cursor-pointer" />
                        </div>
                      </div>
                    </div>
                    <div className={`userBehavior-card_content sevenDaysAgo ${!sevenDaysArrowOpen && "hidden"}`}>
                      {categorizedLogs?.sevenDaysAgo.map((logs: any) => (
                        <>
                          <h2 className="">{logs.date}</h2>
                          {logs?.logs?.length > 0 &&
                            logs?.logs?.map((log: any) => <UserBehaviorCardContentSevenDaysAgo details={log} key={log?.id} type="yesterday" dots={false} isActiveArrow={true} />)}
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="user-groupAppList">{responseMessage}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="show_more hidden">
        <h3 role="none" onClick={handleNavigateToUserAudit}>
     {t("userManagement.showMore")}
        </h3>
      </div>
    </div>
    
  )
}
