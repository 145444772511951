export const termsContent = [
  {
    content:
      "The Akku products (individually and as a suite), services, mobile application(s), and website (https://www.akku.work) (collectively, the “Offerings),” along with their related and supporting software and technology, are subject to these Terms and Conditions. The Offerings are authorized for access and use only according to these Terms and Conditions.",
  },
  {
    content:
      "Akku provides the Offerings only to customers (“Customers”) and registered “Users” who have entered into a binding agreement with Akku, as part of the purchase of some or all of the Offerings, or in connection with an authorized demonstration, test, or other limited-use provision of the Offerings.",
  },
  {
    content:
      " Accordingly, you may only use the Offerings, including as part of a beta test or other exposure to the Offerings in non-commercial or experimental format, if you are an authorized User and/or a Customer. All such authorized Users, and any others who download, access, use, purchase and/or subscribe to the Offerings (collectively or individually “you” or “users”), must do so subject to the following Terms and Conditions.",
  },
  {
    title: "END-USER LICENSE AGREEMENT",
  },
  {
    content: "THIS END-USER LICENSE AGREEMENT (THE “AGREEMENT”) SHALL CONSTITUTE THE ENTIRE AGREEMENT BETWEEN YOU AND AKKU CONCERNING YOUR ACCESS TO AND USE OF THE OFFERINGS.",
  },
  {
    content: "IF YOU DO NOT AGREE WITH ALL THE TERMS OF THIS AGREEMENT, YOU ARE NOT ENTITLED TO ACCESS OR USE THE OFFERINGS, AND SHOULD IMMEDIATELY STOP AND REMOVE THEM FROM YOUR DEVICES.",
  },
  {
    content:
      "Akku reserves the right to update and change this Agreement from time to time without notice. Any new or different features that augment or enhance the Offerings, including the release of tools and resources, shall be subject to this Agreement. Continued use of the Offerings after any such updates or changes shall constitute your consent to the updates or changes. You can review the most current version of this Agreement at any time at https://www.akku.work",
  },
  {
    title: "1. OFFERINGS AND RESTRICTIONS",
  },
  {
    content:
      "1.1 Akku shall provide the Offerings, including the mobile application (the “Application”), to you only for the term of your Customer Agreement (the “Term”). During the Term or otherwise, you shall not: (i) modify, disassemble, decompile or reverse engineer any aspect of the Offerings, including the Application, except to the extent that such restriction is expressly prohibited by law; (ii) share, rent, lease, loan, resell, sublicense, distribute or otherwise transfer the Offerings to any third-party or use the Offerings to provide time sharing or similar services for any third-party; (iii) make any copies of the Offerings; (iv) remove, circumvent, disable, damage or otherwise interfere with security-related features of the Offerings, features that prevent or restrict use or copying of any content accessible through the Offerings, or features that enforce limitations on use of the Offerings; or (v) delete the copyright and other proprietary rights notices on the Offerings. The use of any of the offerings are subject to your Organization’s policies and procedures. By using the offerings you agree to be bound by Akku and your Organization’s policies and procedures including but not limited to those for Bring-Your-Own-Device (BYOD). By using the offering you are expressly granting permission to Akku and your Organization to access the devices used to access the offerings, to perform necessary functions to enable the functioning of Akku including but not limited to wiping out of all Organization specific information, programs, applications, data and / or software from the device of access.",
  },
  {
    content:
      " 1.2 Limited License. During the Term, Akku grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Offerings, including the Application. With respect to any open source or third-party code that may be incorporated in the Application, such open source code is covered by the applicable open source or third-party end user license agreement, if any, authorizing use of such code. The foregoing license grant under this Agreement is not a sale of the Application or any copy thereof, and Akku or its third-party partners or suppliers retain all right, title, and interest in the Application (and any copy thereof). Any attempt by you to transfer any of the rights, duties or obligations hereunder, except as expressly provided for in this Agreement, is void. Akku reserves all rights not expressly granted under this Agreement.",
  },
  {
    content:
      "1.3 Additional License Conditions. Your limited, non-exclusive, non-transferable, revocable license to access and use the Offerings is valid only for the commercial or personal purpose authorized by your Customer Agreement. You are responsible for maintaining the security of your equipment and account access passwords, and are required to use reasonable efforts to prevent any unauthorized access to or use of the Offerings and to immediately notify Akku of any unauthorized access or use that comes to your attention. If there is unauthorized access or use by anyone who obtained access to the Offerings directly or indirectly through you, you will take all steps reasonably necessary to terminate such unauthorized access or use. You will be responsible and liable for all activity conducted by and/or through your Users’ accounts. You will likewise reasonably cooperate and assist with any actions taken by Akku to prevent or terminate unauthorized use of the Offerings.",
  },
  {
    subTitle: "2. OWNERSHIP.",
    content:
      "All right, title, and interest in the Offerings, including the Application, and in any ideas, know-how, code, derivative works or intellectual property associated therewith, including without limitation any enhancements or modifications made to the Offerings, including the Application, by any person (however employed or associated) shall at all times remain solely and exclusively the property of Akku.",
  },
  {
    subTitle: "3. FEES AND CHARGES.",
    content:
      "Akku reserves the right to charge fees for use of the Offerings. Any such fees, including but not limited to monthly or other subscription plan fees for use of the Offerings, including the Application, are subject to change upon thirty (30) days’ notice from Akku. This notice may be provided at any time by direct communication to you and/or by posting notice on the Akku website (https://www.akku.work). Akku shall not be liable to you or to any third-party for any modification, price change, suspension, interruption, or discontinuance of the Offerings, including the Application. As a User, Akku will not charge you any fees for your use of the Offerings without your permission. Your wireless carrier or other service provider may charge fees for data usage, messaging, phone calls, or other services that may be required for you to use the Offerings. You may cease using the Offerings at any time.",
  },
  {
    subTitle: "4. PROPRIETARY MARKS",
    content:
      "Except as specifically authorized in writing by Akku, you shall not alter, change, copy, or remove from the Offerings any trademark, other proprietary mark, or proprietary rights notice.",
  },
  {
    subTitle: "5. WAIVER OF WARRANTIES.",
    content:
      "THE OFFERINGS ARE DISTRIBUTED “AS IS” WITHOUT ANY WARRANTIES, WHETHER WRITTEN, ORAL, STATUTORY, EXPRESS OR IMPLIED. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AKKU SPECIFICALLY DISCLAIMS ANY WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. This waiver of warranties affects your specific legal rights; you may have rights which may vary depending upon where you are located. Some jurisdictions do not allow limitations on implied warranties, so the limitations above may not apply to you.",
  },
  {
    subTitle: "6. SUPPORT.",
    content:
      "Akku has no obligation to provide support, professional services, upgrades, modifications, or new releases to or for the Offerings under this Agreement. Akku may voluntarily provide some or all of these items; should Akku do so, any such voluntary action shall not be considered a waiver of this provision.",
  },
  {
    subtTitle: "7. LIMITATION OF LIABILITY.",
    content:
      "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AKKU (AND ITS LICENSORS, SUPPLIERS, OFFICERS, DIRECTORS, AND EMPLOYEES) SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR ARISING OUT OF THIS AGREEMENT OR THE USE OF THE OFFERINGS, INCLUDING THE APPLICATION, REGARDLESS OF HOW SUCH DAMAGES ARISE AND/OR WHETHER SUCH DAMAGES ARE CLAIMED IN TORT, CONTRACT OR OTHER THEORY OF LIABILITY, EVEN IF AKKU HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL AKKU’S LIABILITY FOR ANY CLAIM WHATSOEVER HEREUNDER (OR ASSOCIATED HEREWITH) EXCEED THE AMOUNTS PAID TO AKKU BY EACH CUSTOMER ON BEHALF OF ITS REGISTERED USERS (OR, IN THE CASE OF A NON-CUSTOMER OR REGISTERED USER, A MAXIMUM OF ONE HUNDRED DOLLARS ($100.00 USD) PER PERSON). Some jurisdictions do not allow for the exclusion or limitation of incidental or consequential damages, so the limitations above may not apply to you.",
  },
  {
    subTitle: "8. TERM AND TERMINATION",
    content:
      "This Agreement and the availability of the Offerings may be terminated by Akku at any time, for any reason, with or without notice to you. AKKU RESERVES THE RIGHT TO DISABLE ANY USER’S ACCESS TO AND USE OF THE OFFERINGS, INCLUDING THE APPLICATION, FOR ANY REASON AND WITHOUT ANY NOTICE. When this Agreement expires or terminates, Akku shall cease providing the Offerings to you, and you shall immediately cease accessing and using the Offerings. Sections 1.1, 2, 4, 5, 6, 7, 8 and 9 shall survive the termination or expiration of this Agreement.",
  },
  {
    subTitle: "9. GENERAL.",
    content:
      "9.1 Miscellaneous. All notices shall be in writing and shall be considered given on the date of (i) confirmed delivery if sent by overnight courier or express mail service, (ii) confirmed delivery if sent by postage pre-paid certified or registered mail (or the equivalent), return receipt requested or (iii) personal delivery. You shall not assign or otherwise transfer any of your rights or obligations without the prior written consent of Akku. Except to the extent applicable law, if any, provides otherwise, this Agreement shall be governed, construed and enforced in all respects by the laws of the State of Illinois, USA, excluding its choice of law/conflict of law provisions, and shall not be governed by the United Nations Convention on Contracts for the Sale of Goods. Unless Akku elects (in its sole option) to proceed in your local jurisdiction, the jurisdiction and venue of any arbitration, litigation or other dispute resolution method between the parties (which arises out of or relates to this Agreement) shall be exclusively in Chicago, Illinois; you expressly submit and consent to such exclusive jurisdiction and venue. You further understand that the Offerings may be subject to restrictions and controls imposed by the U.S. Export Administration Act, as amended, and agree, if informed by Akku, to comply with applicable export and import control laws and regulations issued from time to time by the U.S. Department of Commerce and other governmental agencies, foreign or domestic. If any provision of this Agreement is for any reason and/or to any extent determined to be unenforceable under applicable law, the remaining provisions of this Agreement shall remain in full force and effect. The failure of either party to object to or act with respect to any conduct of the other party that is in violation of the terms of this Agreement shall not be construed as a waiver thereof.",
  },
  {
    content:
      "9.2 Government Restricted Rights. This provision applies if the Offerings are used directly or indirectly by or on behalf of any government. Akku is the manufacturer of the Offerings, including the Application, and the provider of any services under the Offerings. The Application is a commercial product, licensed on the open market at market prices. Any use modification, reproduction, release, performance, display, or disclosure of the Offerings, including the Application, by any government shall be governed solely by the terms of this Agreement and shall be prohibited except to the extent expressly permitted by the terms of this Agreement, and no license to the Application is granted to any government requiring different terms. The Offerings and any accompanying documentation are comprised of “commercial computer software” and “commercial computer software documentation” as such terms are used in 48 C.F.R. 12.212 and qualify as “commercial items” as defined in 48 C.F.R. 2.101. If the Offerings are being ordered by or on behalf of the U.S. Government or by a U.S. Government prime contractor or subcontractor (at any tier), then the Government’s rights to the Service and accompanying documentation will be only as set forth in this Agreement; and in accordance with FAR 52.227-14 Rights in Data- General, “Restricted Rights,” any equivalent agency regulation or contract clause, or such applicable successor provisions.",
  },
  {
    content: "9.3 Assignment. This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Akku without restriction.",
  },
  {
    content: "9.4 Providing Notice. Should you wish to or are required to notify Akku under this Agreement, use the contact information provided on the Akku website located at https://www.akku.work",
  },
];
