import React, { useEffect, useState } from 'react';
import CommonHeader from './CommonHeader';
import { Form, Formik } from 'formik';
import { Button } from 'antd';
import { sessionValidationSchema } from '../schema/ValidationScheme';
import CustomButtonBack from '../../../../component/CustomButtonBack';
import LoginSettingForm from './LoginSettingForm';
import SessionIdleForm from './SessionIdleForm';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerDetailFromApi, setReloadCustomerDashboard } from '../../../../../redux/slice/CustomerSlice';
import Loader from '../../../../component/Loader';
import useGetApiRequests from '../../../../../services/axios/useApiRequests';
import { triggerNotification } from '../../../../toast/ToastBar';
import { handleRequestError } from '../../../../toast/ErrorNotificationMessage';
import { compareObjects } from './customerHelper';
import UpdateModal from './UpdateModal';
import { useTranslation } from 'react-i18next';
const SsoSessionSettingForm = (props: any) => {
  const {
    setCurrentTab,
    currentTab,
    isEdit,
    getCustomerDetails,
    setGetCustomerDetails,
    setSsoDataChanges,
    ssoDataChanges,
    setSsoDetailsPayload,
    active,
  } = props;
  const { t } = useTranslation();

  const customerId = useSelector((state: any) => state?.CustomerSlice?.customerId);
  const customerSsoDetails = useSelector((state: any) => state?.CustomerSlice?.getCustomerDetails?.ssoDetails);
  const updateCustomerDetails = useGetApiRequests('createCustomer', 'PUT');
  const [isOpenPopup, setIsOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<any>({
    ssoSessionIdleTimeout: '120',
    ssoSessionMaxLifespan: '480',
    ssoSessionIdleTimeoutRememberMe: '30',
    ssoSessionMaxLifespanRememberMe: '30',
    loginTimeout: '10',
    loginActionTimeout: '5',
  });
  useEffect(() => {
    if (Object.keys(customerSsoDetails).length > 0) {
      setInitialValues(customerSsoDetails);
      setSsoDataChanges(customerSsoDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);
  const [loader, setLoader] = useState(false);

  const timeOutDuration = ['Min'];
  const handleBack = () => {
    setCurrentTab(2);
  };

  const hasValues = (obj: any) => Object.keys(obj).length > 0;
  const updateCustomerDetailsForApi = (details: any, newValues: any) => ({
    ...details,
    ssoDetails: newValues,
  });

  const defaultToZeroIfFalsy = (value: any) => value ?? 0;
  const handleSuccess = (response: any) => {
    if (response?.status === 201 || response?.status === 200) {
      setLoader(false);
      dispatch(setReloadCustomerDashboard());

      triggerNotification('success', '', response?.data?.message, 'topRight');
      setCurrentTab(4);
    }
  };
  const handleError = (err: any) => {
    setLoader(false);
    if (err?.response?.status === 401 || err?.response?.status === 500) {
      handleRequestError(err);
    } else {
      triggerNotification('error', '', err?.response?.data?.message, 'topRight');
    }
  };

  const handleSubmit = (values: any) => {
    if (hasValues(values)) {
      const updatedDetails = updateCustomerDetailsForApi(getCustomerDetails, values);
      dispatch(setCustomerDetailFromApi(updatedDetails));
      setGetCustomerDetails(updatedDetails);
    }

    setLoader(true);
    const payload: any = {
      ssoSessionIdleTimeout: values?.ssoSessionIdleTimeout,
      ssoSessionMaxLifespan: values?.ssoSessionMaxLifespan,
      ssoSessionIdleTimeoutRememberMe: defaultToZeroIfFalsy(values?.ssoSessionIdleTimeoutRememberMe),
      ssoSessionMaxLifespanRememberMe: defaultToZeroIfFalsy(values?.ssoSessionMaxLifespanRememberMe),
      loginTimeout: values?.loginTimeout,
      loginActionTimeout: values?.loginActionTimeout,
    };
    for (const key in payload) {
      if (payload.hasOwnProperty(key) && values?.[key] !== undefined) {
        const value = parseInt(values[key], 10);
        if (!isNaN(value)) {
          payload[key] = value * 60;
        }
      }
    }

    const finalPayload: object = {
      sessionDto: payload,
    };
    setSsoDetailsPayload(finalPayload);
    if (!isEdit) {
      setLoader(false);
      setCurrentTab(4);
    } else {
      const valueMatched = compareObjects(ssoDataChanges, values);
      if (!valueMatched) {
        const pathParams: any = {
          name: customerId,
        };
        updateCustomerDetails(finalPayload, '', pathParams).then(handleSuccess).catch(handleError);
      } else {
        setCurrentTab(4);
      }
    }
  };
  const handleBlurEvent = async (e: any, setFieldTouched: any, errors: any) => {
    const fieldName = e.target.name;
    await setFieldTouched(fieldName, true);
    if (!errors[fieldName] && active === true) {
      setIsOpen(true);
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <CommonHeader text={t('tenants.ssoSessionSettings')} onClick={handleBack} />
      <div className="w-full sso-session-setting">
        <div className="w-full">
          <Formik initialValues={initialValues} validationSchema={sessionValidationSchema(t)} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ values, setFieldTouched, setFieldValue, errors }) => {
              return (
                <Form>
                  <div className="w-full">
                    <div className="w-full sso-session-setting-form pb-10 overflow-y-auto sso-setting">
                      <div className="mb-10 w-[92%] ml-8 flex flex-wrap ssoDropdown">
                        <SessionIdleForm
                          timeOutDuration={timeOutDuration}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          handleBlurEvent={handleBlurEvent}
                        />

                        <LoginSettingForm
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          errors={errors}
                          handleBlurEvent={handleBlurEvent}
                        />
                      </div>
                      <div className="footer">
                        <div className="modal-footer w-[95%] mx-auto ">
                          <div className="w-full flex justify-end">
                            <CustomButtonBack onClick={handleBack} text={t('common.back')} />

                            <Button
                              htmlType="submit"
                              type="primary"
                              className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
                            >
                              {t('common.next')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {loader && <Loader />}
      </div>
      {<UpdateModal handleClose={handleClose} isOpenPopup={isOpenPopup} />}
    </>
  );
};

export default SsoSessionSettingForm;
