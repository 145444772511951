import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../../../layouts/component/CustomButton";
import { Input, Modal } from "antd";

const CustumFieldModal = (props: any) => {
  const { openModal, handleAddModal, handleExternalPush } = props;
  const [open, isOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const handleCloseModal = () => {
    handleAddModal();
  };

  const handleAdd = () => {
    setIsError(true);
    // Basic validation: input should not be empty and should be at least 3 characters long
    if (!inputValue.trim()) {
      setError("Field name is required");
      return;
    }
    if (inputValue.length < 3) {
      setError("Field name must be at least 3 characters long");
      return;
    }

    handleExternalPush(inputValue);
    handleCloseModal();
  };

  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);

  const handleNewField = (event: any) => {
    const inputVal = event.target.value;
    setInputValue(inputVal);
    setIsError(true);

    if (inputVal.length < 3 && isError) {
      console.log(inputVal);

      setIsError(true);
      setError("Field name must be at least 3 characters long");
    } else {
      setIsError(false);

      setError("");
    }
  };

  return (
    <Modal className="new-field rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body pt-4">
        <div className="flex w-full justify-center items-center flex-col">
          <div className="relative w-full">
            <h5 className="text-[24px] font-Inter pb-6 text-center font-semibold">Enter field name</h5>
            <Input placeholder="Type" onChange={handleNewField} value={inputValue} maxLength={15} />
            {error && isError && <div className="error-message text-red-500 ">{error}</div>}
          </div>
        </div>
      </div>
      <div className="modal-footer pt-8 mt-14">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} />
          <CustomButtonNext onClick={handleAdd} text={"Add"} />
        </div>
      </div>
    </Modal>
  );
};

export default CustumFieldModal;
