import React from "react";
import UserType from "../adduser/UserType";

export default function AddUsers() {
  return (
    <>
      <UserType />
    </>
  );
}
