import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../layouts/component/Loader";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import SuccessMessageModal from "../../../../access-manager/create/IpTimeSuccessModal";
import ProvisionRoleMappingForm from "./ProvisionRoleMappingForm";
import { setActiveStep, setMappingGroupAttributes, setMappingRolesAttributes } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { provisioningConstant } from "../const/provisioningConts";
import { useTranslation } from "react-i18next";
import { getHeaders } from "../../../../../const/getHeader";
const ProvisionRoleMapping = () => {
  const { t, i18n } = useTranslation();
  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL || "";

  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const mappingRoleAttributes = useSelector((state: any) => state?.provisioning?.mappingRolesAttributes);
  const isRoleConfigured = useSelector((state: any) => state?.provisioning?.isRoleProvisionConfigured);

  const provisioningRoleAttributes = useGetApiRequests("provisioningRoleAttributes", "POST");
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const updateProvisioningRoleAttributes = useGetApiRequests("updateProvisioningRoleAttributes", "PUT");

  const realmId = retrieveData("realmId", true);
  const token: string = retrieveData("authToken", true);
  const [loader, setLoader] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(mappingRoleAttributes);
  const [target, setTarget] = useState([]);
  const [source, setSource] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [connectorDetails, setConnectorDetails] = useState<any>(null);
  const [isDirty, setIsDirty] = useState(false);
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const transformList: any = [];
  const validationSchema = Yup.object({
    forms: Yup.array().of(
      Yup.object({
        source: Yup.string().required(t("appManagement.provisioning.errors.pleaseSelectRequiredValue")),
        target: Yup.string().required(t("appManagement.provisioning.errors.pleaseSelectRequiredValue")),
      }),
    ),
  });
  useEffect(() => {
    getProvisioningConnectorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormInitialValues(mappingRoleAttributes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingRoleAttributes]);

  const handleSubmit = async (values: any) => {
    if (isDirty) {
      if (connectorDetails.akkuProvisioningRoleMapDtoList) {
        updateProvisioningRoleAttributesApi(values);
      } else {
        dispatch(setMappingGroupAttributes(values));
        apiCallSaveAttributes(values);
      }
    } else {
      dispatch(setActiveStep(8));
    }
  };
  const createPayloadSAVE = (forms: any) => {
    try {
      return forms?.forms?.map((form: any) => {
        return {
          akkuRoleId: form?.sourceId,
          spRoleId: form?.targetId,
          akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId,
          realmId: realmId,
        };
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;

      if (status === 200) {
        const data = response?.data?.data;
        setConnectorDetails(data);

        getProvisioningGroupMappingAttributes(data);
      }

      // setLoader(false);
    } catch (err: any) {
      // setLoader(false);
      if (err?.response.status !== 500) handleRequestError(err);
    }
  };

  const apiCallSaveAttributes = async (values: any) => {
    setLoader(true);
    const payload = createPayloadSAVE(values);
    try {
      const response: any = await provisioningRoleAttributes(payload);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setOpenSuccessModal(true);
        setTimeout(() => {
          handleCloseSuccessModal();
          dispatch(setActiveStep(8));
        }, 3000);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      console.log(err);
      handleRequestError(err);
    }
  };
  const createPayload = () => {
    const connectorName = generalDetails?.name?.toUpperCase();
    const payload = {
      realmId: realmId,
      apiType: provisioningConstant.apiTypeReadPermission,
      connectorName: connectorName,
      endPointDescription: provisioningConstant.roleListEndPointDescription,
      authorization: `Bearer ${token}`,
    };
    return payload;
  };
  const getProvisioningGroupMappingAttributes = async (el: any) => {
    setLoader(true);
    const payload = createPayload();
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data?.statusCode === 200) {
        // if (provisioning?.isActiveEdit) {
        const finalResults = constructFinalResult(el?.akkuProvisioningRoleMapDtoList, data);
        dispatch(
          setMappingRolesAttributes({
            forms: finalResults,
          }),
        );
        // }
        setSource(data?.akku_data);
        setTarget(data?.sp_data);

        setLoader(false);
      } else {
        // this else is for handling fetch api errors
        // handleRequestError(data);
        console.error("Error fetching in role attributes", data);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const updateProvisioningRoleAttributesApi = (values: any) => {
    const payload = createPayloadSAVE(values);
    const roleMapDtoList = connectorDetails.akkuProvisioningRoleMapDtoList || [];
    let addAkkuProvisioningRoleMapIds = payload.map((item1: any) => {
      const match = roleMapDtoList.find((item: any) => item?.akkuRoleId === item1?.akkuRoleId || item?.spRoleId === item1?.spRoleId);
      if (match) {
        return {
          ...item1,
          akkuProvisioningRoleMapId: match.akkuProvisioningRoleMapId,
        };
      } else {
        return item1; // Return the payload item if no match is found
      }
    });
    const nonMatchingGroupMapIds = roleMapDtoList
      ?.filter((item: any) => {
        const matchingItem = addAkkuProvisioningRoleMapIds?.find(
          (secondItem: any) => secondItem?.spRoleId === item?.spRoleId || secondItem?.akkuRoleId === item?.akkuRoleId,
        );
        return !matchingItem;
      })
      .map((item: any) => item?.akkuProvisioningRoleMapId);
    const finalPayload = {
      akkuProvisioningRoleMapDtoList: addAkkuProvisioningRoleMapIds,
      removeRoleMapIdList: [...nonMatchingGroupMapIds],
    };
    updateApiCall(finalPayload);
  };

  const updateApiCall = async (payload: any) => {
    try {
      const response: any = await updateProvisioningRoleAttributes(payload);
      const status = response?.status;
      if (status === 200) {
        setLoader(false);
        setResponseMessage(response.data.message);
        setOpenSuccessModal(true);
        setTimeout(() => {
          handleCloseSuccessModal();
          dispatch(setActiveStep(8));
        }, 3000);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      console.log(err);
      handleRequestError(err);
    }
  };
  function constructFinalResult(mappedData: any, apiResponse: any) {
    try {
      const { akku_data, sp_data } = apiResponse;
      const results = mappedData?.map((mappedItem: any) => {
        // Find the corresponding akku_data and sp_data based on the ids
        const akkuItem = akku_data.find((item: any) => item.id === mappedItem?.akkuRoleId) || { id: "", name: "" };
        const spItem = sp_data.find((item: any) => item.id === mappedItem?.spRoleId) || { id: "", name: "" };
        // Construct the final result for each mappedItem

        if (!akkuItem.name?.trim()) {
          return { source: "", target: "", sourceId: "", targetId: "" };
        }

        return {
          source: akkuItem.name || "", // Map akku data name to source
          sourceId: akkuItem.id || "", // Map akku data id to sourceId
          target: spItem.name || "", // Map sp data name to target
          targetId: spItem.id || "", // Map sp data id to targetId
        };
      });
      // If final result is empty, add a default empty object

      // Check if results are empty or undefined
      if (!results || results.length === 0) {
        return [{ source: "", target: "", sourceId: "", targetId: "" }];
      }
      const allEmptyResults = results.every((result: any) => !result.source && !result.target && !result.sourceId && !result.targetId);

      // If all results are empty, return only one object
      if (allEmptyResults) {
        return [{ source: "", target: "", sourceId: "", targetId: "" }];
      }
      return results;
    } catch (e) {
      console.log(e, "error");
      return [{ source: "", target: "", sourceId: "", targetId: "" }]; // Return default in case of an error
    }
  }

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <>
      <div className="w-full  mapping-attributes mapping-attributes relative h-full connector-provision">
        <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-125px)]">
          <p className="app-header-title">
            {t("appManagement.provisioning.mappingRoles")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
          </p>

          <div className="w-full mapping-attributes">
            <Formik
              initialValues={formInitialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize
              innerRef={formikRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <ProvisionRoleMappingForm
                    values={values}
                    setFieldValue={setFieldValue}
                    transformList={transformList}
                    setFormInitial={setFormInitialValues}
                    target={target}
                    source={source}
                    handleSubmit={handleSubmit}
                    setIsDirty={setIsDirty}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {openSuccessModal && (
        <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />
      )}
    </>
  );
};

export default ProvisionRoleMapping;
