export const authenticate = {
  clientAuthentication: false,
  authorization: false,
  serviceAccountsRoles: false,
  directAccessGrants: false,
  standardFlow: false,
  implicitFlow: false,
  deviceAuthorizationGrantEnabled: false,
  oidcCibaGrantEnabled: false,
};
export const samlInitialValue = {
  saml: [
    {
      clientId: "",
      masterSamlProcessingUrl: "",
      validRedirectLogoutUrl: [""],
      assertionConsumerUrlRedirect: "",
      assertionConsumerUrlPost: "",
      baseUrl: "",
      validRedirectUrl: [""],
      rootUrl: "",
      apiKey: "",
      delegatedUser: "",
    },
  ],
};

export const jiraInitialValue = {
  jira: [
    {
      apiKey: "",
      apiSecret: "",
    },
  ],
  mappingGroups:[
    { source:"",target:''}]
};

export const openIdInitialValue = {
  openId: [
    {
      clientId: "",
      validRedirectLogoutUrl: [""],
      rootUrl: "",
      baseUrl: "",
      validRedirectUrl: [""],
      webOrigins: [""],
      apiKey: "",
      delegatedUser: "",
      frontChannelLogoutUrl:''
    },
  ],
};
export const appCommonDetails = {
  description: "",
  name: "",
  protocol: "saml",
};
export const initialApiData = (responseData: any) => {
  const initialData = {
    validRedirectLogoutUrl: responseData.validRedirectLogoutUrl ? [...responseData.validRedirectLogoutUrl] : [""],
    rootUrl: responseData.rootUrl ?? "-",
    baseUrl: responseData.baseUrl ?? "-",
    validRedirectUrl: responseData.validRedirectUrl.length > 0 ? responseData.validRedirectUrl : [""],
    webOrigins: responseData.webOrigins.length > 0 ? responseData.webOrigins : [""],
    masterSamlProcessingUrl: responseData.masterSamlProcessingUrl ?? "",
    clientId: responseData.clientId ?? "-",
    assertionConsumerUrlPost: responseData.assertionConsumerUrlPost ?? "-",
    assertionConsumerUrlRedirect: responseData.assertionConsumerUrlRedirect ?? "-",
    apiKey: responseData.apiKey ?? "-",
    delegatedUser: responseData.delegatedUser ?? "-",
    frontChannelLogoutUrl:responseData.frontChannelLogoutUrl ?? "-"
  };

  return initialData;
};
export const authenticateApiData = (responseData: any) => {
  const auth = {
    clientAuthentication: !responseData.clientAuthentication,
    authorization: responseData.authorization ?? false,
    serviceAccountsRoles: responseData.serviceAccountsRoles ?? false,
    directAccessGrants: responseData.directAccessGrants ?? false,
    standardFlow: responseData.standardFlow ?? false,
    implicitFlow: responseData.implicitFlow ?? false,
    deviceAuthorizationGrantEnabled: convertToBoolean(responseData.deviceAuthorizationGrantEnabled) ?? false,
    oidcCibaGrantEnabled: convertToBoolean(responseData.oidcCibaGrantEnabled) ?? false,
  };
  return auth;
};
const convertToBoolean = (value: any) => {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  }
  return null;
};
