import { Button, Radio } from "antd";
import React, { useState } from "react";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";

const MappingUserStatus = () => {
  const customerTablePlanValue = [
    { label: "Save in Target as Active", value: "JiraActive" },
    { label: "Save in Target as Suspend", value: "JiraSuspend" },
    { label: "Delete from Target", value: "JiraDelete" },
  ];
  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const [filterValue, setFilterValue] = useState("JiraActive");
  const handleNext = () => {
    const updatedStepper = {
      activeStep: 0,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  const handleBack = () => {
    const updatedStepper = {
      childStepper: "",
      activeStep: 8,
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  return (
    <div className="mapping-user-status">
      <div className="w-full px-10 pb-5 ">
        <p className="app-header pt-8">Mapping - User status {provisioning?.isDuplicate ? "(Copy)" : null}</p>
      </div>
      <div className="w-full user-mapping  flex justify-center items-center mt-12">
        <div className="user-card">
          <p className="px-10">When a user status is marked as disabled in the Akku, what would you like to do?</p>
          <div className="w-full flex justify-center">
            <Radio.Group
              onChange={(e) => {
                setFilterValue(e.target.value);
              }}
              value={filterValue}
            >
              {customerTablePlanValue?.map((filter: any, index: number) => (
                <div key={filter?.value} className=" mt-7">
                  <Radio value={filter?.value}>{filter?.label}</Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
          <div className="footer">
            <div className="modal-footer w-full pr-5 mx-auto ">
              <div className="w-full flex justify-end">
                <CustomButtonBack text={"Back"} onClick={() => handleBack()} />
                <Button onClick={handleNext} type="primary" htmlType="submit" className="bg-[#5441DA] ml-5  submit-btn">
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingUserStatus;
