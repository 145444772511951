import React, { useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import ConnectedApplicationEdit from "../user-dashboard/groups/layout/ConnectedApplicationEdit";
import { useTranslation } from "react-i18next";
import ConnectedProvisionAppsUserManagement from "../user-dashboard/groups/layout/ConnectedProvisionAppsUserManagement";

export default function UserAppTabs({
  setIsDirty,
  setListOfApps,
  listOfApps,
  name = "Connected Apps",
  styleName = "",
  individualApps,
  tempListApps,
  listOfProvisioningApps,
  setListOfProvisioningApps,
  tempListProvisioningApps,
  setTempListProvisioningApps,
  individualProvisioningApps,
  setIndividualProvisioningApps,
  setIndividualApps,
}: any) {
  const { t } = useTranslation();

  const [isActiveCurrentTab, setIsActiveCurrentTab] = useState<string>("1");

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `${t("userManagement.ssoApps")}`,
      children: (
        <ConnectedApplicationEdit
          setListOfApps={setListOfApps}
          listOfApps={listOfApps}
          name={t("userManagement.individualApps")}
          styleName="individual-apps"
          individualApps={individualApps}
          tempListApps={tempListApps}
          setIsDirty={setIsDirty}
          setIndividualApps={setIndividualApps}
        />
      ),
    },
    {
      key: "2",
      label: `${t("userManagement.provisioningApps")}`,
      children: (
        <ConnectedProvisionAppsUserManagement
          setListOfApps={setListOfProvisioningApps}
          listOfApps={listOfProvisioningApps}
          name={t("userManagement.individualApps")}
          styleName="individual-apps"
          individualApps={individualProvisioningApps}
          tempListApps={tempListProvisioningApps}
          setIsDirty={setIsDirty}
          setIndividualProvisioningApps={setIndividualProvisioningApps}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    setIsActiveCurrentTab(key);
  };
  return (
    <div className="userAppTabs">
      <h3 className="userAppTabs-title">{t("userManagement.userApps")}</h3>
      <Tabs defaultActiveKey={isActiveCurrentTab} items={items} onChange={onChange} />
    </div>
  );
}
