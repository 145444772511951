import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CustomButtonNext from "../../../../../../../../layouts/component/CustomButton";
import ImageView from "../../../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../../../assets/images/ImagePaths";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../../../../../../layouts/toast/ToastBar";
import { ErrorMessages } from "../../../../../../../../const/Messages";
import { useTranslation } from "react-i18next";

const TestingProcessModal = (props: any) => {
  const { t } = useTranslation();
  const { openModal, handleClose, enableTest, customerId, setEnableTest, setAvailableButton, isLDAP } = props;
  const getSubdomain = useGetApiRequests("getDomain", "GET");
  const [open, setOpen] = useState<boolean>(false);
  const handleCloseModal = () => {
    handleClose();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(openModal);
    if (isLDAP !== true) {
      isDomainAvailableOrNot();
    }
  }, []);

  const isDomainAvailableOrNot = async () => {
    const queryParams: object = {
      subDomainName: customerId,
    };
    try {
      const response: any = await getSubdomain("", queryParams);
      const status = response.status;
      if (status === 200) {
        if (response?.data?.data.length === 0) {
          if (response?.data?.status === "OK") {
            setAvailableButton(true);
            setEnableTest(false);
          }
        } else {
          triggerNotification("error", "", ErrorMessages?.domainExist, "topRight");
          setEnableTest(true);
          setAvailableButton(false);
          handleClose();
        }
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  return (
    <Modal className="p-16 rounded-lg  show-notification  _suspended " maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body   min-h-[150px] flex items-center pt-4">
        <div className="flex flex-col w-full justify-center items-center ">
          <div className="w-12">
            <ImageView src={images.sync} />
          </div>
          <p className="font-Inter pt-5  font-medium text-xl text-[#000] ">{t("common.testingInProgress")}</p>
        </div>
      </div>
      {!enableTest && (
        <>
          <p className="text-center text-[#19A227] font-Inter text-xl font-medium pb-5 ">{t("common.success")}</p>
          <div className="modal-footer">
            <div className=" flex justify-center w-full">
              <CustomButtonNext text={t("common.done")} onClick={handleCloseModal} />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default TestingProcessModal;
