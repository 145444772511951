import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RootState {
  userDetailDto: {};
  customerDetailsDefaultPassword: boolean;
}

const initialState: RootState = {
userDetailDto :{},
customerDetailsDefaultPassword: false,
}
const userBasicDetailsSlice: any = createSlice({
  name: "userBasicDetails",
  initialState,
  reducers: {
      setUserDetailDto: (state, action: PayloadAction<any>) => {
      state.userDetailDto = action.payload;
    },
     setCustomerDetailsDefaultPassword: (state, action: PayloadAction<boolean>) => {
      state.customerDetailsDefaultPassword = action.payload;
    },
      },
});
export const {
    setUserDetailDto,
    setCustomerDetailsDefaultPassword
}  = userBasicDetailsSlice.actions;
export default userBasicDetailsSlice.reducer;