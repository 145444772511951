import React from "react";
import loader from "./../../assets/images/loader.gif";

export default function ScreenLoader() {
  return (
    <div className="full-page-loader" data-testid="full-page-loader">
      <img className="loader-akku" src={loader} alt="loader" />
    </div>
  );
}
