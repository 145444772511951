import React, { useState } from 'react';
import { Modal } from 'antd';
import useGetApiRequests from '../../../services/axios/useApiRequests';
import { handleRequestError } from '../../../layouts/toast/ErrorNotificationMessage';
import ImageView from '../../../layouts/component/ImageView';
import { images } from '../../../assets/images/ImagePaths';
import CustomButtonBack from '../../../layouts/component/CustomButtonBack';
import CustomButtonNext from '../../../layouts/component/CustomButton';
import Loader from '../../../layouts/component/Loader';
import SuccessMessageModal from '../../access-manager/create/IpTimeSuccessModal';
import './AuditLogsDeleteModal.scss';
import { useTranslation } from 'react-i18next';

interface AuditLogsDeleteModalInterface {
  deleteLogsModalOpen: boolean;
  handelDeleteAuditLogsModalClose: () => void;
  selectedRow: any[];
  formValues: {};
  handleGetUserAuditDetails: (formValues: {}, page: number, size: number) => void;
  clearSelection: () => void;
  setCurrentPage: (page: number) => void;
  setSizeChanger: (size: number) => void;
}
const AuditLogsDeleteModal = (props: AuditLogsDeleteModalInterface) => {
  const { t } = useTranslation();
  const {
    deleteLogsModalOpen,
    handelDeleteAuditLogsModalClose,
    selectedRow,
    handleGetUserAuditDetails,
    formValues,
    clearSelection,
    setCurrentPage,
    setSizeChanger,
  } = props;

  const deleteAuditLogsApi = useGetApiRequests('deleteAuditLogs', 'DELETE');

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);

  const handleClose = () => {
    handelDeleteAuditLogsModalClose();
  };
  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handlePageNavigationAfterDelete = () => {
    handleGetUserAuditDetails(formValues, 0, 10);
    setCurrentPage(0);
    setSizeChanger(10);
    handleClose();
    handleCloseSuccessModal();
  };

  const handleDeleteDeviceManagers = async () => {
    setLoader(true);
    const auditDeleteList = selectedRow?.toString();
    const pathParams = {
      id: auditDeleteList,
    };
    try {
      const response: any = await deleteAuditLogsApi('', {}, pathParams);
      const status = response.status;

      setTimeout(() => {
        if (status === 200) {
          setSuccessModal(true);
          clearSelection();
          setResponseMessage(response?.data?.message);
          setTimeout(() => {
            handlePageNavigationAfterDelete();
          }, 2000);
        }
      }, 1000);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  return (
    <>
      <Modal
        className="p-10  show-notification delete_audit_modal rounded-lg"
        maskClosable={false}
        centered
        open={deleteLogsModalOpen}
        footer={false}
        onCancel={handleClose}
      >
        <div className="modal-body  pt-4 ">
          <div className="flex w-full justify-center items-center flex-col">
            <div className="w-20 h-20">
              <ImageView src={images.notificationDelete} alt="delete" />
            </div>
            <p className="text-[20px] text-center font-Inter font-medium text-[#212121]">{t('auditLogs.deleteActivityLogConfirmation')}</p>
          </div>
        </div>
        <div className="modal-footer pt-9">
          <div className="w-full flex justify-center">
            <CustomButtonBack onClick={handleClose} text={t('common.cancel')} />
            <CustomButtonNext onClick={handleDeleteDeviceManagers} text={t('common.proceed')} />
          </div>
        </div>
        {loader && <Loader />}
      </Modal>
      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default AuditLogsDeleteModal;
