import React, { useState } from "react";
import { Button } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { retrieveData, storeData } from "../../services/storage/Storage";
import { setFromUserName, setMobileNumber, setUserReferenceNumber, setVerifyOtpFromMobile, setVerifyPayload } from "../../redux/slice/Users/MobileNumberSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";
import { ErrorMessages } from "../../const/Messages";

interface FormData {
  mobileNumber: string;
}

const SentOTPMobile: React.FC = () => {
  const dispatch = useDispatch();
  const verifyPayload = useSelector((state: any) => state?.forgotUsernameMobileNumber?.verifyPayload);
  const [loader, setLoader] = useState<boolean>(false);
  const sendOtpApi = useGetApiRequests("sendOtp", "POST");
  const realmName: string = retrieveData("realmName", true);
  const realmId: string = retrieveData("realmId", true);

  const navigate = useNavigate();
  const [nonFormikError, setNonFormikError] = useState<any>(null);

  const validationSchema = Yup.object({
    mobileNumber: Yup.string()
      .matches(/^\d+$/, "Must be a number")
      .max(15, "Must be at most 15 characters")
      .min(7, "Must be at least 7 characters")
      .max(15, "Must be at most 15 characters")
      .required("Mobile Number is required"),
  });

  const handleRedirectSecurityVerification = () => {
    navigate("/validate-otp");
  };

  const sendMobileOtp = (userPhoneNumber: string) => {
    setLoader(true);
    const payloadNew = { phoneNumber: userPhoneNumber, realmName: realmName, mailType: "forgot-username", realmId };
    sendOtpApi(payloadNew)
      .then((response: any) => {
        setLoader(false);
        dispatch(setUserReferenceNumber(response?.data?.data?.reference));
        dispatch(setMobileNumber(userPhoneNumber));
        dispatch(setVerifyOtpFromMobile(true));
        dispatch(setFromUserName(true));
        dispatch(
          setVerifyPayload({
            ...verifyPayload,
            phoneNumber: userPhoneNumber,
          }),
        );
        handleRedirectSecurityVerification();
      })
      .catch((err: any) => {
        setLoader(false);

        if (err?.response?.status === 409 || err?.response?.status === 404 || err?.response?.status === 400 ) {
          setNonFormikError(err?.response?.data?.message);
        } else {
          setNonFormikError(ErrorMessages?.unexpectedError);
        }
      });
  };

  const mobileNumber: string = retrieveData("mobileNumber", true);
  const formik = useFormik<FormData>({
    initialValues: {
      mobileNumber: mobileNumber || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      storeData("mobileNumber", values.mobileNumber, true);
      sendMobileOtp(values.mobileNumber);
    },
  });
  const onChangeHandle = (otp: any) => {
    formik.setFieldValue("mobileNumber", otp);
    setNonFormikError(null);
  };
  return (
    <div className="w-full login-card">
      <div className="login-title forget-tittle">
        <div className="w-full flex justify-center pb-2">{/* <img src={authIcon} alt="authIcon" /> */}</div>
        <h2 className="resetHeader">Forgot Username ?</h2>
        <p className="text-center mb-8 forgetPlaceholder">No worries, Enter your mobile number below.</p>
      </div>
      <div className="login-form mt-8">
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup login-userName">
            <PhoneInput inputClass="ant-input h-[42px] w-[100%]" country={"us"} value={formik.values.mobileNumber} onChange={(value) => onChangeHandle(value)} />
            {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className="error">{formik.errors.mobileNumber}</div> : null}
            {!formik.errors.mobileNumber && nonFormikError ? <div className="error mt-1">{nonFormikError}</div> : null}
          </div>
          <div className="btn">
            <Button type="primary" className="bg-akku login-btn" htmlType="submit" loading={loader}>
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLoginTheme(SentOTPMobile);
