import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch, useSelector } from "react-redux";
import FinalReviewUserDataAccordionAttributes from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionAttributes";
import FinalReviewUserDataAccordionGroups from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionGroups";
import FinalReviewUserDataAccordionRoles from "./FinalReviewUserDataAccordion/FinalReviewUserDataAccordionRoles";
import "./FinalReview.scss";
import { Button, Collapse } from "antd";
import { setActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { CollapseProps } from "antd/lib";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import SuccessMessageModal from "../../../../access-manager/create/IpTimeSuccessModal";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
import { retrieveData } from "../../../../../services/storage/Storage";
import { provisioningConstant } from "../const/provisioningConts";
import { useTranslation } from "react-i18next";
import { getHeaders } from "../../../../../const/getHeader";

const FinalReviewUserData = (props: any) => {
  const { t } = useTranslation();
  const realmId = retrieveData("realmId", true);
  const token: string = retrieveData("authToken", true);
  const getProvisioningMappingAttributesURL: string = process.env.REACT_APP_PROVISIONING_CLOUD_FUN_BASEURL || "";
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const [roleAttributeDetails, setRoleAttributeDetails] = useState<any>(null);
  const [activeKey, setActiveKey] = useState<string>("1");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);
  const isGroupConfigured = useSelector((state: any) => state?.provisioning?.isGroupProvisionConfigured);
  const isRoleConfigured = useSelector((state: any) => state?.provisioning?.isRoleProvisionConfigured);

  const [loader, setLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [noOfApiCalls, setNoOfApiCalls] = useState();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [groupAttributeDetails, setGroupAttributeDetails] = useState<any>(null);
  const [mappingAttributeDetails, setMappingAttributeDetails] = useState<any>(null);
  useEffect(() => {
    if (apiHitCount === noOfApiCalls) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        handleCloseSuccessModal();
        navigate("/app-store");
      }, 3000);
    }
  }, [apiHitCount]);

  useEffect(() => {
    getProvisioningConnectorDetailsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createRolePayload = () => {
    const connectorName = generalDetails?.name?.toUpperCase();
    const payload = {
      realmId: realmId,
      apiType: provisioningConstant.apiTypeReadPermission,
      connectorName: connectorName,
      endPointDescription: provisioningConstant.roleListEndPointDescription,
      authorization: `Bearer ${token}`,
    };
    return payload;
  };

  const createGroupPayload = () => {
    const connectorName = generalDetails?.name?.toUpperCase();
    const payload = {
      realmId: realmId,
      apiType: provisioningConstant.apiTypeReadPermission,
      connectorName: connectorName,
      endPointDescription: provisioningConstant.groupListEndPointDescription,
      authorization: `Bearer ${token}`,
    };
    return payload;
  };

  const geMappingAttributes = async (data: any) => {
    try {
      return data?.flatMap((item: any) => {
        // For delete_user, check headerParameters or pathVariable
        const pathVariableMappings = Object.entries(item?.pathVariable || {}).map(([source, target]) => ({
          source,
          target,
        }));
        const headerParameterMappings = Object.entries(item?.headerParameters || {}).map(([source, target]) => ({
          source,
          target,
        }));

        // For create_user, use attributeMappings
        const attributeMappings = Object.entries(item?.attributeMappings || {})
          ?.filter(([source, target]: any) => target.trim() !== "")
          ?.map(([source, target]) => ({
            source,
            target,
          }));

        // Combine both delete_user and create_user mappings
        return [...attributeMappings, ...headerParameterMappings, ...pathVariableMappings];
      });
    } catch (e) {
      console.error("geMappingAttributes error:", e);
      return []; // Return an empty array in case of error
    }
  };

  const getProvisioningConnectorDetailsApi = async () => {
    setLoader(true);
    let params = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;

      if (status === 200) {
        const data = response?.data?.data;
        const mappingAttribute = await geMappingAttributes(data?.endPointResponseDtos);
        setMappingAttributeDetails(mappingAttribute);
        getProvisioningGroupMappingAttributes(data);
        getProvisioningRoleMappingAttributes(data);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getProvisioningGroupMappingAttributes = async (el: any) => {
    setLoader(true);
    const payload = createGroupPayload();
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructFinalResult(el?.akkuProvisioningGroupMapDtoList, data);
          setGroupAttributeDetails(finalResults);
        } catch (e) {
          console.log("Error in group attribute", e);
        }
        setLoader(false);
      } else {
        console.error("getProvisioningGroupMappingAttributes error:", data);
        // handleRequestError(data);
      }
      setLoader(false);
    } catch (err) {
      console.log("getProvisioningGroupMappingAttributes err:", err);
      setLoader(false);
      handleRequestError(err);
    }
  };
  function constructFinalResult(mappedData: any, apiResponse: any) {
    const { akku_data, sp_data } = apiResponse;
    return mappedData.map((mappedItem: any) => {
      // Find the corresponding akku_data and sp_data based on the ids
      const akkuItem = akku_data.find((item: any) => item.id === mappedItem.akkuGroupId) || { id: "", name: "" };
      const spItem = sp_data.find((item: any) => item.id === mappedItem.spGroupId) || { id: "", name: "" };
      // Construct the final result for each mappedItem
      if (!akkuItem.name?.trim()) {
        return { source: "", target: "", sourceId: "", targetId: "" };
      }

      return {
        source: akkuItem.name || "", // Map akku data name to source
        sourceId: akkuItem.id || "", // Map akku data id to sourceId
        target: spItem.name || "", // Map sp data name to target
        targetId: spItem.id || "", // Map sp data id to targetId
      };
    });
  }
  const getProvisioningRoleMappingAttributes = async (element: any) => {
    setLoader(true);
    const payload = createRolePayload();
    try {
      const response = await fetch(getProvisioningMappingAttributesURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data?.statusCode === 200) {
        try {
          const finalResults = constructFinalResultForRoleAttributes(element?.akkuProvisioningRoleMapDtoList, data);
          setRoleAttributeDetails(finalResults);
        } catch (e) {
          console.log("Error in role attribute", e);
        }

        // // if (provisioning?.isActiveEdit) {
        //   const finalResults = constructFinalResultForRoleAttributes(element?.akkuProvisioningRoleMapDtoList, data);
        //   setRoleAttributeDetails(finalResults);
        // // }

        setLoader(false);
      } else {
        // handleRequestError(data);
        console.error("getProvisioningRoleMappingAttributes error:", data);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  function constructFinalResultForRoleAttributes(mappedData: any, apiResponse: any) {
    try {
      const { akku_data, sp_data } = apiResponse;
      return mappedData?.map((mappedItem: any) => {
        // Find the corresponding akku_data and sp_data based on the ids
        const akkuItem = akku_data.find((item: any) => item.id === mappedItem?.akkuRoleId) || { id: "", name: "" };
        const spItem = sp_data.find((item: any) => item.id === mappedItem?.spRoleId) || { id: "", name: "" };

        if (!akkuItem.name?.trim()) {
          return { source: "", target: "", sourceId: "", targetId: "" };
        }

        // Construct the final result for each mappedItem
        return {
          source: akkuItem.name || "", // Map akku data name to source
          sourceId: akkuItem.id || "", // Map akku data id to sourceId
          target: spItem.name || "", // Map sp data name to target
          targetId: spItem.id || "", // Map sp data id to targetId
        };
      });
    } catch (e) {
      console.log("error in constructFinalResultForRoleAttributes", e);
    }
  }
  const handleBack = () => {
    dispatch(setActiveStep(7));

    // if (isRoleConfigured && isGroupConfigured) {
    //   dispatch(setActiveStep(7));
    // } else if (isRoleConfigured && !isGroupConfigured) {
    //   dispatch(setActiveStep(7));
    // } else if (!isRoleConfigured && isGroupConfigured) {
    //   dispatch(setActiveStep(6));
    // } else if (!isRoleConfigured && !isGroupConfigured) {
    //   dispatch(setActiveStep(5));
    // }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const handleNavigate = () => {
    navigate("/app-store");
  };
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[20px] font-normal"> {t("appManagement.provisioning.attributes")}</p>,
      children: <FinalReviewUserDataAccordionAttributes mappingAttributeDetails={mappingAttributeDetails} />,
    },
    ...(groupAttributeDetails?.length > 0
      ? [
          {
            key: "2",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.groups")}</p>,
            children: <FinalReviewUserDataAccordionGroups groupAttributeDetails={groupAttributeDetails} />,
          },
        ]
      : []),
    ...(roleAttributeDetails?.length > 0
      ? [
          {
            key: "3",
            label: <p className="text-[#000] font-Inter text-[20px] font-normal">{t("appManagement.provisioning.roles")}</p>,
            children: <FinalReviewUserDataAccordionRoles roleAttributeDetails={roleAttributeDetails} />,
          },
        ]
      : []),
  ];

  const handleAddConnection = () => {
    navigate("/app-store");
  };

  return (
    <>
      <div className="w-full  relative h-full">
        <div className="bg-[#fff]  m-5 rounded-lg p-7 min-h-[calc(100vh-127px)]">
          <div className="w-full final-review-user-data  bg-[#ffffff]">
            <ul className="flex breadcrumb">
              <li role="none" className="font-Inter  pr-1 cursor-pointer !text-[14px]" onClick={handleNavigate}>
                {t("appManagement.appManagement")} {"/ "}
              </li>
              <li className="font-Inter !text-[14px] cursor-pointer" onClick={() => dispatch(setActiveStep(2))}>
                {" "}
                {t("appManagement.provisioningLabel")} {"/ "}
              </li>
              <li className="font-Inter !text-[14px] cursor-pointer" onClick={() => dispatch(setActiveStep(5))}>
                {t("appManagement.provisioning.mapping")} {"/ "}
              </li>
              <li className="active font-Inter !text-[14px]">{t("appManagement.provisioning.finalReview")}</li>
            </ul>

            <div className="w-full">
              <p className="app-header-title mt-5">
                {t("appManagement.provisioning.finalReviewUserData")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
              </p>
              <p className="text-[#475467] text-[18px] font-Inter  mt-2"> {t("appManagement.provisioning.reviewMapping")}</p>
            </div>

            <div className=" overflow-y-auto mx-auto pt-3 pb-60 xl:pb-20 w-[100%] flex flex-col gap-2 overflow-x-auto final-review-user-data-accordions-wrappr">
              <div className="w-full final-review-user-data-wrapper pb-60 xl:pb-20">
                <Collapse
                  accordion
                  activeKey={activeKey}
                  onChange={handleCollapseChange}
                  items={items}
                  className="w-[95%] custom-header  "
                  expandIconPosition="end"
                  expandIcon={() => null}
                />
              </div>
            </div>
            <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer mx-auto w-full pr-5">
                <div className="w-full justify-end flex ">
                  <CustomButtonBack onClick={handleBack} text={t("common.back")} />
                  <Button type="primary" onClick={handleAddConnection} className="bg-[#5441DA]  submit-btn">
                    {t("common.confirm")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
      {openSuccessModal && (
        <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />
      )}
    </>
  );
};

export default FinalReviewUserData;
