import React, { useState } from "react";
import { Collapse, CollapseProps, Select, Input, Button } from "antd";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { useDispatch, useSelector } from "react-redux";

const ConnectionDatabaseDetailsProvision = () => {
  const initialValues = {
    queryType: "",
    query: "",
  };
  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);

  const handleSubmit = async (values: any) => {
    console.log(values);
  };
  const connectorType = [{ label: "", value: "" }];
  const handleOnChange = (connectorType: string, value: any) => {
    console.log(value);
  };
  const itemsUser: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter font-normal text-[18px] ">User Provisioning</p>,
      children: (
        <div className="w-[80%] mx-auto ">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, dirty, setFieldValue }) => {
              return (
                <Form>
                  <div className="flex pt-10 flex-wrap app-connector ">
                    <div className="relative input-container mb-12 xl:w-[45%] w-[100%] ">
                      <p>Query Type*</p>
                      <Field
                        as={Select}
                        placeholder="Select"
                        className=" w-[100%] h-[56px]"
                        name="queryType"
                        value={initialValues.queryType}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value: any) => handleOnChange("queryType", value)}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                      >
                        {connectorType?.map((option: any) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="queryType" component="div" className="error-message" />
                    </div>

                    <div className="mb-12 xl:w-[45%] w-[100%]  relative input-container">
                      <p>Query*</p>
                      <Field as={Input} type="text" className="form-type-box" name="query" placeholder="Enter" />
                      <ErrorMessage name="query" component="div" className="error-message" />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ),
    },
  ];
  const itemsRole: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter font-normal text-[18px] ">Role Provisioning</p>,
      children: (
        <div className=" w-[80%] mx-auto ">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, dirty, setFieldValue }) => {
              return (
                <Form>
                  <div className="flex pt-10 flex-wrap app-connector ">
                    <div className="mb-12 xl:w-[45%] w-[100%]  relative input-container">
                      <p>Query Type*</p>
                      <Field
                        as={Select}
                        className=" w-[100%] h-[56px]"
                        onChange={(value: any) => handleOnChange("queryType", value)}
                        value={initialValues.queryType}
                        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Select"
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        name="queryType"
                      >
                        {connectorType?.map((option: any) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="queryType" component="div" className="error-message" />
                    </div>

                    <div className="relative input-container mb-12 xl:w-[45%] w-[100%]  ">
                      <p>Query*</p>
                      <Field as={Input} type="text" className="form-type-box" name="query" placeholder="Enter" />
                      <ErrorMessage name="query" component="div" className="error-message" />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ),
    },
  ];
  const [activeKey, setActiveKey] = useState<string>("");
  const [roleProvisionAccordionKey, setRoleProvisionAccordionKey] = useState<string>("");

  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const handleRoleProvisionAccordionChange = (key: any) => {
    setRoleProvisionAccordionKey(key);
  };

  const handleNext = () => {
    const updatedStepper = {
      activeStep: 12,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };

  return (
    <div className="w-full px-10 pb-20 connector-details-provision h-[70vh] overflow-y-auto connector-database-provision">
      <p className="app-header pt-8">Connector Details - Provisioning {provisioning?.isDuplicate ? "(Copy)" : null}</p>
      <Collapse accordion activeKey={activeKey} onChange={handleCollapseChange} items={itemsUser} className="w-[95%] custom-header  pt-10" expandIconPosition="end" />

      <Collapse accordion activeKey={roleProvisionAccordionKey} onChange={handleRoleProvisionAccordionChange} items={itemsRole} className="w-[95%] custom-header  pt-10" expandIconPosition="end" />
      <div className="footer ">
        <div className="modal-footer w-full mx-auto ">
          <div className="w-full flex justify-end pr-5">
            <Button className=" back-btn">Back</Button>
            <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn" onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionDatabaseDetailsProvision;
