import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PermissionsState {
  credentialInitialData: [];
  isUpdate: boolean;
  searchValue:string;
  message:string;
  setTableData:[]
}

const initialState: PermissionsState = {
  credentialInitialData: [],
  isUpdate: false,
  searchValue:"",
  message:"",
  setTableData:[]

};

const credentialsSlice = createSlice({
  name: "credentialsSlice",
  initialState,
  reducers: {
    setCredentials(state, action: any) {
      state.credentialInitialData = action.payload;
    },
     setTableData(state, action: any) {
      state.setTableData = action.payload;
    },
     setSearchConnection(state, action: any) {
      state.searchValue = action.payload;
    },
   clearSearchConnection(state) {
      state.searchValue = '';
    },
     setSuccessMessage(state,action: any) {
      state.message = action.payload;
    },
      clearConnection(state) {
      state.message = '';
    },
 
    setClearCredentials(state) {
      state.credentialInitialData = [];
      state.isUpdate = false;
    },
    setCredentialUpdate(state, action: PayloadAction<boolean>) {
      state.isUpdate = action.payload;
    },
 
  },
});

export const { setTableData,setSearchConnection,clearConnection,setSuccessMessage,clearSearchConnection,setCredentials, setClearCredentials, setCredentialUpdate } = credentialsSlice.actions;

export default credentialsSlice.reducer;
