import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomButtonBack from '../../layouts/component/CustomButtonBack';
import CustomButtonNext from '../../layouts/component/CustomButton';
import ImageView from '../../layouts/component/ImageView';
import { images } from '../../assets/images/ImagePaths';

interface AdaptiveMFADataLostModalInterface {
  handleCancel: () => void;
  open: boolean;
  handleDataLoss: () => void;
}
export default function AdaptiveMFADataLostModal(props: AdaptiveMFADataLostModalInterface) {
  const { handleCancel, open, handleDataLoss } = props;
  const { t } = useTranslation();

  return (
    <div className="closeConfirmationModal">
      <Modal
        className="p-10 rounded-lg adaptive-mfa-modal"
        closable={false}
        centered
        open={open}
        footer={false}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <div className="pt-4 modal-body">
          <div className="flex-col flex justify-center items-center w-full">
            <ImageView src={images.warningInfo} alt="warningIcon" className="h-20 w-20" />
            <p className="pt-4 text-[18px] text-center font-Inter">
              {t('adaptiveMfa.changesWillBeLost')} <br></br> {t('adaptiveMfa.confirmCancel')}
            </p>
          </div>
        </div>
        <div className="pt-8 modal-footer">
          <div className="flex justify-center w-full">
            <CustomButtonBack onClick={handleCancel} text={t('common.no')} />
            <CustomButtonNext text={t('common.yes')} onClick={handleDataLoss} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
