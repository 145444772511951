export const maskEmail = (email: any) => {
  if (email) {
    // Split the email address into username and domain
    const [username, domain] = email.split("@");
    // Get the length of the username
    const usernameLength = username.length;
    // Keep the first and last characters of the username visible
    const maskedUsername = username.charAt(0) + "*".repeat(usernameLength - 2) + username.charAt(usernameLength - 1);
    // Combine the masked username and domain to form the masked email address
    return `${maskedUsername}@${domain}`;
  }
};

export const maskMobileNumber = (mobile: any) => {
  if (mobile) {
    // Check if the mobile number is valid
    if (typeof mobile !== "string" || mobile.length < 2) {
      return mobile; // Return the original mobile number if it's not valid
    }
    // Get the last two characters of the mobile number
    const lastTwoDigits = mobile.slice(-2);
    // Replace all but the last two characters with asterisks
    const maskedDigits = "*".repeat(mobile.length - 2);
    // Combine the masked digits and the last two characters to form the masked mobile number
    return maskedDigits + lastTwoDigits;
  }
};

function splitBeforeDomain(url: any) {
  // Remove protocol and slashes
  let strippedUrl = url.replace(/^(https?:\/\/)?(www\.)?/, "");
  let splitArray = strippedUrl?.split("cloudnowtech.com");
  splitArray[1] = splitArray[1]?.replace(/\/$/, "");

  return splitArray;
}

function removeDot(subdomain: any) {
  // Remove trailing dot
  return subdomain.replace(/\.$/, "");
}

export const extractSubdomain = (url: any) => {
  const resultArray = splitBeforeDomain(url);
  const subdomain = resultArray[0];
  const result = removeDot(subdomain);
  return result;
};


export const cursorNotAllowedStyle = (permission:boolean) => {
  return permission? "cursor-pointer" : "cursor-not-allowed";
}
