import React from "react";
import { Radio } from "antd";
import { credentialFilter } from "../../helper/credentialHelper";
interface ConnectionFilterDropdownProps {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
}
const ConnectionFilterDropdown: React.FC<ConnectionFilterDropdownProps> = ({ setSelectedKeys, selectedKeys, confirm }) => {
  return (
    <div className="px-4 pt-2 pb-6">
      <Radio.Group
        onChange={(e) => {
          const value = e.target.value.toLowerCase();
          setSelectedKeys(value ? [value] : []);
          confirm();
        }}
        value={selectedKeys[0]}
      >
        {credentialFilter?.map((filter: any) => (
          <div key={filter.value} className="pt-3 credential-filter">
            <Radio value={filter?.value.toLowerCase()}>{filter.text}</Radio>
          </div>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ConnectionFilterDropdown;
