import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  deviceRestrictionList: any;
}

const initialState: AuthState = {
  deviceRestrictionList: [],
};

const deviceRestrictionSlice = createSlice({
  name: "deviceRestriction",
  initialState,
  reducers: {
    setDeviceRestrictionList: (state, action: PayloadAction<any>) => {
      state.deviceRestrictionList = action.payload;
    },
    clearDeviceRestrictionList: (state) => {
      state.deviceRestrictionList = [];
    },
  },
});

export const { setDeviceRestrictionList, clearDeviceRestrictionList } = deviceRestrictionSlice.actions;
export default deviceRestrictionSlice.reducer;
