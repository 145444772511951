import React from "react";
import LicenseCard from "../../../layouts/dashboard-module/license-card/LicenseCard";
import RiskManagementCard from "../../../layouts/dashboard-module/risk-management/RiskManagementCard";
import Application from "../../../layouts/dashboard-module/application/Application";
import CustomerTable from "../../../layouts/dashboard-module/customer-table/CustomerTable";
import MFAChart from "../../../layouts/dashboard-module/mfa-chart/MFAChart";
import RecoveryAttempts from "../../../layouts/dashboard-module/attempts/RecoveryAttempts";
import LicenseManagement from "../../../layouts/dashboard-module/license/LicenseManagement";
import ProductAdoption from "../../../layouts/dashboard-module/product-adoption/ProductAdoption";
import { useSelector } from "react-redux";
import "./Assessment.scss";
const TenantAssessments = () => {
  const toggleTable = useSelector((state: any) => state?.TableExpand?.expanedTab);
  const tenantActiveStep = useSelector((state: any) => state?.CustomerSlice?.tenantActiveStep);
  const adminsCustomerType = useSelector((state: any) => state?.CustomerSlice?.adminsCustomerType);
  const tenantsCustomerType = useSelector((state: any) => state?.CustomerSlice?.tenantsCustomerType);

  return (
    <div className={!toggleTable ? "dashboard-container" : "dashboard-container table-container"} data-testid="Application">
      <div className="license-container dashboard-left flex mb-5 pt-8">
        <div className="dashboard-right w-[60%] mr-4">
          <LicenseManagement />
          <div className="mt-5">
            <Application />
          </div>
          <div className="mt-5">
            <RecoveryAttempts />
          </div>
        </div>
        <div className="w-[40%] dashboard-right assessment">
          <ProductAdoption />
          <div className="risk-assessment mt-5">
            <RiskManagementCard />
          </div>
          <div className="mt-5">
            <MFAChart />
          </div>
        </div>
      </div>
      <div className="hidden">
        <div className="_card-container-1 hidden">
          <div className="_card-license">
            <LicenseCard />
          </div>
          <div className="_card-risk_management">
            <ProductAdoption />
          </div>
        </div>
      </div>

      {/* {tenantActiveStep !== 2 &&
        (tenantActiveStep === 1
          ? (adminsCustomerType === "MASTER" || adminsCustomerType === "MSSP") && (
              <div className={toggleTable ? "flex w-full mt-4 CustomerTable" : "flex w-full mt-8 expand CustomerTable"}>
                <CustomerTable />
              </div>
            )
          : tenantActiveStep === 2 &&
            (tenantsCustomerType === "MASTER" || tenantsCustomerType === "MSSP") && (
              <div className={toggleTable ? "flex w-full mt-4 CustomerTable" : "flex w/full mt-8 expand CustomerTable"}>
                <CustomerTable />
              </div>
            ))} */}

      {tenantActiveStep !== 2 && (adminsCustomerType === "MASTER" || adminsCustomerType === "MSSP") && (
        <div className={toggleTable ? "flex w-full mt-4 CustomerTable" : " flex w-full mt-8 expand CustomerTable"}>
          <CustomerTable />
        </div>
      )}
    </div>
  );
};
export default TenantAssessments;
