import { useState } from "react";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { retrieveData } from "../../../services/storage/Storage";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { useDispatch } from "react-redux";
import { setGetConnetorDetailsData } from "../../../redux/slice/provisioning/ProvisioningSlice";
import { setIsPublished } from "../../../redux/slice/profile/ProfileDetailsSlice";
import { provisioningRootState } from "../../../redux/slice/provisioning/ProvisioningTypes";

interface UseProvisioningDetailsProps {
  provisioning: any;
}
export const useProvisioningDetails = ({ provisioning }: UseProvisioningDetailsProps) => {
  const dispatch = useDispatch();
  const [provisionDetails, setProvisionDetails] = useState<Record<string, provisioningRootState>>({});
  const [loader, setLoader] = useState<boolean>(false);
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");

  // Function to fetch provisioning connector details from the API
  const getProvisioningDetails = async (): Promise<void> => {
    setLoader(true);
    const params = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };

    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setProvisionDetails(data);
        dispatch(setGetConnetorDetailsData(data));
        dispatch(setIsPublished(data?.publishStatus));
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  return { provisionDetails, loader, getProvisioningDetails };
};
