import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customerTable } from "../../constant/prerequisites/prerequisites";

interface AuthState {
  data: any;
  customerTableData: any[]; // Add a property for table data
  riskAssessmentData: any[];
  application: any[];
  MFA: any[];
  customerTableFilterData: any; // Add a property for table filter data
  recoveryAttempts: any[];
  totalLicenses: any;
  userList: {};
  licenseManagement: any;
  selectedRiskAssessment: any;
  isActiveAkkuMaster: boolean;
}

const initialState: AuthState = {
  data: null,
  customerTableData: [],
  riskAssessmentData: [],
  application: [],
  MFA: [],
  recoveryAttempts: [],
  totalLicenses: {},
  customerTableFilterData: customerTable.initialFilterData, // Initialize with default filter data
  userList: {},
  licenseManagement: {},
  selectedRiskAssessment: null,
  isActiveAkkuMaster: false,
};

const dashboardDetailsSlice = createSlice({
  name: "dashboardDetails",
  initialState,
  reducers: {
    setDashboardDetails: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    setCustomerTableData: (state, action: PayloadAction<any[]>) => {
      state.customerTableData = action.payload;
    },
    setCustomerTableFilterData: (state, action: PayloadAction<any>) => {
      state.customerTableFilterData = action.payload;
    },
    setRiskAssessmentData: (state, action: PayloadAction<any[]>) => {
      state.riskAssessmentData = action.payload;
    },
    setApplication: (state, action: PayloadAction<any>) => {
      state.application = action.payload;
    },
    setMFA: (state, action: PayloadAction<any>) => {
      state.MFA = action.payload;
    },
    setRecoveryAttempts: (state, action: PayloadAction<any>) => {
      state.recoveryAttempts = action.payload;
    },
    setTotalLicenses: (state, action: PayloadAction<any>) => {
      state.totalLicenses = action.payload;
    },
    setAkkuMasterStatus: (state, action: PayloadAction<boolean>) => {
      state.isActiveAkkuMaster = action.payload;
    },
    clearDashboardDetails: (state) => {
      state.data = null;
      state.customerTableData = []; // Clear table data when clearing dashboard details
      state.customerTableFilterData = customerTable.initialFilterData; // Reset filter data to initial values when clearing dashboard details
    },
    setUserDetail: (state, action: PayloadAction<any>) => {
      state.userList = action.payload;
    },
    clearUserDetail: (state) => {
      state.userList = {};
    },
    setLicenseManagement: (state, action: PayloadAction<any>) => {
      state.licenseManagement = action.payload;
    },
    setSelectedRiskAssessment: (state, action: PayloadAction<any>) => {
      state.selectedRiskAssessment = action.payload;
    },
  },
});

export const {
  setDashboardDetails,
  setCustomerTableData,
  setCustomerTableFilterData,
  clearDashboardDetails,
  setRiskAssessmentData,
  setApplication,
  setMFA,
  setRecoveryAttempts,
  setTotalLicenses,
  setUserDetail,
  clearUserDetail,
  setSelectedRiskAssessment,
  setAkkuMasterStatus,
} = dashboardDetailsSlice.actions;
export default dashboardDetailsSlice.reducer;
