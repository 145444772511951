import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  rolesResponse: {}
}

const initialState: AuthState = {
    rolesResponse: {},
};

const rolesAndPermissionData = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setRolesResponse: (state, action: PayloadAction<boolean>) => {
      state.rolesResponse = action.payload;
    },
    clearRolesResponse: state => {
      state.rolesResponse = {};
    },
  },
});

export const { setRolesResponse, clearRolesResponse } = rolesAndPermissionData.actions;
export default rolesAndPermissionData.reducer;
