import * as Yup from "yup";

export const validationSchema = (t: any) =>
  Yup.object({
    forms: Yup.array().of(
      Yup.object({
        source: Yup.string().required(t("userManagement.errors.ldapReviewMapping.sourceRequired")),
        target: Yup.string().required(t("userManagement.errors.ldapReviewMapping.targetRequired")),
        userType: Yup.string().required(t("userManagement.errors.ldapReviewMapping.userTypeRequired")),
      }),
    ),
  });
