import { Input } from "antd";
import { FieldArray, Field, ErrorMessage, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup"; // Import Yup for validation
import DynamicFieldArray from "../../../../../../../layouts/form/dynamic-field-array/DynamicFieldArray";

// Define Yup schema for URL validation
const urlValidationSchema = Yup.string().url("Invalid URL format").required("URL is required");

interface ScopeFieldArrayProps {
  values: any;
}

export const ScopeFieldArray: React.FC<ScopeFieldArrayProps> = ({ values }) => {
  const { t } = useTranslation();
  const { setFieldTouched, validateForm, setFieldError } = useFormikContext();

  // Helper function to validate the URL
  const validateUrl = async (url: string, index: number) => {
    try {
      await urlValidationSchema.validate(url); // Try validating the URL
      return true;
    } catch (error) {
      setFieldError(`scope.${index}`, (error as Yup.ValidationError).message); // Set error message
      setFieldTouched(`scope.${index}`, true); // Mark the field as touched
      return false;
    }
  };

  return (
    <FieldArray name="scope">
      {({ push, remove }) => (
        <div className="mb-12 w-[100%] input-container relative">
          <p className="provision-field-labels"> {t("appManagement.provisioning.scope")}</p>
          {values?.scope?.length === 0 && push("") /* Ensure at least one input is present */}

          {values?.scope?.map((scopeValue: any, index: number) => (
            <div className="relative flex justify-between mb-9" key={index}>
              <Field
                as={Input}
                type="text"
                className="form-type-box bg-[#f3f3f8]"
                name={`scope.${index}`}
                placeholder="Enter"
                onBlur={async () => {
                  // Trigger validation and mark the field as touched
                  setFieldTouched(`scope.${index}`, true);
                  await validateForm();
                }}
              />

              <div className="provision-action ml-2">
                {values?.scope?.length > 1 || (index === 0 && values?.scope[0]?.trim() !== "") ? (
                  <button type="button" className="remove-btn" onClick={() => remove(index)}>
                    <span className="material-symbols-outlined provision-plus-btn">remove</span>
                  </button>
                ) : null}

                <button
                  type="button"
                  onClick={async () => {
                    const isValid = await validateUrl(values?.scope[index], index); // Validate the current field
                    if (isValid) {
                      push(""); // Add new field only if the current field is valid
                    } else {
                      await validateForm(); // Ensure the form is validated if not valid
                    }
                  }}
                >
                  <span className="material-symbols-outlined provision-plus-btn">add</span>
                </button>
              </div>
              <ErrorMessage name={`scope.${index}`} component="div" className="error-message bottom-[-30px]" />
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  );
};
