import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { storeData } from "../../services/storage/Storage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { fromWhere, setUserDetails } from "../../redux/slice/Users/UserDetailSlice";
import { setFromUserName } from "../../redux/slice/Users/MobileNumberSlice";
import * as Yup from "yup";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";
import { extractSubdomain } from "../../hooks/commonHooks";
import { ErrorMessages } from "../../const/Messages";

interface FormData {
  userName: string;
}

const ForgotPassword: React.FC = () => {
  const localRealmName: string | undefined = process.env.REACT_APP_LOCALHOST_REALM_NAME;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const getUserNameDetailsApi = useGetApiRequests("userDetails", "GET");
  const getLocation = window.location;
  const [realmName, setRealmName] = useState<string | undefined>("");
  const [nonFormikError, setNonFormikError] = useState<any>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const userNameFromUrl = urlParams.get("username");
  const validationSchemaForForgotPassword = Yup.object({
    userName: Yup.string().required("Username is required"),
  });

  useEffect(() => {
    storeData("userName", "", true);
    setRealName();
  }, []);

  const setRealName = () => {
    if (getLocation.hostname === "localhost" || getLocation.hostname === "sso-demo2" || getLocation.hostname === "development") {
      storeData("realmName", localRealmName, true);
      setRealmName(localRealmName);
    } else {
      const realmNameFromFunction = extractSubdomain(getLocation.href);
      setRealmName(realmNameFromFunction);
      storeData("realmName", realmNameFromFunction, true);
    }
  };

  const formik = useFormik<FormData>({
    initialValues: {
      userName: userNameFromUrl ?? "",
    },
    validationSchema: validationSchemaForForgotPassword,
    onSubmit: (values, { resetForm }) => {
      storeData("userName", values.userName, true);
      const queryParamsObject = {
        userName: values.userName,
        realmName: realmName,
        isSetPassword: location.pathname === "/setpassword",
      };
      getUserNameDetailsApi("", queryParamsObject)
        .then((response: any) => {
          dispatch(fromWhere("forgot-password"));
          storeData("fromSetPassword", false, true);
          const responseData = response?.data?.data;
          if (response.data.status === "OK") {
            dispatch(setUserDetails(responseData));
            dispatch(setFromUserName(false));
            storeData("userName", values.userName, true);
            storeData("userDetails", response.data, true);
            navigate("/multi-factor-authentication");
          }
        })
        .catch((err) => {
          if (err?.response?.status === 409 || err?.response?.status === 404 || err?.response?.status === 400) {
            setNonFormikError(err?.response?.data?.message);
          } else {
            setNonFormikError(ErrorMessages?.unexpectedError);
          }
          resetForm();
        });
    },
  });
  const onChangeHandle = (userName: any) => {
    formik.setFieldValue("userName", userName.target.value);
    setNonFormikError(null);
  };
  return (
    <div className="w-full login-card forgot">
      <div className="login-title forget-tittle">
        <div className="w-full flex justify-center pb-2">{/* <img src={authIcon} alt="authIcon" /> */}</div>
        <h2 className="resetHeader">{location.pathname === "/setpassword" ? "Set Password" : "Reset Password"}</h2>
        {location.pathname !== "/setpassword" && <p className="text-center mb-4 forgetUsername">To reset your password, please provide your username.</p>}
      </div>

      <div className="login-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup login-userName">
            <label className="label">Username</label>
            <Input placeholder="Enter username" name="userName" value={formik.values.userName} onChange={(value) => onChangeHandle(value)} onBlur={formik.handleBlur} />
            {formik.touched.userName && formik.errors.userName ? <div className="error absolute mt-1">{formik.errors.userName}</div> : null}
            {!formik.errors.userName && nonFormikError ? <div className="error absolute mt-1">{nonFormikError}</div> : null}
          </div>

          <div className="btn">
            <Button type="primary" className="login-btn" htmlType="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLoginTheme(ForgotPassword);
