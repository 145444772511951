import React, { useState } from "react";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { Button, Input } from "antd";
import { createAuthSchema, initialAuthValues } from "../helper/connectorHelper";
import TestingModal from "../modal/TestingModal";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep } from "../../../../../redux/slice/app-store/AppDetailsSlice";

const ConnectionDatabaseAuthentication = () => {
  const [enableTest, setEnableTest] = useState<boolean>(true);
  const [testModal, setTestModal] = useState<boolean>(false);
  const provisioning = useSelector((state: any) => state?.provisioning);

  const dispatch = useDispatch();
  const handleSubmit = async (values: any) => {
    setTestModal(true);
    setEnableTest(false);
  };
  const handleBackToUser = () => {
    const updatedStepper = {
      activeStep: 0,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  const handleToNext = () => {
    const updatedStepper = {
      activeStep: 11,
      childStepper: "",
    };
    dispatch(setCurrentStep(updatedStepper));
  };
  return (
    <div className="w-full px-10 pb-20 connection-database-details-auth">
      <p className="app-header pt-8">
        Connector Details - Authentication {provisioning?.isDuplicate ? "(Copy)" : null}
        <span>(All fields are mandatory)</span>
      </p>
      <div className="w-full mx-auto">
        <Formik initialValues={initialAuthValues} validationSchema={createAuthSchema} onSubmit={handleSubmit}>
          {({ values, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="w-full pt-10  app-connector auth flex-wrap ">
                  <div className="mb-12 w-[45%] input-container relative">
                    <p>Database*</p>
                    <Field as={Input} type="text" className="form-type-box" name="tokenApiUrl" data-testid="tokenApiUrl-input" />
                    <ErrorMessage name="tokenApiUrl" component="div" className="error-message" />
                  </div>
                  <div className="mb-12 w-[45%] input-container relative">
                    <p>Database Host*</p>
                    <Field as={Input} type="text" className="form-type-box" name="userName" data-testid="userName-input" />
                    <ErrorMessage name="userName" component="div" className="error-message" />
                  </div>
                  <div className="mb-12 w-[45%] input-container relative">
                    <p>Port*</p>
                    <Field as={Input} type="text" className="form-type-box" name="password" data-testid="password-input" />
                    <ErrorMessage name="password" component="div" className="error-message" />
                  </div>
                  <div className="mb-12 w-[45%] input-container relative">
                    <p>Database Name *</p>
                    <Field as={Input} type="text" className="form-type-box" name="grantType" data-testid="grantType-input" />
                    <ErrorMessage name="grantType" component="div" className="error-message" />
                  </div>
                  <div className="mb-12 w-[45%] input-container relative">
                    <p>Schema_name*</p>
                    <Field as={Input} type="text" className="form-type-box" name="clientId" data-testid="clientId-input" />
                    <ErrorMessage name="clientId" component="div" className="error-message" />
                  </div>
                  <div className="mb-12 w-[45%] input-container relative">
                    <p>User name *</p>
                    <Field as={Input} type="text" className="form-type-box" name="clientSecret" data-testid="clientSecret-input" />
                    <ErrorMessage name="clientSecret" component="div" className="error-message" />
                  </div>
                  <div className="mb-12 w-[45%] input-container relative">
                    <p>Password* </p>
                    <Field as={Input} type="text" className="form-type-box" name="authToken" data-testid="authToken-input" />
                    <ErrorMessage name="authToken" component="div" className="error-message" />
                  </div>

                  {/* <>{initialValues.connectorType === "REST_API" ? <RestApiForm /> : <DBConnectorForm initialValues={initialValues} setFieldValue={setFieldValue} />}</> */}

                  <div className="footer ">
                    <div className="modal-footer w-full mx-auto ">
                      {enableTest ? (
                        <div className="flex w-full  pr-5 justify-end ">
                          <Button className=" back-btn" onClick={handleBackToUser}>
                            Back
                          </Button>
                          <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn " onClick={handleToNext}>
                            Test{" "}
                          </Button>
                        </div>
                      ) : (
                        <div className=" pr-5 w-full flex justify-end">
                          <Button onClick={handleToNext} className="bg-[#5441DA] submit-btn " type="primary">
                            Next
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {testModal && <TestingModal enableTest={enableTest} testModal={testModal} setTestModal={setTestModal} />}
    </div>
  );
};

export default ConnectionDatabaseAuthentication;
