import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import BasicDetailsForm from "../../form/BasicDetailsForm";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../../../../../layouts/toast/ToastBar";
import Loader from "../../../../../../../layouts/component/Loader";
import { useUserContext } from "../../../userContext/UserContext";
import { retrieveData } from "../../../../../../../services/storage/Storage";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";
import "./EditUserModal.scss";
import { useTranslation } from "react-i18next";
const EditUserDetailsModal = (props: any) => {
  const { t } = useTranslation();

  const { getFilterData } = useUserContext();
  const { editUserDetails, handelCloseModal, selectedRowObject } = props;
  const editUser = useGetApiRequests("editUser", "PUT");
  const [loader, setLoader] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: selectedRowObject?.name?.split(" ")[0],
    lastName: selectedRowObject?.name?.split(" ")[1],
    email: selectedRowObject.email,
    userType: "User",
    phone: "",
    verifyEmail: "",
  });
  const isEdit = true;
  useEffect(() => {
    setOpen(editUserDetails);
  }, [editUserDetails]);

  const handleClose = () => {
    handelCloseModal();
    setOpen(false);
  };

  const requestEditUser = async (el: any) => {
    const realmName = retrieveData("realmName", true);

    const payload = {
      realmName: realmName,
      userEntityKeyValueDtoList: [
        {
          id: selectedRowObject.userId,
          username: el.email,
          firstName: el.firstName,
          lastName: el.lastName,
          email: el.email,
          emailVerified: true,
          enabled: true,
        },
      ],
    };

    try {
      const response: any = await editUser(payload);
      const status = response.status;

      if (status === 200) {
        triggerNotification("success", "", response?.data?.message, "topRight");
        getFilterData(0, 10);
        setInitialValues({
          firstName: "",
          lastName: "",
          email: "",
          userType: "User",
          phone: "",
          verifyEmail: "",
        });
        setLoader(false);
        handelCloseModal();
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  return (
    <Modal className="p-5  add-user edit rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleClose}>
      {loader && <Loader />}
      <div className="edit-user-modal">
        <h5 className="text-[32px] font-Inter font-semibold text-[#171717]">{t("userManagement.editInformation")}</h5>
      </div>
      <div className="modal-body px-10 pt-8">
        <p className="text-[#171717] text-[24px] font-inter font-semibold">{t("userManagement.basicDetails")}</p>
        {/* <p className="text-[#747577] text-[18px] font-Inter italic font-medium">
            Last Login : <span>Wednesday 27 December 2023 04:12:32</span>
          </p> */}
      </div>
      <div className="edit-basic-info px-10">
        <BasicDetailsForm
          selectedRowObject={selectedRowObject}
          handleClose={handleClose}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          isEdit={isEdit}
          requestEditUser={requestEditUser}
        />
      </div>
    </Modal>
  );
};

export default EditUserDetailsModal;
