import * as Yup from "yup";

export const handleKerberosNavigate = (stepper: any) => {
  const newStepper = stepper.map((step: any, index: number) => {
    if (index === 0) {
      return {
        title: "General Details",
        status: "check",
        description: "",
        active: true,
        icon: "check",
      };
    }
  });

  const filteredArray = newStepper.filter((item: any) => item !== null && item !== undefined);

  return filteredArray;
};

export const kerberosInitialValues = {
  kerberosName: "",
  kerberosRealmName: "",
  serverPrincipalName: "",
  keytabPath: "",
};

export const kerberosValidationSchema = (t: any) =>
  Yup.object().shape({
    kerberosName: Yup.string().required(t("userManagement.errors.kerberos.kerberosNameRequired")).min(3, t("userManagement.errors.kerberos.kerberosNameMin")),
    kerberosRealmName: Yup.string().required(t("userManagement.errors.kerberos.kerberosRealmNameRequired")).min(3, t("userManagement.errors.kerberos.kerberosRealmNameMin")),
    serverPrincipalName: Yup.string().required(t("userManagement.errors.kerberos.serverPrincipalNameRequired")).min(3, t("userManagement.errors.kerberos.serverPrincipalNameMin")),
    keytabPath: Yup.string().required(t("userManagement.errors.kerberos.keytabPathRequired")),
  });
