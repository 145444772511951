import { Modal } from "antd";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import warningImage from "../../../../../../assets/images/user/warning.svg";
import ImageView from "../../../../../../layouts/component/ImageView";
import { useTranslation } from "react-i18next";

export default function DeviceRestrictionWarningModal({ modalOpen, setModalOpen, contentMessage }: any) {
  const { t } = useTranslation();

  const handleClose = () => {
    setModalOpen();
  };

  return (
    <Modal className=" _suspended p-10  show-notification rounded-lg" centered open={modalOpen} footer={false} onCancel={handleClose} closable={false} maskClosable={false}>
      <div className="modal-body pt-4">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={warningImage} alt="warningImage" className="w-20 h-20" />
          <p className="text-[20px] pt-4 text-center font-semibold">{contentMessage}</p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className=" justify-center w-full flex">
          <CustomButtonNext text={t("common.okay")} onClick={handleClose} />
        </div>
      </div>
    </Modal>
  );
}
