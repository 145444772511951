import { Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import generateTitle from "../../../../layouts/Table/generateTitle";
import { SortOrder } from "antd/lib/table/interface";
import CredentialManagementTableHeader from "../header/CredentialManagementTableHeader";
import { useSelector } from "react-redux";
import { prerequisitesTable } from "../../../../constant/prerequisites/prerequisites";
import ConnectionFilterDropdown from "../connection/ConnectionFilterDropdown";

const CredentialManagementTable = (props: any) => {
  const { setCurrentTabStatus } = props;
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [customerTableData, setCustomerTableData] = useState<[]>([]);
  const setTableData = useSelector((state: any) => state?.credentialsSlice?.setTableData);

  useEffect(() => {
    handleConnectionList();
  }, [setTableData]);

  const handleConnectionList = () => {
    const transformedArray = setTableData?.map((item: any) => ({
      key: item?.userId,
      name: `${item?.firstName} ${item?.lastName}`,
      connectionId: item?.connectionId,
      createdOn: item?.connectionCreatedDate,
      status: item?.connectionStatus !== "invitation" ? "active" : "waiting",
    }));
    setCustomerTableData(transformedArray);
  };

  const groupFilterIcon = () => (
    <Tooltip title={"Filter"}>
      <p onClick={() => setFilterVisible(!filterVisible)} className="flex text-[20px] items-center justify-start font-Inter text-[#1D1D24] font-medium " data-testid="filterId">
        <span className="material-symbols-outlined ml-2 group-filter">filter_list</span>
      </p>
    </Tooltip>
  );

  const columns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          "Name",
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (text: any) => (
        <div>
          <p className={`${text?.toLowerCase()} credentials-data text-[#5441da] cursor-pointer`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: () => generateTitle("Connection ID", ""),
      dataIndex: "connectionId",
      key: "connectionId",
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      render: (text: any) => (
        <div>
          <p className={` credentials-data cursor-pointer`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: () => generateTitle("Created on", ""),
      dataIndex: "createdOn",
      key: "createdOn",
      width: "20%",
      render: (text: any) => (
        <div>
          <p className={`credentials-data cursor-pointer`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: () => generateTitle("Status", ""),
      dataIndex: "status",
      key: "status",
      filterIcon: groupFilterIcon,
      filterDropdown: ConnectionFilterDropdown,
      onFilter: (value: any, record: any) => record.status === value,
      render: (text: any) => (
        <div>
          <p className={`${text?.toLowerCase()} credentials-data  cursor-pointer status capitalize`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <CredentialManagementTableHeader setCurrentTabStatus={setCurrentTabStatus} customerTableData={customerTableData} />
      <div className="mt-5 w-full credentials-table">
        <Table
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: prerequisitesTable.pageSizeOptions,
          }}
          columns={columns}
          dataSource={customerTableData}
        />
      </div>
    </>
  );
};

export default CredentialManagementTable;
