import { Button, Dropdown, Tooltip } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddDeviceRestrictionModal from "../modal/AddDeviceRestrictionModal";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { useSelector } from "react-redux";
import { getPermissionStyle, hasPermission, ModulePermissions } from "../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../const/RolePermissions";
import PermissionsModal from "../../../layouts/permissionsModal/PermissionsModal";
import { useTranslation } from "react-i18next";

const DeviceManagementHeader = () => {
  const permissions: ModulePermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const deviceManagerCreatePermission = permissions && hasPermission(permissions, modulePermissions.deviceManager, "create");
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [addDeviceRestrictionModalOpen, setAddDeviceRestrictionModalOpen] = useState<boolean>(false);
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);

  const handleNavigate = () => {
    navigate("/device-management/csv-upload");
  };
  const handleAddDeviceRestrictionOpenModal = () => {
    setAddDeviceRestrictionModalOpen(true);
  };
  const handelClose = () => {
    setPermissionsModal(false);
  };
  const items: any["items"] = [
    {
      label: (
        <span role="none" className={`flex justify-start items-center  px-2 pt-2 `} onClick={handleAddDeviceRestrictionOpenModal}>
          {/* <span className="material-icons  mr-3 ">add_to_queue</span> */}
          <ImageView src={images.addDevice} className="!w-7 !h-7 img-upload mr-3" />
          <span className="font-Inter  text-[16px] font-medium text-[#1D1D24]">{t("deviceManagement.addDevice")}</span>
        </span>
      ),
      key: "1",
    },

    {
      label: (
        <span role="none" className={`flex justify-start items-center px-3 pt-2 `} onClick={handleNavigate}>
          <span className="material-symbols-outlined mr-3">text_snippet</span>
          <span className="font-Inter  text-[16px] font-medium text-[#1D1D24]">{t("deviceManagement.csv")}</span>
        </span>
      ),
      key: "2",
    },
  ];

  const handleAddDeviceRestrictionCloseModal = () => {
    setAddDeviceRestrictionModalOpen(false);
  };
  const handleDropdownClick = () => {
    if (!permissions || !deviceManagerCreatePermission) {
      setPermissionsModal(true);
    }
  };
  return (
    <div className="header-content flex justify-between items-center">
      <h2>{t("deviceManagement.deviceManagement")}</h2>
      <div className="flex-1 flex justify-end">
        <Dropdown menu={{ items }} trigger={["click"]} disabled={!deviceManagerCreatePermission}>
          <Tooltip title={t("deviceManagement.addNewDevice")}>
            <Button type="primary" className={` add-btn  ${getPermissionStyle(deviceManagerCreatePermission)}`} onClick={handleDropdownClick}>
              <span className="material-symbols-outlined">add </span>
            </Button>
          </Tooltip>
        </Dropdown>
      </div>
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handelClose} />}

      {addDeviceRestrictionModalOpen && <AddDeviceRestrictionModal modalOpen={addDeviceRestrictionModalOpen} handleCloseModal={handleAddDeviceRestrictionCloseModal} />}
    </div>
  );
};

export default DeviceManagementHeader;
