import React, { useState } from "react";
import { Button, Input } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData, storeData } from "../../services/storage/Storage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { setVerifyPayload } from "../../redux/slice/Users/MobileNumberSlice";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";
import { ErrorMessages } from "../../const/Messages";

interface FormData {
  email: string;
}

const SentOTPEmail: React.FC = () => {
  const realmName: string = retrieveData("realmName", true);
  const dispatch = useDispatch();
  const verifyPayload = useSelector((state: any) => state?.forgotUsernameMobileNumber?.verifyPayload);
  const navigate = useNavigate();
  const forgotUserNameApi = useGetApiRequests("forgotUserName", "POST");
  const [loader, setLoader] = useState<boolean>(false);
  const [nonFormikError, setNonFormikError] = useState<any>(null);

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required").email("Invalid email"),
  });

  const email: string = retrieveData("email", true);

  const formik = useFormik<FormData>({
    initialValues: {
      email: email || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      storeData("email", values?.email, true);
      requestCreateOTP(values.email);
      dispatch(
        setVerifyPayload({
          ...verifyPayload,
          userName: values?.email,
        }),
      );
    },
  });
  const onChangeHandle = (email: any) => {
    formik.setFieldValue("email", email.target.value);
    setNonFormikError(null);
  };
  const requestCreateOTP = (email: string) => {
    setLoader(true);
    const payloadNew = { personalEmailId: email, realmName: realmName, mailType: "forgot-username" };
    forgotUserNameApi(payloadNew)
      .then((response: any) => {
        navigate("/validate-otp");
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);

        if (err.response.status === 409 || err.response.status === 404 || err.response.status === 400) {
          setNonFormikError(err?.response?.data?.message);
        } else {
          setNonFormikError(ErrorMessages?.unexpectedError);
        }
      });
  };

  return (
    <div className="w-full login-card">
      <div className="login-title forget-tittle">
        <div className="w-full flex justify-center pb-2">{/* <img src={authIcon} alt="authIcon" /> */}</div>
        <h2 className="resetHeader">Forgot Username ?</h2>
        <p className="text-center mb-8 forgetPlaceholder">No worries, Enter your personal email below.</p>
      </div>

      <div className="login-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup login-userName">
            <label className="label">Email</label>

            <Input
              placeholder="Personal email"
              name="email"
              value={formik.values.email}
              onChange={(value) => onChangeHandle(value)}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? <div className="error absolute mt-1">{formik.errors.email}</div> : null}
            {!formik.errors.email && nonFormikError ? <div className="error mt-1">{nonFormikError}</div> : null}
          </div>

          <div className="btn">
            <Button type="primary" className="login-btn" htmlType="submit" loading={loader}>
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLoginTheme(SentOTPEmail);
