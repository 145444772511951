import { Button, Select } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import { useTranslation } from "react-i18next";

const MappingConnectorAttributes = (props: any) => {
  const dispatch = useDispatch();
  const {
    values,
    setFormInitial,
    source,
    setFieldValue,
    target,
    setIsDirty,
    setFieldTouched,
    errors,
    touched,
    createUserTarget,
    deleteUserTarget,
    roleUserTarget,
    groupUserTarget,
    deleteRoleUserTarget,
    deleteGroupUserTarget,

    isCreateUserConfigured,
    isDeleteUserConfigured,
    isRoleUserConfigured,
    isGroupUserConfigured,
  } = props;
  const { t } = useTranslation();
  const provisioning = useSelector((state: any) => state?.provisioning);

  const handleOnChange = (
    field: string,
    section: "createUserData" | "deleteUserData" | "roleUserData" | "groupUserData" | "deleteGroupUserData" | "deleteRoleUserData",
    index: number,
    val: any,
    values: any,
  ) => {
    setIsDirty(true);
    setFieldValue(`${section}.${index}.${field}`, val);

    // // Keep the current field as touched
    setFieldTouched(`${section}.${index}.${field}`, true, false);

    // Mark all fields in the same section as touched to retain error display for other fields
    values[section].forEach((_: any, ind: number) => {
      if (ind !== index) {
        setFieldTouched(`${section}.${ind}.source`, true, false);
        setFieldTouched(`${section}.${ind}.target`, true, false);
      }
    });

    // Update the form state
    const updatedForms = values?.[section]?.map((formItem: any, ind: number) => {
      if (index === ind) {
        return {
          ...formItem,
          [field]: val,
        };
      }
      return formItem;
    });

    setFormInitial((prevValues: any) => ({
      ...prevValues,
      [section]: updatedForms,
    }));
  };

  const handleRemove = (remove: any, index: number) => {
    setIsDirty(true);
    remove(index);
  };

  const handleBack = () => {
    dispatch(setActiveStep(4));
  };

  // const handleFilterSource = (source: any) => {
  //   const storedSource = source || [];
  //   if (storedSource.length > 0) {
  //     const selectedCreateSources = values?.createUserData?.map((formItem: any) => formItem?.source);
  //     const selectedDeleteSources = values?.deleteUserData?.map((formItem: any) => formItem?.source);
  //     const selectedRoleSources = values?.roleUserData?.map((formItem: any) => formItem?.source);
  //     const selectedGroupSources = values?.groupUserData?.map((formItem: any) => formItem?.source);

  //     const selectedSources = [...(selectedCreateSources || []), ...(selectedDeleteSources || []), ...(selectedRoleSources || []), ...(selectedGroupSources || [])];
  //     const filteredSource = storedSource?.filter((option: any) => !selectedSources?.includes(option));
  //     return filteredSource;
  //   }
  //   return storedSource;
  // };
  const handleFilterSource = (
    source: any,
    section: "createUserData" | "deleteUserData" | "roleUserData" | "groupUserData" | "deleteGroupUserData" | "deleteRoleUserData",
  ) => {
    const storedSource = source || [];
    if (storedSource.length > 0) {
      // Get selected sources only for the specific section
      const selectedSources = values?.[section]?.map((formItem: any) => formItem?.source);

      // Filter out the selected sources from the stored source list
      const filteredSource = storedSource?.filter((option: any) => !selectedSources?.includes(option));

      return filteredSource;
    }
    return storedSource;
  };

  // const handleFilterTarget = (type: "create" | "delete" | "role" | "group" | "deleteGroup" | "deleteRole") => {
  //   // Get the stored target based on the type
  //   const storedTarget = type === "create" ? createUserTarget || [] : type === "delete" ? deleteUserTarget || [] : type === "role" ? roleUserTarget || [] : groupUserTarget || [];

  //   // Get the selected targets based on the type
  //   const selectedTargets =
  //     type === "create"
  //       ? values?.createUserData?.map((formItem: any) => formItem?.target)
  //       : type === "delete"
  //         ? values?.deleteUserData?.map((formItem: any) => formItem?.target)
  //         : type === "role"
  //           ? values?.roleUserData?.map((formItem: any) => formItem?.target)
  //           : values?.groupUserData?.map((formItem: any) => formItem?.target);

  //   // Filter storedTarget by excluding the already selected targets
  //   if (storedTarget?.length > 0) {
  //     const filteredTarget = storedTarget?.filter((option: any) => !selectedTargets?.includes(option));
  //     return filteredTarget;
  //   }

  //   return storedTarget;
  // };
  const handleFilterTarget = (type: "create" | "delete" | "role" | "group" | "deleteGroup" | "deleteRole") => {
    let storedTarget = [];
    let selectedTargets: any = [];

    // Determine storedTarget based on the type
    if (type === "create") {
      storedTarget = createUserTarget || [];
    } else if (type === "delete") {
      storedTarget = deleteUserTarget || [];
    } else if (type === "role") {
      storedTarget = roleUserTarget || [];
    } else if (type === "group") {
      storedTarget = groupUserTarget || [];
    } else if (type === "deleteGroup") {
      storedTarget = deleteGroupUserTarget || [];
    } else if (type === "deleteRole") {
      storedTarget = deleteRoleUserTarget || [];
    }

    // Determine selectedTargets based on the type
    if (type === "create") {
      selectedTargets = values?.createUserData?.map((formItem: any) => formItem?.target) || [];
    } else if (type === "delete") {
      selectedTargets = values?.deleteUserData?.map((formItem: any) => formItem?.target) || [];
    } else if (type === "role") {
      selectedTargets = values?.roleUserData?.map((formItem: any) => formItem?.target) || [];
    } else if (type === "group") {
      selectedTargets = values?.groupUserData?.map((formItem: any) => formItem?.target) || [];
    } else if (type === "deleteGroup") {
      selectedTargets = values?.deleteGroupUserData?.map((formItem: any) => formItem?.target) || [];
    } else if (type === "deleteRole") {
      selectedTargets = values?.deleteRoleUserData?.map((formItem: any) => formItem?.target) || [];
    }

    // Filter storedTarget by excluding the already selected targets
    if (storedTarget?.length > 0) {
      const filteredTarget = storedTarget.filter((option: any) => !selectedTargets.includes(option));
      return filteredTarget;
    }

    return storedTarget;
  };

  const handleAdd = (push: any) => {
    push({ source: "", target: "", userType: "", id: "" });
    setIsDirty(true);
  };

  return (
    <div className="overflow-y-auto mapping-attributes-wrapper provisioning-wrapper mt-5">
      {/* {isCreateUserConfigured && ( */}
      <div className="w-full p-5 overflow-auto ">
        <div className="border-[#DADADA] w-[full] rounded-lg border bg-[#fff] pb-3">
          <h2 className="px-4 py-3 font-Inter text-[20px] font-medium text-[#000] bg-[#F0F0F0]">
            {t("appManagement.provisioning.userProvisioning")}
          </h2>
          <FieldArray name="createUserData">
            {({ push, remove }) => (
              <div className="provisioning-container mt-5 px-7 2xl:px-9 ms-3 2xl:ms-1 overflow-x-auto pb-5 2xl:mr-10 mr-2">
                {values?.createUserData?.map((formItem: any, index: any) => (
                  <div key={index} className="w-[500px] xl:w-full flex mapper-attributes items-center">
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[75px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3"> {t("appManagement.provisioning.sourceAkku")}</p>
                      )}

                      <Field
                        placeholder={t("common.searchAndSelect")}
                        showSearch
                        allowClear={true}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        data-testid="usersIt-input"
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`createUserData.${index}.source`}
                        onChange={(value: any) => handleOnChange("source", "createUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* {handleFilterSource(source)?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))} */}
                        {handleFilterSource(source, "createUserData")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`createUserData.${index}.source`} component="div" className="error-message custom-error" />
                    </div>

                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[80px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3">
                          {" "}
                          {t("appManagement.provisioning.target")} - {provisioning?.generalDetails?.name}
                        </p>
                      )}
                      <Field
                        showSearch
                        allowClear={true}
                        placeholder={t("common.searchAndSelect")}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`createUserData.${index}.target`}
                        onChange={(value: any) => handleOnChange("target", "createUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {handleFilterTarget("create")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`createUserData.${index}.target`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[auto] flex justify-start  mt-0 2xl:mt-10 min-h-[75px] xl:min-h-[115px]">
                      <span
                        role="none"
                        className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values.createUserData.length < createUserTarget.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                        onClick={() => values.createUserData.length < createUserTarget.length && handleAdd(push)}
                      >
                        add
                      </span>
                      {values.createUserData.length !== 1 && (
                        <span
                          role="none"
                          className={`${index === 0 ? "mt-3" : "mt-0"} ${values.createUserData.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"} mapping-attributes-plus-icon add remove material-symbols-outlined ml-5`}
                          onClick={() => values.createUserData.length > 1 && handleRemove(remove, index)}
                        >
                          remove
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      </div>
      {/* )} */}
      {/* {isDeleteUserConfigured && ( */}
      <div className="w-full p-5 overflow-auto ">
        <div className="border-[#DADADA] pb-3 w-[full] rounded-lg border bg-[#fff]">
          <h2 className="px-4 py-3 font-Inter text-[20px] font-medium text-[#000] bg-[#F0F0F0]">
            {t("appManagement.provisioning.userDeprovisioning")}{" "}
          </h2>

          <FieldArray name="deleteUserData">
            {({ push, remove }) => (
              <div className="provisioning-container h-auto  mt-5 px-7 2xl:px-9 ms-3 2xl:ms-1 overflow-x-auto pb-5 2xl:mr-10 mr-2">
                {values?.deleteUserData?.map((formItem: any, index: any) => (
                  <div key={index} className="w-[500px] xl:w-full flex mapper-attributes items-center">
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[75px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3"> {t("appManagement.provisioning.sourceAkku")} </p>
                      )}
                      <Field
                        showSearch
                        allowClear={true}
                        placeholder={t("common.searchAndSelect")}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        data-testid="usersIt-input"
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`deleteUserData.${index}.source`}
                        onChange={(value: any) => handleOnChange("source", "deleteUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* {handleFilterSource(source)?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))} */}
                        {handleFilterSource(source, "deleteUserData")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`deleteUserData.${index}.source`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[80px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3">
                          {" "}
                          {t("appManagement.provisioning.target")} - {provisioning?.generalDetails?.name}
                        </p>
                      )}
                      <Field
                        placeholder={t("common.searchAndSelect")}
                        showSearch
                        allowClear={true}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`deleteUserData.${index}.target`}
                        onChange={(value: any) => handleOnChange("target", "deleteUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {handleFilterTarget("delete")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`deleteUserData.${index}.target`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[auto] flex justify-start  mt-0 2xl:mt-10 min-h-[75px] xl:min-h-[115px]">
                      <span
                        role="none"
                        className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values.deleteUserData.length < deleteUserTarget.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                        onClick={() => values.deleteUserData.length < deleteUserTarget.length && handleAdd(push)}
                      >
                        add
                      </span>
                      {values.deleteUserData.length !== 1 && (
                        <span
                          role="none"
                          className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add remove material-symbols-outlined ml-5 ${values.deleteUserData.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"}`}
                          onClick={() => values.deleteUserData.length > 1 && handleRemove(remove, index)}
                        >
                          remove
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      </div>
      {/* )} */}
      {/* {isRoleUserConfigured && ( */}
      <div className="w-full p-5 overflow-auto ">
        <div className="border-[#DADADA] w-[full] pb-3 rounded-lg border bg-[#fff]">
          <h2 className="px-4 py-3 font-Inter text-[20px] font-medium text-[#000] bg-[#F0F0F0]">
            {" "}
            {t("appManagement.provisioning.roleProvisioning")}
          </h2>

          <FieldArray name="roleUserData">
            {({ push, remove }) => (
              <div className="provisioning-container h-auto  mt-5 px-7 2xl:px-9 ms-3 2xl:ms-1 overflow-x-auto pb-5 2xl:mr-10 mr-2">
                {values?.roleUserData?.map((formItem: any, index: any) => (
                  <div key={index} className="w-[500px] xl:w-full flex mapper-attributes items-center">
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[75px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3"> {t("appManagement.provisioning.sourceAkku")}</p>
                      )}
                      <Field
                        showSearch
                        allowClear={true}
                        placeholder={t("common.searchAndSelect")}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        data-testid="usersIt-input"
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`roleUserData.${index}.source`}
                        onChange={(value: any) => handleOnChange("source", "roleUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* {handleFilterSource(source)?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))} */}
                        {handleFilterSource(source, "roleUserData")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`roleUserData.${index}.source`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[80px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3">
                          {" "}
                          {t("appManagement.provisioning.target")} - {provisioning?.generalDetails?.name}
                        </p>
                      )}
                      <Field
                        placeholder={t("common.searchAndSelect")}
                        showSearch
                        allowClear={true}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`roleUserData.${index}.target`}
                        onChange={(value: any) => handleOnChange("target", "roleUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {handleFilterTarget("role")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`roleUserData.${index}.target`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[auto] flex justify-start  mt-0 2xl:mt-10 min-h-[75px] xl:min-h-[115px]">
                      <span
                        role="none"
                        className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values?.roleUserData?.length < roleUserTarget?.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                        onClick={() => values.roleUserData.length < roleUserTarget?.length && handleAdd(push)}
                      >
                        add
                      </span>
                      {values.roleUserData.length !== 1 && (
                        <span
                          role="none"
                          className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add remove material-symbols-outlined ml-5 ${values.roleUserData.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"}`}
                          onClick={() => values.roleUserData.length > 1 && handleRemove(remove, index)}
                        >
                          remove
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      </div>

      {/* {Role De=provisioning && ( */}
      <div className="w-full p-5 overflow-auto ">
        <div className="border-[#DADADA] w-[full] rounded-lg border pb-3 bg-[#fff]">
          <h2 className="px-4 py-3 font-Inter text-[20px] font-medium text-[#000] bg-[#F0F0F0]">
            {" "}
            {t("appManagement.provisioning.roleDeprovisioning")}
          </h2>

          <FieldArray name="deleteRoleUserData">
            {({ push, remove }) => (
              <div className="provisioning-container h-auto  mt-5 px-7 2xl:px-9 ms-3 2xl:ms-1 overflow-x-auto pb-5 2xl:mr-10 mr-2">
                {values?.deleteRoleUserData?.map((formItem: any, index: any) => (
                  <div key={index} className="w-[500px] xl:w-full flex mapper-attributes items-center">
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[75px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3"> {t("appManagement.provisioning.sourceAkku")}</p>
                      )}
                      <Field
                        showSearch
                        allowClear={true}
                        placeholder={t("common.searchAndSelect")}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        data-testid="usersIt-input"
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`deleteRoleUserData.${index}.source`}
                        onChange={(value: any) => handleOnChange("source", "deleteRoleUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* {handleFilterSource(source)?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))} */}
                        {handleFilterSource(source, "deleteRoleUserData")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`deleteRoleUserData.${index}.source`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[80px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3">
                          {" "}
                          {t("appManagement.provisioning.target")} - {provisioning?.generalDetails?.name}
                        </p>
                      )}
                      <Field
                        placeholder={t("common.searchAndSelect")}
                        showSearch
                        allowClear={true}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`deleteRoleUserData.${index}.target`}
                        onChange={(value: any) => handleOnChange("target", "deleteRoleUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {handleFilterTarget("deleteRole")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`deleteRoleUserData.${index}.target`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[auto] flex justify-start  mt-0 2xl:mt-10 min-h-[75px] xl:min-h-[115px]">
                      <span
                        role="none"
                        className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values?.deleteRoleUserData?.length < roleUserTarget?.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                        onClick={() => values.deleteRoleUserData.length < roleUserTarget?.length && handleAdd(push)}
                      >
                        add
                      </span>
                      {values.deleteRoleUserData.length !== 1 && (
                        <span
                          role="none"
                          className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add remove material-symbols-outlined ml-5 ${values.deleteRoleUserData.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"}`}
                          onClick={() => values.deleteRoleUserData.length > 1 && handleRemove(remove, index)}
                        >
                          remove
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      </div>

      {/* )} */}

      {/* {isGroupUserConfigured && ( */}
      <div className="w-full p-5 overflow-auto ">
        <div className="border-[#DADADA] pb-3 w-[full] rounded-lg border bg-[#fff]">
          <h2 className="px-4 py-3 font-Inter text-[20px] font-medium text-[#000] bg-[#F0F0F0]">
            {t("appManagement.provisioning.groupProvisioning")}
          </h2>

          <FieldArray name="groupUserData">
            {({ push, remove }) => (
              <div className="provisioning-container h-auto  mt-5 px-7 2xl:px-9 ms-3 2xl:ms-1 overflow-x-auto pb-5 2xl:mr-10 mr-2">
                {values?.groupUserData?.map((formItem: any, index: any) => (
                  <div key={index} className="w-[500px] xl:w-full flex mapper-attributes items-center">
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[75px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3"> {t("appManagement.provisioning.sourceAkku")}</p>
                      )}
                      <Field
                        showSearch
                        allowClear={true}
                        placeholder={t("common.searchAndSelect")}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        data-testid="usersIt-input"
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`groupUserData.${index}.source`}
                        onChange={(value: any) => handleOnChange("source", "groupUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* {handleFilterSource(source)?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))} */}
                        {handleFilterSource(source, "groupUserData")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`groupUserData.${index}.source`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[80px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3">
                          {" "}
                          {t("appManagement.provisioning.target")} - {provisioning?.generalDetails?.name}
                        </p>
                      )}
                      <Field
                        placeholder={t("common.searchAndSelect")}
                        showSearch
                        allowClear={true}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`groupUserData.${index}.target`}
                        onChange={(value: any) => handleOnChange("target", "groupUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {handleFilterTarget("group")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`groupUserData.${index}.target`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[auto] flex justify-start  mt-0 2xl:mt-10 min-h-[75px] xl:min-h-[115px]">
                      <span
                        role="none"
                        className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values.groupUserData.length < groupUserTarget.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                        onClick={() => values.groupUserData.length < groupUserTarget.length && handleAdd(push)}
                      >
                        add
                      </span>
                      {values.groupUserData.length !== 1 && (
                        <span
                          role="none"
                          className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add remove material-symbols-outlined ml-5 ${values.groupUserData.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"}`}
                          onClick={() => values.groupUserData.length > 1 && handleRemove(remove, index)}
                        >
                          remove
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      </div>
      {/* )} */}

      {/* {Group-Deprovisioning && ( */}
      <div className="w-full p-5 overflow-auto ">
        <div className="border-[#DADADA] pb-3 w-[full] rounded-lg border bg-[#fff]">
          <h2 className="px-4 py-3 font-Inter text-[20px] font-medium text-[#000] bg-[#F0F0F0]">
            {t("appManagement.provisioning.groupDeprovisioning")}
          </h2>

          <FieldArray name="deleteGroupUserData">
            {({ push, remove }) => (
              <div className="provisioning-container h-auto  mt-5 px-7 2xl:px-9 ms-3 2xl:ms-1 overflow-x-auto pb-5 2xl:mr-10 mr-2">
                {values?.deleteGroupUserData?.map((formItem: any, index: any) => (
                  <div key={index} className="w-[500px] xl:w-full flex mapper-attributes items-center">
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[75px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3"> {t("appManagement.provisioning.sourceAkku")}</p>
                      )}
                      <Field
                        showSearch
                        allowClear={true}
                        placeholder={t("common.searchAndSelect")}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        data-testid="usersIt-input"
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`deleteGroupUserData.${index}.source`}
                        onChange={(value: any) => handleOnChange("source", "deleteGroupUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* {handleFilterSource(source)?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))} */}
                        {handleFilterSource(source, "deleteGroupUserData")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`deleteGroupUserData.${index}.source`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[50%] 2xl:w-[33%] max-w-[50%] min-w-[33%] mr-10 source relative mt-0 2xl:mt-7 min-h-[80px] xl:min-h-[115px]">
                      {index === 0 && (
                        <p className="capitalize text-center absolute top-[-30px] w-full !pb-3">
                          {" "}
                          {t("appManagement.provisioning.target")} - {provisioning?.generalDetails?.name}
                        </p>
                      )}
                      <Field
                        placeholder={t("common.searchAndSelect")}
                        showSearch
                        allowClear={true}
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        as={Select}
                        className={`${index === 0 ? "mt-3" : "mt-0"} w-[100%] h-[56px]`}
                        name={`deleteGroupUserData.${index}.target`}
                        onChange={(value: any) => handleOnChange("target", "deleteGroupUserData", index, value, values)}
                        filterOption={(input: string, option: React.ReactElement) =>
                          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {handleFilterTarget("deleteGroup")?.map((option: any) => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name={`deleteGroupUserData.${index}.target`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="w-[auto] flex justify-start  mt-0 2xl:mt-10 min-h-[75px] xl:min-h-[115px]">
                      <span
                        role="none"
                        className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add material-symbols-outlined ml-5 ${values.deleteGroupUserData.length < groupUserTarget.length ? "cursor-pointer" : "cursor-not-allowed"}`}
                        onClick={() => values.deleteGroupUserData.length < groupUserTarget.length && handleAdd(push)}
                      >
                        add
                      </span>
                      {values.deleteGroupUserData.length !== 1 && (
                        <span
                          role="none"
                          className={`${index === 0 ? "mt-3" : "mt-0"} mapping-attributes-plus-icon add remove material-symbols-outlined ml-5 ${values.deleteGroupUserData.length === 1 ? "cursor-not-allowed text-gray-400" : "cursor-pointer"}`}
                          onClick={() => values.deleteGroupUserData.length > 1 && handleRemove(remove, index)}
                        >
                          remove
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
        </div>
      </div>

      <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
        <div className="modal-footer w-full pr-5 mx-auto">
          <div className="w-full flex justify-end">
            <CustomButtonBack onClick={handleBack} text={t("common.back")} />
            <Button type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
              {t("common.next")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingConnectorAttributes;
