import React from "react";
import { Button, Modal } from "antd";
import { termsContent } from "./TermsAndConditionModalHelper";
import { useTranslation } from "react-i18next";

const TermsAndConditionModal = (props: any) => {
  const { t } = useTranslation();
  const { open, handleClose } = props;
  interface TermContent {
    title?: string;
    subTitle?: string;
    content?: string;
    link?: string;
  }
  const renderContent = (content: string | undefined) => {
    return content?.split(/(https?:\/\/[^\s]+)/g).map((part, idx) => {
      // Check if the part is a URL
      const urlMatch = part.match(/(https?:\/\/[^\s]+)/);
      if (urlMatch) {
        let url = urlMatch[1];

        // Detect any trailing punctuation that should not be part of the URL
        let trailingPunctuation = "";

        // Check if the URL ends with a punctuation character and remove it from the URL
        const urlPunctuationMatch = url.match(/([.,!?()]+)$/);
        if (urlPunctuationMatch) {
          trailingPunctuation = urlPunctuationMatch[1];
          url = url.slice(0, -trailingPunctuation.length);
        }

        // Return the URL and any trailing punctuation separately
        return (
          <span key={idx}>
            <a className="text-[#5441DA]" href={url}>
              {url} {/* Render the URL */}
            </a>
            {trailingPunctuation} {/* Render the trailing punctuation, if any */}
          </span>
        );
      }
      return <span key={idx}>{part}</span>; // Return non-URL parts as-is
    });
  };
  return (
    <div>
      <Modal
        title={t("termsAndConditions.termsAndConditions")}
        className="terms-conditions-modal"
        maskClosable={false}
        centered
        open={open}
        footer={
          props?.profile
            ? [
                <div key="footer-container" className="w-full flex items-center justify-end">
                  <div className="my-10 flex justify-end">
                    <Button
                      onClick={handleClose}
                      className="flex items-center justify-evenly font-semibold text-[20px] font-Inter h-[56px] bg-[#5441DA] text-white !w-[160px]"
                    >
                      {t("common.close")}
                    </Button>
                  </div>
                </div>,
              ]
            : null
        }
        onCancel={handleClose}
      >
        <div className="min-h-550px overflow-y-auto relative  max-h-[500px]">
          {termsContent?.map((item: TermContent, index: number) => (
            <div key={index}>
              {item.title && <p className="mt-4 text-[#1D1D24] font-Inter text-[18px] font-bold">{item.title}</p>}
              <p className="mt-2 font-normal text-[18px]">
                {item.subTitle && <span className="mt-4 text-[#1D1D24] font-Inter text-[18px] font-bold">{item.subTitle} &nbsp;</span>}
                {renderContent(item?.content)}
              </p>
            </div>
          ))}

          {!props.profile && (
            <div className={`my-10 flex justify-center`}>
              <Button
                onClick={handleClose}
                className="flex items-center justify-evenly font-semibold text-[20px] font-Inter   h-[56px]  bg-[#5441DA] text-white !w-[160px]"
              >
                {t("common.close")}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TermsAndConditionModal;
