import { Navigate, Outlet } from "react-router-dom";
import { retrieveData } from "../../services/storage/Storage";

function PublicRoute() {
  const token = retrieveData("authToken", true);
  const currentRole = retrieveData("currentRole", true);

  if (token) {
    return <Navigate to={currentRole === "user" ? "/dashboard" : "/admin-dashboard"} />;
  }

  return <Outlet />;
}
export default PublicRoute;
