import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { Input, Tooltip } from "antd";

const CustomAttributesField = (props: any) => {
  const { values, setFieldValue, errors } = props;

  const [editableFields, setEditableFields] = useState<any>({});
  const inputRefs = useRef<any>([]);

  useEffect(() => {
    Object.keys(editableFields).forEach((index: any) => {
      if (editableFields[index] && inputRefs.current[index]) {
        inputRefs.current[index]?.focus();
      }
    });
  }, [editableFields]);

  const handleEditClick = (index: any) => {
    setEditableFields((prev: any) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleReset = (index: any, name: any) => {
    setFieldValue(`customField2[${index}].name`, name);
    setEditableFields((prev: any) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <FieldArray name="customField2">
      {({ push, remove }) => {
        return (
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
            {values?.customField2?.map((item: any, index: number) => (
              <div className="" key={item}>
                <Tooltip
                  className="error-tooltip"
                  placement="topLeft"
                  title={errors.customField2?.[index]?.name && <ErrorMessage name={`customField2[${index}].name`} component="span" className="text-[#cf3b30]" />}
                >
                  <div className="flex items-center relative h-4 mb-2">
                    <Field name={`customField2[${index}].name`}>
                      {({ field, form }: any) => (
                        <Input
                          {...field}
                          type="text"
                          disabled={!editableFields[index]}
                          maxLength={15}
                          ref={(el: any) => (inputRefs.current[index] = el)}
                          className={`${!editableFields[index] ? "no-edit" : "allow-edit"} text-[#1D1D24] font-medium text-[18px] edit-field ${errors.customField2?.[index]?.name ? "error-field" : ""}`}
                          onChange={(e) => form.setFieldValue(field.name, e.target.value)}
                        />
                      )}
                    </Field>

                    <div className="actions absolute left-[250px]">
                      <span role="none" className="material-symbols-outlined text-[18px] ml-3 cursor-pointer" onClick={() => handleEditClick(index)}>
                        {editableFields[index] ? "done" : "edit"}
                      </span>
                      <span className="material-symbols-outlined text-[18px] ml-3 cursor-pointer" onClick={() => handleReset(index, item?.name)} role="none">
                        {editableFields[index] ? "close" : ""}
                      </span>
                    </div>
                  </div>
                </Tooltip>

                <div className="mb-2 relative">
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`customField2[${index}].value`} />
                  <ErrorMessage name={`customField2[${index}].value`} component="div" className="error-message bottom-[-28px]" />
                </div>
              </div>
            ))}
          </div>
        );
      }}
    </FieldArray>
  );
};

export default CustomAttributesField;
