import React from "react";

interface PageTitleProps {
  title: string;
  src: string;
}

const CardTitle: React.FC<PageTitleProps> = ({ title, src }: any) => {
  return (
    <>
      <img src={src} alt="title" className="w-6 h-6 mr-2" />
      <h2 className="text-[24px] font-semibold text-[#000] font-Inter card-title">{title}</h2>
    </>
  );
};

export default CardTitle;
