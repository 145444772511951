import React, { useEffect, useState } from 'react';
import CredentialHeader from './header/CredentialHeader';
import CredentialManagementTable from './table/CredentialManagementTable';
import ConnectionManagementTable from './connection/ConnectionManagementTable';
import Loader from '../../../layouts/component/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { clearConnection, setCredentials, setTableData } from '../../../redux/slice/credential-management/credentialsSlice';
import { retrieveData } from '../../../services/storage/Storage';
import { handleRequestError } from '../../../layouts/toast/ErrorNotificationMessage';
import SuccessMessageModal from '../../access-manager/create/IpTimeSuccessModal';
import './CredentialManagement.scss';
import { getHeaders } from '../../../const/getHeader';
const CredentialManagement = () => {
  const [currentTabStatus, setCurrentTabStatus] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [responseData, setResponseData] = useState<any>();
  const dispatch = useDispatch();
  const token: string = retrieveData('authToken', true);
  const baseUrl = process.env.REACT_APP_BLOCKCHAIN_BASEURL;
  const realmId = retrieveData('realmId', true);
  const connectionState = useSelector((state: any) => state?.credentialsSlice);
  const [creationModal, setCreationModal] = useState(false);
  const [creationSuccessMessage, setCreationSuccessMessage] = useState<any>();
  useEffect(() => {
    if (connectionState?.searchValue?.length === 0) {
      handleGetConnectionList();
    } else {
      const data = filterByFirstOrLastName(connectionState?.searchValue);
      dispatch(setTableData(data));
    }
    if (connectionState?.message?.length !== 0) {
      setCreationModal(true);
      setCreationSuccessMessage(connectionState?.message);
      setTimeout(() => {
        setCreationModal(false);
      }, 2500);
    }
    return () => {
      dispatch(clearConnection());
    };
  }, [connectionState?.searchValue, connectionState?.isUpdate]);

  function filterByFirstOrLastName(searchTerm: string) {
    const data = responseData?.filter((item: any) => {
      const fullName = item?.firstName + ' ' + item?.lastName;
      return fullName?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return data;
  }

  const handleGetConnectionList = async () => {
    setLoader(true);
    const url = `${baseUrl}v1/user/realm/${realmId}/credential`;
    const headers = getHeaders();
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...headers,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const credentialResponse = data?.data;

      dispatch(setCredentials(credentialResponse));
      dispatch(setTableData(credentialResponse?.credentialManagerConnectionDetailsKvDtoList));

      setResponseData(data?.data?.credentialManagerConnectionDetailsKvDtoList);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleRequestError(error);
    }
  };
  const handleCloseCreationModal = () => {
    setCreationModal(false);
  };
  return (
    <div className="akku-container relative">
      {loader && <Loader />}
      <div className="main-container ">
        <div className="dashboard-container h-full overflow-y-auto rounded credentials">
          <div className="header-content flex justify-between">
            <h2>Credential Management</h2>
          </div>
          <div className="w-full">
            <CredentialHeader currentTabStatus={currentTabStatus} />
          </div>
          {!currentTabStatus ? (
            <div className="w-full ">
              <CredentialManagementTable setCurrentTabStatus={setCurrentTabStatus} />
            </div>
          ) : (
            <div className="w-full">{<ConnectionManagementTable setCurrentTabStatus={setCurrentTabStatus} />}</div>
          )}
        </div>
      </div>
      {creationModal && (
        <SuccessMessageModal open={creationModal} handleModalClose={handleCloseCreationModal} responseMessage={creationSuccessMessage} />
      )}
    </div>
  );
};

export default CredentialManagement;
