import React from "react";
import { useAccessManager } from "../context/AccessManagerContext";
import CustomButtonBack from "../../../layouts/component/CustomButtonBack";
import DeviceAuthorizationModal from "../../dashboard/user/user-dashboard/adduser/device-authorization/DeviceAuthorizationModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ListItem {
  id: string;
  name: string;
  description: string;
}
export default function CreateLists() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { closeCreateAccessManager, openWhiteListing, closeWhiteListing, isActiveWhiteListModal } = useAccessManager();
  const list: ListItem[] = [
    {
      id: "1",
      name: `${t("accessManager.selectIp")}`,
      description: `${t("accessManager.specifyIpAccess")}`,
    },
    {
      id: "2",
      name: `${t("accessManager.whitelistDevices")}`,
      description: `${t("accessManager.bindDevices")}`,
    },
    {
      id: "3",
      name: `${t("accessManager.setTimeParameters")}`,
      description: `${t("accessManager.accessTime")}`,
    },
  ];

  const handleOpenList = (el: ListItem) => {
    closeCreateAccessManager();
    if (el.id === "1") {
      navigate("/access-manager/select-ip");
    } else if (el.id === "3") {
      navigate("/access-manager/time-parameters");
    } else {
      openWhiteListing();
    }
  };

  const handelClose = () => {
    closeWhiteListing();
  };
  return (
    <>
      <div className="accessManagerModalContent">
        <div className="create-list pt-12 pb-7">
          {list.map((item) => (
            <div className="list-items flex justify-between items-center" key={item.id} onClick={() => handleOpenList(item)} role="none">
              <div className="list-item--content">
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
              <div className="list-item--arrow">
                <span className="material-icons-outlined  text-[#1C1B1F]">expand_more</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <CustomButtonBack onClick={closeCreateAccessManager} text={t("common.cancel")} />
        </div>
      </div>
      {isActiveWhiteListModal && <DeviceAuthorizationModal handelClose={handelClose} from={"access-manager"} />}
    </>
  );
}
