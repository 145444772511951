import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setRole } from "../../redux/slice/permissions/permissions";

export const useRoleDecoder = () => {
  const dispatch = useDispatch();

  const roleDecoder = (token: any) => {
    try {
      if (token) {
        const decoded: any = jwtDecode(token);
        const attributes: any = {};
        const roleRepresentations = decoded?.roleRepresentations;
        roleRepresentations.forEach((item: any) => {
          const isValid = item.name.startsWith("default-roles");
          if (!isValid) {
            for (const key in item.attributes) {
              if (item?.name?.toLowerCase() === "admin") {
                dispatch(setRole(true));
              }
              attributes[key] = JSON.parse(item.attributes[key][0]);
            }
          }
        });
        return attributes;
      }
    } catch (error) {
      console.error("Failed to decode JWT:", error);
    }
  };

  return roleDecoder;
};
