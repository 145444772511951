import React, { useState } from "react";
import { clearSearchConnection } from "../../../../redux/slice/credential-management/credentialsSlice";
import { useDispatch } from "react-redux";

import DeleteConnection from "../../modal/DeleteConnection";

const ConnectionManagementHeader = (props: any) => {
  const { setCurrentTabStatus, selectedRow, connectionTableData, setSelectedRow, setSelectedRowKeys } = props;
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const handleTabView = () => {
    dispatch(clearSearchConnection());
    setCurrentTabStatus(false);
  };
  const handleDelete = async () => {
    setIsDelete(true);
  };
  const handleClose = () => {
    setIsDelete(false);
  };
  return (
    <div className="w-full flex credentials-header">
      <p className="pr-10 mt-[10px]">Total users count : {connectionTableData?.length}</p>
      <p className="pr-10 mt-[10px]">Current view : Credentials </p>
      {selectedRow.length === 0 ? (
        <p className="view-change cursor-pointer" onClick={() => handleTabView()}>
          Change to connection view
          <span className="material-icons credentials-view text-[16px] mb-[7px]">visibility</span>
        </p>
      ) : (
        <p className="pr-10 mt-[10px]" onClick={() => handleDelete()}>
          <span className="material-icons credentials-view delete  text-[16px] mb-[7px]">delete</span>
        </p>
      )}
      {<DeleteConnection selectedRow={selectedRow} handleClose={handleClose} isDelete={isDelete} setSelectedRow={setSelectedRow} setSelectedRowKeys={setSelectedRowKeys} />}
    </div>
  );
};

export default ConnectionManagementHeader;
