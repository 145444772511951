import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setListOfRestrictionModal } from "../../../redux/slice/Users/UserDashboardUpdateSlice";

export default function AccessManagerBackNavigationArrow({ title }: any) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const navigatedFrom = params.get("from");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const returnBack = () => {
    if (navigatedFrom) {
      dispatch(setListOfRestrictionModal(true));
      navigate("/user");
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="flex items-center tileWithBack">
      <span className="cursor-pointer material-icons-outlined  text-[#1C1B1F]" onClick={returnBack}>
        arrow_upward
      </span>
      <h2>{title}</h2>
    </div>
  );
}
