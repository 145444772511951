import * as Yup from "yup";

// Define the SAML and OpenID Connect schemas separately
export const samlSchema = (t: any) =>
  Yup.object({
    saml: Yup.array().of(
      Yup.object({
        clientId: Yup.string().required(t("appManagement.ssoErrors.clientIdRequired")),
        // masterSamlProcessingUrl: Yup.string().required('Master SAML Processing URL is required').url("Invalid URL"),
        // validRedirectLogoutUrl: Yup.array().of(Yup.string().required('Valid Redirect Logout URL is required').url("Invalid URL")),
        assertionConsumerUrlRedirect: Yup.string().required(t("appManagement.ssoErrors.assertionConsumerUrlRedirectRequired")),
        assertionConsumerUrlPost: Yup.string().required(t("appManagement.ssoErrors.assertionConsumerUrlPostRequired")),
        baseUrl: Yup.string().required(t("appManagement.ssoErrors.baseUrlRequired")).url(t("appManagement.ssoErrors.invalidUrl")),
        validRedirectUrl: Yup.array().of(Yup.string().required(t("appManagement.ssoErrors.validRedirectUrlRequired")).url(t("appManagement.ssoErrors.invalidUrl"))),
        // rootUrl: Yup.string().required('Root URL is required').url("Invalid URL"),
      }),
    ),
  });

export const openIdSchema = (t: any) =>
  Yup.object({
    openId: Yup.array().of(
      Yup.object({
        clientId: Yup.string().required(t("appManagement.ssoErrors.clientIdRequired")),
        // clientAuthentication: Yup.string().required('Client Authentication is required'),
        // serviceAccountsEnabled: Yup.string().required('Service Accounts Enabled is required'),
        // authorization: Yup.string().required('Authorization is required'),
        // directAccessGrants: Yup.string().required('Direct Access Grants is required'),
        // standardFlow: Yup.string().required('Standard Flow is required'),
        // implicitFlow: Yup.string().required('Implicit Flow is required'),
        // deviceAuthorizationGrantEnabled: Yup.string().required('Device Authorization Grant Enabled is required'),
        // oidcCibaGrantEnabled: Yup.string().required('OIDC CIBA Grant Enabled is required'),
        // validRedirectLogoutUrl: Yup.array().of(Yup.string().required('Valid Redirect Logout URL is required').url("Invalid URL")),
        // rootUrl: Yup.string().required('Root URL is required').url("Invalid URL"),
        baseUrl: Yup.string().required(t("appManagement.ssoErrors.baseUrlRequired")).url(t("appManagement.ssoErrors.invalidUrl")),
        validRedirectUrl: Yup.array().of(Yup.string().required(t("appManagement.ssoErrors.validRedirectUrlRequired")).url(t("appManagement.ssoErrors.invalidUrl"))),
        // webOrigins: Yup.array().of(Yup.string().required('Web Origins is required').url("Invalid URL")),
        frontChannelLogoutUrl: Yup.string().required(t("appManagement.ssoErrors.frontChannelLogoutUrlRequired")).url(t("appManagement.ssoErrors.invalidUrl")),
      }),
    ),
  });

export const jiraValidationSchema = (t: any) =>
  Yup.object({
    jira: Yup.array().of(
      Yup.object({
        apiKey: Yup.string().required(t("appManagement.ssoErrors.apiKeyRequired")),
        apiSecret: Yup.string().required(t("appManagement.ssoErrors.apiSecretRequired")),
      }),
    ),
    mappingGroups: Yup.array().of(
      Yup.object({
        source: Yup.string().required(t("appManagement.ssoErrors.sourceRequired")),
        target: Yup.string().required(t("appManagement.ssoErrors.targetRoleRequired")),
      }),
    ),
  });
