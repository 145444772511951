import React from "react";
import { clearSearchConnection } from "../../../../redux/slice/credential-management/credentialsSlice";
import { useDispatch } from "react-redux";

const CredentialManagementTableHeader = (props: any) => {
  const { setCurrentTabStatus, customerTableData } = props;
  const dispatch = useDispatch();

  const handleTabView = () => {
    dispatch(clearSearchConnection());
    setCurrentTabStatus(true);
  };

  return (
    <div className="w-full flex credentials-header">
      <p className="pr-10 mt-[10px]">Total users count : {customerTableData?.length}</p>
      <p className="pr-10 mt-[10px]">Current view : Connection </p>
      {/* enable when delete api available {selectedRow?.length === 0 ? ( */}
      <p className="view-change cursor-pointer" onClick={() => handleTabView()}>
        Change to credentials view
        <span className="material-icons credentials-view text-[16px] mb-[7px]">visibility</span>
      </p>
    </div>
  );
};

export default CredentialManagementTableHeader;
