import React from "react";
import { useTranslation } from "react-i18next";
import { InputField } from "../../../connector/form/connector-authentication/InputField";
import SelectField from "../../../../../../../layouts/form/select/SelectField";
import TextAreaField from "../../../../../../../layouts/form/text-area/TextAreaField";
import DynamicFieldArrayTwo from "../../../../../../../layouts/form/dynamic-field-array-two/DynamicFieldArrayTwo";
import { provisionClassNames } from "../../../ProvisionClassNames";

interface UserProvisionProps {
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  formName: string;
}

const DeProvisionFormFields: React.FC<UserProvisionProps> = ({ values, setFieldValue, formName }) => {
  const { t } = useTranslation();
  const methodType = [
    {
      label: t("common.delete"),
      value: "Delete",
    },
  ];

  return (
    <div className="w-full grid grid-cols-1 gap-x-[5%] 2xl:grid-cols-2" key={formName}>
      <InputField
        label={t("appManagement.provisioning.apiEndpointUrl")}
        name={`apiEndpointURL`}
        type="text"
        placeholder="Enter your name"
        className={provisionClassNames?.inputField}
        labelClassName={provisionClassNames?.fieldLabel}
        required={true}
        containerClassName={provisionClassNames?.inputFiledContainer}
      />
      <SelectField
        disabled={true}
        label={t("appManagement.provisioning.methodType")}
        name={`methodType`}
        options={methodType}
        filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().includes(input.toLowerCase())}
        placeholder={t("common.select")}
        labelClassName={provisionClassNames?.fieldLabel}
        required={values?.methodType === "Post"}
        containerClassName={provisionClassNames?.inputFiledContainer}
      />
      <TextAreaField
        label={t("appManagement.provisioning.requestPayload")}
        name={`requestPayload`}
        data-testid="apiToken-input" // Test ID for your testing framework
        id="apiToken-input"
        className={provisionClassNames?.textAreaField}
        labelClassName={provisionClassNames?.fieldLabel}
        required={false}
        containerClassName={provisionClassNames?.inputFiledContainer}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue(`requestPayload`, e.target.value)}
      />
      <TextAreaField
        label={t("appManagement.provisioning.response")}
        name={`response`}
        data-testid="apiToken-input" // Test ID for your testing framework
        id="apiToken-input"
        className={provisionClassNames?.textAreaField}
        labelClassName={provisionClassNames?.fieldLabel}
        required={false}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setFieldValue(`response`, e.target.value);
        }}
      />
      <div className="grid col-span-2">
        <DynamicFieldArrayTwo
          name="headerParam"
          label={t("appManagement.provisioning.requestParameters")}
          labelClassName={provisionClassNames?.fieldLabel}
          className={provisionClassNames?.inputField}
          containerClassName={`${provisionClassNames?.inputFiledContainer} w-[100%]`}
          values={values?.headerParam}
          keyName="key"
          valueName="value"
        />
      </div>
      <div className="grid col-span-2">
        <DynamicFieldArrayTwo
          name="pathVariable"
          label={t("appManagement.provisioning.pathVariable")}
          labelClassName={provisionClassNames?.fieldLabel}
          className={provisionClassNames?.inputField}
          containerClassName={`${provisionClassNames?.inputFiledContainer} w-[100%]`}
          values={values?.pathVariable}
          keyName="key"
          valueName="value"
        />
      </div>
    </div>
  );
};

export default DeProvisionFormFields;
