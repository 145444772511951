import { Field, ErrorMessage, FieldAttributes } from 'formik';
import { Input } from 'antd';
import React from 'react';

interface TextAreaFieldProps extends FieldAttributes<any> {
  label: string;
  name: string;
  className?: string;
  containerClassName?: string;
  required?: boolean; // Optional prop to display a required label
  labelClassName?: string;
}

// Reusable TextAreaField component
const TextAreaField: React.FC<TextAreaFieldProps> = ({
  label,
  name,
  className = '',
  containerClassName = '',
  required = true,
  labelClassName,
  ...props
}) => {
  return (
    <div className={`relative ${containerClassName}`}>
      <p className={labelClassName}>
        {label} {required && <span className=""> *</span>}
      </p>
      <Field
        as={Input.TextArea} // Use Ant Design's TextArea component
        className={`form-type-box  ${className}`} // Minimum height for TextArea
        name={name}
        {...props}
      />
      <ErrorMessage name={name} component="div" className="error-message" />
    </div>
  );
};

export default TextAreaField;
