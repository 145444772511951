import * as Yup from "yup";

export const validationSchemaForTimeRestriction = (t: any) => {
  const timeParameterSchema = Yup.object().shape({
    timeTo: Yup.string().required(t("accessManager.errors.endTimeRequired")),
    timeFrom: Yup.string().required(t("accessManager.errors.startTimeRequired")),
    timezone: Yup.string().required(t("accessManager.errors.timezoneRequired")),
    id: Yup.string().optional(),
  });

  return Yup.object().shape({
    name: Yup.string().required(t("accessManager.errors.nameRequired")).max(20, t("accessManager.errors.nameMax")),
    description: Yup.string().required(t("accessManager.errors.descriptionRequired")).max(50, t("accessManager.errors.descriptionMax")),
    assignTo: Yup.string().required(t("accessManager.errors.assignToRequired")),
    timeParameter: Yup.array().of(timeParameterSchema),
  });
};
