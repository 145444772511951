import React from "react";
import LDAPTitleHeader from "../common/LDAPTitleHeader";
import AttributeMapping from "../../dashboard/user/user-dashboard/add-user/layouts/modal/map-attributes/AttributeMapping";
import { useTranslation } from "react-i18next";

const EditMappingAttributes = (props: any) => {
  const { responseData, setCurrentTab } = props;
  const { t } = useTranslation();
  return (
    <>
      <LDAPTitleHeader title={t("userManagement.editMappingAttributes")} active />
      <>{responseData && <AttributeMapping editAttribute={true} setCurrentTab={setCurrentTab} />}</>
    </>
  );
};

export default EditMappingAttributes;
