import { useSelector } from 'react-redux';
import { cursorNotAllowedStyle } from '../../../../../../hooks/hooks';
import { hasPermission } from '../../../../../../redux/slice/permissions/permissionUtils';
import { modulePermissions } from '../../../../../../const/RolePermissions';
import { PermissionsState } from '../StatusToggleHelper';
import { useTranslation } from 'react-i18next';
import ImageView from '../../../../../../layouts/component/ImageView';
import { images } from '../../../../../../assets/images/ImagePaths';
import { useEffect, useRef, useState } from 'react';

export default function ProvisioningStatusToggle({ provisioningStatus, handleActiveProvisioning, handleDeActiveProvisioning }: any) {
  const { t } = useTranslation();
  const permissions = useSelector((state: PermissionsState) => state?.permissionsSlice?.attributes);

  const hasEditProvisioningPermission = permissions && hasPermission(permissions, modulePermissions.provisioning, 'update');

  const [showProvisionToggle, setShowProvisionToggle] = useState<boolean>(false);

  const toggleRef = useRef<HTMLDivElement>(null);
  const handleSelectStatus = (el: boolean) => {
    setShowProvisionToggle(false);
    if (el) {
      handleActiveProvisioning();
    } else {
      handleDeActiveProvisioning();
    }
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (toggleRef?.current && !toggleRef?.current?.contains(event.target as Node)) {
      setShowProvisionToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleActiveInActive = () => {
    setShowProvisionToggle((prev) => !prev);
  };
  return (
    <div>
      {/* {showProvisionToggle && (
        <div>
          {provisioningStatus ? (
            <p className="card-inactive-btn absolute">
              <p className="card-status-inactive card-status-btn-label cursor-pointer" onClick={() => handleSelectStatus(false)} role="none">
                <ImageView src={images?.inActiveIcon} alt="in-active-icon" className=" w-[16px] h-[16px] pr-1" />
                {t("common.inactive")}
              </p>
            </p>
          ) : (
            <p className="card-active-btn absolute">
              <p className="card-status-active card-status-btn-label cursor-pointer " role="none" onClick={() => handleSelectStatus(true)}>
                <ImageView src={images?.activeIcon} alt="active-icon" className=" w-[16px] h-[16px] pr-1" />
                {t("common.active")}
              </p>
            </p>
          )}
        </div>
      )} */}

      <div>
        {provisioningStatus ? (
          <div className={`sso-toggle provision-toggle sso-card-status ${showProvisionToggle ? 'sso-toggle-active' : ''}`} ref={toggleRef}>
            <p
              role="none"
              onClick={() => handleActiveInActive()}
              className={`card-status-active sso-status  ${cursorNotAllowedStyle(hasEditProvisioningPermission)}`}
            >
              <ImageView src={images?.activeIcon} alt="active-icon" className=" w-[16px] h-[16px] pr-1" />
              {t('common.active')}
            </p>
            <p className={`sso-card-active-btn sso-card-status ${showProvisionToggle ? 'activated' : ''}`}>
              <p
                className="card-status-inactive card-status-btn-label cursor-pointer "
                role="none"
                onClick={showProvisionToggle ? () => handleSelectStatus(false) : undefined}
              >
                {showProvisionToggle ? (
                  <>
                    <ImageView src={images?.inActiveIcon} alt="in-active-icon" className=" w-[16px] h-[16px] pr-1" />
                    {t('common.inactive')}
                  </>
                ) : null}
              </p>
            </p>
          </div>
        ) : (
          <div className={`sso-toggle provision-toggle sso-card-status ${showProvisionToggle ? 'sso-toggle-active' : ''}`} ref={toggleRef}>
            <p
              role="none"
              onClick={() => handleActiveInActive()}
              className={`card-status-inactive sso-status  ${cursorNotAllowedStyle(hasEditProvisioningPermission)}`}
            >
              <ImageView src={images?.inActiveIcon} alt="in-active-icon" className=" w-[16px] h-[16px] pr-1" />
              {t('common.inactive')}
            </p>
            <p className={`sso-card-active-btn sso-card-status ${showProvisionToggle ? 'activated' : ''}`}>
              <p
                className="card-status-active card-status-btn-label cursor-pointer "
                role="none"
                onClick={showProvisionToggle ? () => handleSelectStatus(true) : undefined}
              >
                {showProvisionToggle ? (
                  <>
                    <ImageView src={images?.activeIcon} alt="active-icon" className=" w-[16px] h-[16px] pr-1" />
                    {t('common.active')}
                  </>
                ) : null}
              </p>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
