import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CheckboxImage from "../../components/CheckboxImage";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../../layouts/component/CustomButton";
import { Service } from "../../usertable/customerTableHelper";
import { useUserContext } from "../../../userContext/UserContext";
import "./FilterModal.scss";
import { useTranslation } from "react-i18next";

const ServiceFilterModal = (props: any) => {
  const { t } = useTranslation();

  const { customerOriginalTableData } = useUserContext();
  const { showHandlePopup, handelClose, filterData, setCustomerTableData } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [checkedVal, setCheckedVal] = useState<any>([]);
  const [showFilterData, setShowFilterData] = useState<any[]>([]);

  useEffect(() => {
    setOpen(showHandlePopup);
    setShowFilterData(filterData);
  }, [props]);
  const handleClosePopup = () => {
    handelClose();
  };

  const handleApplyFilter = () => {
    const data: any[] = [];
    customerOriginalTableData?.length > 0 &&
      customerOriginalTableData.forEach((item: any) => {
        item.service.forEach((dataItem: Service) => {
          if (checkedVal.includes(dataItem.clientName)) {
            const keyString = item.key.toString();
            // Check if an item with the same key already exists in the data array
            const isDuplicate = data.some((existingItem) => existingItem.key.toString() === keyString);

            if (!isDuplicate) {
              data.push(item);
            }
          }
        });
      });

    setCustomerTableData(data);
    handleClose();
  };
  const handleClose = () => {
    handelClose();
  };
  const handleCheckboxChange = (value: string) => {
    setCheckedVal((prevVals: any) => {
      if (prevVals.includes(value)) {
        return prevVals.filter((val: any) => val !== value);
      } else {
        return [...prevVals, value];
      }
    });
  };
  return (
    <Modal className="  filter-user edit rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleClosePopup}>
      <div className="edit-user-modal py-[25px]">
        <h5 className="text-[32px] font-Inter font-semibold pb-[10px] text-[#171717]"> {t("common.filter")}</h5>
      </div>
      <div className="modal-body px-10 pt-8 pb-2 ">
        {/* <SearchInput placeholder="Search" onChange={searchUser} /> */}
        <div className="flex pt-8 w-full flex-wrap min-h-[370px] max-h-[0px] overflow-y-auto">
          {showFilterData && showFilterData.length > 0 ? (
            showFilterData?.map((data: Service) => (
              <CheckboxImage clientName={data?.clientName} key={data?.clientName} image={data?.logoPath} setCheckedVal={() => handleCheckboxChange(data?.clientName)} />
            ))
          ) : (
            <p className="text-center"> {t("userManagement.noServicesAvailable")}</p>
          )}
        </div>
      </div>
      <div className="modal-footer p-8">
        <div className="w-full flex justify-end ">
          <CustomButtonBack onClick={handleClose} text={t("common.cancel")} active={false} />
          <CustomButtonNext onClick={handleApplyFilter} text={t("common.apply")} active={checkedVal.length === 0} />
        </div>
      </div>
    </Modal>
  );
};

export default ServiceFilterModal;
