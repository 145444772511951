import { useState, useCallback } from "react";

export default function usePermissionModal() {
  const [permissionModal, setPermissionModal] = useState<boolean>(false);

  const handleOpenPermissionModal = useCallback(() => {
    setPermissionModal(true);
  }, []);

  const handleClosePermissionModal = useCallback(() => {
    setPermissionModal(false);
  }, []);

  return { permissionModal, handleOpenPermissionModal, handleClosePermissionModal };
}
