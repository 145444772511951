export const prerequisitesTable = {
  pageSizeOptions: ["5", "10","25","50","100"],
};
export const customerTable = {
  initialFilterData: {
    customerType: "CLIENT",
    sortBy: "latest",
    orderDirection: "ASC",
    pageNumber: 1,
    pageSize: 10,
  },
};
