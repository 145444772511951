import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import ip from "./../../../../.../../../../assets/images/icons/ip.png";
import timer from "./../../../../.../../../../assets/images/icons/timer.png";
import devices from "./../../../../.../../../../assets/images/icons/devices.png";
export interface UserData {
  userId: string;
  name: string;
  email: string;
  status: string;
  clientDetailsList: any[];
  groupNames: string;
  deviceRestrictionStatus: string;
}
export const createTableObject = (response: any[] | null | undefined) => {
  if (!response || response.length === 0) {
    return [];
  }

  return response.map((item: any) => {
    const clientDetailsList = item?.clientDetailDtos?.map((clientItem: any) => ({
      userId: item?.userId || null,
      email: item?.email || null,
      ...clientItem,
    }));

    const isAdmin = item?.userData?.isAdmin;

    const userRestrictionsList = !isAdmin
      ? [
          {
            restrictionStatus: item?.userData?.ipRestrictionStatus === "active",
            iconName: "Ip Restriction",
            iconPath: ip,
          },
          {
            restrictionStatus: item?.userData?.deviceRestrictionStatus === "active",
            iconName: "Device Restriction",
            iconPath: devices,
          },
          {
            restrictionStatus: item?.userData?.timeRestrictionStatus === "active",
            iconName: "Time Restriction",
            iconPath: timer,
          },
        ]
      : [
          {
            restrictionStatus: item?.userData?.isOrganizationLevelIpRestrictionStatus === "inactive" ? item?.userData?.ipRestrictionStatus === "active" : false,
            iconName: "Ip Restriction",
            iconPath: ip,
          },
          {
            restrictionStatus: item?.userData?.deviceRestrictionStatus === "active",
            iconName: "Device Restriction",
            iconPath: devices,
          },
          {
            restrictionStatus: item?.userData?.isOrganizationLevelTimeRestrictionStatus === "inactive" ? item?.userData?.timeRestrictionStatus === "active" : false,
            iconName: "Time Restriction",
            iconPath: timer,
          },
        ];

    return {
      key: item?.userData?.userId,
      name: item?.userData?.name,
      email: item?.userData?.email,
      status: item?.userData?.status,
      service: clientDetailsList,
      userId: item?.userData?.userId,
      groups: item?.userData?.groupNames,
      restrictions: item?.userData?.deviceRestrictionStatus,
      isAdmin: item?.userData?.isAdmin,
      userRestrictionsList,
    };
  });
};

export const getRolesList = async (realmId: string, roleList: any) => {
  const pathParams = {
    realmId: realmId,
  };
  try {
    const response: any = await roleList("", "", pathParams);
    const status = response.status;
    if (status === 200) {
      const reformedRoleList = response?.data?.data?.map((item: any) => ({
        label: item?.name,
        key: item?.id,
        value: item?.id,
      }));
      return reformedRoleList;
    }
  } catch (err: any) {
    handleRequestError(err);
  }
};
