import * as Yup from "yup";

export const validationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t("roleManagement.errors.roleNameRequired")) // Using the translation function
      .max(50, t("roleManagement.errors.roleNameMax")), // Using the translation function
    roleDescription: Yup.string()
      .required(t("roleManagement.errors.roleDescriptionRequired")) // Using the translation function
      .max(50, t("roleManagement.errors.roleDescriptionMax")), // Using the translation function
  });

export const permissionInitialValues: any = {
  name: "",
  roleDescription: "",
  attributeList: {},
};
