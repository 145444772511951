import React from "react";
import { staticThemeUrl, themeImages } from "../../../User/theme";
import { useSelector } from "react-redux";

export default function BrandLogo() {

  const theme = useSelector((state: any) => state?.theme?.themeDetails);

  const brandLogoUrl = theme?.themeImagesUrl
    ? `${theme.themeImagesUrl}${themeImages.brandLogo}`
    : `${staticThemeUrl.imagesUrl}${themeImages.brandLogo}`;
  return (
    <div className="brandLogo ">
      <img src={brandLogoUrl} alt="brandLogo" />
    </div>
  );
}
