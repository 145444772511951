import React from "react";
import { useTranslation } from "react-i18next";
import AdaptiveMFAContainer from "./AdaptiveMFAContainer";
import "./AdaptiveMFA.scss";
const AdaptiveMFA = () => {
  const { t } = useTranslation();
  return (
    <div className="akku-container">
      <div className="main-container ">
        <div className="dashboard-container adaptive-mfa">
          <div className="header-content flex justify-between">
            <h2 className="font-bold text-[#171717] font-Inter">
              {t("common.configure")}: {t("adaptiveMfa.adaptiveMfa")}
            </h2>
          </div>
          <AdaptiveMFAContainer />
        </div>
      </div>
    </div>
  );
};

export default AdaptiveMFA;
