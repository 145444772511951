import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  LDAP1: any;
  LDAP2: any;
  LDAP3: any;
  LDAP4: any;
  LDAPMapper: any;
  LDAPInitialConfigurations: any;
  LDAPUpdateInitialValue: any;
}

const initialState: AuthState = {
  LDAP1: { ldapName: "", connectionURL: "", bindType: "simple", bindDN: "", bindCredentials: "" },
  LDAP2: {
    editMode: "WRITABLE",
    userDN: "",
    userLDAPAttribute: "",
    rdnAttribute: "",
  },
  LDAP3: {
    uuidLdap: "",
    userObject: "",
    syncInterval: undefined,
  },
  LDAP4: {
    email: "",
    phoneNumber: "",
  },
  LDAPMapper: {},
  LDAPInitialConfigurations: {},
  LDAPUpdateInitialValue: {},
};

const LDAPSliceSlice: any = createSlice({
  name: "LDAPFormDetails",
  initialState,
  reducers: {
    setLDAPValue1: (state, action: PayloadAction<any>) => {
      state.LDAP1 = action.payload;
    },
    setLDAPValue2: (state, action: PayloadAction<any>) => {
      state.LDAP2 = action.payload;
    },
    setLDAPValue3: (state, action: PayloadAction<any>) => {
      state.LDAP3 = action.payload;
    },
    setLDAPValue4: (state, action: PayloadAction<any>) => {
      state.LDAP4 = action.payload;
    },
    setLDAPMapper: (state, action: PayloadAction<any>) => {
      state.LDAPMapper = action.payload;
    },
    clearLDAPValues: (state) => {
      state.LDAP1 = initialState.LDAP1;
      state.LDAP2 = initialState.LDAP2;
      state.LDAP3 = initialState.LDAP3;
      state.LDAP4 = initialState.LDAP4;
      state.LDAPMapper = initialState.LDAPMapper;
    },
    setLDAPInitialValues: (state, action: PayloadAction<any>) => {
      state.LDAPInitialConfigurations = action.payload;
    },
    clearLDAPInitialValues: (state) => {
      state.LDAPInitialConfigurations = {};
    },
    setLDAPUpdateInitialValues: (state, action: PayloadAction<any>) => {
      state.LDAPUpdateInitialValue = action.payload;
    },
    clearLDAPUpdateInitialValues: (state) => {
      state.LDAPUpdateInitialValue = {};
    },
  },
});

export const {
  setLDAPValue1,
  setLDAPValue2,
  setLDAPValue3,
  setLDAPValue4,
  setLDAPMapper,
  clearLDAPValues,
  setLDAPInitialValues,
  clearLDAPInitialValues,
  setLDAPUpdateInitialValues,
  clearLDAPUpdateInitialValues,
} = LDAPSliceSlice.actions;
export default LDAPSliceSlice.reducer;
