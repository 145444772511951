import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  userManagementLoader: boolean;
}

const initialState: AuthState = {
  userManagementLoader: false,
};

const userManagementSlice = createSlice({
  name: "userManagementSlice",
  initialState,
  reducers: {
    setUserManagementLoader: (state, action: PayloadAction<boolean>) => {
      state.userManagementLoader = action.payload;
    },
  },
});

export const { setUserManagementLoader } = userManagementSlice.actions;
export default userManagementSlice.reducer;
