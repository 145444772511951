export const displayAppName = (item:any)=> {
    if(item?.akkuMasterClientDto) {
        return item.akkuMasterClientDto.name 
    }else 
    {
        return item?.akkuProvisioningConnectorDto?.name || ""
    }
   
}
export const displayDescription = (item:any)=> {
    if(item?.akkuMasterClientDto) {
        return item.akkuMasterClientDto.description 
    }else 
    {
        return item?.akkuProvisioningConnectorDto?.description || ""
    }
   
}

export const displayAppLogo = (item:any)=> {
    if(item?.akkuMasterClientDto) {
        return item.akkuMasterClientDto.logoUrl 
    }else 
    {
        return item?.akkuProvisioningConnectorDto?.logoUrl || ""
    }
   
}