import * as Yup from "yup";
import { TFunction } from "i18next";
export const LDAPvalidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    connectionUrl: Yup.string().required(t("userManagement.errors.ldap.connectionUrl")),
    ldapName: Yup.string().required(t("userManagement.errors.ldap.ldapName")),
    bindDn: Yup.string().required(t("userManagement.errors.ldap.bindDn")),
    bindCredentials: Yup.string().required(t("userManagement.errors.ldap.bindCredentials")),
  });
};

export const LDAPAuthenticationSchema = (t: TFunction) =>
  Yup.object().shape({
    editMode: Yup.string().required(t("userManagement.errors.ldap.editModeRequired")),
    usersDn: Yup.string().required(t("userManagement.errors.ldap.usersDnRequired")),
    usernameLDAPAttribute: Yup.string().required(t("userManagement.errors.ldap.usernameLDAPAttributeRequired")),
    rdnLdapAttribute: Yup.string().required(t("userManagement.errors.ldap.rdnLdapAttributeRequired")),
    uuidLdapAttribute: Yup.string().required(t("userManagement.errors.ldap.uuidLdapAttributeRequired")),
    userObjectClasses: Yup.string().required(t("userManagement.errors.ldap.userObjectClassesRequired")),
    syncUpdateInterval: Yup.string().required(t("userManagement.errors.ldap.syncUpdateIntervalRequired")),
    certificate: Yup.string().required(t("userManagement.errors.ldap.certificateRequired")),
  });
