import React, { useEffect, useState } from "react";
import { Collapse, CollapseProps, Input, Tooltip } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import { staticMessage } from "../../../../../constant/StaticContent";
import { useTranslation } from "react-i18next";

const AdvanceSetting = (props: any) => {
  const { t } = useTranslation();
  const { values, isError } = props;
  const [activeKey, setActiveKey] = useState<string>("");
  useEffect(() => {
    if (isError) {
      setActiveKey("1");
    }
  }, [isError]);
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };

  const item: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[18px] font-normal">3 . {t("appManagement.advancedSettings")}</p>,
      children: (
        <FieldArray name="saml">
          {({ insert, remove, push }) => (
            <div className="flex w-full justify-between flex-wrap">
              {values?.saml?.length > 0 &&
                values.saml.map((samlConfig: any, index: any) => (
                  <div className="w-full flex flex-col" key={samlConfig}>
                    <div className="mb-0 w-full pb-6 pt-3">
                      <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">
                        {t("appManagement.assertionConsumerServicePostBindingUrl")}*{" "}
                        <Tooltip title={t("appManagement.assertionConsumerServicePostUrlDescription")}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>

                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.assertionConsumerUrlPost`} data-testid="consumerPostId" />
                      <ErrorMessage name={`saml.${index}.assertionConsumerUrlPost`} component="div" className="error-message custom-error" />
                    </div>
                    <div className="mb-10 w-full">
                      <p className="text-[#1D1D24] text-[18px] font-Inter flex items-center font-medium">
                        {t("appManagement.assertionConsumerServiceRedirectBindingUrl")}*
                        <Tooltip title={t("appManagement.assertionConsumerServiceRedirectUrlDescription")}>
                          <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
                        </Tooltip>
                      </p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`saml.${index}.assertionConsumerUrlRedirect`} data-testid="consumerRedirectId" />
                      <ErrorMessage name={`saml.${index}.assertionConsumerUrlRedirect`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </FieldArray>
      ),
    },
  ];

  return (
    <>
      <div className=" w-full pt-5">
        <Collapse accordion activeKey={activeKey} onChange={handleCollapseChange} items={item} className={`w-[80%]  custom-header`} expandIconPosition={"end"} />
      </div>
    </>
  );
};

export default AdvanceSetting;
