import MyAppsHeader from "./layout/my-apps/MyAppsHeader";
import MyApps from "./layout/my-apps/MyApps";
import AvailableApps from "./layout/my-apps/AvailableApps";
import "./AppStore.scss";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { useEffect, useState } from "react";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../layouts/component/Loader";
const AppStore = () => {
  const publishedAppsList = useGetApiRequests("publishedAppsList", "GET");

  const [publishedAppList, setPublishedList] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);

  // useEffect(() => {
  //   getPublishedApps();
  // }, []);

  const getPublishedApps = async () => {
    try {
      const response: any = await publishedAppsList();
      const status = response.status;
      if (status === 200) {
        const data = response.data?.data || [];
        // const mergeList = [...publishedAppList, ...data]
        setPublishedList(data);
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (err: any) {
      setLoader(false);
      const listNotFound = err?.response?.data?.message?.includes("provider list not found");
      if (!listNotFound) {
        handleRequestError(err);
      }
    }
  };

  return (
    <div className="akku-container relative z-[1]">
      {loader && <Loader />}
      <div className="main-container !p-0">
        <div className="dashboard-container h-full overflow-y-auto">
          <MyAppsHeader />
          <div className="h-full ">
            <MyApps setLoader={setLoader} />
            <AvailableApps />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStore;
