import React, { useCallback, useEffect, useState } from "react";
import MyAppCard from "../card/MyAppCard";
import MyAppsEmpty from "./MyAppsEmpty";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../layouts/component/Loader";

import { retrieveData } from "../../../../services/storage/Storage";
import { debounce } from "lodash";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
import { setMyAppsCount } from "../../../../redux/slice/app-store/AppDetailsSlice";
import { clearAllEditData } from "../../../../redux/slice/app-store/appConfigureSlice";
import { hasPermission } from "../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../const/RolePermissions";
const MyApps = ({ setLoader }: any) => {
  const [myAppDetails, setMyAppDetails] = useState<any>([]);
  const [provisioningLists, setProvisioningLists] = useState<any>([]);
  // const [loader, setLoader] = useState<any>(true);
  const [myAppsList, setMyAppsList] = useState<any>([]);
  const isTenantAdmin = useSelector((state: any) => state?.ProfileDetailsSlice?.isTenantAdmin);

  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);
  const searchValue = useSelector((state: any) => state?.appSearchSlice?.searchApps?.[0]);
  const reloadAppStore = useSelector((state: any) => state?.appsRenderSlice.reloadAppStore);

  //permissions
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const provisioningViewPermission = permissions && hasPermission(permissions, modulePermissions.provisioning, "view");

  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const myAppList = useGetApiRequests("myAppsList", "GET");

  const provisioningAppList = useGetApiRequests("provisioningAppsList", "GET");

  const searchMyApps = (searchApp: any) => {
    if (!searchApp?.name || searchApp?.name?.trim() === "") {
      // If search input is empty, reset to the original list
      setMyAppDetails(myAppsList);
      return;
    }

    const searchTerm = searchApp?.name.toLowerCase();
    const filteredApps = myAppsList?.filter((app: any) => {
      const appName = app?.akkuMasterClientDto?.name?.toLowerCase();
      return appName?.includes(searchTerm);
    });
    setMyAppDetails(filteredApps);
  };

  // Debounced API call function
  const debouncedSearchMyApps = useCallback(
    debounce((searchPayload) => {
      searchMyApps(searchPayload);
    }, 100),
    [searchValue?.name],
  );
  useEffect(() => {
    const searchApp = searchValue;
    if (searchApp?.name && searchApp?.name !== "") {
      debouncedSearchMyApps(searchApp);
    } else {
      getMyAppList();
    }
    dispatch(clearAllEditData());
  }, [reInitiateApps, searchValue, reloadAppStore]);

  const sortMyAppsDetails = (items: any) => {
    const sortedArrays = items?.sort((a: any, b: any) => {
      const firstElements = a?.akkuMasterClientDto?.name?.toUpperCase();
      const secondElements = b?.akkuMasterClientDto?.name?.toUpperCase();
      if (firstElements < secondElements) {
        return -1;
      }
      if (firstElements > secondElements) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArrays;
  };

  const getProvisioningAppList = async (searchValue?: any) => {
    if (!provisioningViewPermission) {
      return [];
    }
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    let queryParams: any;
    if (searchValue && queryParams !== "") {
      queryParams = {
        searchSpName: searchValue?.name,
      };
    } else {
      queryParams = {};
    }

    try {
      const response: any = await provisioningAppList("", queryParams, params);
      const status = response.status;

      if (status === 200) {
        const sortAppDetails: any = sortMyAppsDetails(response?.data?.data);
        const sortedProvisionList = sortAppDetails ?? [];
        // dispatch(setProvisionAppsLength(sortAppDetails?.length));
        setProvisioningLists(sortedProvisionList);
        return sortedProvisionList;
      }
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (err: any) {
      setLoader(false);
      const listNotFound = err?.response?.data?.message?.includes("provider list not found");
      if (!listNotFound) {
        handleRequestError(err);
      }
    }
  };

  const getMyAppList = async () => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    try {
      const response: any = await myAppList("", params);
      const status = response.status;
      if (status === 200) {
        const sortedResponseData: any = sortMyAppsDetails(response?.data?.data);

        const myAppDetails: any = sortedResponseData ?? [];
        const provisionList = await getProvisioningAppList();

        let mismatchedProvisionList: any = [];

        provisionList?.filter((connector: any) => connector?.publishStatus === true);
        let mismatchedObjects: any;
        if (myAppDetails && myAppDetails.length > 0) {
          mismatchedObjects = findProvisioningDuplicate(myAppDetails, provisionList);
        } else {
          mismatchedObjects = provisionList?.map((provision: any) => {
            return {
              akkuMasterClientDto: {
                akkuMasterClientId: provision?.akkuMasterClient?.akkuMasterClientId,
                name: provision?.akkuMasterClient?.name,
                description: provision?.akkuMasterClient?.description,
                logoUrl: provision?.akkuMasterClient?.logoUrl,
                configDocument: provision?.akkuMasterClient?.configDocument,
                akkuCategory: {
                  akkuCategoryId: provision?.akkuMasterClient?.akkuCategory?.akkuCategoryId,
                  name: provision?.akkuMasterClient?.akkuCategory?.name,
                },
              },
              akkuClientDto: null, // Assuming this field is always null because it is returning the provision object
              akkuProvisioningConnectorDto: {
                akkuProvisioningConnectorId: provision?.akkuProvisioningConnectorId,
                name: provision?.name ?? undefined,
                description: provision?.description ?? undefined,
                type: provision?.type ?? undefined,
                tokenType: provision?.tokenType ?? undefined,
                authenticationType: provision?.authenticationType ?? undefined,
                // spDomain: provision?.spDomain,
                isDefaultSourceConnector: provision?.isDefaultSourceConnector,
                realmId: provision?.realmId,
                createdOn: provision?.createdOn,
                logoUrl: provision?.logoUrl,
                baseUrl: provision?.baseUrl,
                // isActive: provision?.isActive,
                isActive: provision?.isActive,
                isDuplicate: true,
                isGroupOuProvisioningConfigured: provision?.isGroupOuProvisioningConfigured,
                isRoleProvisioningConfigured: provision?.isRoleProvisioningConfigured,
                isUserProvisioningConfigured: provision?.isUserProvisioningConfigured,
                publishStatus: provision?.publishStatus,
                akkuProvisioningConnectorAuthConfigId: provision?.akkuProvisioningConnectorAuthConfigId,
                isTenantAdmin: isTenantAdmin,
              },
            };
          });
        }
        if (mismatchedObjects.length !== 0) {
          mismatchedProvisionList = myAppDetails?.filter((provision: any) => {
            // Find a matching appDetail in myAppDetails where akkuMasterClientId matches
            const matchingAppDetail = mismatchedObjects?.find((appDetail: any) => {
              if (provision?.akkuMasterClientDto?.akkuMasterClientId === appDetail?.akkuMasterClientDto?.akkuMasterClientId) {
                provision.akkuProvisioningConnectorDto = appDetail?.akkuProvisioningConnectorDto;
              }
            });

            if (matchingAppDetail) {
              // Check if the names are different (case insensitive)
              const provisionName = provision?.name?.toLowerCase();
              const appDetailName = matchingAppDetail?.akkuMasterClientDto?.name?.toLowerCase();
              // Return true if the names are different, false if they match (i.e., take only mismatched ones)
              return provisionName !== appDetailName;
            }

            // If no matching appDetail is found, include the provision object (no match on akkuMasterClientId)
            return true;
          });
        } else {
          mismatchedProvisionList = myAppDetails?.filter((appDetail: any) => {
            // Find a matching appDetail in myAppDetails where akkuMasterClientId matches
            provisionList?.find((provision: any) => {
              if (appDetail?.akkuMasterClientDto?.akkuMasterClientId === provision?.akkuMasterClient?.akkuMasterClientId) {
                appDetail.akkuMasterClientDto = {
                  akkuMasterClientId: provision?.akkuMasterClient?.akkuMasterClientId,
                  name: provision?.akkuMasterClient?.name,
                  description: provision?.akkuMasterClient?.description,
                  logoUrl: provision?.akkuMasterClient?.logoUrl,
                  configDocument: provision?.akkuMasterClient?.configDocument,
                  akkuCategory: {
                    akkuCategoryId: provision?.akkuMasterClient?.akkuCategory?.akkuCategoryId,
                    name: provision?.akkuMasterClient?.akkuCategory?.name,
                  },
                };
                appDetail.akkuProvisioningConnectorDto = {
                  akkuProvisioningConnectorId: provision?.akkuProvisioningConnectorId,
                  name: provision?.name ?? undefined,
                  description: provision?.description ?? undefined,
                  type: provision?.type ?? undefined,
                  tokenType: provision?.tokenType ?? undefined,
                  authenticationType: provision?.authenticationType ?? undefined,
                  // spDomain: provision?.spDomain,
                  isDefaultSourceConnector: provision?.isDefaultSourceConnector,
                  realmId: provision?.realmId,
                  createdOn: provision?.createdOn,
                  logoUrl: provision?.logoUrl,
                  baseUrl: provision?.baseUrl,
                  // isActive: provision?.isActive,
                  isActive: provision?.isActive,
                  isDuplicate: true,
                  isGroupOuProvisioningConfigured: provision?.isGroupOuProvisioningConfigured,
                  isRoleProvisioningConfigured: provision?.isRoleProvisioningConfigured,
                  isUserProvisioningConfigured: provision?.isUserProvisioningConfigured,
                  publishStatus: provision?.publishStatus,
                  akkuProvisioningConnectorAuthConfigId: provision?.akkuProvisioningConnectorAuthConfigId,
                  isTenantAdmin: isTenantAdmin,
                };
              }
            });
          });
        }
        if (mismatchedObjects && mismatchedObjects?.length) {
          const spreadMismatchedProvisionList = mismatchedProvisionList || [];
          const mergedData = [...spreadMismatchedProvisionList, ...mismatchedObjects];
          const uniqueData: any = [];

          const seenIds = new Set();

          mergedData.forEach((item) => {
            const { akkuMasterClientDto, akkuProvisioningConnectorDto, akkuClientDto } = item;
            // const mergedData = [...mismatchedProvisionList];
            const akkuMasterClientId = akkuMasterClientDto?.akkuMasterClientId;

            if (!seenIds.has(akkuMasterClientId)) {
              // Keep the first occurrence
              uniqueData.push(item);
              seenIds.add(akkuMasterClientId);
            } else {
              // Find the index of the existing item with the same akkuMasterClientId
              const index = uniqueData.findIndex((i: any) => i.akkuMasterClientDto?.akkuMasterClientId === akkuMasterClientId);
              const existingItem: any = uniqueData[index];
              // Replace logic based on different conditions
              if (akkuProvisioningConnectorDto !== null && existingItem?.akkuProvisioningConnectorDto === null) {
                // Replace if the current item has akkuProvisioningConnectorDto not null
                uniqueData[index] = item;
              } else if (existingItem?.akkuClientDto === null && akkuClientDto !== null) {
                // Replace if the current item has akkuClientDto not null and the existing one has null
                uniqueData[index] = item;
              }
            }
          });

          // console.log("result:", uniqueData);

          dispatch(setMyAppsCount(uniqueData?.length));
          setMyAppDetails(uniqueData || []);
          setMyAppsList(uniqueData || []);
        } else {
          dispatch(setMyAppsCount(myAppDetails?.length));
          setMyAppDetails(myAppDetails || []);
          setMyAppsList(response?.data?.data);
        }

        // dispatch(setNonProvisionAppsLength(myAppDetails?.length));
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
    }
  };
  const findProvisioningDuplicate = (myAppDetails: any, provisionList: any) => {
    const mismatchedProvisionList = provisionList?.filter((provision: any) => {
      // Find a matching appDetail in myAppDetails where akkuMasterClientId matches
      const matchingAppDetail = myAppDetails?.find((appDetail: any) => {
        return appDetail?.akkuMasterClientDto?.akkuMasterClientId === provision?.akkuMasterClient?.akkuMasterClientId;
      });
      if (matchingAppDetail) {
        // Check if the names are different (case insensitive)
        const provisionName = provision?.name?.toLowerCase();
        const appDetailName = matchingAppDetail?.akkuMasterClientDto?.name?.toLowerCase();
        // Return true if the names are different, false if they match (i.e., take only mismatched ones)
        return true;
      }

      // If no matching appDetail is found, include the provision object (no match on akkuMasterClientId)
      return true;
    });

    const result = mismatchedProvisionList?.map((provision: any) => {
      const matchingAppDetail = myAppDetails?.find((appDetail: any) => {
        return appDetail?.akkuMasterClientDto?.akkuMasterClientId === provision?.akkuMasterClient?.akkuMasterClientId;
      });
      return {
        akkuMasterClientDto: {
          akkuMasterClientId: provision?.akkuMasterClient?.akkuMasterClientId,
          name: provision?.akkuMasterClient?.name,
          description: provision?.akkuMasterClient?.description,
          logoUrl: provision?.akkuMasterClient?.logoUrl,
          configDocument: provision?.akkuMasterClient?.configDocument,
          akkuCategory: {
            akkuCategoryId: provision?.akkuMasterClient?.akkuCategory?.akkuCategoryId,
            name: provision?.akkuMasterClient?.akkuCategory?.name,
          },
        },
        akkuClientDto: null, // Assuming this field is always null because it is returning the provision object
        akkuProvisioningConnectorDto: {
          akkuProvisioningConnectorId: provision?.akkuProvisioningConnectorId,
          name: provision?.name ?? undefined,
          description: provision?.description ?? undefined,
          type: provision?.type ?? undefined,
          tokenType: provision?.tokenType ?? undefined,
          authenticationType: provision?.authenticationType ?? undefined,
          // spDomain: provision?.spDomain,
          isDefaultSourceConnector: provision?.isDefaultSourceConnector,
          realmId: provision?.realmId,
          createdOn: provision?.createdOn,
          logoUrl: provision?.logoUrl,
          baseUrl: provision?.baseUrl,
          // isActive: provision?.isActive,
          isActive: matchingAppDetail?.akkuProvisioningConnectorAuthConfigDto?.isActive,
          isDuplicate: true,
          isGroupOuProvisioningConfigured: provision?.isGroupOuProvisioningConfigured,
          isRoleProvisioningConfigured: provision?.isRoleProvisioningConfigured,
          isUserProvisioningConfigured: provision?.isUserProvisioningConfigured,
          publishStatus: provision?.publishStatus,
          akkuProvisioningConnectorAuthConfigId: matchingAppDetail?.akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorAuthConfigId,
          isTenantAdmin: isTenantAdmin,
        },
      };
    });

    // Return the newly created array of objects
    return result;
  };
  return (
    <>
      <div data-testid="my-apps-empty" className="pt-8 flex flex-wrap">
        {myAppDetails?.length !== 0 ? (
          <MyAppCard
            myAppDetails={myAppDetails}
            getProvisioningAppList={getProvisioningAppList}
            available={false}
            getMyAppList={getMyAppList}
            provisioningLists={provisioningLists}
            setLoader={setLoader}
          />
        ) : (
          <MyAppsEmpty />
        )}
        {/* {isTenantAdmin && publishedAppList?.length > 0 && <PublishedApps publishedAppList={publishedAppList} />} */}
      </div>
    </>
  );
};

export default MyApps;
