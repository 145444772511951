import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { storeData } from "../../services/storage/Storage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { fromWhere, setUserDetails } from "../../redux/slice/Users/UserDetailSlice";
import { setFromUserName } from "../../redux/slice/Users/MobileNumberSlice";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import UserLoginTheme from "../../pages/user-authentication/LoginTheme/LoginTheme";
import { extractSubdomain } from "../../hooks/commonHooks";

interface FormData {
  userName: string;
}

const ForgotPasswordFromUrl: React.FC = () => {
  const localhostRealmName: string | undefined = process.env.REACT_APP_LOCALHOST_REALM_NAME;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const getUserNameDetailsApi = useGetApiRequests("userDetails", "GET");
  const getLocation = window.location;
  const [realmName, setRealmName] = useState<string | undefined>("");
  const urlParams = new URLSearchParams(window.location.search);
  const userNameFromUrl = urlParams.get("username");
  const validationSchema = Yup.object({
    userName: Yup.string().required("Username is required"),
  });

  useEffect(() => {
    settingRealName();
    storeData("userName", "", true);
  }, []);
  const settingRealName = () => {
    if (getLocation.hostname === "localhost" || getLocation.hostname === "development" || getLocation.hostname === "sso-demo2") {
      storeData("realmName", localhostRealmName, true);
      setRealmName(localhostRealmName);
    } else {
      const realmNameFromFunction = extractSubdomain(getLocation.href);
      storeData("realmName", realmNameFromFunction, true);
      setRealmName(realmNameFromFunction);
    }
  };

  const formik = useFormik<FormData>({
    initialValues: {
      userName: userNameFromUrl ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      storeData("userName", values.userName, true);
      const queryParams = {
        realmName: realmName,
        userName: values.userName,
        isSetPassword: location.pathname === "/setpassword",
      };
      getUserNameDetailsApi("", queryParams)
        .then((response: any) => {
          const responseData = response?.data?.data;
          if (response.data.status === "OK") {
            storeData("fromSetPassword", true, true);
            dispatch(fromWhere("set-password"));
            dispatch(setUserDetails(responseData));
            dispatch(setFromUserName(false));
            storeData("userName", values.userName, true);
            storeData("userDetails", response.data, true);
            navigate("/multi-factor-authentication");
          }
        })
        .catch((err: any) => {
          triggerNotification("error", "", err?.response?.data?.message, "topRight");
          resetForm();
        });
    },
  });

  return (
    <div className="w-full login-card">
      <div className="login-title forget-tittle">
        <div className="w-full flex justify-center pb-2">{/* <img src={authIcon} alt="authIcon" /> */}</div>
        <h2>{location.pathname === "/setpassword" ? "Set Password" : "Reset Password"}</h2>
        {location.pathname === "/setpassword" && <p className="text-center mb-4 forgetUsername">To set your password, please provide your username.</p>}
      </div>

      <div className="login-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="formGroup login-userName">
            <label className="label">Username</label>
            <Input placeholder="Enter Username" name="userName" value={formik.values.userName} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            {formik.touched.userName && formik.errors.userName ? <div className="error absolute mt-1">{formik.errors.userName}</div> : null}
          </div>

          <div className="btn">
            <Button type="primary" className="login-btn" htmlType="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLoginTheme(ForgotPasswordFromUrl);
