import React from "react";
import ImageView from "../../../../../layouts/component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import { useTranslation } from "react-i18next";

interface MFAPushNotificationPopUpInterface {
  setIsMethodSelected: (isMethodSelected: boolean) => void;
  selectedVerification: string;
}
export default function MFAPushNotificationPopUp(props: Readonly<MFAPushNotificationPopUpInterface>) {
  const { t } = useTranslation();

  const { setIsMethodSelected, selectedVerification } = props;
  return (
    <div>
      <div className="modal-verify">
        <div className="modal-header px-4 pt-5 flex justify-between">
          <div className="inline-block cursor-pointer  mt-4" onClick={() => setIsMethodSelected(false)} role="none">
            <ImageView src={images?.arrowBack} className="w-2 h-2 img-upload" />
          </div>
          <div className="modal-title  border-0">
            <p className="mb-2 text-[20px]  font-medium text-center font-Inter text-[#171717]"> {t("userManagement.mfa.multiFactorAuthentication")}</p>
            <p className="text-[28px] font-semibold text-center font-Inter text-[#171717]">{t("userManagement.mfa.mobilePushNotification")}</p>
          </div>
          <div></div>
        </div>

        <div className="modal-body">
          <div className="flex items-center justify-center flex-col gap-y-8">
            <div className={` w-[100%] flex flex-col my-10  justify-center items-center`} role="none">
              {selectedVerification === "mobilePush" && <ImageView src={images?.mobileNotifications} className="w-10 h-10 img-upload" />}
            </div>
            <p className="text-[#282828] font-Inter font-semibold text-[24px]">{t("userManagement.mfa.checkYourDevice")}</p>
            <p className="text-[#727272] font-Inter font-medium  text-[18px] w-[80%] text-center">
              {t("userManagement.mfa.notificationMessage")} <span className="text-[#040404] font-bold"> {t("common.yes")}</span> {t("userManagement.mfa.notificationAction")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
