import React from "react";
import { Modal } from "antd";
import warningImg from "../../../../../assets/images/user/warning.svg";
import { useTranslation } from "react-i18next";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import ImageView from "../../../../../layouts/component/ImageView";
export default function CloseConfirmationModal(props: any) {
  const { handleCancel, handleProceed, closeModalOpen } = props;
  const { t } = useTranslation();
  return (
    <div className="closeConfirmationModal">
      <Modal
        className="p-10 rounded-lg  _suspended show-notification"
        closable={false}
        centered
        open={closeModalOpen}
        footer={false}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <div className="pt-4 modal-body">
          <div className="flex-col flex justify-center items-center w-full">
            <ImageView src={warningImg} alt="warningIcon" className="h-20 w-20" />
            <p className="pt-4 font-semibold font-Inter text-[20px] text-center">{t("common.closeTabConfirmation")}</p>
          </div>
        </div>
        <div className="pt-8 modal-footer">
          <div className="flex justify-center w-full">
            <CustomButtonBack onClick={handleCancel} text={t("common.cancel")} />
            <CustomButtonNext onClick={handleProceed} text={t("common.proceed")} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
