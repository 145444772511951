import React from 'react';
import { ProvisionProvider } from '../context/ProvisionConfigureContext';
import ProvisionConfigurationContainer from '../layout/new-app-configuration/ProvisionConfigurationContainer';

export default function ProvisionConfigure() {
  return (
    <ProvisionProvider>
      <ProvisionConfigurationContainer />
    </ProvisionProvider>
  );
}
