import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { images } from "../../../../../../assets/images/ImagePaths";
import ImageView from "../../../../../../layouts/component/ImageView";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import { useTranslation } from "react-i18next";

const SendAuthorizeMailModal = (props: any) => {
  const { t } = useTranslation();
  const { openModal, handleCloseModal } = props;
  const [open, isOpen] = useState<boolean>(false);

  const handleModalClose = () => {
    isOpen(false);
    handleCloseModal();
  };
  useEffect(() => {
    isOpen(openModal);
  }, [openModal]);
  return (
    <Modal className="p-16  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleModalClose}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.successPinIcon} alt="notificationDone" className="delete-apps" />
          <h5 className="text-[18px] text-[#444] font-medium text-center font-Inter pt-3 ">{t("accessManager.deviceManagerEmailConfirmMessage")}</h5>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonNext onClick={handleModalClose} text={t("common.confirm")} />
        </div>
      </div>
    </Modal>
  );
};

export default SendAuthorizeMailModal;
