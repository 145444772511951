import React from "react";
import ImageView from "../../../../../../layouts/component/ImageView";
import { Checkbox } from "antd";
import { images } from "../../../../../../assets/images/ImagePaths";

const CheckboxImage = (props: any) => {
  const { image, setCheckedVal, clientName } = props;

  const handelonChange = (val: any) => {
    setCheckedVal("");
  };
  return (
    <>
      {image !== null && clientName !== null && (
        <div className="connected-apps w-1/4 pb-8">
          <div className="checkbox-container mr-10">
            <div className="content w-full h-[110px] relative flex items-center justify-center">
              <ImageView src={image || images.noAppImage} alt="connected-apps" className="w-full object-contain p-12" />
              <Checkbox
                onChange={(e) => {
                  handelonChange(e);
                }}
                className="absolute right-3 top-[10px] w-6 h-6"
              />
            </div>
          </div>
          <p className="pr-[38px] pt-1 text-center">{clientName}</p>
        </div>
      )}
    </>
  );
};

export default CheckboxImage;
