import { APIConfigURLs } from '../apiInterfaceType/apiInterfaceType';

const COMMON_API_URL: APIConfigURLs = {
    userInfo: {
        url: '',
    },
    userInfo1: {
        url: '',
    },
    userInfo2: {
        url: '',
    },
};

export default COMMON_API_URL;
