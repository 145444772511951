import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import { userList } from "./ConstMappingData";

const AssignNewUsers = (props: any) => {
  const { openModal, handelCloseModal } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(openModal);
  }, [openModal]);

  const handleCloseModal = () => {
    setIsOpen(false);
    handelCloseModal();
  };
  return (
    <Modal className="device-auth" maskClosable={false} centered open={isOpen} footer={false} onCancel={handleCloseModal}>
      <div className="w-full pt-5 px-10">
        <p className="modal-title font-Inter pb-10">Select group to assign the users</p>
        <div className="w-full">
          <Select placeholder="Search" className="w-full h-14 mb-20" showSearch suffixIcon={null} options={userList} />
        </div>
        <div className="btn-group flex justify-center pb-5">
          <CustomButtonBack onClick={handleCloseModal} text={"Cancel"} />
          <CustomButtonNext text={"Add"} />
        </div>
      </div>
    </Modal>
  );
};

export default AssignNewUsers;
