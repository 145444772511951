import * as Yup from "yup";
export const validationSchemaForIp = (t: any) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(t("accessManager.errors.nameRequired")) // Translated error message
      .max(20, t("accessManager.errors.nameMax")),
    description: Yup.string().required(t("accessManager.errors.descriptionRequired")).max(50, t("accessManager.errors.descriptionMax")),
    authorizationType: Yup.string().required(t("accessManager.errors.ipTypeRequired")),
    assignTo: Yup.string().required(t("accessManager.errors.assignToRequired")),
    ipDetails: Yup.array().of(
      Yup.object().shape({
        IPv6: Yup.lazy((value) => {
          if (value && value.length > 0) {
            return Yup.string()
              .matches(/^([0-9a-fA-F]{4}:){7}[0-9a-fA-F]{4}$/, t("accessManager.errors.invalidIPv6Format"))
              .test("unique-name", t("accessManager.errors.duplicateIp"), function (value) {
                const { path, options }: any = this;
                const fields = options.context.ipDetails;
                const currentField = parseInt(path.match(/\d+/)[0]);
                const isDuplicate = fields?.filter((field: any, index: any) => index !== currentField)?.some((field: any) => field.IPv6 === value);

                return !isDuplicate;
              });
          }
          return Yup.string().nullable();
        }),

        IPv4: Yup.string()
          .test("unique-name", t("accessManager.errors.duplicateIp"), function (value) {
            const { path, options }: any = this;
            const fields: any = options.context.ipDetails;
            const currentFieldIndex = parseInt(path.match(/\d+/)[0]);
            const isDuplicate = fields
              ?.filter((field: any, index: any) => index !== currentFieldIndex)
              ?.some((field: any) => {
                return field.IPv4 === value;
              });

            return !isDuplicate;
          })
          .test("ipSegmentRange", t("accessManager.errors.invalidIpSegmentRange"), function (value) {
            if (!value) return true; // Skip this test if value is not provided
            const segments = value.split(".");
            return segments.every((segment) => {
              const num = Number(segment);
              return num >= 0 && num <= 255;
            });
          })
          .test("ipValidation", t("accessManager.errors.invalidIpFormat"), function (value) {
            const ipRange = this.parent.ipRange;
            if (ipRange === "exactIp") {
              return Yup.string()
                .matches(/^(?:\d{1,3}\.){3}\d{1,3}$/, t("accessManager.errors.exactIpFormat"))
                .isValidSync(value);
            } else if (ipRange === "range16") {
              return Yup.string()
                .matches(/^(\d{1,3}\.){1,2}\d{1,3}$/, t("accessManager.errors.range16Format"))
                .isValidSync(value);
            } else if (ipRange === "range24") {
              return Yup.string()
                .matches(/^(\d{1,3}\.){2}\d{1,3}$/, t("accessManager.errors.range24Format"))
                .isValidSync(value);
            } else {
              return Yup.string().required(t("accessManager.errors.invalidIpFormat")).isValidSync(value);
            }
          })
          .required(t("accessManager.errors.ipRequired")),
        ipRange: Yup.string().required(t("accessManager.errors.ipRangeRequired")),
      }),
    ),
  });
};

export const ipRange = [
  { label: "Exact IP", value: "exactIp" },
  { label: "Range 16", value: "range16" },
  { label: "Range 24", value: "range24" },
];
