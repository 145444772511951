import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  groupUserList: any;
  groupAppList: any;
  enableGroupEditAndDelete: boolean;
  kerberosInitValue:any
}

const initialState: AuthState = {
  groupUserList: [],
  groupAppList: [],
  enableGroupEditAndDelete: false,
  kerberosInitValue:[]

};

const GroupSlice = createSlice({
  name: "groupSlice",
  initialState,
  reducers: {
    setGroupUserList: (state, action: PayloadAction<any>) => {
      state.groupUserList = action.payload;
    },
    clearGroupUserList: (state) => {
      state.groupUserList = [];
    },
    setGroupAppList: (state, action: PayloadAction<any>) => {
      state.groupAppList = action.payload;
    },
    clearGroupAppList: (state) => {
      state.groupAppList = [];
    },
    setGroupEditAndDelete: (state, action: PayloadAction<any>) => {
      state.enableGroupEditAndDelete = action.payload;
    },
     setKerberosInitialValue: (state, action: PayloadAction<any>) => {
      state.kerberosInitValue = action.payload;
    },
  },
});

export const { setGroupUserList, clearGroupUserList, setGroupAppList, clearGroupAppList, setGroupEditAndDelete,setKerberosInitialValue } = GroupSlice.actions;
export default GroupSlice.reducer;
