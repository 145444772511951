import { MANAGER_PASSWORD } from "./ModulePermissionConst";
import { t } from "i18next";

export const menuList = {
  smartAnalytics: { name: "Smart Analytics", key: "1" },
  userManagement: { name: "User Management", key: "2" },
  appManagement: { name: "App Management", key: "3" },
  roleManagement: { name: "Role Management", key: "4" },
  accessManager: { name: "Access Manager", key: "5" },
  credentialManagement: { name: "Credential Management", key: "7" },
  passwordManager: { name: MANAGER_PASSWORD, key: "6" },
  deviceManager: { name: "Device Management", key: "8" },
  userAuditLog: { name: "User Audit Log", key: "9" },
};
