import React from "react";
import Breadcrumbs from "../../layouts/component/Breadcrumb";
import CreateAppHeader from "./layout/my-apps/CreateAppHeader";
import CreateAppForm from "./layout/form/open-id/CreateAppForm";
import { useNavigate } from "react-router-dom";
import CertificateView from "./layout/my-apps/certificate/CertificateView";
import "./layout/form/AppConfigure.scss";
import { useTranslation } from "react-i18next";
const CreateNewApplication = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/app-store");
  };

  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full app-management">
          <Breadcrumbs currentSection={t("appManagement.configure")} onClick={handleNavigate} />
          <div className="h-full  relative">
            <CertificateView />
            <div className="w-full">
              <CreateAppHeader />
            </div>
            <div className=" w-full">
              <CreateAppForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewApplication;
