import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserStepper } from "../../pages/dashboard/user/user-dashboard/add-user/layouts/setpper/AddUserHelper";

interface UserState {
  steeper: any;
}

const initialState: UserState = {
  steeper: [...UserStepper],
};

const AddUserSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    setAddUserStepper: (state, action: PayloadAction<any>) => {
      state.steeper = action.payload;
    },
    clearAddUserSlice: (state) => {
      state.steeper = [...UserStepper];
    },
  },
});

export const { setAddUserStepper, clearAddUserSlice } = AddUserSlice.actions;
export default AddUserSlice.reducer;
