import React, { useState } from "react";
import { Button } from "antd";

const SubmitButton = (props: any) => {
  const { text, disable } = props;
  const [loadings, setLoadings] = useState<boolean[]>([]);

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 2000);
  };
  return (
    <Button
      data-testid="submit-button"
      type="primary"
      htmlType="submit"
      loading={loadings[0]}
      onClick={() => enterLoading(0)}
      disabled={disable}
      className="flex items-center justify-evenly font-semibold text-[20px] font-Inter w-[186px] h-[56px] bg-[#5441DA] text-white"
    >
      {text}
    </Button>
  );
};

export default SubmitButton;
