import React from "react";
import { staticMessage } from "../../../constant/StaticContent";
import UserProgressBar from "./UserProgressBar";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import "./Progress.scss";

const PlansAndProductsHeader = () => {
  return (
    <>
      <div className="plans-header flex flex-col justify-center flex-col pt-[60px] items-center w-full">
        <div className="w-full flex justify-between">
          <div className="w-[45%] brand-logo pl-[140px]">
            <ImageView src={images.registerLogo} className="w-[100px] h-[90px]" />
          </div>
          <div className="w-[55%] flex justify-start flex-col">
            <h5 className="text-[32px] text-[#000]  font-semibold font-Inter">{staticMessage.register.plansAndProducts}</h5>
            <p className="text-[18px]  text-[#464646] font-Inter">{staticMessage.register.products}</p>
          </div>
        </div>
        <div className="w-[60%] mx-auto justify-center" data-testid="user-progress-bar">
          <UserProgressBar />
        </div>
      </div>
    </>
  );
};

export default PlansAndProductsHeader;
