import React, { useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import ConnectedApplication from "./ConnectedApplication";
import ConnectedProvisionApplication from "./ConnectedProvisionApplication";

export default function ConnectedAppsTab(props: any) {
  const { setListOfApps, listOfApps, setDisableButton, listOfProvisioningApps, setListOfProvisioningApps } = props;
  const { t } = useTranslation();

  const [isActiveCurrentTab, setIsActiveCurrentTab] = useState<string>("1");

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `${t("userManagement.ssoApps")}`,
      children: <ConnectedApplication setListOfApps={setListOfApps} listOfApps={listOfApps} setDisableButton={setDisableButton} name="Connected Apps" />,
    },
    {
      key: "2",
      label: `${t("userManagement.provisioningApps")}`,
      children: <ConnectedProvisionApplication setListOfApps={setListOfProvisioningApps} listOfApps={listOfProvisioningApps} setDisableButton={setDisableButton} name="Connected Apps" />,
    },
  ];

  const onChange = (key: string) => {
    setIsActiveCurrentTab(key);
  };
  return (
    <div className="userAppTabs">
      <h3 className="userAppTabs-title">{t("userManagement.connectedApps")}</h3>
      <Tabs defaultActiveKey={isActiveCurrentTab} items={items} onChange={onChange} />
    </div>
  );
}
