import React, { useState, createRef } from "react";

import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { Button, Input } from "antd";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { useSelector } from "react-redux";
import { retrieveData } from "../../../services/storage/Storage";
import { useNavigate } from "react-router-dom";

const ValidateOTP = () => {
  const validateOtp = useGetApiRequests("validateOtp", "POST");
  const resendOtp = useGetApiRequests("resendOtp", "POST");
  const phoneNumber = retrieveData("phoneNumber", true);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [showError, setShowError] = useState<any>(false);
  const toggleAnnual = useSelector((state: any) => state?.RegisterResponse?.registerResponse);
  const navigate = useNavigate();
  const initialValue = {
    otp: 0,
    realmName: toggleAnnual?.customerPlanDto?.customerName,
    recoveryEmailId: toggleAnnual.adminUserEmailId,
  };

  const inputRefs: any = Array(6)
    .fill(0)
    .map(() => createRef());
  const handleOtpChange = (index: any) => (e: any) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
  };

  const handleKeyPress = (index: any) => (e: any) => {
    setShowError(false);
    if (e.key === "Backspace" && index >= 1) {
      inputRefs[index - 1].focus();
    } else if (e.key !== "Backspace" && index <= 4) {
      inputRefs[index + 1].focus();
    }
  };

  const handleSubmit = async (e: any) => {
    const joinedString = otp.join("");
    if (joinedString?.length !== 6) {
      setShowError(true);
      return;
    }
    const queryParam = {
      ...initialValue,
      otp: joinedString,
    };

    e.preventDefault();
    try {
      const response: any = await validateOtp(queryParam);
      const status = response.status;
      if (status === 200) {
        navigate("/upload-logo");
      }
    } catch (err: any) {
      triggerNotification("error", "", err?.message, "topRight");
    }
  };

  // resend otp

  const handelResendOtp = async () => {
    console.log(toggleAnnual);

    const payload = {
      phoneNumber: phoneNumber,
      templateName: "",
    };

    try {
      await resendOtp(payload);
    } catch (err: any) {
      triggerNotification("error", "", err?.message, "topRight");
    }
  };
  return (
    <div className="w-full flex-col register pt-10">
      <div className="w-full flex flex-col justify-center items-center logo">
        <ImageView src={images.registerLogo} className="w-[100px]" />
      </div>
      <div className="w-[80%] mx-auto pb-4">
        <p className="font-Inter text-[32px] pt-[120px] font-semibold">Verify your Email</p>
        <p className="text-[#777] pt-1 font-Inter font-medium text-[16px]">A One-time password will send to your registered Email ID for Verification</p>
      </div>
      <div className="w-[80%] mx-auto input-field pt-10">
        <div>
          <label className="text-[#171717] text-[16px] font-Inter pb-1"> Enter OTP</label>
          <br />
          <ul className="flex pb-10 relative">
            {otp.map((digit: any, index: number) => (
              <li className="w-12 h-12 mr-4" key={digit}>
                <Input
                  className="h-full text-center text-[20px]"
                  value={digit}
                  onChange={handleOtpChange(index)}
                  onKeyUp={handleKeyPress(index)}
                  maxLength={1}
                  ref={(input) => (inputRefs[index] = input)}
                />
              </li>
            ))}
            {showError && (
              <div className="absolute bottom-[10%] ">
                <p className="text-xs text-red-500 flex items-center">
                  <span className="material-symbols-outlined text-red-500 mr-2">error</span>Please enter 6 digit code
                </p>
              </div>
            )}
          </ul>

          <p className="text-[#5441DA] text-[16px] hidden font-Inter pb-1 font-semibold" onClick={handelResendOtp}>
            Resend Verification code
          </p>
        </div>
        <Button
          type="primary"
          onClick={(e) => {
            handleSubmit(e);
          }}
          className="font-semibold text-[20px] font-Inter w-full mt-20 h-[48px] bg-[#5441DA] text-white"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ValidateOTP;
