import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ImageView from "../../../../../component/ImageView";
import { images } from "../../../../../../assets/images/ImagePaths";
import CustomButtonBack from "../../../../../component/CustomButtonBack";
import CustomButtonNext from "../../../../../component/CustomButton";
import { useTranslation } from "react-i18next";

const UpdgradeConfirmationModal = (props: any) => {
  const { isOpenPopup, handleClose, handleUpdate } = props;

  const { t } = useTranslation();

  const [open, isOpen] = useState<boolean>(false);

  useEffect(() => {
    isOpen(isOpenPopup);
  }, [isOpenPopup]);

  const handleUpgradePlan = () => {
    handleUpdate();
  };
  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <Modal className="  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.unauthorized} alt="notificationDone" className="delete-apps" />

          <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#101828]">
            {t("tenants.planChangeWarning")}
            <a href="mailto:support@akku.work" className="text-[#126CEA]">
              support@akku.work
            </a>
          </p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={t("common.cancel")} />
          <CustomButtonNext onClick={handleUpgradePlan} text={t("common.ok")} />
        </div>
      </div>
    </Modal>
  );
};
export default UpdgradeConfirmationModal;
