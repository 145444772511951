import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import CommonHeader from "../../common/CommonHeader";
import SearchAndUpdateForm from "./SearchAndUpdateForm";
import { useDispatch, useSelector } from "react-redux";
import { setAddUserStepper } from "../../../../../../../../redux/slice/AddUserSlice";
import { staticMessage } from "../../../../../../../../constant/StaticContent";
import { retrieveData, storeData } from "../../../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { clearLDAPInitialValues, clearLDAPUpdateInitialValues, clearLDAPValues, setLDAPUpdateInitialValues } from "../../../../../../../../redux/slice/LDAPSlice";
import { triggerNotification } from "../../../../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import { handleRedirectSearch } from "../../navigate-helper/LDAPNavigate";
import { setUpdateDashboard } from "../../../../../../../../redux/slice/Users/UserDashboardUpdateSlice";
import { LDAPAuthenticationSchema } from "../configure-authentication/LDAPvalidationSchema";
import { useTranslation } from "react-i18next";
const ConfigureSearchAndUpdate = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const createLDAP = useGetApiRequests("createLDAP", "POST");
  const [isSubmitted, setIsSubmitted] = useState<boolean>();
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const toggleTable = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const updateInitialValues = {
    editMode: "WRITABLE",
    usersDn: "",
    usernameLDAPAttribute: "",
    rdnLdapAttribute: "",
    uuidLdapAttribute: "",
    userObjectClasses: "",
    syncUpdateInterval: "",
    certificate: "",
  };
  const storedValueFromRedux = useSelector((state: any) => state?.LDAPFormDetails?.LDAPUpdateInitialValue);
  const storedValue = storedValueFromRedux && Object.keys(storedValueFromRedux).length > 0 ? storedValueFromRedux : updateInitialValues;
  const realmId = retrieveData("realmId", true);

  const initialValues: any = useSelector((state: any) => state?.LDAPFormDetails?.LDAP1);

  const formikRef = useRef<any>(null);
  // Language change effect for revalidation
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const handleBack = () => {
    const newStepper = stepper.map((step: any, index: number) => {
      if (index === 2) {
        return {
          ...step,
          status: "check",
          icon: "check",
          active: false,
        };
      } else if (index === 3) {
        return {
          ...step,
          status: "inprogress",
          active: true,
          icon: "",
          children: [
            {
              title: "Authentication",
              description: "Enter child step 1 details",
              active: true,
            },
            {
              title: "Search & Update",
              description: "Enter child step 2 details",
              active: false,
            },
          ],
        };
      } else if (index === 4) {
        return {
          ...step,
          status: "NotStarted",
          active: false,
          icon: "",
          children: [
            {
              title: "Attributes",
              description: "Enter child step 1 details",
              active: false,
            },
            {
              title: "User Status",
              description: "Enter child step 2 details",
              active: false,
            },
            {
              title: "Notifications",
              description: "Enter child step 2 details",
              active: false,
            },
          ],
        };
      } else {
        return {
          ...step,
          active: false,
        };
      }
    });
    dispatch(setAddUserStepper(newStepper));
  };
  function formatCertificate(input: any) {
    // Define the start and end delimiters of the certificate
    const beginCert = "-----BEGIN CERTIFICATE-----";
    const endCert = "-----END CERTIFICATE-----";

    // Extract the certificate content by removing the delimiters
    const certContent = input.replace(beginCert, "").replace(endCert, "");

    // Reassemble the formatted certificate
    return `${beginCert}    ${certContent}     ${endCert}`;
  }
  const handleSubmit = async (values: any) => {
    const realmName: string = retrieveData("realmName", true);
    const formattedCertificate = formatCertificate(values.certificate);
    const payload = {
      ...values,
      ...initialValues,
      realmName: realmName,
      certificate: formattedCertificate,
      realmId: realmId,
    };
    dispatch(setLDAPUpdateInitialValues(values));

    try {
      const response: any = await createLDAP(payload);
      const status = response.status;
      if (status === 200) {
        dispatch(clearLDAPInitialValues());
        dispatch(clearLDAPUpdateInitialValues());
        dispatch(clearLDAPValues());
        storeData("componentId", response?.data, true);
        triggerNotification("success", response?.data?.message, "", "topRight");
        const redirect = handleRedirectSearch(stepper);
        dispatch(setAddUserStepper(redirect));
        dispatch(setUpdateDashboard(!toggleTable));
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  return (
    <div className="px-12 relative w-full">
      <CommonHeader text={t("userManagement.configureSearchUpdate.configureSearchHeading")} onClick={handleBack} className="cursor-pointer" />
      <div className="w-full">
        <Formik initialValues={storedValue} validationSchema={LDAPAuthenticationSchema(t)} onSubmit={handleSubmit} innerRef={formikRef}>
          {({ values, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="w-full">
                  <SearchAndUpdateForm setFieldValue={setFieldValue} values={values} setIsSubmitted={setIsSubmitted} isSubmitted={isSubmitted} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ConfigureSearchAndUpdate;
