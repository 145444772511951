import React, { useState, useEffect, useRef } from "react";
import CommonHeader from "../../common/CommonHeader";
import { Form, Formik } from "formik";
import ConfigureAuthenticationForm from "./ConfigureAuthenticationForm";
import { useDispatch, useSelector } from "react-redux";
import { setAddUserStepper } from "../../../../../../../../redux/slice/AddUserSlice";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../../layouts/toast/ErrorNotificationMessage";
import { setLDAPInitialValues, setLDAPValue1 } from "../../../../../../../../redux/slice/LDAPSlice";
import TestingProcessModal from "./TestingProcessModal";
import Loader from "../../../../../../../../layouts/component/Loader";
import { LDAPvalidationSchema } from "./LDAPvalidationSchema";
import { handleAuthRedirectBack } from "./stepperHelper";
import { useTranslation } from "react-i18next";

const ConfigureAuthentication = () => {
  const configureInitialValue = {
    connectionUrl: "",
    bindType: "Simple",
    bindDn: "",
    bindCredentials: "",
    ldapName: "",
  };
  const { t, i18n } = useTranslation();
  const storedValues = useSelector((state: any) => state?.LDAPFormDetails?.LDAPInitialConfigurations);
  const initialValues = storedValues && Object.keys(storedValues).length > 0 ? storedValues : configureInitialValue;
  const dispatch = useDispatch();
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const realmName: string = retrieveData("realmName", true);
  const createLDAP = useGetApiRequests("testConnection", "POST");
  const [loader, setLoader] = useState<boolean>(false);
  const [enableTest, setEnableTest] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const formikRef = useRef<any>(null);

  // Language change effect for revalidation
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);

  const handleBack = () => {
    const newStepper = handleAuthRedirectBack(stepper);
    dispatch(setAddUserStepper(newStepper));
  };

  const handleSubmit = async (values: any) => {
    setLoader(true);
    setOpenModal(true);
    const pathParams = {
      realmName: realmName,
      testConnection: "test-connection",
    };
    const payload = {
      connectionUrl: values?.connectionUrl,
      action: "testConnection",
    };

    try {
      const response: any = await createLDAP(payload, {}, pathParams);
      const status = response.status;
      if (status === 200) {
        testAuthentication(pathParams, values);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      setOpenModal(false);
      handleRequestError(err);
    }
  };

  const testAuthentication = async (pathParams: any, values: any) => {
    setOpenModal(true);
    const testAuthPayload: object = {
      connectionUrl: values?.connectionUrl,
      bindDn: values?.bindDn,
      bindCredential: values?.bindCredentials,
      action: "testAuthentication",
    };
    try {
      const response: any = await createLDAP(testAuthPayload, {}, pathParams);
      const status = response?.status;
      if (status === 200) {
        dispatch(setLDAPInitialValues(values));
        setEnableTest(false);
        dispatch(setLDAPValue1(values));
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      setOpenModal(false);
      handleRequestError(err);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="w-full px-12 relative">
      {loader && <Loader />}
      <CommonHeader text={t("userManagement.configureAuthentication")} onClick={handleBack} className="cursor-pointer" />
      <div className="w-full">
        <Formik
          innerRef={formikRef} // Attach the formikRef to Formik
          initialValues={initialValues}
          validationSchema={LDAPvalidationSchema(t)}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, dirty, setFieldValue }) => {
            return (
              <Form>
                <div className="w-full">
                  <ConfigureAuthenticationForm setFieldValue={setFieldValue} enableTest={enableTest} setEnableTest={setEnableTest} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {openModal && <TestingProcessModal openModal={openModal} handleClose={handleClose} enableTest={enableTest} isLDAP={true} />}
    </div>
  );
};

export default ConfigureAuthentication;
