export const staticMessage = {
  singleUserMessage: {
    sbuTitle: "SINGLE USER",
    Title: "Basic Details",
    basicDetailsMessage: "This information is necessary for the allocation and administration of the user",
    personalDetailsMessage: "This information will assist Akku in guiding the user through the onboarding process",
  },
  LDAPUserMessage: {
    sbuTitle: "LDAP",
    Title: "LDAP Details",
    ADTitle: "AD",
    LDAPUserMessage: "This information is necessary to migrate the data from the Directory",
  },
  userTab: {
    tabOne: "Basic Details",
    tabTow: "Personal Details",
  },
  register: {
    plansAndProducts: "Plans & Products",
    products: "Now you can buy individual products from the akku.",
    startTrial: "Start 14 day free trial now, No credit card required. ",
    createFailed: "An unexpected error has occurred please try again later",
    userExist: "User name already exists",
    loginFailed: "User log-in failed",
    fetchDashboard: "Failed to fetch dashboard Data details",
  },
  app: {
    editApp: "Failed to fetch application details",
    clientId: "OpenID Connect allows Clients to verify the identity of the End-User based on the authentication performed by an Authorization Server",
    type: "Specifies ID referenced in URI and tokens. For example my-client. For SAML this is also the expected issuer value from authn requests",
    root: "Root URL appended to relative URLs",
    home: "Default URL to use when the auth server needs to redirect or link back to the client.",
    redirect:
      "Valid URI pattern a browser can redirect to after a successful login. Simple wildcards are allowed such as http://example.com/*. Relative path can be specified too such as /my/relative/path/*. Relative paths are relative to the client root URL, or if none is specified the auth server root URL is used. For SAML, you must set valid URI patterns if you are relying on the consumer service URL embedded with the login request.",
    redirectLogout:
      "Valid URI pattern a browser can redirect to after a successful logout. A value of " +
      " or an empty field will use the list of valid redirect URIs. A value of - will not allow any post-logout redirect URIs. Simple wildcards are allowed such as http://example.com/*. Relative path can be specified too such as /my/relative/path/*. Relative paths are relative to the client root URL, or if none is specified the auth server root URL is used",
    idpurl:
      "URL fragment name to reference client when you want to do IDP Initiated SSO. Leaving this empty will disable IDP Initiated SSO. The URL you will reference from your browser will be {server-root}/realms/{realm}/protocol/saml/clients/{client-url-name}",
    idpsso: "Relay state you want to send with SAML request when you want to do IDP Initiated SSO.",
    mastersaml:
      "If configured, this URL will be used for every binding to both the SP is Assertion Consumer and Single Logout Services. This can be individually overridden for each binding and service in the Fine Grain SAML Endpoint Configuration",
    webOrgin: "Allowed CORS origins. To permit all origins of Valid Redirect URIs, add + This does not include the * wildcard though. To permit all origins, explicitly add",
    openIdLog:
      "Valid URI pattern a browser can redirect to after a successful logout. A value of + or an empty field will use the list of valid redirect URIs. A value of - will not allow any post-logout redirect URIs. Simple wildcards are allowed such as http://example.com/*. Relative path can be specified too such as /my/relative/path/*. Relative paths are relative to the client root URL, or if none is specified the auth server root URL is used",
    openRedirect:
      "Valid URI pattern a browser can redirect to after a successful login. Simple wildcards are allowed such as http://example.com/*. Relative path can be specified too such as /my/relative/path/*. Relative paths are relative to the client root URL, or if none is specified the auth server root URL is used. For SAML, you must set valid URI patterns if you are relying on the consumer service URL embedded with the login request",
    clientAuth: "This defines the type of the OIDC client. When it is ON, the OIDC type is set to confidential access type. When it iss OFF, it is set to public access type.",
    auth: "Enable/Disable fine-grained authorization support for a client",
    standradcheck:
      "This enables standard OpenID Connect redirect based authentication with authorization code. In terms of OpenID Connect or OAuth2 specifications, this enables support of Authorization Code Flow for this client",
    dag: "This enables support for OAuth 2.0 Device Authorization Grant, which means that the client is an application on a device that has limited input capabilities or lacks a suitable browser.",
    cibagrant: "This enables support for OIDC CIBA Grant, which means that the user is authenticated via some external authentication device instead of the user is browser",
    implictFlow:
      "This enables support for OpenID Connect redirect-based authentication without authorization code. In terms of OpenID Connect or OAuth2 specifications, this enables support of Implicit Flow for this client",
    directAccess:
      "This enables support for Direct Access Grants, which means that the client has access to the username/password of the user and exchanges it directly with the Keycloak server for an access token. In terms of OAuth2 specification, this enables support of Resource Owner Password Credentials Grant for this client",
    savedAcc:
      "Allows you to authenticate this client to Keycloak and retrieve access token dedicated to this client. In terms of OAuth2 specification, this enables support of Client Credentials Grant for this client",
  },
  LDAPGroupMap: {
    groupMappingHeader: "LDAP Group Mapping",
    groupMappingDescription: "Select the Group from LDAP that you want to be available in Akku ",
    finalReview: "Final Review - User Data",
    finalReviewDescription: "Please review the mapping below and confirm changes if any",
  },
  SelectChannel: {
    importUsers: "Please select the channel to import users",
    akkuDirectory: "Akku as a directory",
    syncDirectory: "Sync from your directory",
  },
  DirectoryConnection: {
    directoryConnectionHeading: "Directory Connection",
    whiteListAkku: "Would you like to whitelist Akku's IP in your firewall or request for a VPN Tunnel",
    IpWhiteList: "Whitelist Akku’s IP",
    vpnTunnel: "Request VPN Tunnel",
    adminContact: "Our Network Admin will contact you",
  },
  Connector: {
    chooseConnectorHeading: "Choose Connector",
  },
  ConfigureAuthentication: {
    configureAuthheading: "Configure - Authentication",
    connectionUrl: "Connection URL*",
    bindtype: "Bind Type*",
    bindDn: "Bind DN*",
    bindCredentials: "Bind Credentials*",
  },
  ConfigureSearchUpdate: {
    configureSearchHeading: "Configure - Search & Update",
    editMode: "Edit Mode*",
    userDn: "Users DN*",
    userLdap: "Username LDAP Attribute*",
    rdnLdap: "RDN LDAP Attribute*",
    uuidLdap: "UUID LDAP Attribute*",
    userObject: "User object classes*",
  },
  MappingAttribute: {
    mappingAttributeHeading: "Mapping - Attributes",
    sourceLdap: "SOURCE - LDAP",
    targetAkku: "TARGET - Akku",
    transfromation: "TRANSFORMATION",
    action: "ACTION",
  },
  MappingUserStatus: {
    mappingUserStatusHeading: "Mapping - User Status",
    mappingUserStatusSubheading: "User status is marked as Disabled in Directory ( 22 Users )",
  },
  MappingNotification: {
    mappingNotificationHeading: "Mapping - Notifications",
    email: "Email",
    fromEmail: "From Email*",
    selectGroup: "Select Group",
    selectTemplate: "Select Template",
    whenToDeliver: "When to deliver",
    selectDate: "1.Select a date and time when you want to start this activity",
    periodicRun: "2.Want to run it periodically?",
  },
  groupMapping: {
    retrieve: "LOAD_GROUPS_BY_MEMBER_ATTRIBUTE",
    mode: "LDAP_ONLY",
  },
  disableConnection: "Successfully disable the directory ",
};
