import React from "react";
import { Modal } from "antd";
import inactiveImg from "../../../../../assets/images/user/inactive.png";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../layouts/component/CustomButton";
import { useTranslation } from "react-i18next";
export default function InActiveUserModal(props: any) {
  const { openModal, handleProceedFunction, handleCancelFunction, displayMessageContent } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Modal centered open={openModal} footer={false} onCancel={handleCancelFunction} closable={false} maskClosable={false} className="_suspended p-10 show-notification rounded-lg ">
        <div className="pt-3 modal-body">
          <div className="w-full flex flex-col items-center">
            <img className="h-20 w-20" src={inactiveImg} alt="inactiveIcon" />
            <p className=" font-semibold pt-4 text-[20px] text-center">{displayMessageContent}</p>
          </div>
        </div>
        <div className="pt-8 modal-footer">
          <div className="w-full flex justify-center" data-testid="editUserDetailsInactive">
            <CustomButtonBack onClick={handleCancelFunction} text={t("common.cancel")} />
            <CustomButtonNext onClick={handleProceedFunction} text={t("common.proceed")} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
