import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  isAnnual: boolean;
}

const initialState: AuthState = {
    isAnnual: false,
};

const courseTabSlice = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setIsAnnual: (state, action: PayloadAction<boolean>) => {
      state.isAnnual = action.payload;
    },
    clearIsAnnual: state => {
      state.isAnnual = false;
    },
  },
});

export const { setIsAnnual, clearIsAnnual } = courseTabSlice.actions;
export default courseTabSlice.reducer;
