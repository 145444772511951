import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  activeStep: number;
  isPublish: boolean;
}
const initialState: AuthState = {
  activeStep: 0,
   isPublish: false,
};

const provisioningPublishSlice: any = createSlice({
  name: "navigateProvisioningTab",
  initialState,
  reducers: {
    setPublishActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    clearPublishActiveStep: (state) => {
      state.activeStep = 0;
    },
    setIsPublish: (state) => {
      state.isPublish = false;
    },
    clearPublishAllData: () => {
      return { ...initialState };
    },
  },
});

export const { setPublishActiveStep, clearPublishActiveStep, clearPublishAllData, setIsPublish } = provisioningPublishSlice.actions;
export default provisioningPublishSlice.reducer;
