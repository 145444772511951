import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  data: any;
}

const initialState: AuthState = {
  data: null,
};

const DashboardFilterSlice = createSlice({
  name: "dashboardDetailsFilter",
  initialState,
  reducers: {
    setDashboardFilter: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clearDashboardFilterDetails: (state) => {
      state.data = null;
    },
  },
});

export const { setDashboardFilter, clearDashboardFilterDetails } = DashboardFilterSlice.actions;
export default DashboardFilterSlice.reducer;
