import React, { useState } from "react";
import { Modal } from "antd";
import { handleLogout } from "../../../User/UserDashboard/user-header/LogoutHelperFile";
import { useDispatch } from "react-redux";
import { retrieveData, retrieveDeviceData } from "../../../services/storage/Storage";
import ImageView from "../../component/ImageView";
import CustomButtonBack from "../../component/CustomButtonBack";
import CustomButtonNext from "../../component/CustomButton";
import warningIcon from "../../../assets/images/user/warning.svg";
import unauthorized from "../../../assets/images/icons/unauthorized.svg";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import Loader from "../../component/Loader";
import "./LicenseExceedsModal.scss";
import { useTranslation } from "react-i18next";

interface UserLimitExceedsInterface {
  openModal: boolean;
  responseMessage: string;
  adminAccessDisabled: boolean;
  closeModal: () => void;
}
const LicenseExceedsModal: React.FC<UserLimitExceedsInterface> = ({ openModal, closeModal, responseMessage, adminAccessDisabled }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");

  const userName = retrieveData("userName", true);
  const deviceUrl = retrieveDeviceData("device");

  const [loader, setLoader] = useState(false);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);

  const logoutFunction = () => {
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };
  return (
    <Modal className="exceed-modal rounded-lg p-10 pb-8" closable={false} maskClosable={false} centered open={openModal} footer={false} onCancel={closeModal}>
      <div className="modal-body  pt-1">
        <div className="flex-col flex w-full justify-center items-center">
          <ImageView className="w-20 h-20" alt="warningIcon" src={adminAccessDisabled ? unauthorized : warningIcon} />
          <p className="mt-2">{responseMessage}</p>
        </div>
      </div>
      <div className="pt-8 modal-footer">
        {!adminAccessDisabled ? (
          <div className="w-full flex justify-center">
            <CustomButtonBack onClick={closeModal} text={t("common.close")} />
            {<CustomButtonNext onClick={closeModal} text={t("common.contactSupport")} />}
          </div>
        ) : (
          <div className="w-full flex justify-center">{<CustomButtonNext onClick={logoutFunction} text={t("common.logout")} />}</div>
        )}
      </div>
      {loader && logoutLoader && <Loader />}
    </Modal>
  );
};

export default LicenseExceedsModal;
