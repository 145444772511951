import { Empty, Table } from "antd";
import React, { useEffect, useState } from "react";
import { images } from "../../../assets/images/ImagePaths";
import { prerequisitesTable } from "../../../constant/prerequisites/prerequisites";
import AuditLogTableHeader from "./AuditLogTableHeader";
import AuditLogsDeleteModal from "./AuditLogsDeleteModal";
import { auditLogTableColumns } from "../UserAuditHelper";
import { useAuditLogContext } from "../context/AuditLogContext";
import { AuditLogTableProps } from "../AuditLogTypes";
import { useTranslation } from "react-i18next";

export default function AuditLogTable() {
  const {
    tableDataLists,
    currentPage,
    sizeChanger,
    totalRecords,
    loader,
    formValue,
    handleGetUserAuditDetails,
    setCurrentPage,
    setSizeChanger,
  }: AuditLogTableProps = useAuditLogContext();
  const { t } = useTranslation();
  const customEmptyPlaceholder = (
    <Empty image={images.tableFilterEmpty} description={<span className="tableEmpty">{t("auditLogs.downloadInfo")}</span>} />
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<React.Key[]>([]);
  const [tableDataSource, setTableDataSource] = useState<any[]>([]);
  const [auditLogsDeleteModalOpen, setAuditLogsDeleteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    createTableData();
  }, [tableDataLists, currentPage, sizeChanger, totalRecords, formValue]);

  const formatDateTime = (timestamp: string) => {
    const date = new Date(timestamp);
    const formattedDate = date?.toLocaleDateString("en-GB"); // Format: DD/MM/YY
    const formattedTime = date?.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }).toLowerCase(); // Format: HH:mm am/pm
    return `${formattedDate} at ${formattedTime}`;
  };

  const createTableData = () => {
    const apiData = tableDataLists || [];
    const dataSource = apiData?.map((item: any) => ({
      key: item?.id,
      username: item?.userName,
      DateTime: formatDateTime(item?.eventTime),
      actionType: item?.actionType,
      activityLogs: item?.activityLog,
      os: item?.osName,
      IPAddress: item?.ipAddress,
      device: item?.device,
    }));
    setTableDataSource(dataSource);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const clearSelection = () => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  };
  const handleAuditLogDeleteModal = () => {
    setAuditLogsDeleteModalOpen(true);
  };
  const handelDeleteAuditLogsModalClose = () => {
    setAuditLogsDeleteModalOpen(false);
  };
  const handlePageChange = (page: number) => {
    const pageSize = Math.round((page - 1) * sizeChanger);
    setCurrentPage(pageSize);

    handleGetUserAuditDetails(formValue, pageSize, sizeChanger);
  };
  const handlePageSizeChange = (current: number, newSize: number) => {
    setSizeChanger(newSize);

    handleGetUserAuditDetails(formValue, currentPage, newSize);
  };
  return (
    <div className={`${totalRecords > 0 ? "" : "custom-background"}`}>
      <AuditLogTableHeader
        totalRecords={totalRecords}
        clearSelection={clearSelection}
        selectedRow={selectedRow}
        handleAuditLogDeleteModal={handleAuditLogDeleteModal}
      />
      <Table
        dataSource={tableDataSource}
        columns={auditLogTableColumns(t)}
        rowSelection={rowSelection}
        pagination={{
          current: currentPage / sizeChanger + 1,
          total: totalRecords,
          showSizeChanger: true,
          pageSizeOptions: prerequisitesTable.pageSizeOptions,
          defaultPageSize: sizeChanger,
          pageSize: sizeChanger,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
        }}
        loading={loader}
        locale={{
          triggerDesc: `${t("common.triggerDesc")}`,
          triggerAsc: `${t("common.triggerAsc")}`,
          cancelSort: `${t("common.cancelSort")}`,
          emptyText: customEmptyPlaceholder,
        }}
      />
      {auditLogsDeleteModalOpen && (
        <AuditLogsDeleteModal
          handleGetUserAuditDetails={handleGetUserAuditDetails}
          deleteLogsModalOpen={auditLogsDeleteModalOpen}
          handelDeleteAuditLogsModalClose={handelDeleteAuditLogsModalClose}
          selectedRow={selectedRow}
          formValues={formValue}
          clearSelection={clearSelection}
          setCurrentPage={setCurrentPage}
          setSizeChanger={setSizeChanger}
        />
      )}
    </div>
  );
}
