// src/constants/ModulePermissionsConstants.js
export const USER_MANAGEMENT = "Cloud Directory";
export const APP_STORE = "SSO & IDP";
export const ACCESS_MANAGER = "Access Manager";
export const DEVICE_MANAGER = "Device Manager";
export const PASSWORD_MANAGER = "Password Manager";
export const MANAGER_PASSWORD = "Password Manager";
export const PROVISIONING = "Provisioning";
export const PASSWORD_SET_SUCCESS = "Password set successfully. You will be redirected to the login page shortly.";
export const PASSWORD_SET_SUCCESSFULLY = "Password set successfully.";

export const PASSWORD_RESET_SUCCESS = "Password has been successfully reset";
export const FORCE_SET_PASSWORD_SUCCESS = "Password has been set successfully."