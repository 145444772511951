import { Modal } from "antd";
import React from "react";
import CustomButtonNext from "../../../../../../../layouts/component/CustomButton";
import ImageView from "../../../../../../../layouts/component/ImageView";
import { images } from "../../../../../../../assets/images/ImagePaths";
import { useAddUserContext } from "../../../add-user-context/AddUserContext";
import { useDispatch, useSelector } from "react-redux";
import { setUpdateDashboard } from "../../../../../../../redux/slice/Users/UserDashboardUpdateSlice";
import { clearAddUserSlice } from "../../../../../../../redux/slice/AddUserSlice";
import { useTranslation } from "react-i18next";
const MobileNotification = (props: any) => {
  const { t } = useTranslation();
  const { closeModal } = useAddUserContext();
  const { handleClose } = props;
  const toggleTable = useSelector((state: any) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    closeModal();
    handleClose();
    dispatch(setUpdateDashboard(!toggleTable));
    dispatch(clearAddUserSlice());
  };

  return (
    <Modal className="p-5  show-notification welcome-notification  rounded-lg" maskClosable={false} centered open={true} footer={false} onCancel={handleCloseModal}>
      <div>
        <div className="modal-body flex justify-center flex-col items-center ">
          <div className="w-20 pt-8">
            <ImageView src={images.notificationMail} />
          </div>
          <p className=" text-center py-8 font-Inter font-medium text-[20px] text-[#444]"> {t("userManagement.askUserToCheckMobileNotifications")}</p>
        </div>
        <div className="modal-footer">
          <div className="w-full flex justify-center">
            <CustomButtonNext onClick={handleCloseModal} text={t("common.done")} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MobileNotification;
