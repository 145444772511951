import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface KeycloakState {
  KeycloakInfo: any;
  KeycloakUserDetails: any;
}

const initialState: KeycloakState = {
  KeycloakInfo: null,
  KeycloakUserDetails: null,
};

const keycloakSlice = createSlice({
  name: "keycloak",
  initialState,
  reducers: {
    setKeycloakInfo: (state, action: PayloadAction<any>) => {
      state.KeycloakInfo = action.payload;
    },
    clearKeycloakInfo: (state) => {
      state.KeycloakInfo = null;
    },
    setKeycloakUserDetails: (state, action: PayloadAction<any>) => {
      state.KeycloakUserDetails = action.payload;
    },
    clearKeycloakUserDetails: (state) => {
      state.KeycloakUserDetails = null;
    },
    keycloakLogout: (state) => {
      // Reset the state to its initial values
      state.KeycloakInfo = null;
      state.KeycloakUserDetails = null;
    },
  },
});

export const { setKeycloakInfo, clearKeycloakInfo, setKeycloakUserDetails, clearKeycloakUserDetails, keycloakLogout } = keycloakSlice.actions;
export default keycloakSlice.reducer;
