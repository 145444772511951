import React from "react";
import { useTranslation } from "react-i18next";

const Breadcrumbs = ({ currentSection, onClick }: any) => {
  const { t } = useTranslation();

  return (
    <div className="breadcrumb">
      <ul className="flex items-center">
        <li className="text-lg font-normal text-[#1D1D24] font-Inter cursor-pointer" onClick={onClick} role="none">
          {t("appManagement.appStore")} {"> "}
        </li>
        <li className="pl-2 text-lg font-semibold text-[#1D1D24] font-Inter">{currentSection}</li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
