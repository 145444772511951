import React, { useEffect, useState } from "react";
import { retrieveData } from "../../../../services/storage/Storage";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CreateAppHeader = () => {
  const { t } = useTranslation();

  const [singleAppDetails, setSingleAppDetails] = useState<any>("");
  const appDetails = useSelector((state: any) => state?.AppDetailsSlice?.appDetails);

  useEffect(() => {
    const configureApp = retrieveData("configureApp", true);
    if (configureApp === "configure") {
      setSingleAppDetails(appDetails);
    } else if (configureApp === "edit") {
      setSingleAppDetails(appDetails?.client);
    }
  }, []);
  const openPdfInNewWindow = () => {
    const url = appDetails?.configDocument;
    window.open(url, "_blank");
  };
  return (
    <div>
      {appDetails?.configDocument && (
        <div className="w-full flex justify-end ">
          <p className="text-lg font-normal hover:text-[#69b1ff] text-[#5441DA] font-Inter flex  mr-2 cursor-pointer" onClick={openPdfInNewWindow} role="none">
            <span className="material-symbols-outlined ml-2">download</span> {t("appManagement.configurationDocument")}
          </p>
        </div>
      )}

      <div className="w-full ">
        <p className="text-2xl font-semibold text-[#171717] font-Inter flex ">
          {t("appManagement.configure")} -<span className="ml-2">{singleAppDetails ? singleAppDetails?.name : `${t("appManagement.appName")}`}</span>
        </p>
      </div>
    </div>
  );
};

export default CreateAppHeader;
