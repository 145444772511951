import React from 'react'
import ImageView from '../../layouts/component/ImageView'
import { images } from '../../assets/images/ImagePaths'

export default function ClientDashboardNoDataFound() {
    return (
        <div className="flex justify-center items-center w-full h-full">
            <div className="flex flex-col">
                <div className="w-[200px]">
                    <ImageView src={images.noData} />
                </div>
                <p className="no-data">No apps found</p>
            </div>
        </div>
    )
}
