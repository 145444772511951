import loader from "./../../assets/images/loader.gif";

const Loader = () => {
  return (
    <div data-testid="loader" className="loader left-0 absolute w-full h-full flex justify-center items-center  z-[999] bg-[#EFEDFC] rounded-lg top-0">
      <img className="loader-akku" src={loader} alt="loader" />
    </div>
  );
};

export default Loader;
