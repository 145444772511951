import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import ImageView from "../../../../../../layouts/component/ImageView";
import SearchAppsModal from "../modal/SearchAppsModal";
import { useDispatch, useSelector } from "react-redux";
import { getListOfApps } from "../modal/ListOfUserHelperFile";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../services/storage/Storage";
import Loader from "../../../../../../layouts/component/Loader";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../layouts/component/CustomButton";
import { images } from "../../../../../../assets/images/ImagePaths";
import { setGroupAppList } from "../../../../../../redux/slice/GroupSlice";
import "./ConnectedApps.scss";
import { useTranslation } from "react-i18next";
const ConnectedApplication = ({ setListOfApps, listOfApps, setDisableButton, name, styleName = "" }: any) => {
  const { t } = useTranslation();
  const getAppList = useGetApiRequests("getListOfAppsByRealmId", "GET");
  const urlParams = new URLSearchParams(window.location.search);
  const isEdit = urlParams.get("edit");
  const realmId = retrieveData("realmId", true);
  const dispatch = useDispatch();
  const editGroupDetails: any = useSelector((state: any) => state.dashboardDetails.userList);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [appList, setAppList] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [disconnectApp, setDisconnectApp] = useState<any>(null);
  const [tempSelectedApps, setTempSelectedApps] = useState<any>([]);
  useEffect(() => {
    if (isEdit === "true") {
      const selectedAppIds = editGroupDetails?.attributes?.clientAppId?.map((item: any) => parseInt(item));
      setSelectedValues(selectedAppIds);
      setTempSelectedApps(selectedAppIds);
      getListOfAllApps();
    } else {
      getListOfAllApps();
    }
  }, []);
  const getListOfAllApps = async () => {
    const reformedAppList = await getListOfApps(realmId, setLoader, getAppList);
    setAppList(reformedAppList);
  };
  const openConfirmationModal = (item: any) => {
    setDisconnectApp(item);
    setDeleteModal(true);
  };
  const handelonChange = () => {
    const appsToBeDelete = [...listOfApps];
    const filteredApps = appsToBeDelete.filter((app) => app.value !== disconnectApp);
    setListOfApps(filteredApps);
    dispatch(setGroupAppList(filteredApps));

    const selectedValuesToRemoved = filteredApps.map((item: any) => item.value);
    setSelectedValues(selectedValuesToRemoved);
    setTempSelectedApps(selectedValuesToRemoved);
    setDisableButton(false);
    handleCloseModal();
  };
  const handleAddModal = () => {
    setIsOpen(true);
  };
  const handelClose = () => {
    setIsOpen(false);
  };
  const handleCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <div className={`connected-apps  ${styleName}`}>
      {loader && <Loader />}
      {/* <p className="text-[#171717] text-2xl font-Inter font-bold pt-2 pb-3 title">{t("userManagement.connectedApps")}</p> */}
      <div className="w-full overflow-x-auto">
        <div className="w-full flex  flex-wrap">
          <div className="connected-apps  flex  flex-wrap">
            {listOfApps &&
              listOfApps.length > 0 &&
              listOfApps.map((item: any) => (
                <div className="mr-3 mb-3 checkbox-container " key={item?.value}>
                  <div className="content  relative flex items-center justify-center w-full h-[125px]">
                    <ImageView src={item?.logoUrl} className="w-full object-contain bg-[#FAFAFC] rounded-lg p-2" alt="connected-apps" />
                    <Checkbox
                      className="absolute left-2 top-[10px] w-4 h-4"
                      onChange={() => {
                        openConfirmationModal(item?.value);
                      }}
                      checked={item?.checked}
                    />
                  </div>
                </div>
              ))}
            <Button onClick={handleAddModal} className="upload">
              <span className="text-[#5441DA] material-symbols-outlined ">add</span>
            </Button>
          </div>
        </div>
      </div>
      {isOpen && (
        <SearchAppsModal
          appList={appList}
          setAppList={setAppList}
          isOpen={isOpen}
          handelClose={handelClose}
          setListOfApps={setListOfApps}
          listOfApps={listOfApps}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          setDisableButton={setDisableButton}
          tempSelectedApps={tempSelectedApps}
          setTempSelectedApps={setTempSelectedApps}
        />
      )}
      {deleteModal && (
        <Modal className="p-10 rounded-lg  show-notification  _suspended " maskClosable={false} centered open={deleteModal} footer={false} onCancel={handleCloseModal}>
          <div className="modal-body  pt-4 ">
            <div className="flex w-full flex-col justify-center items-center ">
              <ImageView src={images.notificationSuspend} alt="notificationDone" className="w-20 h-20" />
              <h5 className="text-[24px] font-Inter pt-3 font-semibold">{t("userManagement.disConnectApp")}</h5>

              <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#475467]">{t("userManagement.confirmDisconnectAppFromGroup")}</p>
            </div>
          </div>
          <div className="modal-footer pt-8">
            <div className="w-full justify-center flex ">
              <CustomButtonBack onClick={() => handleCloseModal()} text={`${t("userManagement.cancel")}`} />
              <CustomButtonNext onClick={handelonChange} text={`${t("userManagement.yes")}`} />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ConnectedApplication;
