import { ACCESS_MANAGER, APP_STORE, DEVICE_MANAGER, PASSWORD_MANAGER, PROVISIONING, USER_MANAGEMENT } from "./ModulePermissionConst";

export const modulePermissions = {
  userManagement: USER_MANAGEMENT,
  appStore: APP_STORE,
  accessManger: ACCESS_MANAGER,
  passwordManager: PASSWORD_MANAGER,
  deviceManager: DEVICE_MANAGER,
  provisioning: PROVISIONING,
};

export const rolePermissionsMessage = {
  contactAdmin: "Upgrade your plan to use this product. Contact Akku support.",
};
