import React from "react";
import { AuditLogProvider } from "./context/AuditLogContext";
import UserAuditLogContainer from "./UserAuditLogContainer";

export default function UserAuditLog() {
  return (
    <AuditLogProvider>
      <UserAuditLogContainer />
    </AuditLogProvider>
  );
}
