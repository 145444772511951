import { useSelector } from 'react-redux';
import { retrieveData } from '../../../../../services/storage/Storage';

export const useConnectionAuthentication = () => {
  const generalDetails = useSelector((state: any) => state?.provisioning?.generalDetails);

  const getAkkuClientRequestResponseDtoStr = () => {
    const trimmedName = generalDetails?.name?.trim()?.toLowerCase();
    const currentSelectAppName = retrieveData('currentAppName', true);
    const trimmedConnectorName = currentSelectAppName?.toUpperCase();

    return {
      clientId: `https://www.${trimmedName + 'prov'}.com/`,
      masterSamlProcessingUrl: '',
      validRedirectLogoutUrl: [''],
      assertionConsumerUrlRedirect: 'https://www.google.com/',
      assertionConsumerUrlPost: 'https://www.google.com/',
      baseUrl: 'https://www.google.com/',
      validRedirectUrl: ['https://www.google.com/'],
      rootUrl: '',
      apiKey: '',
      description:
        'Integrating Google services with custom applications can unlock powerful functionalities and enhance user experience in various ways.',
      name: `${trimmedConnectorName}`,
      protocol: 'saml',
    };
  };

  return { getAkkuClientRequestResponseDtoStr };
};
