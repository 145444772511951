import React, { useState } from "react";
import SearchInput from "../../../../layouts/component/SearchField";
import { Button, Tooltip } from "antd";
import AddConnectionModal from "../../modal/AddConnectionModal";
import AddCredentialsModal from "../../modal/AddCredentialsModal";
import { setSearchConnection } from "../../../../redux/slice/credential-management/credentialsSlice";
import { useDispatch, useSelector } from "react-redux";
const CredentialHeader = (props: any) => {
  const { currentTabStatus } = props;
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isCredential, setIsCredential] = useState<boolean>(false);
  const search = useSelector((state: any) => state?.credentialsSlice?.searchValue);

  const dispatch = useDispatch();

  const handleSearch = (event: any) => {
    const searchValue = event?.target?.value;
    dispatch(setSearchConnection(searchValue));
  };

  const handleAddConnections = () => {
    if (!currentTabStatus) {
      setIsActive(true);
    } else {
      setIsCredential(true);
    }
  };
  const handleClose = () => {
    if (!currentTabStatus) {
      setIsActive(false);
    } else {
      setIsCredential(false);
    }
  };

  return (
    <div className="flex w-full pt-6 pb-8 relative">
      <div className="w-1/2 c-header">
        <SearchInput search={search} placeholder="Search" onChange={handleSearch} className="max-w-[500px] w-full" />
      </div>
      <div className="w-1/2 flex justify-end">
        <Tooltip title={"Add user"}>
          <Button type="primary" className=" add-btn" onClick={handleAddConnections}>
            <span className="material-symbols-outlined">add </span>
          </Button>
        </Tooltip>
      </div>

      {isActive && <AddConnectionModal isActive={isActive} handleClose={handleClose} />}
      {isCredential && <AddCredentialsModal isCredential={isCredential} handleClose={handleClose} />}
    </div>
  );
};

export default CredentialHeader;
