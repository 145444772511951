import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the type of your state

// Define the initial state
const initialState: any = {
  mobileNumber: null,
  verifyOtpFromMobile: false,
  fromUserName: false,
  verifyPayload: {
    userName: "",
    phoneNumber: "",
    isActive: "",
  },
  otpSent: false,
  referenceNumber: null,
};

// Create the slice
const MobileNumberSlice = createSlice({
  name: "forgotUsernameMobileNumber",
  initialState,
  reducers: {
    // Define reducers with correct payload types
    setMobileNumber: (state, action: PayloadAction<any>) => {
      state.mobileNumber = action.payload;
    },
    clearMobileNumber: (state) => {
      state.mobileNumber = null;
    },
    setVerifyOtpFromMobile: (state, action: PayloadAction<any>) => {
      state.verifyOtpFromMobile = action.payload;
    },
    setFromUserName: (state, action: PayloadAction<any>) => {
      state.fromUserName = action.payload;
    },
    setVerifyPayload: (state, action: PayloadAction<any>) => {
      state.verifyPayload = action.payload;
    },
    setSentOtp: (state, action: PayloadAction<any>) => {
      state.otpSent = action.payload;
    },
    setUserReferenceNumber: (state, action: PayloadAction<any>) => {
      state.referenceNumber = action.payload;
    },
  },
});

// Export actions and reducer
export const { setMobileNumber, clearMobileNumber, setVerifyOtpFromMobile, setFromUserName, setVerifyPayload, setSentOtp, setUserReferenceNumber } = MobileNumberSlice.actions;
export default MobileNumberSlice.reducer;
