import React, { useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import GroupAppList from "../edit-user/basic-details/GroupAppList";


export default function GroupsConnectedAppsTab(props: any) {

    const { userDetails, setIsDirty, checkedState, setCheckedState } = props;
    const { t } = useTranslation();

    const [isActiveCurrentTab, setIsActiveCurrentTab] = useState<string>("1");

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: `${t("userManagement.ssoApps")}`,
            children: (
                <GroupAppList setIsDirty={setIsDirty} data={userDetails?.akkuClientGroupMapDto?.userClientGroupDtoListStringMap} setCheckedState={setCheckedState} checkedState={checkedState} />
            ),
        },
        {
            key: "2",
            label: `${t("userManagement.provisioningApps")}`,
            children: (
                <GroupAppList setIsDirty={setIsDirty} data={userDetails?.akkuProvisioningGroupMapDto?.userClientGroupDtoListStringMap} setCheckedState={setCheckedState} checkedState={checkedState} />
            ),
        },
    ];

    const onChange = (key: string) => {
        setIsActiveCurrentTab(key);
    };
    return (
        <div className="userAppTabs">
            <h3 className="userAppTabs-title">{t("userManagement.groupsConnectedApps")}</h3>
            <Tabs defaultActiveKey={isActiveCurrentTab} items={items} onChange={onChange} />
        </div>
    )
}
