import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface AuthState {
    id: any;
    customerId:any;
}
const initialState: AuthState = {
    id: null,
    customerId:null
};
const orgSlice = createSlice({
    name: 'organisationId',
    initialState,
    reducers: {
        setOrgId: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        clearOrgId: state => {
            state.id = null;
        },
        setClientId:(state, action: PayloadAction<any>) => {
            state.customerId = action.payload;
        },

    },
});
export const { setOrgId, clearOrgId,setClientId } = orgSlice.actions;
export default orgSlice.reducer;