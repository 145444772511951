import React from "react";
import UserLoginTheme from "../user-authentication/LoginTheme/LoginTheme";
import { ErrorMessages } from "../../const/Messages";

function DeviceLogout() {
  const urlParams = new URLSearchParams(window.location.search);
  const agentRestart = urlParams.get("agentRestart");
  return (
    <div className="w-full">
      <div className="login-title">
        <h2>{agentRestart === "true" ? ErrorMessages?.restartAgent : ErrorMessages?.loggedOut}</h2>
      </div>
    </div>
  );
}
export default UserLoginTheme(DeviceLogout);
