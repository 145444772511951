import { Tooltip } from "antd";
import generateTitle from "../../layouts/Table/generateTitle";
import { storeData } from "../../services/storage/Storage";
import { TFunction } from "i18next";
// Common render function for text content
const riskAssessmentCommonRender = (text: any) => (
  <div>
    <Tooltip title={text}>
      <p className={`${text?.toLowerCase()} user-status text-[#3A3A3A] `}>
        <span></span> {text || "-"}
      </p>
    </Tooltip>
  </div>
);

export const formatDateTime = (dateStr: string) => {
  const [datePart, timePart] = dateStr.split(", ");
  const [_, day, month, year] = datePart.split(" ");
  const formattedDate = `${day} ${month?.charAt(0)?.toUpperCase() + month?.slice(1)?.toLowerCase()} ${year}`;
  return `${formattedDate} ${timePart}`;
};
const generateTitleOrDataIndex = (selectedTabKey: string, isTitle: boolean, t: TFunction) => {
  if (isTitle) {
    // Generate title
    if (selectedTabKey === "0") {
      return t("riskAssessment.suspiciousLoginAttempt");
    } else if (selectedTabKey === "1") {
      return t("riskAssessment.failedLoginAttempt");
    } else if (selectedTabKey === "3") {
      return t("riskAssessment.lockedLoginAttempt");
    }
  } else if (!isTitle) {
    // Generate Data index
    if (selectedTabKey === "0") {
      return "suspiciousLoginAttempt";
    } else if (selectedTabKey === "1") {
      return "failedLoginAttempt";
    } else if (selectedTabKey === "3") {
      return "lockedLoginAttempt";
    }
  }
};
const handleNameClick = (record: any, navigate: any) => {
  const currentSelectedRow = {
    name: record?.name,
    key: record?.userId,
  };
  storeData("currentSelectedRow", currentSelectedRow, true);
  navigate("/user/edit");
};
const generateColumns = ({ selectedTabKey }: any, navigate: any, tenantActiveStep: number, t: any) => {
  const columnsData = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("common.name"), // Translated Name
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      width: "auto",
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (text: any, record: any) => (
        <div onClick={tenantActiveStep > 0 ? () => handleNameClick(record, navigate) : undefined} className={false ? "cursor-default" : "cursor-pointer"}>
          <p className={`${text?.toLowerCase()} name user-name  ${tenantActiveStep > 0 ? "cursor-default" : "cursor-pointer text-[#5441da]"}`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("common.emailId"), // Translated Email ID
          sortColumns?.find(({ column }: any) => column.key === "email"),
        ),
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
      render: riskAssessmentCommonRender,
      width: "auto",
    },
    {
      title: <p className="text-[18px] text-[#3A3A3A]">{t("riskAssessment.lastSuccessfulLogin")}</p>, // Translated Last Successful Login
      dataIndex: "lastLogin",
      key: "lastLogin",
      render: (text: any) => <p className="text-[#3A3A3A]">{text}</p>,
      width: "auto",
    },
    {
      title: <p className="text-[18px] text-[#3A3A3A]">{generateTitleOrDataIndex(selectedTabKey, true, t)}</p>,
      dataIndex: generateTitleOrDataIndex(selectedTabKey, false, t),
      key: "falseAttempts",
      render: (text: any) => <p className="text-[#3A3A3A]">{text}</p>,
      width: "auto",
    },
    {
      title: <p className="text-[18px] text-[#3A3A3A]">{t("riskAssessment.details")}</p>, // Translated Details
      dataIndex: "reason",
      key: "details",
      render: (text: any) => <p className="text-[#3A3A3A]">{text}</p>,
      width: "auto",
    },
  ];

  return columnsData;
};

export default generateColumns;
