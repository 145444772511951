import React from 'react'
import './RegisterForm.scss'
import ValidateOTP from './ValidateOTP'

const VerifyEmail = () => {
  return (
      <>
   <div className="w-full flex new register">
    <div className="w-[60%]">
        <div className="verify-otp">
            
        </div>
    </div>
    <div className="w-[40%]">
      <ValidateOTP/>
    </div>
   </div>
   </>
  )
}

export default VerifyEmail