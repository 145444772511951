import React from "react";
import { getPermissionStyle } from "../../../redux/slice/permissions/permissionUtils";
import { Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useAccessManager } from "../context/AccessManagerContext";
import { useTranslation } from "react-i18next";

export default function AccessManagerContainerHeader({ accessManagerList }: any) {
  const { t } = useTranslation();

  const accessManagerPermissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const accessManagerCreate = accessManagerPermissions["Access Manager"]?.create;
  const { openCreateAccessManager } = useAccessManager();

  return (
    <div className="header-content flex justify-between items-center">
      <div>
        <h2> {t("accessManager.accessManager")}</h2>
        <p>{t("accessManager.enableControl")}</p>
      </div>
      {accessManagerList?.length > 0 && (
        <div className="">
          <Tooltip title={t("accessManager.createRestrictions")}>
            <Button type="primary" className={`accessManger-addBtn bg-[#5441DA] ${getPermissionStyle(accessManagerCreate)}`} onClick={openCreateAccessManager}>
              <span className="material-symbols-outlined">add </span>
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
