import { SortOrder } from "antd/lib/table/interface";
import generateTitle from "../../../../../layouts/Table/generateTitle";
import { TFunction } from "i18next";

export const addGroupColumns = (t: TFunction) => {
  return [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("userManagement.name"), // Translation for 'Name'
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      width: "30%",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (text: any, record: any) => (
        <div>
          <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[#5441da] text-[16px] `}>{text || "-"}</p>
        </div>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("userManagement.email"), // Translation for 'Email'
          sortColumns?.find(({ column }: any) => column.key === "email"),
        ),
      dataIndex: "email",
      key: "email",
      width: "70%",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
      render: (text: any, record: any) => (
        <div>
          <p className={`${text?.toLowerCase()} name cursor-pointer  font-Inter text-[16px] text-[#3A3A3A] `}>{text || "-"}</p>
        </div>
      ),
    },
  ];
};
