import { images } from '../../../../../../assets/images/ImagePaths';

export interface DataType {
  key: React.Key;
  name: string;
  email: string;
  service: string;
  status: string;
}
interface RecordData {
  id: number;
  name: string;
}

export type UserRecordType = number | RecordData;

export const tableData: DataType[] = [
  {
    key: 1,
    name: `Edward King`,
    email: 'ranjith@cloudnowtech.com',
    status: 'active',
    service: `London, Park Lane no.`,
  },
];

export const avatars = [
  { id: 1, title: 'Jira', img: images.jira },
  { id: 2, title: 'Jira', img: images.jira },
  { id: 3, title: 'Jira', img: images.jira },
  { id: 4, title: 'Jira', img: images.jira },
];

export const customFilters = [
  { text: 'All', value: 'all' },
  { text: 'Active', value: 'active' },
  { text: 'Inactive', value: 'inactive' },
  { text: 'Deleted', value: 'deleted' },
  { text: 'Disabled', value: 'disabled' },
];

export const groupFilterList = [
  { text: 'Campus', value: 'campus' },
  { text: 'Faculty', value: 'faculty' },
  { text: 'Staff', value: 'staff' },
  { text: 'Without Groups', value: 'withoutGroups' },
];

export const generateClassName = (hasUpdatePermission: boolean, userName: string | undefined, currentRowEmail: string): string => {
  return `${!hasUpdatePermission || userName === currentRowEmail ? 'disabledGroup' : 'cursor-pointer'}`;
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
