import React, { useEffect, useState } from "react";
import './Register.scss'
import PlansAndProductsHeader from "./layout/PlansAndProductsHeader";
import OurPlans from "./plans/OurPlans";
import OurPlanLists from "./plans/OurPlanLists";
import OurProductsList from "./plans/product/OurProductsList";


const Register = () => {
  const [viewCategory,setViewCategory]=useState<boolean>(false);
  const [viewPlans,setViewPlans]=useState<boolean>(false);

  useEffect(()=>{
setViewPlans(viewCategory)

  },[viewCategory])
  return (
    <>
      <div className="w-full flex flex-col  h-[100vh]">
        {/* <StartTrial /> */}
        <div className="flex w-full h-full">
          <div className="register-container w-full">
            <PlansAndProductsHeader/>
            <OurPlans setViewCategory={setViewCategory}/>
            {
              viewPlans?  <OurProductsList/>: <OurPlanLists/>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
