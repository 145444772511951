import { Steps } from "antd";
import React, { useEffect, useState } from "react";
import BasicDetailsForm from "../form/BasicDetailsForm";
import ModalHeader from "../components/ModalHeader";
import { staticMessage } from "../../../../../../constant/StaticContent";
import "./AddUserModal.scss";

const Step = Steps.Step;
const AddUserModal = (props: any) => {
  const { handelModelClose, addUserType } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    userType: "User",
    phone: "",
    verifyEmail: "",
  });
  const handleClose = () => {
    handelModelClose(currentStep);
  };
  useEffect(() => {
    if (currentStep === 3) {
      handleClose();
    }
  }, [currentStep]);
  const handleToBack = () => {
    if (currentStep === 1) {
      setCurrentStep(0);
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  };
  const stepsItems = [{ title: "" }, { title: "" }];
  return (
    <div className="p-5 add-user rounded-lg">
      <div className="modal-header" data-testid="modelHeaderId">
        <ModalHeader currentStep={currentStep} handleToBack={handleToBack} addUserType={addUserType} />
      </div>
      <div className="modal-body p-8">
        <div className="w-full flex justify-center pt-6">
          <div className="w-[33%] add-user-stepper">
            <div className="w-full">
              <Steps current={currentStep} className="custom-steps" items={stepsItems} />
              <div className="w-full flex justify-between">
                <p className="relative left-[-30px] pt-3 text-[#444444] text-lg" data-testid="singleMessaageID">
                  {staticMessage.singleUserMessage.Title}
                </p>
                <p className="relative right-[-30px] pt-3 text-[#444444] text-lg" data-testid="UserMessageId">
                  {staticMessage.userTab.tabTow}
                </p>
              </div>
            </div>
          </div>
        </div>
        {currentStep === 0 && <BasicDetailsForm initialValues={initialValues} setInitialValues={setInitialValues} setCurrentStep={setCurrentStep} handelModelClose={handelModelClose} />}
      </div>
    </div>
  );
};
export default AddUserModal;
