import { images } from "../../../assets/images/ImagePaths";
import { ErrorMessages } from "../../../const/Messages";
import { triggerNotification } from "../../../layouts/toast/ToastBar";
import { retrieveData, storeData } from "../../../services/storage/Storage";

export const handleNotification = (record: any, checked: boolean) => {
  let message = "";
  if (record === "IP") {
    message = checked ? ErrorMessages?.IPRestrictionEnabled : ErrorMessages?.IPRestrictionDisabled;
  } else {
    message = checked ? ErrorMessages?.TimeRestrictionEnabled : ErrorMessages?.TimeRestrictionDisabled;
  }
  triggerNotification("success", "", message, "topRight");
};

export const customSortByIp = function (a: any, b: any) {
  const classificationOrder: any = { a: 1, b: 2, c: 3 };
  return classificationOrder[a?.classification] - classificationOrder[b?.classification];
};

export const toggleIPPayloadFormation = (record: any, checked: boolean) => {
  const realmId = retrieveData("realmId", true);
  const transformedData = record?.ipInfo?.map((item: any) => ({
    ipRange: item.ipRange,
    newIpInfo: item.ipInfo,
    oldIpInfo: item.ipInfo,
    ipInfoV6: item.ipInfoV6,
  }));

  const payload: any = {
    akkuUserIpBasedAuthorizationDto: {
      name: record.name,
      description: record.description,
      authorizationType: record.authorizationType,
      ipInfos: transformedData,
      active: checked,
      realmId: realmId,
    },
    ipRestrictionDashboardDto: {
      realmId: realmId,
      authorizationType: record.authorizationType,
      status: checked,
      userRestricted: record.userRestricted,
      groupRestricted: record.groupRestricted,
      organisationRestriction: record.organisationRestriction,
    },
    addIpRestriction: [],
    addIpAddress: [],
    removeIpAddress: [],
    removeIpRestriction: [],
    updateIpInfos: transformedData,
  };
  if (record.userEntityIdOrGroupId[0]) {
    payload.akkuUserIpBasedAuthorizationDto[!record.groupRestricted ? "userEntityId" : "keycloakGroupId"] = record.userEntityIdOrGroupId;
  } else {
    payload.akkuUserIpBasedAuthorizationDto["keycloakGroupId"] = [];
  }
  payload.ipRestrictionDashboardDto.akkuUserIpBasedAuthorizationIds = record?.akkuUserIpBasedAuthorizationIds;

  return payload;
};

export const toggleTimePayloadFormation = (response: any, record: any, isActive: any) => {
  const realmId = retrieveData("realmId", true);

  const payload = {
    userTimeBasedAuthorizationDto: {
      name: record.name,
      description: record.description,
      isActive: isActive,
      realmId: realmId,
      akkuUserTimeBasedAuthorizationId: record.akkuUserTimeBasedAuthorizationId,
    },
    userTimeBasedAuthorizationRestrictionKvDto: response?.data?.timeBasedAuthorization?.userTimeBasedAuthorizationRestrictionKvDtoList,
  };
  return payload;
};

export const getIpDetailsConvertedData = async (data: any) => {
  let assignTo: string;
  if (data.userGroupRestrictions[0]?.keycloakGroupId !== null && data.userGroupRestrictions[0]?.keycloakGroupId !== "") {
    assignTo = "groups";
  } else if (data.userGroupRestrictions[0]?.userEntityId !== null && data.userGroupRestrictions[0]?.userEntityId !== "") {
    assignTo = "users";
  } else {
    assignTo = "organization";
  }

  const convertData: any = {
    name: data?.name || "",
    description: data?.description || "",
    authorizationType: data?.authorizationType || "Whitelist",
    ipDetails: data.ipInfos.map((item: any, index: any) => {
      let ip = item.ipInfo;
      let ipRange = "exactIp";
      if (item.ipRange) {
        if (ip.endsWith(".0.0/16")) {
          ipRange = "range16";
          ip = ip.replace(".0.0/16", "");
        } else if (ip.endsWith(".0/24")) {
          ipRange = "range24";
          ip = ip.replace(".0/24", "");
        }
      }
      return {
        ipRange: ipRange,
        IPv4: ip,
        id: index,
        IPv6: item.ipInfoV6 || "",
      };
    }),
    assignTo: assignTo, // Set this value as needed
    active: data.isActive,
  };

  convertData.userGroupRestrictions = data?.userGroupRestrictions;

  return convertData;
};

export const accessManagerListFormation = (mappingData: any) => {
  const reformedArray = [];

  mappingData?.userTimeBasedAuthorizationKvDtoList?.forEach((item: any) => {
    reformedArray?.push({
      name: item?.name,
      description: item?.description,
      type: images?.timer,
      status: item?.status,
      assigned: item?.assignedTo,
      classification: "c", //time
      akkuUserTimeBasedAuthorizationId: item?.akkuUserTimeBasedAuthorizationId,
      akkuUserTimeBasedAuthorizationIdList: item?.akkuUserTimeBasedAuthorizationIdList,
    });
  });

  if (mappingData?.userDeviceRestrictionDetailsKvDto) {
    storeData("deviceRestrictionUsersCount", mappingData?.userDeviceRestrictionDetailsKvDto?.assignedUserCount, true);
    reformedArray?.push({
      name: mappingData.userDeviceRestrictionDetailsKvDto?.name,
      description: mappingData?.description,
      type: images?.devices,
      status: mappingData?.userDeviceRestrictionDetailsKvDto?.status,
      assigned: mappingData?.userDeviceRestrictionDetailsKvDto?.assignedTo,
      classification: "b", //device
    });
  } else {
    storeData("deviceRestrictionUsersCount", null, true);
  }

  mappingData?.ipRestrictionDashboardResponses?.forEach((element: any) => {
    let assigned: string;

    if (element?.userRestricted) {
      assigned = `User(${element?.count})`;
    } else if (element?.groupRestricted) {
      assigned = `Group(${element?.count})`;
    } else if (element?.organisationRestriction) {
      assigned = "All";
    } else {
      assigned = "-";
    }

    reformedArray?.push({
      ...element,
      classification: "a", // ip
      type: images.ip,
      assigned: assigned,
    });
  });
  reformedArray?.sort(customSortByIp);

  return reformedArray;
};
