import { Button, Table, Tooltip, Switch, TableColumnsType } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
const AccessManagerContainerTable = (props: any) => {
  const {
    accessManagerList,
    accessManagerCreate,
    getPermissionStyle,
    handleClickRestrictionName,
    onChange,
    handleDeleteRestrictionPopup,
    openCreateAccessManager,
  } = props.props;
  const { t } = useTranslation();

  const handleTypes = (e: string) => {
    if (e === "a") {
      return `${t("accessManager.ipRestriction")}`;
    } else if (e === "b") {
      return `${t("accessManager.deviceRestriction")}`;
    } else if (e === "c") {
      return `${t("accessManager.timeRestriction")}`;
    }
  };
  interface RestrictionRecord {
    name: string;
    description: string;
    classification: string;
  }
  const columns: TableColumnsType<RestrictionRecord> = [
    {
      title: `${t("common.name")}`,
      dataIndex: "name",
      key: "name",
      render: (text: string, record: RestrictionRecord) => {
        return (
          <div role="none" onClick={() => handleClickRestrictionName(record)} className="cursor-pointer">
            <div className="accessMangerTable-bg">
              <div className="accessMangerTable-name">
                <h2 className="text-[#5441da]">{record.name}</h2>
                <p>{record.description}</p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: `${t("accessManager.type")}`,
      dataIndex: "type",
      key: "type",
      render: (type: string, record: RestrictionRecord) => (
        <div className="accessMangerTable-bg">
          <i className="accessMangerTable-icon">
            <Tooltip title={handleTypes(record.classification)} placement="top">
              <img src={type} alt={type} />
            </Tooltip>
          </i>
        </div>
      ),
    },
    {
      title: `${t("common.status")}`,
      dataIndex: "status",
      key: "status",
      render: (status: boolean, record: RestrictionRecord) => (
        <div className="accessMangerTable-bg">
          <span className="accessMangerTable-switch">
            <Switch
              disabled={record?.classification === "b"}
              checked={status}
              onChange={(status) => {
                onChange(status, record);
              }}
            />
          </span>
        </div>
      ),
    },
    {
      title: `${t("accessManager.assigned")}`,
      dataIndex: "assigned",
      key: "assigned",
      render: (assigned: string) => (
        <div className="accessMangerTable-bg">
          <span className="accessMangerTable-assigned">{assigned !== "" ? <span>{assigned}</span> : "-"}</span>
        </div>
      ),
    },
    {
      title: `${t("common.action")}`,
      dataIndex: "action",
      render: (text: string, record: RestrictionRecord) => (
        <Button disabled={record?.classification === "b"} className="border-0 btn-disabled">
          <span
            role="none"
            className="material-icons-outlined"
            onClick={() => {
              handleDeleteRestrictionPopup(record);
            }}
          >
            delete
          </span>
        </Button>
      ),
    },
  ];
  return (
    <div className="accessManger mt-3">
      <div className="accessManger-contents " data-testid="accessManager-table">
        {accessManagerList?.length > 0 ? (
          <Table columns={columns} dataSource={accessManagerList} pagination={false} />
        ) : (
          <div className="header-content no_data">
            <div>
              <img src="/no_data.png" alt="nodata" />
            </div>
            <div>
              <p> {t("accessManager.noRulesDefined")} </p>
            </div>

            <button type="button" className={`c-new bg-[#5441DA] ${getPermissionStyle(accessManagerCreate)}`} onClick={openCreateAccessManager}>
              {t("accessManager.createNew")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessManagerContainerTable;
