export const cardData = [
  {
    id: 1,
    count: 35,
    icon: "indeterminate_question_box",
    label: "Suspicious Login",
  },
  {
    id: 2,
    count: 55,
    icon: "feedback",
    label: "Failed Login",
  },
  {
    id: 3,
    count: 87,
    icon: "account_circle_off",
    label: "Unused Licenses",
  },
  {
    id: 4,
    count: 88,
    icon: "lock",
    label: "Locked Accounts",
  },
];