import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function CSVUploadHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getSampleUserCSVUrl: string = process.env.REACT_APP_SAMPLE_USER_IMPORT ? process.env.REACT_APP_SAMPLE_USER_IMPORT : "";

  const handleDownload = () => {
    window.location.href = getSampleUserCSVUrl;
  };
  const redirectToUser = () => {
    navigate("/user");
  };
  return (
    <div className="flex justify-between items-center">
      <div className="csv-upload-header">
        <h2 className="flex items-center csv-upload-header-title">
          <button onClick={redirectToUser} className="material-icons-outlined  text-[#1C1B1F] cursor-pointer arrow_upward" data-testid="buttonId">
            arrow_upward
          </button>
          <span className="ml-3"> {t("userManagement.csvUpload.csvUpload")}</span>
        </h2>
        <p className="mt-2 pl-9">{t("userManagement.csvUpload.csvUploadData")}</p>
      </div>
      <button className="flex items-center sample-download" onClick={handleDownload}>
        <span className="material-symbols-outlined">download</span>
        <p className="upload-label">{t("userManagement.csvUpload.sampleFile")}</p>
      </button>
    </div>
  );
}
