const fr = {
  welcome: "Bienvenue ",
  lastLogin: "Dernière connexion",
  editDetailsPrompt: "Si vous souhaitez modifier les détails, cliquez sur le bouton pour modifier.",
  details: "Détails",
  active: "Actif",
  inActive: "Inactif",
  menu: {
    smartAnalytics: "Analyse intelligente",
    userManagement: "Gestion des utilisateurs",
    appManagement: "Gestion des applications",
    roleManagement: "Gestion des rôles",
    accessManager: "Gestionnaire d’accès",
    passwordManager: "Gestionnaire de mots de passe", // NOSONAR: This is not a credential, just a label
    credentialManagement: "Gestion des justificatifs",
    deviceManagement: "Gestion des appareils",
    currentVersion: "Version actuelle",
    adaptiveMfa: "MFA adaptative",
    userAuditLogs: "Journaux d'audit",
  },
  termsAndConditions: {
    termsAndConditions: "Termes et conditions",
    iHaveReadAgree: "J'ai lu et j'accepte les",
    iAcceptTermsAndConditions: "J'accepte les termes et conditions",
  },
  auditLogs: {
    downloadInfo: "Définir un filtre pour afficher et télécharger le rapport",
    deleteActivityLogConfirmation: "Êtes-vous sûr de vouloir supprimer le journal d'activité de l'utilisateur ?",
    downloadUserAuditLogs: "Télécharger le journal d'audit des utilisateurs",
    userAuditLogs: "Journaux d'audit des utilisateurs",
    actionType: "Type d'action",
    auditLogs: "Journaux d'audit",
    dateRange: "Plage de dates",
    startDate: "Date de début",
    endDate: "Date de fin",
    totalRecordCount: "Nombre total d'enregistrements",
    dateAndTime: "Date et heure",
    activityLogs: "Journaux d'activité",
    device: "Appareil",
    ipAddress: "Adresse IP",
    userType: "Type d'utilisateur",
    userTypeValidation: "Sélectionnez le type d'utilisateur",
  },
  adaptiveMfa: {
    adaptiveMfa: "Adaptive  MFA",
    auditLogs: "User Audit Log",
    touchId: "Touch ID",
    authenticator: "Authentificateur",
    faceId: "Identification faciale",
    primarySettings: "Paramètres principaux",
    secondarySettings: "Paramètres secondaires",
    enableMultipleOptionsInfo: "Vous pouvez activer une ou plusieurs options parmi les facteurs ci-dessous.",
    enableSingleOptionsInfo: "Vous ne pouvez activer qu'une seule option parmi les facteurs ci-dessous.",
    enableAMFA: "Activer AMFA",
    amfaEnabledInfo:
      "Si activé, l'authentification multi-facteurs adaptative sera active. Vous pouvez choisir plusieurs options pour sécuriser votre compte.",
    pushNotification: "Notification push",
    emailOtp: "OTP par email",
    smsOtp: "OTP par SMS",
    changesWillBeLost: "Tous vos changements seront perdus",
    confirmCancel: "Souhaitez-vous vraiment annuler ?",
    emailOtpDescription: "Si activé, un mot de passe à usage unique (OTP) sera envoyé à votre adresse email enregistrée pour l'authentification.",
    smsOtpDescription: "Si activé, un mot de passe à usage unique (OTP) sera envoyé à votre numéro de mobile enregistré pour la vérification.",
    pushNotificationDescription:
      "Si activé, une notification sera envoyée à votre appareil mobile via l'application Akku pour approbation lors de la connexion ou en cas d'activité suspecte.",
    touchIdDescription:
      "Si activé, vous pouvez utiliser l'authentification par empreinte digitale depuis votre appareil mobile pour vous connecter en toute sécurité ou vérifier une activité.",
    faceIdDescription:
      "Si activé, vous pouvez utiliser l'authentification par reconnaissance faciale depuis votre appareil mobile pour vous connecter en toute sécurité ou vérifier une activité.",
  },
  appManagement: {
    remove: "Retirer",
    removing: "Suppression",
    deleteAppsWarning:
      "révoquera les autorisations d'accès des utilisateurs. Cependant, vous pouvez le reconfigurer via les applications disponibles.",
    singleSignOn: "Authentification unique",
    setup: "Installation",
    emptyCardInformation: `Les applications configurées à partir de "Applications disponibles" seront affichées ici`,
    appManagement: "Gestion des applications",
    adminCredentials: "Identifiants administratifs",
    apiSecret: "Secret API",
    mappingGroups: "Groupes de mapping",
    capabilityConfig: "Configuration des capacités",
    apiKey: "Clé API",
    uploadFile: "Télécharger le fichier",
    delegatedUser: "Utilisateur délégué",
    idpInitiatedSsoUrlName: "Nom de l'URL SSO initiée par l'IDP",
    idpInitiatedSsoRelayState: "État de relais SSO initié par l'IDP",
    enterDescription: "Entrez la description",
    appName: "Nom de l'application",
    configurationDocument: "Document de configuration",
    copied: "Copié",
    immediate: "Immédiate",
    activateProvision: "Activer le provisionnement",
    noteStartProvisioningEngine: "Remarque : ci-dessous, vous pouvez spécifier quand démarrer le moteur de provisionnement.",
    selectTheOption: "Sélectionnez l'option",
    provisioningEngineDeactivated: "Moteur de provisionnement désactivé avec succès.",
    provisioningEngineActivated: "Moteur de provisionnement activé avec succès.",
    deleting: "Suppression",
    requestDeactivateProvisioning: "Veuillez désactiver le provisionnement pour accéder à l'option de suppression.",
    requestActivateProvisioningEdit: "Veuillez désactiver le provisionnement pour accéder à l'option de modification.",
    requestConfigureProvisioning: "Veuillez compléter le provisionnement.",
    requestActivateProvisioning: "Veuillez compléter le connecteur de provisionnement pour l'activer.",
    editLowerCase: "modifier",
    configureLowerCase: "configurer",
    configure: "Configurer",
    myApps: "Mes applications",
    appStore: "App Store",
    sso: "SSO",
    provisioningLabel: "Approvisionnement",
    configureSso: "Configurer SSO",
    configureProvisioning: "Veuillez configurer le provisionnement.",
    publishedApps: "Applications publiées",
    published: "Publié",
    duplicate: "Dupliquer",
    publish: "Publier",
    edit: "Modifier",
    delete: "Supprimer",
    whatYouLikeToDo: "Que voudriez-vous ",
    removingWillRevokeUserAccess:
      "La suppression révoquera les autorisations d'accès des utilisateurs. Cependant, vous pouvez le reconfigurer via les applications disponibles.",
    pleaseConfigureSso: "Veuillez configurer SSO.",
    pleaseDeactivateProvisioning: "Veuillez désactiver le provisionnement pour continuer la modification.",
    areYouSureYouWantToDelete: "Êtes-vous sûr de vouloir supprimer le provisionnement et le connecteur SSO ?",
    pleaseCompleteProvisioning: "Veuillez compléter le provisionnement.",
    openIdEndpointConfiguration: "Configuration de point de terminaison OpenId",
    saml2IdentityProviderMetadata: "Métadonnées du fournisseur d’identité SAML 2.0",
    copy: "Copie",
    viewCertificate: "Voir le certificat",
    samlDescriptor: "Descripteur SAML",
    applicationType: "Type d'application",
    saml: "SAML",
    openId: "OpenID",
    cas: "CAS",
    generalSettings: "Paramètres généraux",
    loginSettings: " Paramètres de connexion",
    advancedSettings: "Paramètres avancés",
    clientType: "Type de client",
    clientId: "Type de client",
    rootUrl: "URL racine",
    homeUrl: "URL de la page d’accueil",
    validRedirectUrl: "URL de redirection valide",
    addValidRedirectUrls: "Ajouter des URL de redirection valides",
    validRedirectLogoutUrl: "URL de déconnexion valide",
    masterSamlProcessingUrl: "URL de traitement SAML maître",
    assertionConsumerServicePostBindingUrl: "Assertion Consumer Service POST Binding URL",
    assertionConsumerServiceRedirectBindingUrl: "Assertion Consumer Service Redirect Binding URL",
    clientSecret: "Secret client",
    regenerate: "Régénérer",
    clientAuthentication: "Authentification client",
    authorization: "Autorisation",
    authenticationFlow: "Flux d'authentification",
    standardFlow: "Débit standard",
    implicitFlow: "Flux implicite",
    oauth2DeviceAuthorizationGrant: "Octroi d'autorisation de dispositif OAuth 2.0",
    directAccessGrants: "Octrois d'accès direct",
    oidcCibaGrant: "Octroi OIDC CIBA",
    serviceAccountRoles: "Rôles de compte de service",
    validPostLogoutRedirectUrl: "URL de redirection du compte valide après la fermeture de session",
    addValidPostLogoutRedirectUrl: "Ajouter des URL de redirection de déconnexion valides",
    webOrigin: "Origine web",
    frontChannelLogoutUrl: "URL de déconnexion du canal principal",
    clientTypeDescription:
      "OpenID Connect permet aux clients de vérifier l’identité de l’utilisateur final en fonction de l’authentification effectuée par un serveur d’autorisation",
    clientIdDescription:
      "Spécifie l’ID référencé dans l’URI et les jetons. Par exemple, my-client. Pour le SAML, c’est également la valeur de l’émetteur attendue des demandes authn",
    rootUrlDescription: "URL racine ajoutée aux URLs relatives",
    homeUrlDescription: "URL par défaut à utiliser lorsque le serveur d’authentification doit rediriger ou renvoyer au client.",
    validRedirectUrlDescription:
      "Un modèle d’URI valide vers lequel un navigateur peut rediriger après une connexion réussie. Des caractères génériques simples sont autorisés, par exemple http://example.com/*. Le chemin relatif peut également être spécifié, par exemple /my/relative/path/*. Les chemins relatifs sont relatifs à l’URL racine du client, ou si aucun n’est spécifié, l’URL racine de l’authentification serveur est utilisée. Pour SAML, vous devez définir des modèles d’URI valides si vous comptez sur l’URL du service client intégrée à la demande de connexion.",
    validRedirectLogoutUrlDescription:
      "Un modèle d’URI valide vers lequel un navigateur peut rediriger après une fermeture de session réussie. Une valeur + ou un champ vide utilisera la liste des URI de redirection valides. Une valeur de - ne permet pas d’URI de redirection après la déconnexion. Des caractères génériques simples sont autorisés, comme http://example.com/*. Le chemin relatif peut également être spécifié, par exemple /my/relative/path/*. Les chemins relatifs sont relatifs à l’URL racine du client, ou si aucun n’est spécifié, l’URL racine de l’authentification serveur est utilisée",
    assertionConsumerServicePostUrlDescription:
      "Spécifie l'ID référencé dans l'URI et les tokens. Par exemple my-client. Pour SAML, c'est également la valeur d'émetteur attendue dans les demandes d'authentification.",
    assertionConsumerServiceRedirectUrlDescription:
      "Spécifie l'ID référencé dans l'URI et les tokens. Par exemple my-client. Pour SAML, c'est également la valeur d'émetteur attendue dans les demandes d'authentification.",
    clientAuthenticationDescription:
      "Cela définit le type de client OIDC. Lorsqu'il est activé, le type OIDC est défini sur le type d'accès confidentiel. Lorsqu'il est désactivé, il est défini sur le type d'accès public.",
    authorizationDescription: "Activer/Désactiver le support d'autorisation fine pour un client.",
    webOriginDescription:
      "Origines CORS autorisées. Pour autoriser toutes les origines des URI de redirection valides, ajoutez + Ceci n’inclut pas le caractère générique * cependant. Pour permettre toutes les origines, ajouter explicitement",
    idpUrlDescription:
      "Nom du fragment d'URL pour référencer le client lorsque vous souhaitez effectuer un SSO initié par l'IDP. Laisser ce champ vide désactivera le SSO initié par l'IDP. L'URL que vous référencerez depuis votre navigateur sera {server-root}/realms/{realm}/protocol/saml/clients/{client-url-name}.",
    idpSsoDescription: "État de relais que vous souhaitez envoyer avec la demande SAML lorsque vous souhaitez effectuer un SSO initié par l'IDP.",
    masterSamlDescription:
      "Si configurée, cette URL sera utilisée pour chaque liaison à la fois pour le SP en tant que consommateur d'assertions et pour les services de déconnexion unique. Cela peut être remplacé individuellement pour chaque liaison et service dans la configuration fine des points de terminaison SAML.",
    ssoErrors: {
      clientIdRequired: "L'ID client est requis.",
      masterSamlProcessingUrlRequired: "L'URL de traitement SAML maître est requise.",
      validRedirectUrlRequired: "Une URL de redirection valide est requise",
      validRedirectLogoutUrlRequired: "L'URL de redirection valide après déconnexion est requise.",
      assertionConsumerUrlRedirectRequired: "Assertion Consumer Service Redirect Binding URL est requis",
      assertionConsumerUrlPostRequired: "L'URL de liaison POST du service de consommation d'assertions est requise.",
      baseUrlRequired: "L'URL de base est requise.",
      rootUrlRequired: "L'URL racine est requise.",
      invalidUrl: "URL invalide.",
      clientAuthenticationRequired: "L'authentification du client est requise.",
      serviceAccountsEnabledRequired: "L'activation des comptes de service est requise.",
      authorizationRequired: "L'autorisation est requise.",
      directAccessGrantsRequired: "Les octrois d'accès direct sont requis.",
      standardFlowRequired: "Le flux standard est requis.",
      implicitFlowRequired: "Le flux implicite est requis.",
      deviceAuthorizationGrantEnabledRequired: "L'activation de l'octroi d'autorisation de dispositif est requise.",
      oidcCibaGrantEnabledRequired: "L'activation de l'octroi OIDC CIBA est requise.",
      webOriginsRequired: "Les origines web sont requises.",
      frontChannelLogoutUrlRequired: "Il faut une URL de déconnexion du canal principal",
      apiKeyRequired: "La clé API est requise.",
      apiSecretRequired: "La clé secrète API est requise.",
      sourceRequired: "La source est requise.",
      targetRoleRequired: "Le rôle cible est requise.",
    },
    provisioning: {
      role: "Rôle",
      group: "Groupe",
      user: "Utilisatrice",
      reviewMappingLabel: "Cartographie des avis",
      queryDetectEnginePerformanceProblem: "Interroger le problème de performance du moteur de détection",
      connector: "Connecteur",
      pleaseSelectSourceConnector: "Veuillez sélectionner le connecteur source",
      key: "Clé",
      value: "Valeur",
      connectorDetails: "Détails du connecteur",
      generalDetails: "Details généraux",
      authentication: "Authentification",
      provisioning: "Approvisionnement",
      deprovisioning: "Déprovisionnement",
      selectYourTargetApps: "Sélectionnez vos applications cibles",
      mapping: "Cartographie",
      attributes: "Attributs",
      groups: "Groupes",
      roles: "Rôles",
      connectorName: "Nom du connecteur",
      description: "Description",
      serviceProviderDomain: "Domaine du fournisseur de services",
      connectorType: "Type de connecteur",
      authenticationType: "Type d'authentification",
      tokenType: "Type de jeton",
      basicAuth: "Authentification de base",
      bearerToken: "Jeton Bearer",
      database: "Base de données",
      oAuth2: "OAuth 2.0",
      restApi: "API REST",
      serviceToken: "Service Token",
      apiToken: "Jeton API",
      oidc: "OIDC",
      connectorDetailsGeneral: "Détails du connecteur - Détails généraux",
      connectorDetailsAuthentication: "Détails du connecteur - Authentification",
      tokenApiEndpointUrl: "Token API EndPoint Url",
      administratorUsernameEmail: "Nom d’utilisateur / Email de l’administrateur",
      scope: "Portée",
      tokenApiMethodType: "Token API Method Type",
      usernameEmail: "Nom d’utilisateur / Email",
      username: "Nom d'utilisateur",
      grantType: "Type de subvention",
      clientSecret: "Secret client",
      clientId: "ID client",
      password: "Mot de passe", // NOSONAR: This is not a credential, just a label
      connectorDetailsProvisioning: "Détails du connecteur - Provisionnement",
      userProvisioning: "Approvisionnement des utilisateurs",
      roleProvisioning: "Provisionnement de rôle",
      groupProvisioning: "Provisionnement de groupe",
      apiEndpointUrl: "URL de l’API Endpoint",
      methodType: "Type de méthode",
      post: "Poste",
      get: "Obtenir",
      requestPayload: "Demande de charge utile",
      response: "Réponse",
      responseAttributesMapping: "Mapping des attributs de réponse",
      requestParameters: "Paramètres de la demande",
      pathVariable: "Variable de chemin",
      responseAttributesMappingId: "ID",
      responseAttributesMappingName: "Nom",
      getRole: "Obtenir le rôle",
      assignRole: "Attribuer le rôle",
      getGroup: "Obtenir le groupe",
      assignGroup: "Affecter le groupe",
      methodTypeDeprovisioning: "Type de méthode",
      connectorDetailsDeprovisioning: "Détails du connecteur - Déprovisionnement",
      userDeprovisioning: "Déprovisionnement d'utilisateur",
      roleDeprovisioning: "Déprovisionnement de rôle",
      groupDeprovisioning: "Déprovisionnement de groupe",
      selectYourConnector: "Sélectionnez votre connecteur",
      selectSource: "Sélectionner la source",
      akkuuConnector: "Connecteur Akku",
      connected: "Connecté",
      descriptionAkkuConnector:
        "Le connecteur Akku provisionne des utilisateurs d'Akku vers des applications cibles, gérant les comptes, les autorisations et les données pour un accès fluide et sécurisé.",
      selectTarget: "Sélectionner la cible",
      selectConnectors: "Sélectionner des connecteurs",
      mappingAttributes: "Mapping - Attributs",
      sourceAkku: "SOURCE - Akku",
      target: "CIBLE",
      mappingGroups: "Mapping - Groupes",
      mappingRoles: "Mapping - Rôles",
      finalReviewUserData: "Revue finale - Données utilisateur",
      reviewMapping: "Veuillez examiner le mapping ci-dessous et confirmer les modifications si nécessaire",
      id: "Identifiant",
      finalReview: "Revue finale",
      errors: {
        requiredConnectorName: "Le nom du connecteur est requis",
        requiredDescription: "La description est requise",
        requiredTokenType: "Le type de token est requis",
        requiredConnectorType: "Le type de connecteur est requis",
        requiredAuthType: "Type d’authentification requis",
        tokenApiEndPointUrl: "URL du point de terminaison de l'API de token",
        usernameRequired: "Le nom d'utilisateur est requis",
        passwordRequired: "Le mot de passe est requis", // NOSONAR: This is not a credential, just a label
        grantTypeRequired: "Le type de subvention est requis",
        clientIdRequired: "L'ID du client est requis",
        clientSecretRequired: "Le secret du client est requis",
        serviceToken: "Token de service",
        adminUsernameEmailRequired: "Le nom d'utilisateur / l'email de l'administrateur est requis",
        apiTokenRequired: "Un jeton API est requis",
        usernameEmailRequired: "Nom d'utilisateur / Email requis",
        invalidUrlFormat: "Format d'URL invalide",
        urlRequired: "L'URL est requise",
        tokenApiEndpoint: "L'URL du point de terminaison de l'API de token est requise",
        json: "L'entrée doit être une chaîne JSON valide",
        plainString: "L'entrée doit être une chaîne, pas JSON ou d'autres formats",
        primaryKeyAttributeList: "Entrez une PrimaryKeyAttributeList valide",
        apiEndpointUrlRequired: "L’URL de l’API Endpoint est requise",
        methodTypeRequired: "Le type de méthode est requis",
        requestPayloadRequired: "Le payload de la requête est requis",
        responseRequired: "La réponse est requise",
        serviceTokenRequired: "Un jeton de service est requis",
        validId: "Entrez un ID valide",
        validName: "Entrez un nom valide",
        sourceRequired: "Veuillez sélectionner la valeur source requise",
        targetRequired: "Veuillez sélectionner la valeur cible requise",
        pleaseSelectRequiredValue: "Veuillez sélectionner la valeur requise",
      },
    },
  },
  accessManager: {
    deviceManagerEmailConfirmMessage:
      "Un e-mail sera envoyé aux utilisateurs sélectionnés avec des instructions sur la façon d'installer le service de gestion de périphériques.",
    userLowerCase: "utilisatrice",
    clearSelection: "Effacer la sélection",
    deleteUsers: "Supprimer les utilisateurs",
    deleteUsersConfirmation: "Êtes-vous sûr de vouloir supprimer le(s) utilisateur(s) de la restriction de dispositif ?",
    groupsLowerCase: "groupes",
    usersLowerCase: "useutilisateursrs",
    totalNoOf: "Nombre total de",
    group: "Groupe",
    unassignGroups: "Désattribuer le(s) groupe(s)",
    unassignUsers: "Désattribuer le(s) utilisateur(s)",
    assignedTo: "Attribué à",
    blacklistIp: "IP sur liste noire",
    accessManager: "Gestionnaire d’accès",
    enableControl: "Vous permet de contrôler comment vos applications et données sont accessibles.",
    createRestrictions: "Créer des restrictions",
    type: "Taper",
    assigned: "Assigné",
    action: "Action",
    controlAccessPrompt: "Comment souhaitez-vous contrôler l’accès à vos applications ?",
    selectRestrictions: "Sélectionnez la façon dont vous souhaitez restreindre l’accès, puis assignez des utilisateurs.",
    selectIp: "Sélectionner l’adresse IP",
    specifyIpAccess: "Indiquez l’IP qui peut accéder à vos données",
    whitelistDevices: "Liste blanche des appareils",
    bindDevices: "Lier votre appareil/s à vos données",
    timeParameters: "Paramètres de temps",
    setTimeParameters: "Définir les paramètres de temps",
    accessTime: "Stipuler à quelle heure vos données peuvent être consultées",
    chooseTypeForIp: "Choisissez le type pour l'IP",
    whitelistIp: "Liste blanche IP",
    range: "Gamme",
    ipv4: "IPv4",
    ipv6: "IPv6",
    assignTo: "Attribuer à",
    organization: "Organisation",
    groups: "Groupes",
    users: "Utilisateurs",
    selectZone: "Groupes de recherche",
    selectRange: "Sélectionner une plage",
    startTime: "(Heure de début & Heure de fin)",
    deviceLevel: "Niveau de l’appareil",
    totalUserCount: "Nombre total d’utilisateurs",
    searchGroups: "Rechercher des groupes",
    totalNoOfGroups: "Nombre total de groupes",
    editTimeParameters: "Modifier - Paramètres de temps",
    editDeviceRestriction: "Modifier - Restriction de l’appareil",
    assignUser: "Attribuer un utilisateur",
    searchUsers: "Rechercher des utilisateurs",
    totalUsers: "Nombre total d'utilisateurs",
    editIpRestriction: "Modifier - Restriction IP",
    exactIp: "IP exacte",
    range16: "Plage 16",
    range24: "Plage 24",
    searchUser: "Rechercher un utilisateur",
    timeRestriction: "Restriction de temps",
    deleteConfirmation: "Êtes-vous sûr de vouloir supprimer cette restriction ?",
    noRulesDefined: "Aucune règle définie pour la restriction IP, la restriction de temps et la restriction de dispositif.",
    createNew: "Créer nouveau",
    devicesRestricted: "Les dispositifs sont déjà restreints. Aucune restriction supplémentaire ne peut être appliquée.",
    whitelistPrompt: "Souhaitez-vous mettre vos dispositifs sur liste blanche :",
    policyLevel: "Niveau de politique",
    downloadPrompt: "Veuillez télécharger le Gestionnaire de périphériques Akku",
    desktopApps: "Applications de bureau",
    addToPolicyEngine: "Une fois téléchargé, veuillez ajouter le Gestionnaire de périphériques Akku à votre moteur de politique",
    ipRestriction: "Restriction IP",
    deviceRestriction: "Restriction de dispositif",
    mandatoryAssign: "Il est obligatoire d'attribuer au moins un",
    toTheRestriction: "à la restriction.",
    closeTabConfirmation: "Êtes-vous sûr de vouloir fermer cet onglet ? Les modifications non enregistrées seront perdues.",
    errors: {
      nameRequired: "Le nom est requis",
      nameMax: "Le nom ne peut pas dépasser 20 caractères",
      descriptionRequired: "La description est requise",
      descriptionMax: "La description ne peut pas dépasser 50 caractères",
      ipTypeRequired: "Le type d'IP est obligatoire",
      assignToRequired: "Veuillez sélectionner n’importe lequel",
      duplicateIp: "L'adresse IP existe déjà",
      invalidIPv6Format: "Format d'adresse IPv6 invalide",
      invalidIpSegmentRange: "Plage de segment d'adresse IP invalide",
      exactIpFormat: "Format d'adresse IP invalide pour une IP exacte",
      range16Format: "Format d'adresse IP invalide pour la plage 16",
      range24Format: "Format d'adresse IP invalide pour la plage 24",
      invalidIpFormat: "Format d'adresse IP invalide",
      ipRequired: "L'adresse IP est obligatoire",
      ipRangeRequired: "La plage d'IP est obligatoire",
      endTimeRequired: "Heure de fin requise",
      startTimeRequired: "Heure de début requise",
      timezoneRequired: "Fuseau horaire requis",
    },
  },
  dashboard: {
    searchInApps: "Recherche dans les applications",
    allApps: "Toutes les applications",
    adminDashboard: "Tableau de bord d'administration",
    welcome: "Bienvenue à ",
    lastLogin: "Dernière connexion",
    licenseManagement: "Gestion des licences",
    usedLicense: "Licence d'utilisation ",
    outOf: "sur",
    plan: "Plan",
    upgrade: "Mise à niveau",
    recentPurchase: "Achat récent",
    productAdoption: "Adoption des produits",
    overdueBy: "En retard de",
    days: "jours",
    recoveryAttempts: "Tentatives de récupération",
    totalAttempts: "Total des tentatives",
    successful: "Réussite",
    failure: "Échec",
    mfa: "MFA",
    noMfaAvailable: "Aucune MFA disponible",
    touchId: "Touch ID",
    authenticator: "Authentificateur",
    faceId: "Identification faciale",
    email: "Email",
    mobilePush: "Mobile Push",
    sms: "SMS",
  },
  riskAssessment: {
    riskAssessment: "Évaluation des risques",
    activeUsers: "Utilisateurs actifs",
    suspiciousLogin: "Connexion suspecte",
    lockedAccounts: "Comptes verrouillés",
    failedLogin: "Échec de connexion",
    lastSuccessfulLogin: "Dernière connexion réussie",
    failedLoginAttempt: "Tentative de connexion échouée",
    details: "Détails",
    unusedLicenses: "Licences non utilisées",
    suspiciousLoginAttempt: "Tentative de connexion suspecte",
    lockedLoginAttempt: "Tentative de connexion verrouillée",
    lastRiskAssessmentDate: "Date de la dernière évaluation des risques",
    lastRiskAssessmentResult: "Résultat de la dernière évaluation des risques",
  },
  tenants: {
    errors: {
      customerId: {
        required: "L'ID client est requis",
        noSpaces: "L'ID client ne peut pas contenir d'espaces",
        invalidCharacters: "L'ID client ne doit pas contenir de majuscules ni de caractères spéciaux",
        maxLength: "L'ID client ne peut pas dépasser 30 caractères",
      },
      displayName: {
        required: "Le nom d'affichage est requis",
        maxLength: "Le nom d'affichage doit comporter au maximum 30 caractères",
      },
      customerEmailId: {
        invalid: "Veuillez entrer une adresse e-mail valide pour le client",
        required: "Email du client est requis",
      },
      noOfLicense: {
        required: "No. de licence requis",
        typeError: "Le nombre de licences doit être un nombre",
        max: "Le nombre de licences ne peut pas dépasser 999999",
      },
      phone: {
        required: "Numéro de téléphone mobile requis",
      },
      smsServiceProvider: {
        required: "La région est requise",
      },
      invalidEmail: "Adresse e-mail invalide",
      senderEmail: {
        required: "L'ID e-mail de l'expéditeur est requis",
      },
      displaySenderEmail: {
        required: "Le nom d'affichage pour l'adresse e-mail de l'expéditeur est requis",
        maxLength: "Le nom d'affichage ne peut pas dépasser 20 caractères",
      },
      displayReplyEmail: {
        required: "Le nom d'affichage pour la réponse est requis",
        maxLength: "Le nom d'affichage ne peut pas dépasser 20 caractères",
      },
      replyEmail: {
        required: "L'adresse e-mail de réponse est requise",
        validEmail: "Veuillez entrer une adresse e-mail valide",
        sameAsSender: "L'e-mail de réponse ne peut pas être le même que l'adresse e-mail de l'expéditeur",
      },
      sessionValidation: {
        ssoSessionIdleTimeout: "Il faut du temps",
        ssoSessionMaxLifespan: "Il faut du temps",
        loginTimeout: "Il faut du temps",
        loginActionTimeout: "Un délai d'attente est requis",
      },
    },
    senderEmailAddress: "Adresse e-mail de l'expéditeur",
    displayNameForSenderEmailAddress: "Nom affiché pour l'adresse e-mail de l'expéditeur",
    replyToEmailAddress: "Adresse e-mail de réponse",
    displayNameForReplyEmailAddress: "Nom affiché pour l'adresse e-mail de réponse",
    editDetails: "Modifier les détails",
    ssoSettings: "Paramètres SSO",
    selectPlansWarning: "Veuillez sélectionner un forfait ou choisir des produits individuels à activer pour ce client.",
    planChangeWarning:
      "Changer de forfait peut avoir un impact sur le cycle de facturation. Veuillez contacter l'administrateur d'Akku pour obtenir de l'aide.",
    areYouSureWantToUpdateDetails: "Êtes-vous sûr de vouloir mettre à jour les détails ?",
    testingInProgress: "Test en cours...",
    success: "Succès",
    emailSettings: "Paramètres d'Email",
    available: "Disponible",
    customerEmailId: "Email du client",
    loginTheme: "Thème de connexion",
    resetPassword: "Réinitialiser le mot de passe",
    exitFullScreen: "Sortir du mode plein écran",
    expand: "Développer",
    addCustomer: "Ajouter un client",
    tenants: "Locataires",
    msp: "MSP",
    licenses: "Licences",
    plan: "Forfait",
    adoptionRate: "Taux d'adoption",
    renewalDate: "Date de renouvellement",
    revenue: "Recettes",
    status: "Statut",
    addTenant: "Ajouter un locataire",
    generalDetails: "Renseignements généraux",
    emailConfiguration: "Configuration de l'Email",
    ssoSessionSettings: "Paramètres de la session SSO",
    productsOrPlan: "Produits ou plan",
    customerId: "ID client",
    displayName: "Nom d’affichage",
    noOfLicense: "No. de licence",
    mobileNumber: "Numéro de téléphone mobile",
    region: "Région",
    setPasswordOnFirstLogin: "Définir le mot de passe lors de la première connexion",
    forceUserToSetPasswordOnFirstLogin: "Forcer l'utilisateur à définir un mot de passe lors de la première connexion",
    restOfTheWorld: "Reste du monde",
    nigeria: "Nigéria",
    from: "De",
    fromDisplayName: "Du nom d’affichage",
    replyTo: "Répondre à",
    replyToDisplayName: "Répondre au nom affiché",
    ssoSessionIdle: "Inactivité de la session SSO",
    rememberMe: "(Me rappeler)",
    ssoSessionMax: "Session SSO Max",
    loginSettings: "Paramètres de connexion",
    loginTimeout: "Délai de connexion",
    loginActionTimeout: "Délai d’attente de l’action de connexion",
    sessionIdleTimeoutDescription:
      "Temps qu'une session peut rester inactive avant d'expirer. Les jetons et les sessions de navigateur sont invalidés lorsque la session expire.",
    sessionMaxTimeoutDescription:
      "Temps maximum avant qu'une session n'expire. Les jetons et les sessions de navigateur sont invalidés lorsque la session expire.",
    rememberMeSessionIdleDescription:
      "Temps qu'une session 'Se souvenir de moi' peut rester inactive avant d'expirer. Les jetons et les sessions de navigateur sont invalidés lorsque la session expire. S'il n'est pas défini, il utilise la valeur standard de l'ID de session SSO.",
    rememberMeSessionMaxDescription:
      "Temps maximum avant qu'une session n'expire lorsqu'un utilisateur a activé l'option 'Se souvenir de moi'. Les jetons et les sessions de navigateur sont invalidés lorsque la session expire. S'il n'est pas défini, il utilise la valeur standard de Max SSO Session.",
    loginTimeoutDescription:
      "Temps maximum qu'un utilisateur a pour terminer une connexion. Il est recommandé que cela soit relativement long, comme 30 minutes ou plus.",
    loginActionTimeoutDescription:
      "Temps maximum qu'un utilisateur a pour terminer les actions liées à la connexion, comme la mise à jour du mot de passe ou la configuration de TOTP. Il est recommandé que cela soit relativement long, comme 5 minutes ou plus.",
    youCanSelectYourPlan: "Vous pouvez sélectionner votre forfait",
    selectPlan: "Sélectionner le Forfait",
    selectTheOption: "Sélectionnez l’option",
    adminCanResetPassword: "L'administrateur peut réinitialiser le mot de passe d'un utilisateur et en définir un temporaire",
    monthly: "Mensuel",
    annual: "Annuel",
    basic: "Basique",
    professional: "Professionnel",
    advanced: "Avancé",
    include: "inclure",
    cloudDirectory: "Cloud Directory",
    ssoIDP: "SSO/IDP",
    adaptiveMFA: "Adaptive MFA",
    adaptiveAccessManager: "Gestionnaire d’accès adaptatif",
    passwordManager: "Gestionnaire de mots de passe", // NOSONAR: This is not a credential, just a label
    deviceManager: "Gestionnaire de périphériques",
  },
  header: {
    oldPasswordRequired: "Ancien mot de passe requis", // NOSONAR: This is not a credential, just a label
    passwordMustMatch: "Les mots de passe doivent correspondre", // NOSONAR: This is not a credential, just a label
    confirmPasswordRequired: "Veuillez confirmer votre mot de passe", // NOSONAR: This is not a credential, just a label
    invalidOtp: "OTP invalide",
    resendOtp: "Renvoyer OTP",
    backToLogin: "Retour à la connexion",
    yourUsername: "Votre nom d'utilisateur",
    otpValidFor5MinutesOnly: "Le code OTP est valable uniquement pendant 5 minutes",
    enterVerificationCode: "Entrez le code de vérification envoyé à",
    resendCode: "Renvoyer le code",
    seconds: "seconde",
    verifyOtp: "Vérifier l'OTP",
    enterVerificationCodeMobile: "Entrez le code de vérification envoyé à votre numéro de mobile enregistré",
    ipRestrictionsWarning:
      "Votre administrateur a restreint l'accès à partir de cette adresse IP. Veuillez contacter l'administrateur pour obtenir plus d'informations.",
    timeRestrictionsWarning:
      "L'opération a été annulée en raison de restrictions de temps dans l'application. Veuillez contacter l'administrateur système pour obtenir de l'assistance.",
    unAuthorizedAccess: "Accès non autorisé ",
    welcomeToIAM: "Bienvenue sur votre portail IAM.",
    passwordRequirement: "Vous devez définir un mot de passe pour continuer.", // NOSONAR: This is not a credential, just a label
    setPassword: "Définir le mot de passe",
    reEnterPassword: "Réentrer le mot de passe",
    password: "Mot de passe", // NOSONAR: This is not a credential, just a label
    profileDetails: "Détails du profil",
    changePassword: "Changer le mot de passe",
    oldPassword: "Ancien mot de passe",
    newPassword: "Ancien mot de passe requis",
    confirmPassword: "Confirmer le mot de passe",
    passwordThreeTimesErrorMessage: "Votre mot de passe ne peut pas être le même que les trois derniers mots de passe", // NOSONAR: This is not a credential, just a label
    passwordThreeAttemptsLockWarning: "(Trois tentatives incorrectes verrouilleront le compte)", // NOSONAR: This is not a credential, just a label
    firstNameRequired: "Le prénom est requis",
    lastNameRequired: "Le nom de famille est requis",
  },
  common: {
    closeTabConfirmation: "Êtes-vous sûr de vouloir fermer cet onglet ? Les modifications non enregistrées seront perdues.",
    enableClipboardAccessMessage: "Pour coller du contenu ici, veuillez activer l'accès au presse-papiers dans les paramètres de votre navigateur",
    paste: "Coller",
    clearSelection: "Effacer la sélection",
    enter: "Entrer",
    submit: "Soumettre",
    clear: "Claire",
    configure: "Configurer",
    tryAgain: "Essayer à nouveau",
    deploymentErrorMessage:
      "Nous effectuons actuellement une maintenance inattendue pour résoudre un problème technique. Nous nous excusons pour tout inconvénient que cela pourrait causer et espérons que le système sera de nouveau en ligne sous peu. Merci de votre patience.",
    pageNotFound: "Page non trouvée !",
    wrongAddressOrPageMoved: "Vous avez peut-être saisi une mauvaise adresse ou le",
    tryAgainMessage: "la page a été déplacée. en attendant, réessayez.",
    goToDashboard: "Aller au tableau de bord",
    used: "Utilisée",
    unUsed: "Inutilisée",
    noRecordsFound: "Aucun enregistrement trouvé",
    popUpBlocked: "Pop-up a été bloqué",
    allowPopUptoLogout: "Veuillez autoriser le Pop-up à se déconnecter.",
    searchAndSelect: "Recherche et sélection",
    skip: "Sauter",
    agentSent: "Agent envoyé",
    authorize: "Autoriser",
    description: "Description",
    status: "Statut",
    sessionExpired: "Session Expirée",
    sessionExpiredMessage: "Votre session a expiré; veuillez vous reconnecter.",
    contactSupport: "Contacter l'assistance",
    firstName: "Prénom",
    lastName: "Nom de famille",
    workEmailId: "Adresse e-mail professionnelle",
    userType: "Type d'utilisateur",
    personalEmailId: "Email personnel",
    phoneNumber: "Numéro de téléphone",
    mobileNumber: "Numéro de téléphone",
    confirm: "Confirmer",
    okay: "D'accord",
    restrictions: "des restrictions",
    goBack: "Retourner",
    profile: "Profil",
    userDashboard: "Tableau de bord utilisateur",
    logout: "Déconnexion",
    logoutConfirmation: "Êtes-vous sûr de vouloir vous déconnecter?",
    note: "Remarque",
    emailId: "Adresse email",
    email: "Email",
    name: "Nom",
    totalUsersCount: "Nombre total d'utilisateurs",
    enabled: "Activé",
    ascending: "Ascendant",
    descending: "Descendant",
    add: "Ajouter",
    edit: "Modifier",
    apply: "Appliquer",
    filter: "Filtrer",
    all: "Tous",
    active: "Actif",
    inactive: "Inactif",
    deleted: "Supprimé",
    disabled: "Désactivé",
    back: "Retour",
    personalDetails: "Renseignements personnels",
    basicDetails: "Détails de base",
    cancel: "Annuler",
    next: "Suivant",
    update: "Mise à jour",
    save: "Sauver",
    proceed: "Continuer",
    search: "Recherche",
    ok: "Ok",
    test: "Test",
    testingInProgress: "Test en cours...",
    success: "Succès",
    done: "Terminé",
    areYouSureWantToDelete: "Êtes-vous sûr de vouloir supprimer?",
    no: "Non",
    yes: "Oui",
    action: "Action",
    sync: "Synchroniser",
    close: "Fermer",
    delete: "Supprimer",
    triggerDesc: "Cliquez pour trier par ordre décroissant",
    triggerAsc: "Cliquez pour trier par ordre croissant",
    cancelSort: "Cliquez pour annuler le tri",
    select: "Sélectionner",
  },
  roleManagement: {
    rolesAndPermissions: "Rôles et autorisations",
    addNewRole: "Ajouter un nouveau rôle",
    totalRolesCount: "Nombre total de rôles",
    predefinedRolesWarning:
      "Les rôles prédéfinis comme Admin et User sont essentiels pour le fonctionnement du système et ne peuvent pas être modifiés.",
    role: "Rôle",
    description: "Description",
    roleManagement: "Gestion des rôles",
    new: "Nouveau",
    addRole: "Ajouter un rôle",
    roleName: "Nom du poste",
    roleDescription: "Description du rôle",
    roleDescriptionRequired: "La description du rôle est requise",
    roleNameRequired: "Le nom du rôle est requis",
    permissions: "Autorisations",
    products: "Produits",
    create: "Créer",
    edit: "Modifier",
    view: "Voir",
    delete: "Supprimer",
    confirmDeleteRole: "Êtes-vous sûr de vouloir supprimer ce rôle ?",
    errors: {
      roleNameRequired: "Le nom du rôle est requis",
      roleNameMax: "Le nom du rôle doit comporter au maximum 50 caractères",
      roleDescriptionRequired: "La description du rôle est requise",
      roleDescriptionMax: "La description du rôle doit comporter au maximum 50 caractères",
    },
  },
  passwordManager: {
    passwordManager: "Gestionnaire de mots de passe", // NOSONAR: This is not a credential, just a label
    passwordPolicy: "Politique sur les mots de passe", // NOSONAR: This is not a credential, just a label
    passwordExpiry: "Expiration du mot de passe", // NOSONAR: This is not a credential, just a label
    passwordValidityDescription: "Le nombre de jours pendant lesquels le mot de passe est valide avant qu’un nouveau mot de passe soit requis", // NOSONAR: This is not a credential, just a label
    numericalDigitRequirement: "Au moins un chiffre numérique requis dans le mot de passe.",
    digits: "Chiffres",
    passwordMinimumLength: "Longueur minimale du mot de passe", // NOSONAR: This is not a credential, just a label
    minimumCharactersDescription: "Le nombre minimum de caractères requis pour le mot de passe.",
    notRecentlyUsed: "Non utilisé récemment",
    recentPasswordReuseWarning: "Le dernier mot de passe utilisé récemment ne peut pas être réutilisé.",
    uppercaseCharacters: "Caractères majuscules",
    uppercaseRequirement: "Au moins une lettre majuscule requise dans le mot de passe.",
    lowercaseCharacters: "Caractères minuscules",
    lowercaseRequirement: "Au moins une lettre minuscule requise dans le mot de passe.",
    specialCharacters: "Caractères spéciaux",
    specialCharacterRequirement: "Au moins un caractère spécial requis dans le mot de passe.",
    loginAttempts: "Tentatives de connexion",
    adminLoginLimits: "L’administrateur peut définir les limites de tentatives de connexion des utilisateurs",
    recommended: "Recommandé",
    restoreDefault: "Restaurer la valeur par défaut",
    loginAttemptIsRequired: "La tentative de connexion est requise",
    good: "Bon",
    strong: "Fort",
    superStrong: "Super Fort",
    unnecessarilyStrong: "Inutilement Fort",
  },
  deviceManagement: {
    enterSerialNumber: "Entrer le numéro de série",
    enterDeviceUUID: "Entrer deviceUUID",
    selectType: "Sélectionner le type",
    allocationInformation: "Ces informations sont nécessaires pour l’affectation et l’administration de l’utilisateur",
    editDetails: "Modifier les détails",
    assignUsers: "Attribuer des utilisateurs",
    searchAndSelect: "Rechercher et sélectionner",
    deviceManagement: "Gestion des appareils",
    addNewDevice: "Ajouter un nouvel appareil",
    addDevice: "Ajouter un appareil",
    csv: "CSV",
    devicesSelected: "Appareils sélectionnés",
    clearSelection: "Effacer la sélection",
    totalDeviceCount: "Nombre total de dispositifs ",
    deleteDevice: "Supprimer l’appareil",
    areYouSureYouWantToDeleteTheDevice: "Êtes-vous sûr de vouloir supprimer l’appareil?",
    serialNumber: "Numéro de série",
    deviceUUID: "UUID du périphérique",
    deviceType: "Type de dispositif",
    assignedUsers: "Utilisateurs assignés",
    laptop: "Ordinateur portable",
    desktop: "Ordinateur de bureau",
    csvUpload: {
      csvUpload: "CSV Upload",
      csvUploadData: "Ajoutez facilement plusieurs utilisateurs via le téléchargement de listes de données",
      sampleFile: "Exemple de fichier CSV",
      clickToUploadOr: "Cliquez pour télécharger ou",
      dragAndDrop: "Déposez et faites glisser",
      fileFormat: "Format de fichier CSV (max 2 Mo)",
      processing: "Traitement de vos données...",
      waitingMessage: "Veuillez patienter, car cela peut prendre du temps si vos données sont importantes",
      uploadCsvFile: "Vous ne pouvez télécharger que des fichiers CSV !",
      csvFileLimit: "Le fichier CSV doit être inférieur à 2Mo !",
      csvErrorMessageForDeviceManagement: "Le fichier CSV doit être inférieur à 2Mo !",
    },
    errors: {
      serialNumberRequired: "Le numéro de série est requis",
      deviceUUIdRequired: "Le UUID de l’appareil est requis",
      deviceTypeRequired: "Type de dispositif requis",
    },
  },
  userManagement: {
    submitDetailsPrompt: "On dirait que vous avez apporté quelques modifications",
    userBehavior: "Comportement de l'utilisateur",
    yesterday: "Hier",
    today: "Aujourd'hui",
    sevenDaysAgo: "il y a 7 jours",
    showMore: "Afficher plus",
    confirmDeprovisionUser: "Êtes-vous sûr de vouloir déprovisionner cet utilisateur du groupe ?",
    userApps: "Applications utilisateur",
    provisioningApps: "Applications de provisionnement",
    dataNotFound: "Données non trouvées",
    ssoApps: "Applications SSO",
    userInformation: "Informations sur l'utilisateur",
    clickTheBelowButtonToSyncApps: "Cliquez sur le bouton ci-dessous pour synchroniser les applications",
    withYourUsers: "avec vos utilisateurs",
    askUserToCheckMobileNotifications:
      "Demandez à l’utilisateur de vérifier son téléphone portable pour voir s’il reçoit la notification d’accueil pour créer son compte !",
    contactSales: "Contacter le Support",
    zone: "Zone",
    editConfigureSearchAndUpdate: "Éditer - Configurer (Rechercher et mettre à jour)",
    editMappingAttributes: "Éditer - Mappage (Attributs)",
    mapperSyncTitle: "Synchronisation du mappage",
    disablingActiveDirectoryWarning:
      "La désactivation de l'annuaire actif entraînera la désactivation de tous les comptes d'utilisateur. Êtes-vous sûr de vouloir poursuivre?",
    deleteDirectoryConnectionWarning: "Supprimer cette connexion d’annuaire empêchera les utilisateurs de se connecter aux applications approuvées.",
    areYouSureYouWantToContinue: "Êtes-vous sûr de vouloir continuer?",
    managementDirectory: "Répertoire de gestion",
    editConfigureAuthentication: "Éditer - Configurer (Authentification)",
    selectChannel: "Sélectionnez le canal",
    pleaseSelectChannelToImportUsers: "Veuillez sélectionner le canal pour importer les utilisateurs",
    akkuAsDirectory: "Akku en tant que répertoire",
    syncFromYourDirectory: "Synchroniser à partir de votre répertoire",
    finalReviewUserData: "Examen final - Données utilisateur",
    reviewMappingDescription: "Veuillez examiner la mise en correspondance ci-dessous et confirmer les modifications le cas échéant",
    deleteRestrictionsWarning: "Êtes-vous sûr de vouloir supprimer les restrictions ?",
    noServicesAvailable: "Aucun service disponible",
    resetPasswordInitiatedBy: "Réinitialisation du mot de passe initiée par",
    resetPasswordHistory: "Réinitialiser l'historique des mots de passe",
    totalResetList: "Liste de réinitialisation totale",
    inActiveUser: "Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
    activeUser: "Voulez-vous vraiment activer cet utilisateur ?",
    updatePhoneNumberWarning:
      "Êtes-vous sûr de vouloir mettre à jour le numéro de téléphone puisque l'utilisateur a déjà enregistré l'appareil avec ce numéro ?",
    disConnectApp: "Déconnecter l'application",
    connectedDirectory: "Répertoire connecté",
    syncGroup: "Groupe de synchronisation",
    syncChangeUsers: "Synchroniser les utilisateurs de changement",
    serverPrincipal: "Principal du serveur",
    kerberosGeneralDetails: "Kerberos - Détails généraux",
    uiDisplayName: "Nom d’affichage de l’interface utilisateur",
    kerberosRealm: "Domaine Kerberos",
    keyTab: "Onglet des touches",
    syncChangedUsers: "Synchroniser les utilisateurs modifiés",
    syncAllUsers: "Synchroniser tous les utilisateurs",
    mapperSync: "Synchronisation du Mapper",
    deleteAttribute: "Supprimer l'attribut",
    reviewMapping: "Examen du mappage",
    sourceLDAP: "SOURCE - LDAP",
    targetAkku: "CIBLE - Akku",
    transformation: "TRANSFORMATION",
    mappingAttributes: "Mappage - Attributs",
    readOnly: "En lecture seule",
    writeable: "Modifiable",
    unsynced: "Non synchronisé",
    editMode: "Mode de modification",
    usersDn: "Utilisateurs DN",
    usernameLDAPAttribute: "Nom d’utilisateur LDAP Attribute",
    rdnLdapAttribute: "Attribut LDAP RDN",
    uuidLdapAttribute: "Attribut LDAP UUID",
    syncUpdateInterval: "Intervalle de mise à jour de synchronisation (secondes)",
    certificate: "Certificat",
    userObjectClasses: "Classes d’objets utilisateur",
    directoryName: "Nom de l’annuaire",
    connectionURL: "URL de connexion",
    bindType: "Type de liaison",
    bindDN: "Lier le DN",
    bindCredentials: "Lier les identifiants",
    simple: "Simple",
    configureAuthentication: "Configurer - Authentification",
    chooseConnector: "Sélectionnez un connecteur",
    ldap: "LDAP",
    ad: "AD",
    kerberos: "KERBEROS",
    confirmationMessage: "Êtes-vous sûr de vouloir fermer cet onglet ? Toutes les modifications non enregistrées seront perdues.",
    deAllocateUsersFromGroup: "Retirer l'affectation des utilisateur(s) du groupe",
    groupDetails: "Détails du groupe",
    groupName: "Nom du groupe",
    selectUsers: "Sélectionner les utilisateurs à ajouter dans les groupes",
    emailId: "Adresse email",
    disable: "Désactiver",
    enable: "Activer",
    search: "Recherche",
    userManagement: "Gestion des utilisateurs",
    editInformation: "Modifier les informations",
    basicDetails: "Renseignements de base",
    resetPassword: "Réinitialiser le mot de passe",
    lastUpdatedOn: "Dernière mise à jour le ",
    firstName: "Prénom",
    lastName: "Nom de famille",
    workEmailId: "Email de travail",
    userType: "Type d’utilisateur",
    restrictions: "des restrictions",
    personalEmailId: "Email personnel",
    mobileNumber: "Numéro de téléphone",
    enterDetailsOrAddCustomField: "Veuillez saisir les détails suivants ou ajouter un champ personnalisé",
    groupsConnectedApps: "Applications connectées aux groupes",
    update: "Mettre à jour",
    deleteRestrictionsConfirmation: "Êtes-vous sûr de vouloir supprimer les restrictions ?",
    untitled: "Sans titre",
    connectedApps: "Applications connectées",
    individualApps: "Applications individuelles",
    disconnectApps: "Déconnecter l'application",
    confirmDisconnectAppFromGroup: "Êtes-vous sûr de vouloir déconnecter cette application de ce groupe ?",
    cancel: "Annuler",
    yes: "Oui",
    no: "Non",
    add: "Ajouter",
    selectAppsToAddUser: "Sélectionner des applications à ajouter à l'utilisateur",
    selectAppsToAddGroup: "Select apps to add in the groups",
    restrictionType: "Type de restriction",
    serialNumber: "Numéro de série",
    createdOn: "Créé le",
    name: "Nom",
    description: "Description",
    selectZone: "Sélectionner la zone",
    selectRange: "Sélectionner la plage",
    deviceRestriction: "Restriction de l'appareil",
    ipRestriction: "Restriction IP",
    timeParameters: "Paramètres de temps",
    inActive: "Inactif",
    active: "Actif",
    status: "Statut",
    agentSent: "Agent envoyé",
    chooseTypeForIP: "Choisir le type pour l'IP",
    startingIP: "IP de départ",
    range: "Plage",
    notAvailable: "Non disponible",
    timeRestriction: "restriction de temps",
    enter: "Entrer",
    temporaryPassword: "Entrez le mot de passe temporaire",
    note: "Remarque",
    passwordRequirement:
      "Le mot de passe défini sur cet écran sera requis pour la première connexion de l'utilisateur après la réinitialisation de son mot de passe.", // NOSONAR: This is not a credential, just a label
    uppercaseCharacters: "Le mot de passe doit contenir au moins une lettre majuscule",
    lowercaseCharacters: "Le mot de passe doit contenir au moins une lettre en minuscules",
    specialCharacters: "Le mot de passe doit contenir au moins un caractère spécial",
    digits: "Le mot de passe doit contenir au moins un chiffre",
    passwordLength: "Le mot de passe doit contenir au moins {{length}} caractères", // NOSONAR: This is not a credential, just a label
    noAppsFound: "Aucune application trouvée",
    addUser: "Ajouter un utilisateur",
    users: "Utilisateurs",
    directory: "Répertoire",
    csv: "CSV",
    totalUserCount: "Nombre total d’utilisateurs",
    changeToGroupView: "Changer pour la vue de groupe",
    clearSelection: "Effacer la sélection",
    syncAll: "Synchroniser tout",
    sync: "Synchroniser",
    pleaseConfigureAtLeastOneApp: "Veuillez configurer au moins une application pour ajouter des utilisateurs",
    upgradeYourPlan: "Mettez à niveau votre plan pour utiliser ce produit. Contactez le support Akku.",
    contactAdmin: "Contacter l'administrateur",
    suspend: "Suspendre",
    delete: "Supprimer",
    areYouSureSuspend: "Êtes-vous sûr de vouloir suspendre ces comptes d'utilisateur ?",
    deletingUserAccount: "La suppression de ce compte d'utilisateur entraînera la perte de tous les droits associés à ses applications.",
    userSelected: "Utilisateur sélectionné",
    usersSelected: "Utilisateurs sélectionnés",
    groups: "Groupes",
    accessManager: "Gestionnaire d’accès",
    assignGroup: "Attribuer un groupe",
    theSearchIsCaseSensitive: "La recherche est sensible à la casse",
    totalGroups: "Total des groupes",
    changeToUserView: "Passer à la vue utilisateur",
    deleteGroup: "Supprimer le groupe",
    createGroup: "Créer un groupe",
    email: "Email",
    services: "Services",
    triggerDesc: "Cliquez pour trier par ordre décroissant",
    triggerAsc: "Cliquez pour trier par ordre croissant",
    cancelSort: "Cliquez pour annuler le tri",
    filter: "Filtrer",
    removeRestriction: "Supprimer la restriction",
    deAllocateUser: "Désallouer l'utilisateur",
    deAllocateUserConfirmation: "Êtes-vous sûr de vouloir désallouer l'utilisateur sélectionné de ce groupe ?",
    deleteGroupConfirmation: "Êtes-vous sûr de vouloir supprimer le(s) groupe(s) ?",
    singleUser: "UTILISATEUR UNIQUE",
    allocationInformation: "Cette information est nécessaire pour l'allocation et l'administration de l'utilisateur",
    personalDetailsMessage: "Cette information aidera Akku à guider l'utilisateur tout au long du processus d'intégration",
    configureSearchUpdate: {
      configureSearchHeading: "Configurer - Rechercher et Mettre à jour",
      editMode: "Mode Édition*",
      userDn: "DN des utilisateurs*",
      userLdap: "Attribut LDAP du nom d'utilisateur*",
      rdnLdap: "Attribut LDAP du RDN*",
      uuidLdap: "Attribut LDAP de l'UUID*",
      userObject: "Classes d'objets utilisateur*",
    },
    deviceAuthorization: {
      deviceAuthorization: "Autorisation de périphérique",
      selectionContent: "Souhaitez-vous autoriser vos appareils sur :",
      policyLevel: "Niveau de politique",
      deviceLevel: "Niveau de l'appareil",
      downloadAkku: "Veuillez télécharger le gestionnaire d'appareils Akku",
      desktopApps: "Applications de bureau",
      pleaseAddContent: "Une fois téléchargé, veuillez ajouter le gestionnaire d'appareils Akku à votre moteur de politique",
      cancel: "Annuler",
      next: "Suivant",
    },
    csvUpload: {
      csvUpload: "CSV Upload",
      csvUploadData: "Ajoutez facilement plusieurs utilisateurs via le téléchargement de listes de données",
      sampleFile: "Exemple de fichier CSV",
      clickToUploadOr: "Cliquez pour télécharger ou",
      dragAndDrop: "Déposez et faites glisser",
      fileFormat: "Format de fichier CSV (max 2 Mo)",
      processing: "Traitement de vos données...",
      waitingMessage: "Veuillez patienter, car cela peut prendre du temps si vos données sont importantes",
      uploadCsvFile: "Vous ne pouvez télécharger que des fichiers CSV !",
      csvFileLimit: "Le fichier CSV doit être inférieur à 2Mo !",
      csvErrorMessageForDeviceManagement: "Le fichier CSV doit être inférieur à 2Mo !",
    },
    errors: {
      firstName: {
        required: "Veuillez saisir le prénom",
        letterAndSpace: "Seuls les lettres et les espaces sont autorisés dans le prénom",
        minLength: "La longueur minimale est de 4 caractères pour le prénom",
        maxLength: "La longueur maximale est de 16 caractères pour le prénom",
      },
      lastName: {
        required: "Veuillez saisir le nom de famille",
        letterAndSpace: "Seuls les lettres et les espaces sont autorisés dans le nom",
        minLength: "La longueur minimale est de 1 caractère pour le nom",
        maxLength: "La longueur maximale est de 16 caractères pour le nom",
      },
      phone: {
        required: "Le numéro de téléphone est requis",
        minLength: "Le numéro de téléphone doit comporter au moins 8 caractères",
        maxLength: "Le numéro de téléphone ne peut pas dépasser 13 caractères",
      },
      personalEmail: {
        required: "L'email est requis",
        invalidEmail: "Format d'email invalide",
      },
      workEmail: {
        required: "L'email est requis",
        invalidEmail: "Format d'email invalide",
      },
      customField: {
        required: "Veuillez remplir ce champ",
        minLength: "Veuillez saisir au moins 3 caractères",
        uniqueName: "Ce champ existe déjà",
      },
      email: {
        invalid: "Format d'email invalide",
        required: "Veuillez entrer l'email",
      },
      userType: {
        required: "Le type d'utilisateur est requis",
      },
      newField: { required: "Veuillez remplir ce champ", min: "Veuillez entrer au moins 3 caractères" },
      fieldLabel: {
        uniqueCheck: "Le nom d'attribut existe déjà",
        required: "Le champ est obligatoire",
        min: "Veuillez entrer au moins 3 caractères",
        duplicate: "Le champ existe déjà",
      },
      groupDescriptionRequired: "La description du groupe est obligatoire",
      groupDescriptionLength: "La description du groupe doit être au maximum de 100 caractères",
      groupNameRequired: "Le nom du groupe est obligatoire",
      ldap: {
        connectionUrl: "L’URL de connexion est requise",
        ldapName: "Le nom de l’annuaire est requis",
        bindDn: "Bind DN est requis",
        bindCredentials: "Les identifiants de connexion sont requis",
        editModeRequired: "Le mode édition est requis",
        usersDnRequired: "Le DN de l’utilisateur est requis",
        usernameLDAPAttributeRequired: "Les attributs LDAP du nom d’utilisateur sont requis",
        rdnLdapAttributeRequired: "Les attributs RDN LDAP sont requis",
        uuidLdapAttributeRequired: "Attributs LDAP UUID requis",
        userObjectClassesRequired: "Les classes d’objets utilisateur sont requises",
        syncUpdateIntervalRequired: "Synchronisation de l’intervalle de mise à jour est nécessaire",
        certificateRequired: "Certificat requis",
      },
      kerberos: {
        kerberosNameRequired: "Le nom d’affichage de l’interface utilisateur est requis",
        kerberosNameMin: "Le nom d'affichage de l'interface utilisateur doit avoir au moins 3 caractères",
        kerberosRealmNameRequired: "Le nom de domaine Kerberos est requis",
        kerberosRealmNameMin: "Le nom du royaume Kerberos doit avoir au moins 3 caractères",
        serverPrincipalNameRequired: "Le principal du serveur est requis",
        serverPrincipalNameMin: "La principale du serveur doit avoir au moins 3 caractères",
        keytabPathRequired: "Onglet clé requis",
      },
      ldapReviewMapping: {
        sourceRequired: "Le LDAP source est requis",
        targetRequired: "Le LDAP cible est requis",
        userTypeRequired: "Le type de transformation est requis",
      },
    },
    addUsers: { header: "Ajouter des utilisateurs", iconAlt: "ajouter un utilisateur" },
    stepper: {
      channel: "Canal",
      directoryConnection: "Connexion au répertoire",
      chooseConnector: "Choisir le connecteur",
      configure: "Configurer",
      authentication: "Authentification",
      searchAndUpdate: "Recherche et mise à jour",
      mapping: "Cartographie",
      attributes: "Attributs",
      userStatus: "Statut de l’utilisateur",
      notifications: "Les notifications",
    },
    userStepper: {
      channel: { title: "Canal" },
      directoryConnection: { title: "Connexion au répertoire", description: "Entrez vos détails personnels" },
      chooseConnector: { title: "Choisissez un connecteur", description: "Entrez vos détails personnels" },
      configure: {
        title: "Configurer",
        description: "Entrez vos détails de sécurité",
        children: {
          authentication: { title: "Authentification", description: "Entrez les détails de l'étape enfant 1" },
          searchAndUpdate: { title: "Rechercher et mettre à jour", description: "Entrez les détails de l'étape enfant 2" },
        },
      },
      mapping: {
        title: "Mappage",
        description: "Entrez vos détails de compte",
        children: {
          attributes: { title: "Attributs", description: "Entrez les détails de l'étape enfant 1" },
          userStatus: { title: "Statut de l'utilisateur", description: "Entrez les détails de l'étape enfant 2" },
          notifications: { title: "Les notifications", description: "Entrez les détails de l'étape enfant 3" },
        },
      },
    },
    mfa: {
      multiFactorAuthentication: "Authentification à plusieurs facteurs",
      selectVerificationMethod: "Sélectionner une méthode de vérification",
      mobilePushNotification: "Notification push mobile",
      emailOtp: "OTP par email",
      smsOtp: "OTP par SMS",
      verifiedSuccessfully: "Vérifié avec succès",
      navigatingToUserManagement: "Navigation vers la console de gestion des utilisateurs.",
      enterVerificationCodeEmail: "Entrez le code de vérification envoyé à votre adresse email enregistrée",
      resendCode: "Renvoyer le code",
      seconds: "seconde",
      verifyOtp: "Vérifier l'OTP",
      enterVerificationCodeMobile: "Entrez le code de vérification envoyé à votre numéro de mobile enregistré",
      checkYourDevice: "Vérifiez votre appareil",
      notificationMessage: "Akku a envoyé une notification à votre appareil. Appuyez sur ",
      notificationAction: "sur la notification pour vérifier que c'est bien vous.",
    },
  },
};
export default fr;
