import React, { useEffect, useState } from "react";
import CustomButtonBack from "../../../../component/CustomButtonBack";
import CustomButtonNext from "../../../../component/CustomButton";
import ImageView from "../../../../component/ImageView";
import { images } from "../../../../../assets/images/ImagePaths";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

const UpdateModal = (props: any) => {
  const { isOpenPopup, handleClose } = props;
  const { t } = useTranslation();
  const [open, isOpen] = useState<boolean>(false);

  const handleUpdate = () => {
    handleClose();
  };
  useEffect(() => {
    isOpen(isOpenPopup);
  }, [isOpenPopup]);

  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <Modal className="  show-notification  _suspended rounded-lg" maskClosable={false} centered open={open} footer={false} onCancel={handleCloseModal}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={images.unauthorized} alt="notificationDone" className="delete-apps" />
          <p className="text-[20px] pt-4 text-center font-Inter font-medium text-[#101828]">{t("tenants.areYouSureWantToUpdateDetails")}</p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleCloseModal} text={t("common.cancel")} />
          <CustomButtonNext onClick={handleUpdate} text={t("common.save")} />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
