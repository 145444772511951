import { ErrorMessages } from "../../../../const/Messages";
import { timeZones } from "./TimeZoneList";

export const columns = (t: (key: string) => string) => [
  {
    title: t("common.name"), // Assuming "common.name" is the translation key for "Name"
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: t("common.email"), // Assuming "common.email" is the translation key for "Email"
    dataIndex: "email",
    key: "email",
    render: (text: any, record: any) => record.email,
  },
];

export const groupColumns = (t: (key: string) => string) => [
  {
    title: t("common.name"), // Reusing the translation key for "Name"
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => record.name,
  },
  {
    title: t("common.description"), // Assuming "common.description" is the translation key for "Description"
    dataIndex: "description",
    key: "description",
    render: (text: any, record: any) => record.description,
  },
];

export const getTimeDifferenceFromGMT = (timeZone: any) => {
  const date = new Date();
  const offsetInMinutes: any = date.toLocaleString("en-US", { timeZone, timeZoneName: "short" }).split(" ")[3];
  const offset = offsetInMinutes > 0 ? "+" + offsetInMinutes : offsetInMinutes;
  return offset;
};

export const getZones = async (setLoader: any, setZonesList: any) => {
  setLoader(true);

  try {
    const listOfTimeZones = timeZones;
    const timeDifferences = listOfTimeZones?.map((timeZone: any) => {
      return { label: timeZone + ` (${getTimeDifferenceFromGMT(timeZone)})` };
    });

    setZonesList(timeDifferences);
  } catch (error) {
    console.error(ErrorMessages?.timeZoneFetchingError, error);
  } finally {
    setLoader(false);
  }
};
