import React, { useEffect, useState } from "react";
import "../../channel/channel.scss";
import { useSelector } from "react-redux";
import BasicDetailsForm from "./BasicDetailsForm";
import PersonalDetailsForm from "./PersonalDetailsForm";
import CommonHeader from "./CommonHeader";
import Stepper from "./Stepper";
import { useTranslation } from "react-i18next";

const SingleUploadDirectory = (props: any) => {
  const { t } = useTranslation();

  const { handleCloseModal } = props;
  const currentTab = useSelector((state: any) => state?.SingleUploadSlice?.courseTab);

  const [currentPosition, setCurrentPosition] = useState(1);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    userType: "User",
    phone: "",
    verifyEmail: "",

    customField: [{ newField: "", fieldLabel: `${t("userManagement.untitled")}`, id: "untitled" }],
  });

  useEffect(() => {}, [currentPosition]);
  const handleToBack = () => {
    setCurrentPosition(1);
  };
  const handleToBackFromPersonal = () => {
    setCurrentPosition(1);
  };

  return (
    <div className="relative add-single-user h-full w-full ">
      {currentPosition === 1 && (
        <div className="basic-form">
          <CommonHeader currentStep={currentTab} handleToBack={handleToBack} className="hidden" />
          <div className="w-full form-content ">
            <Stepper />
            <BasicDetailsForm initialValues={initialValues} setInitialValues={setInitialValues} setCurrentStep={setCurrentPosition} handleCloseModal={handleCloseModal} />
          </div>
        </div>
      )}
      {currentPosition === 2 && (
        <div className="personal-form">
          <CommonHeader handleToBack={handleToBackFromPersonal} />
          <div className="w-full form-content form-content-overflow">
            <Stepper currentPosition={currentPosition} />
            <PersonalDetailsForm handleCloseModal={handleCloseModal} initialValues={initialValues} setInitialValues={setInitialValues} setCurrentStep={setCurrentPosition} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleUploadDirectory;
