import React from "react";
import { Select } from "antd";
import ImageView from "./ImageView"; // Adjust this import as needed

const CustomSelect = ({ suffixIconSrc, className, value, options, onChange, disabled }: any) => {
  return (
    <Select
      disabled={disabled}
      suffixIcon={<ImageView className="custom-select" src={suffixIconSrc} alt="chevronDown" />}
      className={className}
      showSearch
      onChange={onChange}
      placeholder="Category"
      value={value}
      optionFilterProp="label"
      filterOption={(input: any, option: any) => (option?.label ?? "")?.toLowerCase()?.startsWith(input?.toLowerCase())}
    >
      {options.map((option: any) => (
        <Select.Option key={option?.value} value={option?.value} label={option?.label}>
          {option?.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
