import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  featuredList: {}
}

const initialState: AuthState = {
    featuredList: {},
};

const courseTabSlice = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setFeatureList: (state, action: PayloadAction<boolean>) => {
      state.featuredList = action.payload;
    },
    clearFeatureList: state => {
      state.featuredList = false;
    },
  },
});

export const { setFeatureList, clearFeatureList } = courseTabSlice.actions;
export default courseTabSlice.reducer;
