import { Navigate, Outlet } from 'react-router-dom';
import { retrieveData } from '../../services/storage/Storage';

function ProtectedRoute() {
    const token = retrieveData("authToken", true);

    if (!token) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
}
export default ProtectedRoute;
