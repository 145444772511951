import { retrieveData } from "../services/storage/Storage";

export const getHeaders = () => {
  const routingConfig: any = retrieveData("routingConfig", true);
  const headers: HeadersInit = {
    "Content-Type": "application/json", // Ensure the content type is set
  };

  const realmName = retrieveData("realmName", true);
  if (realmName) {
    headers["realm-Name"] = realmName; // Add realm-Name header if it exists
  }

  if (routingConfig) {
    if (routingConfig?.ipv4 !== "Not Available") {
      headers["ip"] = routingConfig?.ipv4;
    } else {
      headers["ip"] = routingConfig?.ipv6;
    }
    headers["device-Type"] = routingConfig?.deviceType;
    headers["operating-System"] = routingConfig?.operatingSystem || "";
  }

  return headers;
};
