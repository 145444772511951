import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { prerequisitesTable } from "../../../constant/prerequisites/prerequisites";
import generateTitle from "../../Table/generateTitle";
import { SortOrder } from "antd/lib/table/interface";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveData, storeData } from "../../../services/storage/Storage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { handleRequestError } from "../../toast/ErrorNotificationMessage";
import Loader from "../../component/Loader";
import { useTranslation } from "react-i18next";

const ApplicationDetailsModal = (props: any) => {
  const { t } = useTranslation();
  const { handleClose, isActive, selectedApplication, active, selectedApplicationName } = props;
  const navigate = useNavigate();
  const applicationUserList = useGetApiRequests("getApplicationUserList", "GET");
  const getMFAUserList = useGetApiRequests("getMFAUserList", "GET");

  const tenantActiveStep = useSelector((state: any) => state?.CustomerSlice?.tenantActiveStep);
  const tenantAdminRealmId = useSelector((state: any) => state?.CustomerSlice?.tenantAdminRealmId);
  const customerRealmId = useSelector((state: any) => state?.CustomerSlice?.customerRealmId);

  const realmId = retrieveData("realmId", true);
  const appsData = useSelector((state: any) => state?.dashboardDetails?.data?.applicationAssignedUserDetailsDto?.applicationAssignedUserDetailsCountList);

  const [open, setOpen] = useState<boolean>(false);
  const [customerTableData, setCustomerTableData] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setOpen(isActive);
    if (active) {
      getMFAChartDetails();
    } else {
      fetchUserList();
    }
  }, [isActive]);

  const fetchUserList = async () => {
    setLoader(true);
    let transformedRealmId = "";
    if (tenantActiveStep === 1) {
      transformedRealmId = tenantAdminRealmId;
    } else if (tenantActiveStep === 2) {
      transformedRealmId = customerRealmId;
    } else {
      transformedRealmId = realmId;
    }
    const clientAppObject = appsData?.find((item: any) => item?.name === selectedApplicationName);
    const pathParams: object = {
      realmId: `${transformedRealmId}/user-details`,
    };
    const queryParams: object = {
      clientAppId: clientAppObject?.clientAppId,
    };
    try {
      const response: any = await applicationUserList("", queryParams, pathParams);
      const status = response.status;

      if (status === 200) {
        setLoader(false);
        const transformedArray = response?.data?.data?.map((user: any) => ({
          key: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.username,
        }));
        setCustomerTableData(transformedArray);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const getMFAChartDetails = async () => {
    let transformedRealmId = "";
    if (tenantActiveStep === 1) {
      transformedRealmId = tenantAdminRealmId;
    } else if (tenantActiveStep === 2) {
      transformedRealmId = customerRealmId;
    } else {
      transformedRealmId = realmId;
    }
    const pathParams: object = {
      type: selectedApplicationName,
      realmId: `${transformedRealmId}`,
    };
    try {
      const response: any = await getMFAUserList("", pathParams);
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        const transformedArray = response?.data?.data?.map((elm: any) => ({
          key: elm.userId,
          name: elm.name,
          email: elm.email,
        }));
        setCustomerTableData(transformedArray);
      }
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const columns = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("common.name"), // Use translation function here
          sortColumns?.find(({ column }: any) => column.key === "name"),
        ),
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      sorter: (a: any, b: any) => a?.name?.localeCompare(b?.name),
      render: (text: any, record: any) => (
        <div>
          <p role="none" onClick={() => handleEditUser(record)} className={`${text?.toLowerCase()} credentials-data text-[#5441da] cursor-pointer`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("common.email"), // Use translation function here
          sortColumns?.find(({ column }: any) => column.key === "email"),
        ),
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      render: (text: any) => (
        <div>
          <p className="credentials-data cursor-pointer">
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
  ];

  const handleEditUser = (records: any) => {
    storeData("currentSelectedRow", records, true);
    navigate("/user/edit");
  };
  return (
    <Modal className="rounded-lg app-details" maskClosable={false} centered open={open} footer={false} onCancel={handleClose}>
      <div className="modal-header">
        <p className="title">Application ({selectedApplication})</p>
      </div>
      <div className="modal-body  justify-center  pt-4  ">
        <div className="w-full ">
          <p className=" count  pb-4">
            {" "}
            {t("common.totalUsersCount")} : {customerTableData?.length}
          </p>
          <div className="w-full">
            <Table
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: prerequisitesTable.pageSizeOptions,
              }}
              columns={columns}
              dataSource={customerTableData}
              loading={loader}
              locale={{
                triggerDesc: `${t("common.triggerDesc")}`,
                triggerAsc: `${t("common.triggerAsc")}`,
                cancelSort: `${t("common.cancelSort")}`,
              }}
            />
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </Modal>
  );
};

export default ApplicationDetailsModal;
