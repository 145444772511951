import React, { useState } from "react";
import { getPermissionStyle, hasPermission } from "../../../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../../../const/RolePermissions";
import { useDispatch, useSelector } from "react-redux";
import ProvisioningStatusToggle from "../status-toggle/provisioning-status-toggle/ProvisioningStatusToggle";
import ActivateProvisionModal from "../modal/ActivateProvisionModal";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import DeActivateProvisionSuccessModal from "../modal/DeActivateProvisionSuccessModal";
import ProvisionEditStatusModal from "../modal/ProvisionEditStatusModal";
import PermissionsModal from "../../../../../layouts/permissionsModal/PermissionsModal";
import { cursorNotAllowedStyle } from "../../../../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { setAppConfigDetails } from "../../../../../redux/slice/app-store/AppDetailsSlice";
import { setAppRender } from "../../../../../redux/slice/appsRenderSlice";
import { Tooltip } from "antd";
import { retrieveData, storeData } from "../../../../../services/storage/Storage";
import { useTranslation } from "react-i18next";
import usePermissionModal from "../../../../../hooks/permission/usePermissionModal.hook";
export default function ProvisioningCardOption({ item, setLoader }: any) {
  const { t } = useTranslation();
  const { permissionModal, handleOpenPermissionModal, handleClosePermissionModal } = usePermissionModal();

  const userProfileDetails = useSelector((state: any) => state?.ProfileDetailsSlice?.profileDetails);
  const isMaster = userProfileDetails?.customerType === "MASTER";

  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const appDetailsSlice = useSelector((state: any) => state?.AppDetailsSlice);

  const hasCreateProvisioningPermission = permissions && hasPermission(permissions, modulePermissions.provisioning, "create");
  const hasEditProvisioningPermission = permissions && hasPermission(permissions, modulePermissions.provisioning, "update");

  const activateDeactivateConnector = useGetApiRequests("provisioningConnector", "PUT");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);

  const [activateProvisionModal, setActivateProvisionModal] = useState<boolean>(false);
  const [deActivateSuccessModalOpen, setDeActivateSuccessModalOpen] = useState<boolean>(false);
  const [deActivateSuccessMessage, setDeActivateSuccessMessage] = useState<string>("");
  const [activeAccess, setActiveAccess] = useState<boolean>(false);
  const [editAccess, setEditAccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const handleCloseActivateProvisionModal = () => {
    setActivateProvisionModal(false);
  };

  const activateConnectorFunction = () => {
    createActivateDeactivatePayload(item);
    handleCloseActivateProvisionModal();
  };

  const createActivateDeactivatePayload = async (item: any) => {
    setLoader(true);
    const realmId = retrieveData("realmId", true);
    const payload = { ...item.akkuProvisioningConnectorDto };
    const akkuProvisioningConnectorDto = item?.akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorDto;
    const akkuMasterClientId = item?.akkuMasterClientDto?.akkuMasterClientId || akkuProvisioningConnectorDto?.akkuMasterClientDto?.akkuMasterClientId;
    payload.isActive = !item?.akkuProvisioningConnectorDto?.isActive;
    payload.akkuProvisioningConnectorAuthConfigId = item?.akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorAuthConfigId;
    payload.akkuMasterClientId = akkuMasterClientId;
    payload.realmId = realmId;
    const pathParams: any = {
      id: item?.akkuProvisioningConnectorDto?.akkuProvisioningConnectorId,
    };
    try {
      const response: any = await activateDeactivateConnector(payload, "", pathParams);
      const status = response.status;
      if (status === 200) {
        setDeActivateSuccessModalOpen(true);
        const activeStatus = item?.akkuProvisioningConnectorDto?.isActive;
        setDeActivateSuccessMessage(
          activeStatus ? `${t("appManagement.provisioningEngineDeactivated")}` : `${t("appManagement.provisioningEngineActivated")}`,
        );
        setTimeout(() => {
          handleCloseDeActivateSuccessModal();
          dispatch(setAppRender(!reInitiateApps));
          setLoader(false);
        }, 3000);
      }
    } catch (err) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const handleCloseDeActivateSuccessModal = () => {
    setDeActivateSuccessModalOpen(false);
  };

  const handleActiveProvisioning = () => {
    if (
      item?.akkuProvisioningConnectorDto?.isGroupOuProvisioningConfigured ||
      item?.akkuProvisioningConnectorDto?.isRoleProvisioningConfigured ||
      item?.akkuProvisioningConnectorDto?.isUserProvisioningConfigured
    ) {
      if (hasEditProvisioningPermission) {
        createActivateDeactivatePayload(item);
      } else {
        handleOpenPermissionModal();
      }
    } else {
      setEditAccess(true);
      setMessage(t("appManagement.requestConfigureProvisioning"));
    }
  };
  const handleDeActiveProvisioning = () => {
    if (hasEditProvisioningPermission) {
      createActivateDeactivatePayload(item);
    } else {
      handleOpenPermissionModal();
    }
  };

  const handleCloseActiveAccessModal = () => {
    setActiveAccess(false);
  };

  const redirectProvisioning = (item: any) => {
    storeData("currentAppName", item?.name, true);
    if (!isMaster) {
      if (hasCreateProvisioningPermission) {
        try {
          const publishedAppsList = appDetailsSlice.publishedAppsList || [];
          const akkuProvisioningConnectorDto = item?.akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorDto;
          const akkuMasterClientId = akkuProvisioningConnectorDto?.akkuMasterClientDto?.akkuMasterClientId || item.akkuMasterClientId;
          let publishedAppDto = null;
          publishedAppsList.find((pubApp: any) => {
            if ((pubApp.akkuMasterClientId || pubApp.akkuMasterClient?.akkuMasterClientId) === akkuMasterClientId) {
              publishedAppDto = pubApp;
            }
          });

          dispatch(setAppConfigDetails(publishedAppDto));
          navigate("/publish-provision");
        } catch (e) {
          console.error("Error in finding published app", e);
        }
      } else {
        handleOpenPermissionModal();
      }
    } else {
      if (hasCreateProvisioningPermission) {
        dispatch(setAppConfigDetails(item));
        navigate("/account-provision");
      } else {
        handleOpenPermissionModal();
      }
    }
  };

  const handleCloseEditAccessModal = () => {
    setEditAccess(false);
  };

  const isTenantAdminConfigureButton = (item: any) => {
    const publishedAppsList = appDetailsSlice.publishedAppsList || [];
    const akkuProvisioningConnectorDto = item?.akkuProvisioningConnectorAuthConfigDto?.akkuProvisioningConnectorDto;
    const akkuMasterClientId =
      akkuProvisioningConnectorDto?.akkuMasterClientDto?.akkuMasterClientId ||
      item.akkuMasterClientId ||
      item.akkuMasterClientDto?.akkuMasterClientId;
    const configStatus = publishedAppsList.find((pubApp: any) => {
      if ((pubApp.akkuMasterClientId || pubApp.akkuMasterClient?.akkuMasterClientId) === akkuMasterClientId) {
        return true;
      }
    });
    if (!configStatus) {
      return (
        <>
          {hasCreateProvisioningPermission ? (
            <Tooltip title={"Connector not available at this moment"}>
              <p
                className={`app-configure-btn app-configure-btn-disabled font-Inter text-[#fff] ${cursorNotAllowedStyle(hasCreateProvisioningPermission)}`}
                role="none"
              >
                {t("appManagement.configure")}
              </p>
            </Tooltip>
          ) : (
            <p
              className={`app-configure-btn app-configure-btn-disabled font-Inter text-[#fff] ${cursorNotAllowedStyle(hasCreateProvisioningPermission)}`}
              onClick={handleOpenPermissionModal}
              role="none"
            >
              {t("appManagement.configure")}
            </p>
          )}
        </>
      );
    } else {
      return (
        <p
          className={`app-configure-btn font-Inter text-[#fff] hover:bg-[#7c6ae6] ${cursorNotAllowedStyle(hasCreateProvisioningPermission)}`}
          onClick={() => redirectProvisioning(item?.akkuMasterClientDto)}
          role="none"
        >
          {t("appManagement.configure")}
        </p>
      );
    }
  };
  return (
    <>
      {item?.akkuProvisioningConnectorDto ? (
        <ProvisioningStatusToggle
          provisioningStatus={item?.akkuProvisioningConnectorDto?.isActive}
          handleDeActiveProvisioning={handleDeActiveProvisioning}
          handleActiveProvisioning={handleActiveProvisioning}
        />
      ) : !isMaster ? (
        isTenantAdminConfigureButton(item)
      ) : (
        <p
          className={`app-configure-btn font-Inter text-[#fff] hover:bg-[#7c6ae6] ${cursorNotAllowedStyle(hasCreateProvisioningPermission)}`}
          onClick={() => redirectProvisioning(item?.akkuMasterClientDto)}
          role="none"
        >
          {t("appManagement.configure")}
        </p>
      )}

      {deActivateSuccessModalOpen && (
        <DeActivateProvisionSuccessModal
          open={deActivateSuccessModalOpen}
          handleModalClose={handleCloseDeActivateSuccessModal}
          responseMessage={deActivateSuccessMessage}
        />
      )}

      {activeAccess && (
        <ProvisionEditStatusModal
          open={activeAccess}
          handleModalClose={handleCloseActiveAccessModal}
          message={t("appManagement.requestActivateProvisioning")}
        />
      )}
      {editAccess && <ProvisionEditStatusModal open={editAccess} handleModalClose={handleCloseEditAccessModal} message={message} />}
      {activateProvisionModal && (
        <ActivateProvisionModal
          open={activateProvisionModal}
          handleClose={handleCloseActivateProvisionModal}
          activateConnectorFunction={activateConnectorFunction}
        />
      )}
      {permissionModal && <PermissionsModal open={permissionModal} close={handleClosePermissionModal} />}
    </>
  );
}
