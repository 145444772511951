import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import MappingConnectorAttributes from "./MappingConnectorAttributesNew";
import { useDispatch, useSelector } from "react-redux";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import Loader from "../../../../../layouts/component/Loader";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../../../services/storage/Storage";
import "./ProvisionMapping.scss";
import {
  setActiveStep,
  setEndPointResponseDtos,
  setIsGroupProvisionConfigured,
  setIsRoleProvisionConfigured,
  setMappingAttributes,
} from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import SuccessMessageModal from "../../../../access-manager/create/IpTimeSuccessModal";
import { mappingAttributesList } from "../helper/connectorHelper";
import { useTranslation } from "react-i18next";
const ProvisionMappingAttributes = () => {
  const { t, i18n } = useTranslation();
  const formKey = 1;
  const dispatch = useDispatch();
  //     deleteRoleUserData: [{ source: "", target: "", userType: "", id: "" }],
  //deleteGroupUserData: [{ source: "", target: "", userType: "", id: "" }],
  const provisioning = useSelector((state: any) => state?.provisioning);
  const mappingAttributes = useSelector((state: any) => state?.provisioning?.mappingAttributes);
  const provisioningConnectorDetails = useGetApiRequests("provisioningConnectorDetails", "GET");
  const provisionSourceAttributes = useGetApiRequests("provisionSourceAttributes", "GET");
  const provisioningMappingAttributesUpdate = useGetApiRequests("provisioningMappingAttributesUpdate", "PUT");
  const realmId = retrieveData("realmId", true);
  const [loader, setLoader] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(mappingAttributes);
  const [target, setTarget] = useState([]);
  const [source, setSource] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [noOfApiCalls, setNoOfApiCalls] = useState(); // do not set initial value as 0
  const [responseMessage, setResponseMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const [createUserTarget, setCreateUserTarget] = useState([]);
  const [deleteUserTarget, setDeleteUserTarget] = useState([]);
  const [roleUserTarget, setRoleUserTarget] = useState([]);
  const [groupUserTarget, setGroupUserTarget] = useState([]);
  const [deleteRoleUserTarget, setDeleteRoleUserTarget] = useState([]);
  const [deleteGroupUserTarget, setDeleteGroupUserTarget] = useState([]);

  const [isCreateUserConfigured, setIsCreateUserConfigured] = useState<boolean>(false);
  const [isDeleteUserConfigured, setIsDeleteUserConfigured] = useState<boolean>(false);
  const [isRoleUserConfigured, setIsRoleUserConfigured] = useState<boolean>(false);
  const [isGroupUserConfigured, setIsGroupUserConfigured] = useState<boolean>(false);
  const [isDeleteGroupUserConfigured, setIsDeleteGroupUserConfigured] = useState<boolean>(false);
  const [isDeleteRoleUserConfigured, setIsDeleteRoleUserConfigured] = useState<boolean>(false);

  const [connectorDetails, setConnectorDetails] = useState<any>([]);
  const transformList: any = [];

  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Update the validation schema when the language changes
    const updateValidationSchema = async () => {
      if (formikRef.current) {
        formikRef.current.validateForm(); // Revalidate the form to update the error messages
      }
    };

    updateValidationSchema();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    i18n.language,
    isCreateUserConfigured,
    isDeleteUserConfigured,
    isRoleUserConfigured,
    isGroupUserConfigured,
    isDeleteGroupUserConfigured,
    isDeleteRoleUserConfigured,
  ]);
  const loadValidationSchema = async () => {
    try {
      const isCreateUser = mappingAttributesList("createUserData", mappingAttributes);
      setIsCreateUserConfigured(isCreateUser);

      const isDeleteUser = mappingAttributesList("deleteUserData", mappingAttributes);
      setIsDeleteUserConfigured(isDeleteUser);

      const isAssignRole = mappingAttributesList("roleUserData", mappingAttributes);
      setIsRoleUserConfigured(isAssignRole);
      dispatch(setIsRoleProvisionConfigured(isAssignRole));

      const isGroupList = mappingAttributesList("groupUserData", mappingAttributes);
      setIsGroupUserConfigured(isGroupList);
      dispatch(setIsGroupProvisionConfigured(isGroupList));

      const isDeleteGroup = mappingAttributesList("deleteGroupUserData", mappingAttributes);
      setIsDeleteGroupUserConfigured(isDeleteGroup);

      const isDeleteRole = mappingAttributesList("deleteRoleUserData", mappingAttributes);
      setIsDeleteRoleUserConfigured(isDeleteRole);

      return getValidationSchema(isCreateUser, isDeleteUser, isAssignRole, isGroupList, isDeleteRole, isDeleteGroup);
    } catch (error) {
      console.log(error);
    }
  };
  const getValidationSchema = (
    isCreateUser: boolean,
    isDeleteUser: boolean,
    isAssignRole: boolean,
    isGroupList: boolean,
    isDeleteRoleUserConfigured: boolean,
    isDeleteGroupUserConfigured: boolean,
  ) => {
    return Yup.object({
      createUserData: isCreateUser
        ? Yup.array().of(
            Yup.object({
              source: Yup.string().required(t("appManagement.provisioning.errors.sourceRequired")),
              target: Yup.string().required(t("appManagement.provisioning.errors.targetRequired")),
            }),
          )
        : Yup.array().notRequired(),

      deleteUserData: isDeleteUser
        ? Yup.array().of(
            Yup.object({
              source: Yup.string().required(t("appManagement.provisioning.errors.sourceRequired")),
              target: Yup.string().required(t("appManagement.provisioning.errors.targetRequired")),
            }),
          )
        : Yup.array().notRequired(),

      roleUserData: isAssignRole
        ? Yup.array().of(
            Yup.object({
              source: Yup.string().required(t("appManagement.provisioning.errors.sourceRequired")),
              target: Yup.string().required(t("appManagement.provisioning.errors.targetRequired")),
            }),
          )
        : Yup.array().notRequired(),

      groupUserData: isGroupList
        ? Yup.array().of(
            Yup.object({
              source: Yup.string().required(t("appManagement.provisioning.errors.sourceRequired")),
              target: Yup.string().required(t("appManagement.provisioning.errors.targetRequired")),
            }),
          )
        : Yup.array().notRequired(),

      deleteGroupUserData: isDeleteGroupUserConfigured
        ? Yup.array().of(
            Yup.object({
              source: Yup.string().required(t("appManagement.provisioning.errors.sourceRequired")),
              target: Yup.string().required(t("appManagement.provisioning.errors.targetRequired")),
            }),
          )
        : Yup.array().notRequired(),

      deleteRoleUserData: isDeleteRoleUserConfigured
        ? Yup.array().of(
            Yup.object({
              source: Yup.string().required(t("appManagement.provisioning.errors.sourceRequired")),
              target: Yup.string().required(t("appManagement.provisioning.errors.targetRequired")),
            }),
          )
        : Yup.array().notRequired(),
    });
  };
  const handleSubmit = async (values: any) => {
    const transformPayload = createPayloads(connectorDetails?.endPointResponseDtos, values);
    if (isDirty) {
      // const filteredData = provisioning?.endPointResponseDtos?.filter((item: any) => {
      //   if (item?.endpointDescription === "create_user" || item?.endpointDescription === "delete_user") {
      //     return item;
      //   }
      // });
      // const transformPayload = createPayloads(filteredData, values?.forms);
      setNoOfApiCalls(transformPayload?.length);
      dispatch(setMappingAttributes(values));
      // dispatch(setMappingAttributesPayloadCreation(transformPayload));
      transformPayload?.map((transformPayload: any) => {
        updateProvisionAttributes(transformPayload);
      });
    } else {
      dispatch(setActiveStep(6));
      // if (isGroupUserConfigured && !isRoleUserConfigured) {
      //   dispatch(setActiveStep(6));
      // } else if (!isGroupUserConfigured && isRoleUserConfigured) {
      //   dispatch(setActiveStep(7));
      // } else if (isGroupUserConfigured && isRoleUserConfigured) {
      //   dispatch(setActiveStep(6));
      // } else if (!isGroupUserConfigured && !isRoleUserConfigured) {
      //   dispatch(setActiveStep(8));
      // }
    }
  };

  useEffect(() => {
    getProvisioningConnectorDetails();
    getProvisionSourceAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiHitCount === noOfApiCalls) {
      setOpenSuccessModal(true);
      setTimeout(() => {
        setLoader(false);
        handleCloseSuccessModal();
        dispatch(setActiveStep(6));

        // if (isGroupUserConfigured && !isRoleUserConfigured) {
        //   dispatch(setActiveStep(6));
        // } else if (!isGroupUserConfigured && isRoleUserConfigured) {
        //   dispatch(setActiveStep(7));
        // } else if (isGroupUserConfigured && isRoleUserConfigured) {
        //   dispatch(setActiveStep(6));
        // } else if (!isGroupUserConfigured && !isRoleUserConfigured) {
        //   dispatch(setActiveStep(8));
        // }
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiHitCount]);

  const createPayloads = (provisioning: any, values: any) => {
    try {
      // Combine createUserData and deleteUserData for mapping
      const combinedUserData = [
        ...(values?.createUserData || []),
        ...(values?.deleteUserData || []),
        ...(values?.roleUserData || []),
        ...(values?.groupUserData || []),
        ...(values?.deleteGroupUserData || []),
        ...(values?.deleteRoleUserData || []),
      ];

      // Create a map for quick lookup of targets to sources
      const targetToSourceMap = combinedUserData?.reduce((map: any, result: any) => {
        map[result?.target] = result?.source;
        return map;
      }, {});

      // Generate payloads based on the provisioning data
      return provisioning?.reduce((payloads: any, endpoint: any) => {
        // Combine attributeMappings and headerParameters into one object
        const combinedMappings = {
          ...endpoint?.attributeMappings,
          ...endpoint?.headerParameters,
          ...endpoint?.pathVariable,
        };

        // Create the attributesMap
        const attributesMap = Object.entries(combinedMappings)?.reduce((map: any, [target, value]) => {
          if (targetToSourceMap.hasOwnProperty(target)) {
            map[target] = targetToSourceMap[target];
          }
          return map;
        }, {});

        // If attributesMap is not empty, create a payload
        if (Object.keys(attributesMap)?.length > 0) {
          payloads?.push({
            akkuProvisioningConnectorEndpointConfigId: endpoint?.akkuProvisioningConnectorEndpointConfigId,
            attributesMap: attributesMap,
          });
        }
        // Check for missing attributes in values and add empty string if both key and value are present
        Object.entries(combinedMappings)?.forEach(([key, value]) => {
          // Check if key is in attributeMappings or headerParameters, and value is present
          if (!attributesMap?.hasOwnProperty(key) && !targetToSourceMap?.hasOwnProperty(key)) {
            // Only add key if it exists in attributeMappings or headerParameters with a trimmed value
            const isInAttributeMappings =
              endpoint?.attributeMappings && key in endpoint.attributeMappings && endpoint?.attributeMappings[key]?.trim() !== "";
            const isInHeaderParameters =
              endpoint?.headerParameters && key in endpoint.headerParameters && endpoint?.headerParameters[key]?.trim() !== "";
            if (isInAttributeMappings || isInHeaderParameters) {
              attributesMap[key] = ""; // Add key with empty string value
            }
          }
        });
        return payloads;
      }, []);
    } catch (err) {
      console.log(err);
    }
  };

  const updateProvisionAttributes = async (transformPayload: any) => {
    setLoader(true);
    const params = {
      akkuProvisioningConnectorEndpointConfigId: `${transformPayload?.akkuProvisioningConnectorEndpointConfigId}/attributes`,
    };
    const payload = { attributesMap: transformPayload?.attributesMap };
    try {
      const response: any = await provisioningMappingAttributesUpdate(payload, "", params);
      const status = response?.status;
      if (status === 200) {
        const message = response?.data?.message;
        setResponseMessage(message);
        // setLoader(false);
        setApiHitCount((prevCount) => prevCount + 1);
      }

      // setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  const getProvisioningConnectorDetails = async () => {
    setLoader(true);
    const params = {
      akkuProvisioningConnectorId: provisioning?.akkuProvisioningConnectorId,
    };
    const queryParams = { realmId: retrieveData("realmId", true) };
    try {
      const response: any = await provisioningConnectorDetails("", queryParams, params);
      const status = response?.status;
      if (status === 200) {
        // const data = response?.data?.data;
        const responseData = response?.data?.data;
        setConnectorDetails(responseData);
        const attributeMappingKeys = await extractKeys(responseData);
        const transformedData = processEndpointData(responseData);
        const filteredData = responseData?.endPointResponseDtos?.filter((item: any) => {
          if (
            item?.endpointDescription === "create_user" ||
            item?.endpointDescription === "delete_user" ||
            item?.endpointDescription === "assign_role" ||
            item?.endpointDescription === "role_list" ||
            item?.endpointDescription === "assign_group" ||
            item?.endpointDescription === "group_ou_list" ||
            item?.endpointDescription === "delete_group" ||
            item?.endpointDescription === "delete_role"
          ) {
            return item;
          }
        });
        const createUserData: any = [];
        const deleteUserData: any = [];
        const roleUserData: any = [];
        const groupUserData: any = [];
        const deleteRoleUserData: any = [];
        const deleteGroupUserData: any = [];

        const processData = (arr: any) => {
          arr.forEach((item: any) => {
            if (item.endpointDescription === "create_user") {
              createUserData.push(item);
            } else if (item.endpointDescription === "delete_user") {
              deleteUserData.push(item);
            } else if (item.endpointDescription === "assign_role" || item.endpointDescription === "role_list") {
              roleUserData.push(item);
            } else if (item.endpointDescription === "assign_group" || item.endpointDescription === "group_ou_list") {
              groupUserData.push(item);
            } else if (item.endpointDescription === "delete_group") {
              deleteGroupUserData.push(item);
            } else if (item.endpointDescription === "delete_role") {
              deleteRoleUserData.push(item);
            }
          });
        };

        processData(filteredData);
        const mappingAttributesData = mapResponseToAttributes(filteredData);
        try {
          const isCreateUser = mappingAttributesList("createUserData", mappingAttributesData);
          setIsCreateUserConfigured(isCreateUser);

          const isDeleteUser = mappingAttributesList("deleteUserData", mappingAttributesData);
          setIsDeleteUserConfigured(isDeleteUser);

          const isAssignRole = mappingAttributesList("roleUserData", mappingAttributesData);
          setIsRoleUserConfigured(isAssignRole);
          dispatch(setIsRoleProvisionConfigured(isAssignRole));

          const isGroupList = mappingAttributesList("groupUserData", mappingAttributesData);
          setIsGroupUserConfigured(isGroupList);
          dispatch(setIsGroupProvisionConfigured(isGroupList));

          const isDeleteGroup = mappingAttributesList("deleteGroupUserData", mappingAttributesData);
          setIsDeleteGroupUserConfigured(isDeleteGroup);

          const isDeleteRole = mappingAttributesList("deleteRoleUserData", mappingAttributesData);
          setIsDeleteRoleUserConfigured(isDeleteRole);
          // const validationSchema = await getValidationSchema(isCreateUser, isDeleteUser, isAssignRole, isGroupList);
          // setValidationSchema(validationSchema);
        } catch (error) {
          console.log(error);
        }

        setFormInitialValues(mappingAttributesData);
        dispatch(setMappingAttributes(mappingAttributesData));
        dispatch(setEndPointResponseDtos(transformedData));
        setTarget(attributeMappingKeys);
        setLoader(false);

        const createUserMappingKeys = extractKeysForDropDown(createUserData);
        const deleteUserMappingKeys = extractKeysForDropDown(deleteUserData);
        const roleUserMappingKeys = extractKeysForDropDown(roleUserData);
        const groupUserMappingKeys = extractKeysForDropDown(groupUserData);
        const deleteGroupUserMappingKeys = extractKeysForDropDown(deleteGroupUserData);
        const deleteRoleUserMappingKeys = extractKeysForDropDown(deleteRoleUserData);

        setCreateUserTarget(createUserMappingKeys);
        setDeleteUserTarget(deleteUserMappingKeys);
        setRoleUserTarget(roleUserMappingKeys);
        setGroupUserTarget(groupUserMappingKeys);
        setDeleteGroupUserTarget(deleteGroupUserMappingKeys);
        setDeleteRoleUserTarget(deleteRoleUserMappingKeys);

        const mappedAttributesCreateUserData = mapResponseToAttributes(filteredData);
        const mappedAttributesDeleteUserData = mapResponseToAttributes(filteredData);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const getProvisionSourceAttributes = async () => {
    setLoader(true);
    let params = {
      realmId: realmId,
    };
    try {
      const response: any = await provisionSourceAttributes("", params);
      const status = response?.status;
      if (status === 200) {
        const data = response?.data?.data;
        setLoader(false);
        setSource(data?.sourceAttributes);
      }
      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };
  // const extractAttributeMappingKeys = (endpoints: any) => endpoints.flatMap((endpoint: any) => Object.keys(endpoint.attributeMappings).filter((key) => key !== "products"));
  const mapResponseToAttributes = (response: any) => {
    const createUserData: any[] = [];
    const deleteUserData: any[] = [];
    const roleUserData: any[] = [];
    const groupUserData: any[] = [];
    const deleteRoleUserData: any[] = [];
    const deleteGroupUserData: any[] = [];

    response?.forEach((config: any) => {
      const currentData: any = [];

      // Process attributeMappings
      Object.entries(config?.attributeMappings || {})?.forEach(([target, source]: any) => {
        if (target && source?.trim()) {
          currentData.push({
            source: source.trim(), // Trim whitespace
            target,
            userType: "",
          });
        }
      });

      // Process headerParameters
      Object.entries(config?.headerParameters || {})?.forEach(([target, source]: any) => {
        if (target && source?.trim()) {
          currentData.push({
            source: source.trim(),
            target,
            userType: "",
          });
        }
      });

      // Process pathVariable for all relevant endpoints (including delete_user and assign_group)
      // if (config?.endpointDescription === "delete_user" || config?.endpointDescription === "assign_group") {
      Object.entries(config?.pathVariable || {})?.forEach(([target, source]: any) => {
        if (target && source?.trim()) {
          currentData.push({
            source: source.trim(),
            target,
            userType: "",
          });
        }
      });
      // }

      // Push to respective arrays based on endpointDescription
      if (config?.endpointDescription === "create_user") {
        createUserData.push(...currentData);
      } else if (config?.endpointDescription === "delete_user") {
        deleteUserData.push(...currentData);
      } else if (config?.endpointDescription === "group_ou_list" || config?.endpointDescription === "assign_group") {
        groupUserData.push(...currentData);
      } else if (config?.endpointDescription === "assign_role" || config?.endpointDescription === "role_list") {
        roleUserData.push(...currentData);
      } else if (config?.endpointDescription === "delete_role") {
        deleteRoleUserData.push(...currentData);
      } else if (config?.endpointDescription === "delete_group") {
        deleteGroupUserData.push(...currentData);
      }
    });

    // Ensure at least one form is returned with default structure if the result is empty
    if (createUserData.length === 0) {
      createUserData.push({ source: "", target: "", userType: "", id: "" });
    }

    if (deleteUserData.length === 0) {
      deleteUserData.push({ source: "", target: "", userType: "", id: "" });
    }

    if (roleUserData.length === 0) {
      roleUserData.push({ source: "", target: "", userType: "", id: "" });
    }

    if (groupUserData.length === 0) {
      groupUserData.push({ source: "", target: "", userType: "", id: "" });
    }

    if (deleteRoleUserData.length === 0) {
      deleteRoleUserData.push({ source: "", target: "", userType: "", id: "" });
    }

    if (deleteGroupUserData.length === 0) {
      deleteGroupUserData.push({ source: "", target: "", userType: "", id: "" });
    }

    // Return the object directly with createUserData and deleteUserData
    return {
      createUserData,
      deleteUserData,
      roleUserData,
      groupUserData,
      deleteRoleUserData,
      deleteGroupUserData,
    };
  };

  // const mapResponseToAttributes = (response: any) => {
  //   const result: any = [];

  //   response?.forEach((config: any) => {
  //     // Process attributeMappings
  //     Object.entries(config?.attributeMappings || {})?.forEach(([target, source]: any) => {
  //       if (source?.trim()) {
  //         result?.push({
  //           source: source?.trim(),
  //           target,
  //           userType: "",
  //         });
  //       }
  //     });

  //     // Process headerParameters
  //     Object.entries(config?.headerParameters || {})?.forEach(([target, source]: any) => {
  //       if (source?.trim()) {
  //         result?.push({
  //           source: source?.trim(),
  //           target,
  //           userType: "",
  //         });
  //       }
  //     });
  //   });
  //   // Ensure at least one form is returned with default structure if the result is empty
  //   if (result?.length === 0) {
  //     result.push({ source: "", target: "", userType: "", id: "" });
  //   }

  //   return { forms: result };
  // };
  const processEndpointData = (data: any) => {
    return (data?.endPointResponseDtos || [])?.map((endpoint: any) => ({
      akkuProvisioningConnectorEndpointConfigId: endpoint?.akkuProvisioningConnectorEndpointConfigId || "",
      endpointDescription: endpoint?.endpointDescription || "",
      attributeMappings: endpoint?.attributeMappings || {},
      headerParameters: endpoint?.headerParameters || {},
    }));
  };

  const extractKeys = (data: any) => {
    try {
      const result: any = [];
      data?.endPointResponseDtos?.forEach((endpoint: any) => {
        if (
          endpoint?.endpointDescription === "create_user" ||
          endpoint?.endpointDescription === "role_list" ||
          endpoint?.endpoint === "assign_role" ||
          endpoint?.endpointDescription === "group_ou_list" ||
          endpoint?.endpointDescription === "assign_group"
        ) {
          const attributeKeys = Object.keys(endpoint?.attributeMappings || {}).filter(
            (key) => !(data?.name?.toLowerCase() === "jira" && key === "products"),
          );
          // Extract keys from headerParameters
          const headerKeys = Object.keys(endpoint?.headerParameters || {});

          result.push(...attributeKeys, ...headerKeys);
        }
        // Extract keys from attributeMappings, excluding 'products' if name is jira
        if (
          endpoint?.endpointDescription === "delete_user" ||
          endpoint?.endpointDescription === "delete_role" ||
          endpoint?.endpointDescription === "delete_group"
        ) {
          const attributeKeys = Object.keys(endpoint?.attributeMappings || {}).filter(
            (key) => !(data?.name?.toLowerCase() === "jira" && key === "products"),
          );
          // Extract keys from headerParameters
          const headerKeys = Object.keys(endpoint?.headerParameters || {});
          const pathKeys = Object.keys(endpoint?.pathVariable || {});
          result.push(...attributeKeys, ...headerKeys, ...pathKeys);
        }
      });

      return result;
    } catch (e) {
      console.log("extractKeys errors", e);
    }
  };

  const extractKeysForDropDown = (data: any) => {
    try {
      const result: any = [];
      const name = data?.name?.toLowerCase(); // Assuming name is at the top level of the data array

      data?.forEach((endpoint: any) => {
        // Check for create_user, role_list, assign_role, group_ou_list, assign_group conditions
        if (
          endpoint?.endpointDescription === "create_user" ||
          endpoint?.endpointDescription === "role_list" ||
          endpoint?.endpointDescription === "assign_role" ||
          endpoint?.endpointDescription === "group_ou_list" ||
          endpoint?.endpointDescription === "assign_group"
        ) {
          let attributeKeys = Object.keys(endpoint?.attributeMappings || {});
          const headerKeys = Object.keys(endpoint?.headerParameters || {});
          const pathKeys = Object.keys(endpoint?.pathVariable || {});

          // If name is 'jira', filter out 'products' from attributeMappings
          if (name === "jira") {
            attributeKeys = attributeKeys.filter((key) => key !== "products");
          }

          result.push(...attributeKeys, ...headerKeys, ...pathKeys);
        }

        // Check for delete_user condition
        if (
          endpoint?.endpointDescription === "delete_user" ||
          endpoint?.endpointDescription === "delete_role" ||
          endpoint?.endpointDescription === "delete_group"
        ) {
          let attributeKeys = Object.keys(endpoint?.attributeMappings || {});
          const headerKeys = Object.keys(endpoint?.headerParameters || {});
          const pathKeys = Object.keys(endpoint?.pathVariable || {});

          // If name is 'jira', filter out 'products' from attributeMappings
          if (name === "jira") {
            attributeKeys = attributeKeys.filter((key) => key !== "products");
          }

          result.push(...attributeKeys, ...headerKeys, ...pathKeys);
        }
      });

      return result;
    } catch (e) {
      console.log("extractKeys errors", e);
      return [];
    }
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  return (
    <div className="w-full mapping-attributes relative h-full connector-provision ">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-125px)]">
        <p className="app-header-title">
          {t("appManagement.provisioning.mappingAttributes")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
        </p>

        <div className="w-full mapping-attributes">
          <Formik
            initialValues={formInitialValues}
            validationSchema={getValidationSchema(
              isCreateUserConfigured,
              isDeleteUserConfigured,
              isRoleUserConfigured,
              isGroupUserConfigured,
              isDeleteRoleUserConfigured,
              isDeleteGroupUserConfigured,
            )}
            formKey={formKey}
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
              <Form>
                <MappingConnectorAttributes
                  values={values}
                  setFieldValue={setFieldValue}
                  transformList={transformList}
                  setFormInitial={setFormInitialValues}
                  target={target}
                  source={source}
                  handleSubmit={handleSubmit}
                  setIsDirty={setIsDirty}
                  createUserTarget={createUserTarget}
                  deleteUserTarget={deleteUserTarget}
                  roleUserTarget={roleUserTarget}
                  groupUserTarget={groupUserTarget}
                  deleteRoleUserTarget={deleteRoleUserTarget}
                  deleteGroupUserTarget={deleteGroupUserTarget}
                  setFieldTouched={setFieldTouched}
                  errors={errors}
                  touched={touched}
                  isCreateUserConfigured={isCreateUserConfigured}
                  isDeleteUserConfigured={isDeleteUserConfigured}
                  isRoleUserConfigured={isRoleUserConfigured}
                  isGroupUserConfigured={isGroupUserConfigured}
                />
              </Form>
            )}
          </Formik>
        </div>
        {loader && <Loader />}
        {openSuccessModal && (
          <SuccessMessageModal open={openSuccessModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />
        )}
      </div>
    </div>
  );
};

export default ProvisionMappingAttributes;
