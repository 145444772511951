import { staticThemeUrl, themeImages } from "../../../User/theme";
import { useSelector } from "react-redux";
export default function Footer() {
  const theme = useSelector((state: any) => state?.theme?.themeDetails);
  let brandLogoUrl: string = ""

  if (theme?.themeImagesUrl) {
    brandLogoUrl = `${theme?.themeImagesUrl}${themeImages.footerLogo}`
  }
  else {
    brandLogoUrl = `${staticThemeUrl.imagesUrl}${themeImages.footerLogo}`
  }
  return (
    <footer className="footer">
      <p>
        <img src={brandLogoUrl} alt="footer logo" />
        <span> Copyright Akku. All Rights Reserved</span>
      </p>
    </footer>
  );
}
