import React, { useEffect, useState } from "react";
import PlansHeader from "./PlansHeader";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../toast/ErrorNotificationMessage";
import { triggerNotification } from "../../../../../toast/ToastBar";
import Loader from "../../../../../component/Loader";
import { ErrorMessages } from "../../../../../../const/Messages";
import { plansDetails } from "./ProductsAndPlanHelperFIle";
import "./Plans.scss";
import { useTranslation } from "react-i18next";
import { setIsPlansDirty } from "../../../../../../redux/slice/CustomerSlice";
import { useDispatch } from "react-redux";

const ProductsAndPlansEdit = (props: any) => {
  const { t } = useTranslation();
  const { customerPlanData, yearlySubscription, setYearlySubscription, plansSelected, setPlansSelected, productsSelected, setProductsSelected, setSelectedId, setFeatureIds } = props;

  const disabled = false;
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loader, setLoader] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const getPlansAndFeatures = useGetApiRequests("customerPlansAndFeatures", "GET");

  useEffect(() => {
    dispatch(setIsPlansDirty(false));
    getActivePlans();
    setPlansSelected(customerPlanData?.planId);
    if (customerPlanData?.featureIdList && customerPlanData?.featureIdList.length > 0) {
      setProductsSelected(true);
    }
    setSelectedId(customerPlanData?.planId);
    setSelectedPlan(customerPlanData?.planId);
    if (customerPlanData?.subscriptionType === "yearly") {
      setYearlySubscription(true);
    }
    if (customerPlanData?.featureIdList && customerPlanData?.featureIdList?.length > 0) setFeatureIds(customerPlanData?.featureIdList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPlanData]);

  const getActivePlans = async () => {
    try {
      setLoader(true);
      const response: any = await getPlansAndFeatures("");
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        if (response?.data?.data?.planList.length > 0) {
          const mergedArray: any = [];
          response.data?.data?.planList.forEach((responseItem: any) => {
            const matchingPlan = plansDetails.find((plan: any) => plan?.planType === responseItem?.name);
            if (matchingPlan) {
              const mergedItem = {
                akkuPlanId: responseItem?.akkuPlanId,
                name: responseItem?.name,
                isActive: responseItem?.isActive,
                isCustom: responseItem?.isCustom,
                include: matchingPlan?.include,
                products: matchingPlan?.products,
                planType: responseItem?.description,
              };
              mergedArray.push(mergedItem);
            }
          });
          const customOrder = ["Basic", "Professional", "Advanced"];
          const sortedPlans = mergedArray.sort((a: any, b: any) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name));

          setAllPlans(sortedPlans);
        } else {
          triggerNotification("warning", "", ErrorMessages?.noPlans, "topRight");
        }
      }
    } catch (err: any) {
      setLoader(false);

      handleRequestError(err);
    }
  };

  const onPlanSelection: CheckboxProps["onChange"] = (e: any) => {
    if (e.target.checked === false) {
      setSelectedId(null);
      setSelectedPlan(null);
    } else {
      setFeatureIds([]);
    }
    setPlansSelected(e.target.checked);
    if (productsSelected) {
      setProductsSelected(false);
    }
  };

  const handleCheckboxChange = (planType: any, e: any, item: any) => {
    dispatch(setIsPlansDirty(true));
    if (e.target.checked === true) {
      setSelectedPlan(item?.akkuPlanId);
      setSelectedId(item?.akkuPlanId);
    } else {
      setSelectedPlan(null);
      setSelectedId(null);
    }
  };

  // Utility function to convert string to camelCase and handle special cases like "SSO/IDP"
  const formatString = (str: string) => {
    return str
      .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase()) // Remove non-alphanumeric characters, capitalize the next letter
      .replace(/^(.)/, (match) => match.toLowerCase()) // Ensure the first letter is lowercase
      .replace(/SSOIDP/i, "ssoIDP"); // Special case handling for "SSO/IDP"
  };
  return (
    <div className="w-full">
      <div className="mb-2 plan-header">
        <PlansHeader yearlySubscription={yearlySubscription} setYearlySubscription={setYearlySubscription} />
      </div>
      <div className="w-full plans-container">
        <p className="plan-note pl-12 text-[#5441DA] font-Inter text-[17px] font-medium italic">
          {t("common.note")}:{t("tenants.youCanSelectYourPlan")}
        </p>

        <div className="plans mt-7 pl-[50px]">
          <div className="plans-selected mb-6 ">
            <Checkbox checked={plansSelected} disabled={disabled} onChange={onPlanSelection}>
              {t("tenants.selectPlan")}
            </Checkbox>
          </div>
          <div className="w-full mx-auto flex flex-wrap">
            {allPlans?.map((elm: any) => (
              <div className="w-[25%] ml-9 flex items-start justify-center product-list" key={elm?.akkuPlanId}>
                <Checkbox disabled={!plansSelected} checked={selectedPlan === elm?.akkuPlanId} className="mr-3" onChange={(e) => handleCheckboxChange(elm.planType, e, elm)} />
                <div className={`${selectedPlan === elm?.akkuPlanId ? "selected" : ""} card w-full`}>
                  <h2>{t(`tenants.${formatString(elm?.planType)}`)}</h2>
                  <p>{t(`tenants.${formatString(elm?.include)}`)}</p>
                  <ul>{elm?.products.map((item: any, index: any) => <li key={index}>{t(`tenants.${formatString(item)}`)}</li>)}</ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default ProductsAndPlansEdit;
