import React from "react";
import { Modal } from "antd";
import CustomButtonBack from "../../../../../../../../layouts/component/CustomButtonBack";
import CustomButtonNext from "../../../../../../../../layouts/component/CustomButton";
import ImageView from "../../../../../../../../layouts/component/ImageView";
import warningIcon from "../../../../../../../../assets/images/user/warning.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function UserCreationNotificationModal({ type, modalOpen, setModalOpen, responseMessage, closeAllModal }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    setModalOpen(false);
  };
  const closeAll = () => {
    setModalOpen(false);
    closeAllModal();
  };
  const handleNavigation = () => {
    navigate("/user");
  };
  return (
    <Modal className="p-10  show-notification  _suspended rounded-lg" closable={false} maskClosable={false} centered open={modalOpen} footer={false} onCancel={handleClose}>
      <div className="modal-body  pt-4 ">
        <div className="flex w-full justify-center items-center flex-col">
          <ImageView src={warningIcon} alt="warningIcon" className="w-20 h-20" />
          <p className="text-[20px] pt-4 text-center font-medium">{responseMessage}</p>
        </div>
      </div>
      <div className="modal-footer pt-8">
        <div className="w-full flex justify-center">
          <CustomButtonBack onClick={handleClose} text={t("common.cancel")} />
          {type === "personalDetails" ? <CustomButtonNext onClick={closeAll} text={t("userManagement.contactSales")} /> : <CustomButtonNext onClick={handleNavigation} text={t("common.proceed")} />}
        </div>
      </div>
    </Modal>
  );
}
