import React, { useEffect, useState } from "react";
import { Button, Input, Tooltip } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import ImageUploadProfile from "../component/ImageUploadProfile";
import * as Yup from "yup";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { handleRequestError } from "../toast/ErrorNotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { triggerNotification } from "../toast/ToastBar";
import "./Profile.scss";
import Loader from "../component/Loader";
import { setUpdateDashboard } from "../../redux/slice/Users/UserDashboardUpdateSlice";
import { useTranslation } from "react-i18next";
import { images } from "../../assets/images/ImagePaths";
import ImageView from "../component/ImageView";
import TermsAndConditionModal from "../../User/UserDashboard/modal/TermsAndConditionModal";
import { RootState } from "./ProfileTypes";

const Profile = (props: any) => {
  const { handleModalClose, setProfileUpdate, profileUpdate } = props;

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(`${t("header.firstNameRequired")}`),
    lastName: Yup.string().required(`${t("header.lastNameRequired")}`),
  });
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
  const keycloak = useSelector((state: RootState) => state?.keycloak);
  const userId: string = keycloak?.KeycloakInfo?.subject || "";
  const toggleTable = useSelector((state: RootState) => state?.UserDashboardUpdateSlice?.updateDashboard);
  const userProfileDetails = useSelector((state: RootState) => state?.ProfileDetailsSlice?.profileDetails);
  const termsAndCondition = useSelector((state: RootState) => state?.ProfileDetailsSlice?.termsAndCondition);

  const dispatch = useDispatch();

  const isActiveClientDashboard = window.location.pathname === "/dashboard";

  const updateProfileApiUrl = isActiveClientDashboard ? "updateClientProfile" : "updateProfile";

  //keycloak.KeycloakInfo.subject
  const updateProfile = useGetApiRequests(updateProfileApiUrl, "PUT");

  const [isFormDirty, setFormDirty] = useState<boolean>(true);
  const [imageData, setImageData] = useState<any>("");
  const [formKey, setKormKey] = useState<number>(1);
  const [termsConditionsModal, setTermsConditionsModal] = useState<boolean>(false);

  const [profileImageRemoved, setProfileImageRemoved] = useState<boolean>(false);
  const [resetImageUpload, setResetImageUpload] = useState<boolean>(false);
  const [imageUrlFromGet, setImageUrlFromGet] = useState<any>(null);
  const [profileLoader, setProfileLoader] = useState(false);
  const [profileFileName, setProfileFileName] = useState<string>("");
  const [initialValues, setInitialValues] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    profileImage: null,
  });

  useEffect(() => {
    getDetails();
    setResetImageUpload(true);
  }, [props]);

  const getDetails = async () => {
    setKormKey(formKey + 1);

    const data = userProfileDetails;
    const updatedInitialValues: any = {};
    await data.attributes.forEach((item: any) => {
      switch (item.name) {
        case "telephoneNumber":
          updatedInitialValues.phoneNumber = item.value;
          break;
        case "profileImage":
          updatedInitialValues.profileImage = item?.value ?? null;
          setImageUrlFromGet(item?.value);
          break;
        case "personal_email_id":
          updatedInitialValues.email = item.value;
          break;
        // You can add more cases for other fields as needed
        default:
          break;
      }
    });
    updatedInitialValues.firstName = data.firstName;
    updatedInitialValues.lastName = data.lastName;
    setInitialValues(updatedInitialValues);
  };
  const onSubmit = async (values: any) => {
    setResetImageUpload(false);
    setProfileLoader(true);
    const payload = {
      userId: userId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      personalEmailId: values.email,
      profileImageRemoved: profileImageRemoved,
    };

    try {
      type HeadersType = {
        "Content-Type": string;
        "User-Id"?: string; // Optional because it might not be present
      };

      let headers: HeadersType = { "Content-Type": "multipart/form-data" };
      headers["User-Id"] = userId;
      const response: any = await updateProfile(imageData === "" ? {} : imageData, { userProfileDto: payload }, {}, headers);
      const status = response.status;
      if (status === 200) {
        const data = response?.data;
        triggerNotification("success", "", data?.message, "topRight");
        setProfileUpdate(!profileUpdate);
        handleModalClose();
        dispatch(setUpdateDashboard(!toggleTable));
      }
      setProfileLoader(false);
    } catch (err: any) {
      handleRequestError(err);
      setProfileLoader(false);
    }
  };

  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    setProfileFileName(file.name);
    setImageData(formData);
    setProfileImageRemoved(false);
    setFormDirty(false);
  };
  const handleImageDelete = (file: File) => {
    setImageData("");
    setProfileFileName("");
    setProfileImageRemoved(true);
    setFormDirty(false);
    setImageUrlFromGet(null);
  };

  const getUserInitial = (name: any) => name?.[0];

  const toggleTermsConditionsModal = () => {
    setTermsConditionsModal((prev) => !prev);
  };

  return (
    <>
      {profileLoader && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        isInitialValid={isFormDirty}
        key={formKey}
      >
        {({ handleSubmit, errors, setFieldValue, dirty }) => (
          <Form
            onSubmit={(e) => {
              setFormDirty(true);
              handleSubmit(e);
            }}
          >
            <div className="w-full flex pt-0 profile">
              <div className="w-[20%] flex mx-auto flex-wrap pt-2  uploadError justify-center relative h-auto cursor-auto">
                <ImageUploadProfile
                  reset={resetImageUpload}
                  initial={getUserInitial(initialValues?.firstName)}
                  className="picture-circle cursor-pointer"
                  imageUrl={imageUrlFromGet && imageBaseUrl + imageUrlFromGet}
                  onImageUpload={handleImageUpload}
                  onImageDelete={handleImageDelete}
                  maxFileSize={1024 * 1024 * 5}
                  allowedTypes={["image/jpeg", "image/png"]}
                />
                {profileFileName && (
                  <Tooltip placement="bottom" title={profileFileName}>
                    <p className="file-color">{profileFileName}</p>
                  </Tooltip>
                )}
                {termsAndCondition && (
                  <div className="absolute bottom-6 flex gap-1 min-w-[170px]">
                    <ImageView src={images.greenCheck} />
                    <span
                      className="text-[#454545] font-Inter cursor-pointer font-medium text-[14px] underline"
                      onClick={toggleTermsConditionsModal}
                      role="none"
                    >
                      {t("termsAndConditions.termsAndConditions")}
                    </span>
                  </div>
                )}
              </div>
              <div className=" w-[70%] flex mx-auto justify-between flex-wrap profile pr-5">
                <div className="mb-7 w-full">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.firstName")}*</p>
                  <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="firstName" maxLength={30} data-testid="fnameId" />
                  <ErrorMessage name="firstName" component="div" className="error-message" />
                </div>
                <div className="mb-7 w-full">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.lastName")}*</p>
                  <Field as={Input} type="text" className="h-[48px] font-medium text-[18px]" name="lastName" maxLength={30} data-testid="lnameId" />
                  <ErrorMessage name="lastName" component="div" className="error-message" />
                </div>
                <div className="mb-7  w-full">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.personalEmailId")}*</p>
                  <Field as={Input} type="email" className={`h-[48px] font-medium text-[18px]`} name="email" data-testid="emailId" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>
                <div className="mb-7  w-full">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("common.phoneNumber")}*</p>
                  <PhoneInput
                    inputClass="ant-input h-[48px] w-[100%] "
                    country={"us"}
                    value={initialValues.phoneNumber}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                  />
                  <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                </div>
                <div className="flex justify-end w-full mt-4">
                  <Button
                    htmlType="submit"
                    disabled={isFormDirty && !dirty}
                    loading={profileLoader}
                    className="btn bg-[#5441DA] w-[153px] h-[55px] font-Inter text-[20px]"
                    type="primary"
                  >
                    {t("common.update")}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {termsConditionsModal && <TermsAndConditionModal profile={true} open={termsConditionsModal} handleClose={toggleTermsConditionsModal} />}
    </>
  );
};

export default Profile;
