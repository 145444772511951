import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const GroupsBreadcrumb = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <ul className="flex breadcrumb">
        <li
          role="none"
          className="font-Inter pr-1 cursor-pointer"
          onClick={() => {
            navigate("/user");
          }}
        >
          {t("userManagement.userManagement")} /
        </li>
        <li className="font-Inter pr-1 active"> {t("userManagement.groups")} </li>
      </ul>
    </div>
  );
};

export default GroupsBreadcrumb;
