import React from "react";
import { Button } from "antd";

const DropdownMenu = ({ menu, handleCancel }: any) => (
  <div>
    <div>{menu}</div>
    <div>
      <div style={{ padding: "8px", textAlign: "center" }}>
        <div className="w-full flex justify-end">
          <Button className="w-[144px] h-[48px] mr-2 font-semibold text-sm" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" className="bg-[#5441DA] w-[144px] h-[48px] font-semibold text-sm" onClick={handleCancel}>
            Add
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default DropdownMenu;
