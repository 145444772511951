import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  searchApps: any;
}

const initialState: AuthState = {
    searchApps: {},
};

const appSearchSlice:any = createSlice({
  name: 'searchApps',
  initialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<any>) => {
      state.searchApps = action.payload;
    },
    setCRUDData:(state, action: PayloadAction<any>) => {
      state.searchApps = action.payload;
    },
    clearSearchValue: state => {
      state.searchApps = {};
    },
  },
});

export const { setSearchValue, clearSearchValue ,setCRUDData} = appSearchSlice.actions;
export default appSearchSlice.reducer;
