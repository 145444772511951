import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setListOfRestrictionModal } from "../../redux/slice/Users/UserDashboardUpdateSlice";
import "./Breadcrumb.scss";
import { useTranslation } from "react-i18next";
const Breadcrumb = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(setListOfRestrictionModal(true));

    navigate("/user");
  };
  return (
    <ul className="flex breadcrumb">
      <li role="none" className="font-Inter pr-1 cursor-pointer" onClick={handleNavigate}>
        {t("menu.userManagement")} /
      </li>
      <li className="active font-Inter">{t("accessManager.deviceLevel")}</li>
    </ul>
  );
};

export default Breadcrumb;
