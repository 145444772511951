import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface profileDetailsType {
  [key: string]: any;
}
export interface ProfileDetailsRootState {
  profileDetails: profileDetailsType;
  isTenantAdmin: boolean;
  isPublished: boolean;
  termsAndCondition: boolean;
  [key: string]: any;
}

const initialState: ProfileDetailsRootState = {
  profileDetails: {},
  isTenantAdmin: false,
  isPublished: false,
  termsAndCondition: false,
};

const ProfileDetailsSlice: any = createSlice({
  name: "profileTabSlice",
  initialState,
  reducers: {
    setProfileDetails: (state, action: PayloadAction<profileDetailsType>) => {
      state.profileDetails = action.payload;
    },
    setIsTenantAdmin: (state, action: PayloadAction<boolean>) => {
      state.isTenantAdmin = action.payload;
    },
    setIsPublished: (state, action: PayloadAction<boolean>) => {
      state.isPublished = action.payload;
    },
    setTermsAndCondition: (state, action: PayloadAction<boolean>) => {
      state.termsAndCondition = action.payload;
    },
    clearProfileDetails: (state) => {
      state.profileDetails = {};
    },
  },
});

export const { setProfileDetails, setIsTenantAdmin, clearProfileDetails, setIsPublished, setTermsAndCondition } = ProfileDetailsSlice.actions;
export default ProfileDetailsSlice.reducer;
