import React, { useState } from "react";
import SearchInput from "../../../layouts/component/SearchField";
import DeviceManagementHeader from "./DeviceManagementHeader";
import DeviceManagementTable from "./DeviceManagementTable";
import "./DeviceManagement.scss";
import { useTranslation } from "react-i18next";
const DeviceManagement = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target?.value;
    setSearch(searchValue);
  };

  return (
    <div className="akku-container">
      <div className="main-container">
        <div className="dashboard-container h-full rounded device-management overflow-y-auto">
          <DeviceManagementHeader />
          <div className="mt-[30px]">
            <SearchInput search={search} placeholder={t("common.search")} onChange={handleSearch} />
          </div>
          <div className="w-full">
            <DeviceManagementTable search={search} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceManagement;
