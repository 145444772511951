import React, { useEffect } from "react";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardFilter } from "../../redux/slice/DashboardFilterSlice";
import { customerTable } from "../../constant/prerequisites/prerequisites";
import { retrieveData } from "../../services/storage/Storage";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import "./Dashboard.scss";
import { setCustomerDetailsDefaultPassword } from "../../redux/slice/user-basic-details/UserBasicDetailsSlice";
import { setAkkuMasterStatus } from "../../redux/slice/DashboardSlice";
import TenantAssessments from "./components/TenantAssessments";
import TenantHeader from "./TenantHeader";
import useDashboardService from "./useDashboardService";

const TenantDashboard: React.FC = () => {
  const currentRole = retrieveData("currentRole", true);
  const realmName = retrieveData("realmName", true);
  const reloadCustomerDashboard = useSelector((state: any) => state?.CustomerSlice?.reloadCustomerDashboard);
  const customerTableCurrentPage = useSelector((state: any) => state?.CustomerSlice?.customerTableCurrentPageNumber);
  const restriction = useSelector((state: any) => state?.restriction?.restrictionDetails);
  const userDetails = useSelector((state: any) => state?.dashboardDetails);

  const tenantAdminRealmId = useSelector((state: any) => state?.CustomerSlice?.tenantAdminRealmId);
  const customerRealmId = useSelector((state: any) => state?.CustomerSlice?.customerRealmId);
  const tenantAdminRealmName = useSelector((state: any) => state?.CustomerSlice?.tenantAdminRealmName);
  const customerRealmName = useSelector((state: any) => state?.CustomerSlice?.customerRealmName);
  const tenantActiveStep = useSelector((state: any) => state?.CustomerSlice?.tenantActiveStep);

  const requestDashboardFilter = useGetApiRequests("dashboardFilter", "GET");
  const getCustomerDetailsApi = useGetApiRequests("createCustomer", "GET");

  const realmIdFromRedux = useSelector((state: any) => state?.CustomerSlice?.realmId);
  const dispatch = useDispatch();

  const { getDashboardDetails } = useDashboardService();

  const realmId = tenantActiveStep === 2 ? customerRealmId : tenantAdminRealmId;
  const requestPayload = {
    realmId: realmId,
    riskAssessmentDto: { customerType: "all" },
    recoveryAttemptsDto: {
      recoveryType: "Password",
    },
    topSellerDto: {
      year: 2024,
      orderBy: "product",
    },
  };

  if (currentRole === "user") {
    window.location.href = "/dashboard";
  }

  useEffect(() => {
    !restriction.isActive && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCustomerDashboard, realmIdFromRedux, tenantActiveStep]);

  const init = () => {
    const customerTableInitialFilterData: any = { ...customerTable?.initialFilterData, pageNumber: customerTableCurrentPage };
    getDashboardDetails(customerTableInitialFilterData, "dashboardAll", requestPayload);
    getFilterData();
    // getCustomerDetails();
  };
  const getCustomerDetails = async () => {
    const realmName = tenantActiveStep === 2 ? customerRealmName : tenantAdminRealmName;
    const pathParams = {
      name: realmName,
    };
    try {
      const response: any = await getCustomerDetailsApi("", {}, pathParams);

      const status = response.status;
      if (status === 200) {
        const defaultPassword = response?.data?.data?.customerGeneralDetailsDto?.defaultPassword;
        dispatch(setCustomerDetailsDefaultPassword(defaultPassword));
      }
    } catch (error) {
      console.error("error:", error);
      // handleRequestError(error);
      // dispatch(setCustomerDetailsDefaultPassword(true));
    }
  };

  const getFilterData = async () => {
    try {
      const response: any = await requestDashboardFilter();
      const status = response.status;
      if (status === 200) {
        const data = response.data;
        dispatch(setDashboardFilter(data));
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  const handleSetStatus = () => {
    dispatch(setAkkuMasterStatus(!userDetails?.isActiveAkkuMaster));
  };

  useEffect(() => {
    try {
      const scrollableContainer = document.querySelector(".main-container");
      if (scrollableContainer) {
        scrollableContainer.scrollTo(0, 0); // Scroll the container to top
      } else {
        window.scrollTo(0, 0); // Fallback to scrolling the window
      }
    } catch (error) {
      console.error("Error while scrolling:", error);
    }
  }, [tenantActiveStep]);

  return (
    <div className="akku-container">
      {/* <AdminHeaderHeader /> */}
      <TenantHeader />
      {!restriction.isActive && (
        <div className="main-container admin !pt-14">
          {/* <pre>{JSON.stringify(userDetails?.isActiveAkkuMaster, null, 1)}</pre>
          <button onClick={handleSetStatus}>test</button> */}
          <TenantAssessments data-testid="assessments-component" />
        </div>
      )}
    </div>
  );
};

export default TenantDashboard;
