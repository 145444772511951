import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  themeDetails: {
    themeImagesUrl: string;
    themeCssUrl: string;
  };
}

const initialState: ThemeState = {
  themeDetails: {
    themeImagesUrl: "",
    themeCssUrl: "",
  },
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeConfigure: (state, action: PayloadAction<{ themeImagesUrl: string; themeCssUrl: string }>) => {
      state.themeDetails = action.payload;
    },
    clearThemeConfigure: (state) => {
      state.themeDetails = {
        themeImagesUrl: "",
        themeCssUrl: "",
      };
    },
  },
});

export const { setThemeConfigure, clearThemeConfigure } = themeSlice.actions;
const themeReducer = themeSlice.reducer;
export default themeReducer;
