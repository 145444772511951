import React from "react";
import { Modal } from "antd";
import ImageView from "../../../../layouts/component/ImageView";
import warningImg from "../../../../assets/images/user/warning.svg";
import CustomOkayButton from "../../../../layouts/component/CustomButton";
import { useTranslation } from "react-i18next";

export default function GroupAndUserNecessaryModal({ handleCloseNecessaryModal, openNecessaryModal, type }: any) {
  const { t } = useTranslation();

  return (
    <Modal className="_suspended p-10 rounded-lg show-notification" closable={false} centered open={openNecessaryModal} footer={false} maskClosable={false}>
      <div className=" modal-body necessaryModal pt-4">
        <div className="flex justify-center items-center w-full flex-col">
          <ImageView className="h-15 w-15" src={warningImg} alt="warning-icon" />
          <p className="text-[20px] pt-4 font-semibold text-center">
            {t("accessManager.mandatoryAssign")} {type} {t("accessManager.toTheRestriction")}
          </p>
        </div>
      </div>
      <div className="modal-footer necessaryModal pt-8">
        <div className="justify-center flex w-full">
          <CustomOkayButton onClick={handleCloseNecessaryModal} text={t("common.ok")} />
        </div>
      </div>
    </Modal>
  );
}
