import { Button } from "antd";
import React from "react";

// CustomButton component definition
const CustomButtonNext = ({ onClick, text, active, loader = false }: any) => {
  return (
    <Button
      loading={loader}
      onClick={onClick}
      className="flex items-center justify-evenly font-semibold text-[20px] font-Inter  min-w-[186px] w-auto h-[56px]  bg-[#5441DA] text-white"
      size="large"
      disabled={active}
    >
      {text}
    </Button>
  );
};

export default CustomButtonNext;
