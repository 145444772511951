export type Service = {
  logoPath: string | null;
  clientName: string;
};

export type DataItem = {
  key: number;
  name: string;
  email: string;
  status: string;
  service: Service[];
};

export const getUniqueServices = (data: DataItem[]): Service[] => {
  const uniqueServices: Service[] = [];

  data.forEach((item) => {
    item?.service?.forEach((service) => {
      // Check if service already exists in the uniqueServices array
      const existingService = uniqueServices.find((s) => s?.clientName === service?.clientName);

      if (!existingService) {
        // If service does not exist, push it to the uniqueServices array
        uniqueServices.push(service);
      }
    });
  });

  return uniqueServices;
};
