import React from "react";
import { useNavigate } from "react-router-dom";
import RiskAssessmentTabs from "./components/RiskAssessmentTabs";
import "./components/RiskManagement.scss";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData } from "../../services/storage/Storage";
import { setTenantActiveStep } from "../../redux/slice/CustomerSlice";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
const RiskAssessment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const realmName = retrieveData("realmName", true);

  const tenantAdminDisplayName = useSelector((state: any) => state?.CustomerSlice?.tenantAdminDisplayName);
  const tenantsCustomerDisplayName = useSelector((state: any) => state?.CustomerSlice?.tenantsCustomerDisplayName);

  const userDetails = useSelector((state: any) => state?.dashboardDetails?.riskAssessmentData);
  const tenantActiveStep = useSelector((state: any) => state?.CustomerSlice?.tenantActiveStep);

  const handleNavigate = () => {
    if (tenantActiveStep === 1 || tenantActiveStep === 2) {
      navigate("/tenant-admin-dashboard");
    } else {
      dispatch(setTenantActiveStep(0));
      navigate("/admin-dashboard");
    }
  };
  return (
    <div className="akku-container">
      <div className="main-container bg-[#F5F5FA]">
        <div className="dashboard-container risk-assessment">
          <ul className="flex breadcrumb">
            {tenantActiveStep > 0 ? (
              <>
                <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate()}>
                  {startCase(realmName)} {">"}
                </li>
                <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate()}>
                  {tenantActiveStep === 2 ? tenantsCustomerDisplayName : tenantAdminDisplayName} {">"}
                </li>
              </>
            ) : (
              <li role="none" className="font-Inter  pr-1 cursor-pointer text-[18px]" onClick={() => handleNavigate()}>
                {t("menu.smartAnalytics")} {"/ "}
              </li>
            )}
            <li className="active font-Inter text-[18px]" data-testid="risk-assessment">
              {" "}
              {t("riskAssessment.riskAssessment")}{" "}
            </li>
          </ul>
          <div className="flex justify-between mb-7 2xl:mb-0">
            <p className="title py-2"> {t("riskAssessment.riskAssessment")}</p>
            <div className="border flex justify-between px-5 py-7 items-center w-[275px] h-[50px] bg-[#F9FAFB] rounded">
              <span className="material-symbols-outlined">account_circle_off</span>
              <span className="font-Inter text-[17px] text-[#000] pr-3 text-center"> {t("riskAssessment.unusedLicenses")}</span>
              <span className="font-Inter font-semibold text-[20px] text-[#3A3A3A] leading-[32px]  text-rwd26">{userDetails?.unusedLicenses}</span>
            </div>
          </div>
          <div>
            <RiskAssessmentTabs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskAssessment;
