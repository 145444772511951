import React, { useEffect, useState } from "react";
import DirectoryBreadcrumb from "./breadcrumb/DirectoryBreadcrumb";
import { useNavigate } from "react-router-dom";
import EditSearchAndUpdate from "./forms/EditSearchAndUpdate";
import EditAuthentication from "./forms/EditAuthentication";
import EditMappingAttributes from "./forms/EditMappingAttributes";
import { retrieveData } from "../../services/storage/Storage";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import Loader from "../../layouts/component/Loader";
import EditDirectorySync from "./forms/EditDirectorySync";
import { useDispatch } from "react-redux";
import { setLdapInitialData, setLdapIsEnable } from "../../redux/slice/edit-ldap/LDAPInitialDataSlice";
import "./EditLDAPConfiguration.scss";
const EditLDAPConfiguration = () => {
  const navigate = useNavigate();
  const editConnectedDirectory = useGetApiRequests("editConnectedDirectory", "GET");
  const getComponentId = retrieveData("getComponentDetails", true);
  const realmName = retrieveData("realmName", true);
  const [currentTab, setCurrentTab] = useState(1);
  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleNavigate = () => {
    navigate("/user");
  };
  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    setLoader(true);
    const payload = {
      realmName: realmName,
    };
    try {
      const response: any = await editConnectedDirectory("", payload, [getComponentId]);
      const status = response.status;
      if (status === 200) {
        const data = response.data;
        const isActive = data?.config?.enabled[0] === "true";
        setResponseData(data);
        dispatch(setLdapInitialData(data));
        dispatch(setLdapIsEnable(isActive));
        setLoader(false);
      }
    } catch (err: any) {
      triggerNotification("error", "", err?.message, "topRight"); // Handle API request error
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="akku-container">
        <div className="main-container">
          <div className="dashboard-container h-full ">
            <DirectoryBreadcrumb onClick={handleNavigate} />
            <div className="w-full">
              {currentTab === 1 && responseData && <EditAuthentication responseData={responseData} setCurrentTab={setCurrentTab} />}
              {currentTab === 2 && responseData && <EditSearchAndUpdate responseData={responseData} setCurrentTab={setCurrentTab} />}
              {currentTab === 3 && <EditMappingAttributes responseData={responseData} setCurrentTab={setCurrentTab} />}
              {currentTab === 4 && <EditDirectorySync responseData={responseData} setCurrentTab={setCurrentTab} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLDAPConfiguration;
