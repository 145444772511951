import { retrieveData } from "../../../../../../../services/storage/Storage";

const componentId = retrieveData("componentId", true);

export const connectorBack = (stepper: any) => {
  const newStepper = stepper.map((step: any, index: number) => {
    if (index === 1) {
      return {
        ...step,
        icon: "",
        active: true,
        status: "inprogress",
        
        
      };
    } else if (index === 2) {
      return {
        ...step,
         icon: "",
        status: "NotStarted",
        active: false,
       
      };
    } else {
      return {
        active: false,
        ...step,
        
      };
    }
  });
  return newStepper;
};

export const connectorNext = (stepper: any) => {
  const newStepper = stepper.map((step: any, index: number) => {
    if (index === 2) {
      return {
        ...step,
        active: false,
        icon: "check",
        status: "check",
        
        
      };
    } else if (index === 3) {
      return {
        ...step,
         active: true,
        status: "inprogress",
        icon: "",
        children: [
          {
            title: "Authentication",
            active: true,
            description: "Enter child step 1 details",
          },
          {
            title: "Search & Update",
            active: false,
            description: "Enter child step 2 details",
            
          },
        ],
      };
    } else {
      return {
        ...step,
        active: false,
      };
    }
  });
  return newStepper;
};

export const handleRedirectSearch = (stepper: any) => {
  const newStepper = stepper.map((step: any, index: number) => {
    if (index === 2) {
      return {
        ...step,
        icon: "check",
        status: "check",
        active: false,
      };
    } else if (index === 3) {
      return {
        ...step,
        status: "check",
        icon: "check",
        active: false,
        children: [
          {
            title: "Authentication",
            active: false,
            description: "Enter child step 1 details",
            
          },
          {
            title: "Search & Update",
             active: false,
            description: "Enter child step 2 details",
           
          },
        ],
      };
    } else if (index === 4) {
      return {
        ...step,
        icon: "",
         active: true,
        status: "inprogress",
       
        
        children: [
          {
            title: "Attributes",
            active: true,
            description: "Enter child step 1 details",
            
          },
          {
            title: "User Status",
            active: false,
            description: "Enter child step 2 details",
            
          },
          // {
          //   title: "Notifications 5",
          //   description: "Enter child step 2 details",
          //   active: false,
          // },
        ],
      };
    } else {
      return {
        ...step,
        active: false,
      };}
  });
  return newStepper;
};

export const handleRedirectMapAttribute = (stepper: any) => {
  const newStepper = stepper.map((step: any, index: number) => {
    if (index === 2) {
      return {
        ...step,
         active: false,
        status: "check",
        icon: "check",
       
      };
    } else if (index === 3) {
      return {
        ...step,
        icon: "check",
        status: "check",
        active: false,
        
        children: [
          {
            title: "Authentication",
              active: false,
            description: "Enter child step 1 details",
          
          },
          {
            title: "Search & Update",
            active: false,
            description: "Enter child step 2 details",
            
          },
        ],
      };
    } else if (index === 4) {
      return {
        ...step,
        icon: "",
        active: true,
        status: "inprogress",
        children: [
          {
            title: "Attributes",
            active: false,
            description: "Enter child step 1 details",
            
          },
          {
            title: "User Status",
            active: true,
            description: "Enter child step 2 details",
            
          },
          // {
          //   title: "Notifications 6",
          //   description: "Enter child step 2 details",
          //   active: false,
          // },
        ],
      };
    } else {
      return {
        ...step,
        active: false,
      };}
  });
  return newStepper;
};
