import { ScopeFieldArray } from "../connector/form/connector-authentication/ScopeFieldArrayNew";
import { useTranslation } from "react-i18next";
import TextAreaField from "../../../../../layouts/form/text-area/TextAreaField";
import { InputField } from "../connector/form/connector-authentication/InputField";
import SelectField from "../../../../../layouts/form/select/SelectField";
import { provisionClassNames } from "../ProvisionClassNames";
import { useState } from "react";

interface Values {
  tokenApiEndpoint?: string;
  userName?: string;
  password?: string;
  grantType?: string;
  clientId?: string;
  secret?: string;
  userNameEmail?: string;
  apiToken?: string;
  tokenMethodType?: string;
  [key: string]: any;
}

interface RenderAuthFieldsProps {
  connectorDetailsTokenType: string;
  values: Values;
  publish?: any;
  setFieldValue: any;
}

const RenderAuthFields: React.FC<RenderAuthFieldsProps> = ({ connectorDetailsTokenType, values, publish, setFieldValue }) => {
  const { t } = useTranslation();
  const methodType = [
    {
      label: t("appManagement.provisioning.get"),
      value: "GET",
    },
    {
      label: t("appManagement.provisioning.post"),
      value: "POST",
    },
  ];
  const [clipboardAccessMessage, setClipboardAccessMessage] = useState<boolean>(false);
  const handlePasteClick = async (fieldName: string) => {
    if (navigator.clipboard) {
      try {
        const clipboardText = await navigator.clipboard.readText();
        setFieldValue(fieldName, clipboardText);
      } catch (err) {
        console.error("Failed to read clipboard contents: ", err);
        setClipboardAccessMessage(true);
        setTimeout(() => {
          setClipboardAccessMessage(false);
        }, 3000);
      }
    } else {
      console.error("Clipboard API is not available.");
    }
  };
  switch (connectorDetailsTokenType) {
    case "OIDC":
      return (
        <div className="grid grid-cols-1 connection-authentication-token gap-x-[5%] 2xl:grid-cols-2 ">
          <InputField
            label={t("appManagement.provisioning.tokenApiEndpointUrl")}
            name={`tokenApiEndpoint`}
            type="text"
            placeholder="Enter your name"
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            containerClassName={`${provisionClassNames?.inputFiledContainer} w-[90%]`}
          />
          <InputField
            label={t("appManagement.provisioning.username")}
            name={`userName`}
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            containerClassName={`${provisionClassNames?.inputFiledContainer} w-[90%]`}
          />
          <InputField
            label={t("appManagement.provisioning.password")}
            name={`password`}
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            containerClassName={`${provisionClassNames?.inputFiledContainer} w-[90%]`}
          />
          <InputField
            label={t("appManagement.provisioning.grantType")}
            name={`grantType`}
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            containerClassName={`${provisionClassNames?.inputFiledContainer} w-[90%]`}
          />
          <InputField
            label={t("appManagement.provisioning.clientId")}
            name={`clientId`}
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            containerClassName={`${provisionClassNames?.inputFiledContainer} w-[90%]`}
          />
          <InputField
            label={t("appManagement.provisioning.clientSecret")}
            name={`clientSecret`}
            className={provisionClassNames?.inputField}
            labelClassName={provisionClassNames?.fieldLabel}
            containerClassName={`${provisionClassNames?.inputFiledContainer} w-[90%]`}
          />

          <div className="mb-12 w-[100%] input-container relative">
            <ScopeFieldArray values={values} />
          </div>
        </div>
      );
    case "SERVICE_TOKEN":
      if (publish) {
        return (
          <div className="grid grid-cols-1 gap-x-[5%] 2xl:grid-cols-2 connection-authentication-token">
            <div className="my-5 2xl:mt-0 relative ">
              <div
                className="flex gap-2 items-center w-[100px] h-[32px] bg-[#fff] border border-[#D6D6D6] rounded px-2 py-1 absolute top-[35px] z-[10]  right-[14px] cursor-pointer  select-none"
                onClick={() => handlePasteClick("tokenApiUrl")}
                role="none"
              >
                <span className="material-symbols-outlined">copy_all</span>
                <span className="font-Inter font-medium text-[18px] text-[#282829]">{t("common.paste")}</span>
              </div>
              <TextAreaField
                label={t("appManagement.provisioning.serviceToken")}
                name={`tokenApiUrl`}
                data-testid="apiToken-input" // Test ID for your testing framework
                id="apiToken-input"
                className={provisionClassNames?.textAreaField}
                labelClassName={provisionClassNames?.fieldLabel}
                required={true}
                containerClassName={`${provisionClassNames?.inputFiledContainer} !mb-0`}
              />
              {clipboardAccessMessage && (
                <div className="absolute bottom-[-25px] left-0 right-0">
                  <div className="error-message">{t("common.enableClipboardAccessMessage")}</div>
                </div>
              )}
            </div>
            <InputField
              label={t("appManagement.provisioning.usernameEmail")}
              name={`userNameEmail`}
              className={provisionClassNames?.inputField}
              labelClassName={provisionClassNames?.fieldLabel}
              containerClassName={provisionClassNames?.inputFiledContainer}
            />
          </div>
        );
      } else {
        return (
          <div className="grid grid-cols-1 gap-x-[5%] 2xl:grid-cols-2 connection-authentication-token">
            <div className="  relative mt-5 2xl:mt-0">
              <div
                className="flex gap-2 items-center w-[100px] h-[32px] bg-[#fff] border border-[#D6D6D6] rounded px-2 py-1 absolute top-[35px] z-[10]  right-[14px] cursor-pointer  select-none"
                onClick={() => handlePasteClick("tokenApiUrl")}
                role="none"
              >
                <span className="material-symbols-outlined">copy_all</span>
                <span className="font-Inter font-medium text-[18px] text-[#282829]">{t("common.paste")}</span>
              </div>
              <TextAreaField
                label={t("appManagement.provisioning.serviceToken")}
                name={`tokenApiUrl`}
                data-testid="apiToken-input" // Test ID for your testing framework
                id="apiToken-input"
                className={`${provisionClassNames?.textAreaField} !min-h-[450px]`}
                labelClassName={provisionClassNames?.fieldLabel}
                required={true}
                containerClassName={`${provisionClassNames?.inputFiledContainer} !mb-0`}
              />
              {clipboardAccessMessage && (
                <div className="absolute bottom-[-25px] left-0 right-0">
                  <div className="error-message">{t("common.enableClipboardAccessMessage")}</div>
                </div>
              )}
            </div>

            <div className="w-[100%] mt-10 2xl:mt-0  input-container relative">
              <InputField
                label={t("appManagement.provisioning.tokenApiEndpointUrl")}
                name={`tokenApiEndpoint`}
                type="text"
                className={provisionClassNames?.inputField}
                labelClassName={provisionClassNames?.fieldLabel}
                containerClassName={`${provisionClassNames?.inputFiledContainer} w-[89%]`}
                required={false}
              />
              <InputField
                label={t("appManagement.provisioning.administratorUsernameEmail")}
                name={`userNameEmail`}
                type="text"
                className={provisionClassNames?.inputField}
                labelClassName={provisionClassNames?.fieldLabel}
                data-testid="userNameEmail-input"
                containerClassName={`${provisionClassNames?.inputFiledContainer} w-[89%]`}
              />
              <div className="mb-12 w-[100%] input-container relative">
                <ScopeFieldArray values={values} />
              </div>
            </div>
          </div>
        );
      }
    default:
      if (publish) {
        return (
          <div className="grid grid-cols-1 gap-x-[5%] 2xl:grid-cols-2 connection-authentication-token">
            <div className="mt-10 2xl:mt-0 relative ">
              <div
                className="flex gap-2 items-center w-[100px] h-[32px] bg-[#fff] border border-[#D6D6D6] rounded px-2 py-1 absolute top-[35px] z-[10]  right-[14px] cursor-pointer  select-none"
                onClick={() => handlePasteClick("apiToken")}
                role="none"
              >
                <span className="material-symbols-outlined">copy_all</span>
                <span className="font-Inter font-medium text-[18px] text-[#282829]">{t("common.paste")}</span>
              </div>
              <TextAreaField
                label={t("appManagement.provisioning.apiToken")}
                name={`apiToken`}
                data-testid="apiToken-input" // Test ID for your testing framework
                id="apiToken-input"
                className={`${provisionClassNames?.textAreaField} !min-h-[450px]`}
                labelClassName={provisionClassNames?.fieldLabel}
                containerClassName={`${provisionClassNames?.inputFiledContainer} !mb-0`}
              />
              {clipboardAccessMessage && (
                <div className="absolute bottom-[-25px] left-0 right-0">
                  <div className="error-message">{t("common.enableClipboardAccessMessage")}</div>
                </div>
              )}
            </div>

            <div className="w-[100%] input-container relative mt-10 2xl:mt-0">
              <InputField
                label={t("appManagement.provisioning.usernameEmail")}
                name={`userNameEmail`}
                type="text"
                placeholder="Enter your name"
                className={provisionClassNames?.inputField}
                labelClassName={provisionClassNames?.fieldLabel}
                containerClassName={`${provisionClassNames?.inputFiledContainer} w-[89%]`}
                required={false}
              />
              <InputField
                label={t("appManagement.provisioning.password")}
                name={`password`}
                type="text"
                className={provisionClassNames?.inputField}
                labelClassName={provisionClassNames?.fieldLabel}
                containerClassName={`${provisionClassNames?.inputFiledContainer} w-[89%]`}
                required={false}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className="grid grid-cols-1 gap-x-[5%] 2xl:grid-cols-2 connection-authentication-token">
            <div className="relative mt-5 2xl:mt-0 ">
              <div
                className="flex gap-2 items-center w-[100px] h-[32px] bg-[#fff] border border-[#D6D6D6] rounded px-2 py-1 absolute top-[35px] z-[10]  right-[14px] cursor-pointer  select-none"
                onClick={() => handlePasteClick("apiToken")}
                role="none"
              >
                <span className="material-symbols-outlined">copy_all</span>
                <span className="font-Inter font-medium text-[18px] text-[#282829]">{t("common.paste")}</span>
              </div>
              <TextAreaField
                label={t("appManagement.provisioning.apiToken")}
                name={`apiToken`}
                data-testid="apiToken-input" // Test ID for your testing framework
                id="apiToken-input"
                className={`${provisionClassNames?.textAreaField} !min-h-[450px]`}
                labelClassName={provisionClassNames?.fieldLabel}
                containerClassName={`${provisionClassNames?.inputFiledContainer} !mb-0`}
              />
              {clipboardAccessMessage && (
                <div className="absolute bottom-[-25px] left-0 right-0">
                  <div className="error-message">{t("common.enableClipboardAccessMessage")}</div>
                </div>
              )}
            </div>
            <div className="w-[100%] input-container relative mt-10 2xl:mt-0">
              <InputField
                label={t("appManagement.provisioning.tokenApiEndpointUrl")}
                name={`tokenApiEndpoint`}
                type="text"
                placeholder="Enter your name"
                className={`${provisionClassNames?.inputField} authenticationEndPoint`}
                labelClassName={provisionClassNames?.fieldLabel}
                containerClassName={`${provisionClassNames?.inputFiledContainer} w-[89%]`}
                required={false}
              />
              <SelectField
                label={t("appManagement.provisioning.tokenApiMethodType")}
                name={`tokenMethodType`}
                options={methodType}
                filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().includes(input.toLowerCase())}
                placeholder={t("common.select")}
                labelClassName={provisionClassNames?.fieldLabel}
                required={false}
                containerClassName={`${provisionClassNames?.inputFiledContainer} w-[89%]`}
              />
              <InputField
                label={t("appManagement.provisioning.usernameEmail")}
                name={`userNameEmail`}
                className={`${provisionClassNames?.inputField} authenticationEndPoint`}
                labelClassName={provisionClassNames?.fieldLabel}
                containerClassName={`${provisionClassNames?.inputFiledContainer} w-[89%]`}
              />
              <div className="mb-12 w-[100%] input-container relative">
                <ScopeFieldArray values={values} />
              </div>
            </div>
          </div>
        );
      }
  }
};

export default RenderAuthFields;
