import React, { useEffect, useState } from "react";
import { images } from "../../../../../../../assets/images/ImagePaths";
import { useSelector } from "react-redux";
import { useAddUserContext } from "../../../add-user-context/AddUserContext";
import Loader from "../../../../../../../layouts/component/Loader";
import { useTranslation } from "react-i18next"; // Import the translation hook
import "./AddUserStepper.scss";

type StepTitle = "Channel" | "Directory Connection" | "Choose Connector" | "Configure" | "Mapping";
type ChildTitle = "Authentication" | "Search & Update" | "Attributes" | "User Status" | "Notifications";

export default function AddUserStepper() {
  const { t } = useTranslation(); // Initialize translation
  const stepper = useSelector((state: any) => state?.addUserSlice?.steeper);
  const { addUserType } = useAddUserContext();
  const [steppers, setStepper] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    setStepper(addUserType === "singleUser");
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 300);
  }, [addUserType]);

  const getStepperStatus = (index: any) => {
    return index === 1 || index === 0 ? "hidden kerberos" : "";
  };

  // Define a mapping for titles to their respective translation keys
  const titleKeyMap: Record<StepTitle, string> = {
    Channel: "channel",
    "Directory Connection": "directoryConnection",
    "Choose Connector": "chooseConnector",
    Configure: "configure",
    Mapping: "mapping",
  };

  // Adjusted child key mapping to include 'children' in the path
  const childKeyMap: Record<ChildTitle, string> = {
    Authentication: "configure.children.authentication",
    "Search & Update": "configure.children.searchAndUpdate",
    Attributes: "mapping.children.attributes",
    "User Status": "mapping.children.userStatus",
    Notifications: "mapping.children.notifications", // Add this line
  };

  return (
    <>
      {loader && <Loader />}
      <div className={steppers ? "hidden" : "addUser"}>
        <div className="userHeader flex justify-center flex-wrap">
          <img src={images.addUserIcons} alt={t("userManagement.addUsers.iconAlt")} />
          <h1 className="w-full text-center">{t("userManagement.addUsers.header")}</h1>
        </div>
        <ul className="addUser-stepper parent">
          {stepper.map((step: any, index: number) => (
            <li className={`parent-item ${step?.active ? "active" : ""} ${getStepperStatus(index)}`} key={step.title}>
              <header className="flex">{t(`userManagement.userStepper.${titleKeyMap[step.title as StepTitle]}.title`)}</header>
              <i className={`icon material-symbols-outlined ${step.status}`}>{step.icon}</i>
              {step.children && step.children.length > 0 && (
                <ul className="child">
                  {step.children.map((childStep: any, childIndex: number) => (
                    <li className={`child-item ${childStep?.active ? "active" : ""}`} key={childStep?.title}>
                      <h6>{t(`userManagement.userStepper.${childKeyMap[childStep?.title as ChildTitle]}.title`)}</h6>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
