import { AxiosError, AxiosResponse } from "axios";
import { API_CONFIG } from "../apiUrlMethods/apiUrls";
import { APIConfig } from "../apiInterfaceType/apiInterfaceType";
import axios from "./apiInterceptors";

const useGetApiRequests = (apiName: string, method: string) => {
  const api: APIConfig = API_CONFIG[apiName];

  const apiCalls = async (payload: any = null, queryParams: any = {}, pathParams: object = {}, header: object = {}, imageFile: any = {}) => {
    return new Promise<AxiosResponse | AxiosError>((resolve, reject) => {
      let url: any;

      if (api?.baseURL) {
        const { baseURL, url: main } = api;
        url = `${process.env.REACT_APP_USER_BASEURL}${baseURL}${main}`;
      } else if (api.alternateURL) {
        const { alternateURL, url: main } = api;
        url = `${alternateURL}${main}`;
      } else url = api?.url;
      if (Object.keys(pathParams).length > 0) url += `/${Object.values(pathParams).join("/")}`;

      if (Object.keys(queryParams).length > 0) {
        const convertObjectToQueryString = (obj: any) => {
          const queryParams = new URLSearchParams();
          for (const key in obj) {
            if (typeof obj[key] === "object") queryParams.append(key, JSON.stringify(obj[key]));
            else queryParams.append(key, String(obj[key]));
          }
          return queryParams.toString();
        };
        url += `?${convertObjectToQueryString(queryParams)}`;
      }

      axios({
        method: method,
        url: url,
        data: payload,
        headers: header,
      })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  return apiCalls;
};

export default useGetApiRequests;
