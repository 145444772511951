import { Input, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { prerequisitesTable } from "../../../../../constant/prerequisites/prerequisites";
import "../device-restriction/DeviceBasedRestriction.scss";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import GroupDetails from "./layout/GroupDetails";
import { Form, Formik } from "formik";
import { groupValidationSchema } from "./layout/GroupValidationSchema";
import SubmitButton from "../../../../../layouts/component/SubmitButton";
import { getListOfSsoAndProvisioningApps, getUsersList } from "./modal/ListOfUserHelperFile";
import useGetApiRequests from "../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../services/storage/Storage";
import Loader from "../../../../../layouts/component/Loader";
import { triggerNotification } from "../../../../../layouts/toast/ToastBar";
import { handleRequestError } from "../../../../../layouts/toast/ErrorNotificationMessage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UsersSearchDropdown from "./UserSearchModal";
import { addGroupColumns } from "./AddGroupsHelperFile";
import DeleteGroupModal from "./DeleteGroupModal";
import { clearGroupAppList, clearGroupUserList, setGroupUserList } from "../../../../../redux/slice/GroupSlice";
import { ErrorMessages } from "../../../../../const/Messages";
import PermissionsModal from "../../../../../layouts/permissionsModal/PermissionsModal";
import "./layout/ConnectedApps.scss";
import { useTranslation } from "react-i18next";
import ConnectedAppsTab from "./layout/ConnectedAppsTab";
const { Option } = Select;

const AddGroupDetails = () => {
  const { t, i18n } = useTranslation();
  const getUserList = useGetApiRequests("userManagement", "POST");
  const createGroup = useGetApiRequests("groupCreation", "POST");
  const getAppList = useGetApiRequests("getListOfAppsByRealmId", "GET");
  const getGroupAppData = useGetApiRequests("getGroupAppsList", "GET");
  const updateGroup = useGetApiRequests("updateGroup", "PUT");
  const assignUsersToGroup = useGetApiRequests("assignUsersToGroup", "POST");
  const deAllocateUserApi = useGetApiRequests("unAssignUsersFromGroup", "DELETE");

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number | undefined>(10);
  const [sizeChanger, setSizeChanger] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<any>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [userList, setUserList] = useState<any>();
  const [listOfApps, setListOfApps] = useState([]);
  const realmName = retrieveData("realmName", true);
  const [listOfUsers, setListOfUsers] = useState<any>([]);
  const realmId = retrieveData("realmId", true);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const isEdit = urlParams.get("edit");
  const editRecordDetails: any = useSelector((state: any) => state.dashboardDetails.userList);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [usersSearchList, setUsersSearchList] = useState([]);
  const [checkingUsersList, setCheckingUsersList] = useState([]);
  const [deAllocateUserModal, setDeAllocateUserModal] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [userDeleted, setUserDeleted] = useState<boolean>(false);
  const userCount = retrieveData("totalUserRecords", true);
  const [updatedUserList, setUpdatedUserList] = useState<any>([]);
  const [selectedObjectList, setSelectedObjectList] = useState<any>([]);
  const [deAllocationList, setDeAllocationList] = useState<any>([]);
  const [permissionModal, setPermissionModal] = useState<boolean>(false);
  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [initialValues, setInitialValues] = useState({
    groupName: "",
    groupDescription: "",
  });
  const dispatch = useDispatch();
  const addedUserList = useSelector((state: any) => state?.GroupSlice?.groupUserList);
  const addedAppList = useSelector((state: any) => state?.GroupSlice?.groupAppList);
  const groupPermission = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const [listOfProvisioningApps, setListOfProvisioningApps] = useState([]);

  const hasDeAllocatePermission = groupPermission["Cloud Directory"]["delete"];
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  useEffect(() => {
    if (isEdit === "true") {
      setInitialValues({
        groupName: editRecordDetails?.name,
        groupDescription: editRecordDetails?.description,
      });
      getAppData();
    } else {
      getUsersList(currentPage, pageSize, search, false, setLoader, realmId, getUserList, setListOfUsers, false, [], setTotalRecords, setUserList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDeleted]);

  const getAppData = async () => {
    try {
      const appIds = editRecordDetails?.attributes?.clientAppId;
      const provisioningAppIds = editRecordDetails?.attributes?.provisioningAppId;

      const { individualApps, reformedProvisioningList }: any = await getListOfSsoAndProvisioningApps(realmId, setLoader, getAppList);

      // const reformedAppList = await getListOfApps(realmId, setLoader, getAppList);
      const selectedObjects = individualApps?.filter((item: any) => appIds?.includes(item.value.toString()));
      const selectedProvision = reformedProvisioningList?.filter((item: any) => provisioningAppIds?.includes(item?.value.toString()));

      if (addedAppList.length > 0) {
        setListOfApps(addedAppList);
      } else {
        setListOfApps(selectedObjects);
        setListOfProvisioningApps(selectedProvision);
      }

      const pathParams: object = {
        id: `${editRecordDetails?.id}/users`,
      };
      const queryParams: object = {
        realmName: realmName,
      };
      try {
        setLoader(true);
        const response: any = await getGroupAppData("", queryParams, pathParams);
        const status = response.status;

        if (status === 200) {
          const data = response?.data?.data;
          const reformedUserList = data?.map((item: any) => ({
            name: item?.lastName ? `${item?.firstName} ${item?.lastName}` : item?.firstName,
            email: item?.email,
            key: item?.id,
            value: item?.id,
          }));
          const reformedUserListKeys = reformedUserList.map((item: any) => item?.key);
          setCheckingUsersList(reformedUserListKeys);
          if (addedUserList.length > 0) {
            const list = [...reformedUserList, ...addedUserList];
            setListOfUsers(list);
            getUsersList(currentPage, pageSize, search, false, setLoader, realmId, getUserList, setUsersSearchList, true, list, setTotalRecords, setUserList, true, setDataFromApi);
          } else {
            setListOfUsers(reformedUserList);
            getUsersList(currentPage, pageSize, search, false, setLoader, realmId, getUserList, setUsersSearchList, true, reformedUserList, setTotalRecords, setUserList, true, setDataFromApi);
          }
          setSizeChanger(reformedUserList.length <= 5 ? 5 : 10);
          setTotalRecords(response?.data?.meta?.totalRecords);
          setUserList(data);
        }
      } catch (err: any) {
        setLoader(false);
        handleRequestError(err);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
    }
  };
  const handlePageSizeChange = (current: any, newSize: any) => {
    setSizeChanger(newSize);
  };
  const handlePagination = (pageNumber: number, pageSize?: number | undefined) => {
    let currentPage = pageNumber - 1;
    const pageSizeValue: number = pageSize ?? 1;
    let finalPage = 1;
    if (currentPage > 0) {
      finalPage = currentPage * pageSizeValue;
    } else if (currentPage === 0) {
      finalPage = 0;
    }
    if (isEdit !== "true") {
      getUsersList(finalPage, pageSize, search, false, setLoader, realmId, getUserList, setListOfUsers, false, [], setTotalRecords, setUserList);
    }
    setCurrentPage(pageNumber);
    setPageSize(pageSize);
  };

  const handleLabelClick = (event: any, value: any) => {
    event.stopPropagation();
    const newValue: any = [...selectedValues];
    if (newValue.includes(value)) {
      newValue.splice(newValue.indexOf(value), 1);
    } else {
      newValue.push(value);
    }
    setSelectedValues(newValue);
  };
  const handleChange = (value: string) => {
    setSelectedValues(value);
    const previousValue = [...selectedObjectList];
    const missingIds = previousValue.filter((item) => !value.includes(item.value)).map((item) => item.value);
    const listOfUsersDetails = [...listOfUsers];
    const needToDelete = listOfUsersDetails.filter((item) => !missingIds.includes(item.value));
    setUpdatedUserList(needToDelete);
  };
  const handleAddApps = () => {
    if (selectedValues.length > 0) {
      const selectedObjects = usersSearchList.filter((item: any) => selectedValues.includes(item.value));
      setSelectedObjectList(selectedObjects);
      dispatch(setGroupUserList([...addedUserList, ...selectedObjects]));
      const mergedArray = [...updatedUserList, ...selectedObjects];
      const uniqueMergedArray = Array.from(new Set(mergedArray.map((obj) => obj.value))).map((value) => {
        return mergedArray.find((obj) => obj.value === value);
      });
      setListOfUsers(uniqueMergedArray);
      setSizeChanger(uniqueMergedArray.length <= 5 ? 5 : 10);
      setDropdownVisible(false);
      setDisableButton(false);
    } else {
      setListOfUsers(dataFromApi);
      setSizeChanger(dataFromApi?.length <= 5 ? 5 : 10);
      setDropdownVisible(false);
    }
  };
  const dropdownRender = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown totalRecords={totalRecords} handleAddApps={handleAddApps} setDropdownVisible={setDropdownVisible} />
      </div>
    );
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const closePermissionModal = () => {
    setPermissionModal(false);
  };
  const deAllocateUsers = async (showMessage: boolean) => {
    const payload: any = {
      userIds: deAllocationList,
      groupId: editRecordDetails?.id,
      realmName: realmName,
    };
    const presentItems = usersSearchList.filter((item: any) => deAllocationList.includes(item.value));

    if (presentItems.length === 0) {
      try {
        const response: any = await deAllocateUserApi(payload);
        const status = response.status;
        if (status === 200) {
          setLoader(false);
          if (showMessage) {
            triggerNotification("success", "", response?.data?.message, "topRight");
            navigate("/user/groups");
          }
        }
      } catch (err: any) {
        handleRequestError(err);
        setLoader(false);
      }
    } else {
      triggerNotification("warning", "Allocate users to the Group", ErrorMessages?.deallocationNotPossible, "topRight");
    }
  };

  const handleNegativeCases = (userIds: any, payload: any, allConditionsTrue: boolean) => {
    if (userIds?.length === 0 && deAllocationList?.length > 0 && allConditionsTrue) {
      deAllocateUsers(true);
    } else if (userIds?.length > 0) {
      const payloadForUserUpdate: object = {
        userIds: userIds,
        groupId: editRecordDetails?.id,
        realmName: realmName,
      };
      assignUsersToGroup(payloadForUserUpdate)
        .then((response: any) => {
          if (deAllocationList?.length > 0) deAllocateUsers(false);
          dispatch(clearGroupUserList());
          dispatch(clearGroupAppList());
        })
        .catch((err: any) => {
          setLoader(false);
          handleRequestError(err);
        });
      payload.groupId = editRecordDetails?.id;
      updateGroup(payload)
        .then((response: any) => {
          setLoader(false);
          dispatch(clearGroupUserList());
          dispatch(clearGroupAppList());
          triggerNotification("success", "", response?.data?.message, "topRight");
          navigate("/user/groups");
        })
        .catch((err: any) => {
          setLoader(false);
          handleRequestError(err);
        });
    } else {
      payload.groupId = editRecordDetails?.id;
      updateGroup(payload)
        .then((response: any) => {
          setLoader(false);
          if (deAllocationList?.length > 0) deAllocateUsers(false);
          dispatch(clearGroupUserList());
          dispatch(clearGroupAppList());

          triggerNotification("success", "", response?.data?.message, "topRight");
          navigate("/user/groups");
        })
        .catch((err: any) => {
          setLoader(false);
          handleRequestError(err);
        });
    }
  };
  const handleRequestGroupApi = (values: any) => {
    const selectedApps = listOfApps?.map((item: any) => item?.value);
    const selectedProvisioningApps = listOfProvisioningApps?.map((item: any) => item?.value);

    setLoader(true);
    const payload: any = {
      name: values?.groupName,
      description: values?.groupDescription,
      akkuClientAppIds: selectedApps,
      akkuProvisioningAppIds: selectedProvisioningApps,
      realmName: realmName,
    };
    if (isEdit !== "true" && selectedRow?.length > 0) {
      payload.usersToGroupDto = {
        userIds: selectedRow,
      };
    }
    if (isEdit !== "true") {
      createGroup(payload)
        .then((response: any) => {
          setLoader(false);
          dispatch(clearGroupUserList());
          dispatch(clearGroupAppList());
          triggerNotification("success", "", response?.data?.message, "topRight");
          navigate("/user/groups");
        })
        .catch((err: any) => {
          setLoader(false);
          handleRequestError(err);
        });
    } else {
      const stringIds = payload?.akkuClientAppIds?.map((id: any) => id?.toString());
      const userIds = listOfUsers?.map((item: any) => item?.key);
      const isNameEqual = payload?.name === editRecordDetails?.name;
      const isDescriptionEqual = payload?.description === editRecordDetails?.description;
      const isAkkuClientAppIdsEqual = JSON.stringify(stringIds) === JSON.stringify(editRecordDetails?.attributes?.clientAppId);
      const isUserListIsEqual = JSON.stringify(checkingUsersList) === JSON.stringify(userIds);
      const allConditionsTrue = isNameEqual && isDescriptionEqual && isAkkuClientAppIdsEqual;
      if (!isUserListIsEqual && allConditionsTrue) {
        const payloadForUserUpdate: any = {
          userIds: userIds,
          groupId: editRecordDetails?.id,
          realmName: realmName,
        };
        if (userIds?.length === 0 && deAllocationList?.length > 0) {
          deAllocateUsers(true);
        } else if (userIds?.length > 0) {
          assignUsersToGroup(payloadForUserUpdate)
            .then((response: any) => {
              if (deAllocationList?.length > 0) deAllocateUsers(false);
              dispatch(clearGroupUserList());
              dispatch(clearGroupAppList());
              triggerNotification("success", "", response?.data?.message, "topRight");
              navigate("/user/groups");
            })
            .catch((err: any) => {
              setLoader(false);
              handleRequestError(err);
            });
        }
      } else if (isUserListIsEqual && !allConditionsTrue) {
        payload.groupId = editRecordDetails?.id;
        updateGroup(payload)
          .then((response: any) => {
            setLoader(false);
            dispatch(clearGroupUserList());
            dispatch(clearGroupAppList());
            triggerNotification("success", "", response?.data?.message, "topRight");
            navigate("/user/groups");
          })
          .catch((err: any) => {
            setLoader(false);
            handleRequestError(err);
          });
      } else {
        handleNegativeCases(userIds, payload, allConditionsTrue);
      }
    }
  };
  const handleSearch = (event: any) => {
    setSearch(event.target.value);
    getUsersList(currentPage, pageSize, event.target.value, true, setLoader, realmId, getUserList, setListOfUsers, false, [], setTotalRecords, setUserList);
  };
  const handleCancel = () => {
    dispatch(clearGroupUserList());
    dispatch(clearGroupAppList());
    navigate("/user/groups");
  };
  const handleDeAllocateUser = () => {
    if (hasDeAllocatePermission) {
      setDeAllocateUserModal(true);
    } else {
      setPermissionModal(true);
    }
  };

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <div className="akku-container">
      {loader && <Loader />}
      <div className="main-container main-container-auto !p-0">
        <div className="userGroupFrom bg-[#F5F5FA]">
          <Formik initialValues={initialValues} onSubmit={handleRequestGroupApi} values={initialValues} enableReinitialize={true} validationSchema={groupValidationSchema(t)} innerRef={formikRef}>
            {({ values, errors, handleSubmit, setFieldValue }) => {
              return (
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="akku-container">
                    <div className="main-container main-container-auto">
                      <div className="dashboard-container h-full rounded device-restriction groups-add">
                        <ul className="flex breadcrumb">
                          <li
                            className="font-Inter pr-1 cursor-pointer"
                            onClick={() => {
                              navigate("/user");
                            }}
                            role="none"
                          >
                            {t("userManagement.userManagement")}/
                          </li>
                          <li
                            className="font-Inter pr-1 cursor-pointer"
                            onClick={() => {
                              navigate("/user/groups");
                            }}
                            role="none"
                          >
                            {t("userManagement.groups")} /
                          </li>
                          <li className="font-Inter pr-1 active">{isEdit === "true" ? `${editRecordDetails?.name}` : `${t("userManagement.createGroup")}`} </li>
                        </ul>
                        <div className="w-full">
                          <p className="text-[#171717] text-2xl font-Inter font-bold py-2"> {t("userManagement.groupDetails")}</p>

                          <GroupDetails values={values} setFieldValue={setFieldValue} setDisableButton={setDisableButton} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="akku-container connectedAppsTab">
                    <div className="main-container">
                      <div className="dashboard-container h-full rounded device-restriction groups-add">
                        <div className="w-full userCard">
                          <ConnectedAppsTab
                            listOfProvisioningApps={listOfProvisioningApps}
                            setListOfProvisioningApps={setListOfProvisioningApps}
                            setListOfApps={setListOfApps}
                            listOfApps={listOfApps}
                            setDisableButton={setDisableButton}
                            name="Connected Apps"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="akku-container  main-container-auto">
                    <div className="pb-[25px] px-[25px] pt-[10px]">
                      <div className="dashboard-container  rounded device-restriction groups-add">
                        <div className="w-full ">
                          <p className="text-[#171717] text-2xl font-Inter font-bold pb-5">{t("userManagement.selectUsers")}</p>

                          {isEdit !== "true" ? (
                            <div className="relative">
                              <Input type="text" placeholder={t("common.search")} value={search} className={`w-[35%] h-[48px] pl-9 mb-5 text-[20px]`} onChange={(e: any) => handleSearch(e)} />
                              <span className="material-symbols-outlined absolute left-2 top-3 text-[#667085] ">search</span>
                            </div>
                          ) : (
                            <div className="w-full searchApps">
                              <div className="w-[35%]">
                                <Select
                                  className="h-14 w-full mb-5 custom-dropdown"
                                  mode="tags"
                                  onChange={handleChange}
                                  filterOption={filterOption}
                                  tokenSeparators={[","]}
                                  placeholder={t("common.search")}
                                  optionLabelProp="label"
                                  dropdownRender={dropdownRender}
                                  onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
                                  open={dropdownVisible}
                                >
                                  {usersSearchList?.map((option: any) => (
                                    <Option key={option?.value} value={option?.value} label={option?.name}>
                                      <input
                                        type="checkbox"
                                        className="dropdown-list w-5"
                                        name={option?.name}
                                        id={option?.value}
                                        checked={selectedValues.includes(option?.value)}
                                        onChange={(e) => handleLabelClick(e, option?.value)}
                                      />
                                      <label htmlFor={option.name} className="flex flex-col mb-3 h-14 pointer-events-none justify-center">
                                        {option.name} <span className="absolute top-[32px] text-[#747577]">{option?.email}</span>
                                      </label>
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                          )}

                          <div className="w-full">
                            <div className="w-full flex mb-5">
                              <p className="users-total pr-5">
                                {t("userManagement.totalUserCount")}: {isEdit !== "true" ? userCount : listOfUsers.length}{" "}
                              </p>
                              {selectedRow && selectedRow.length > 0 && isEdit === "true" && (
                                <p className={`pr-5 ${!hasDeAllocatePermission ? "users-disableDeAllocate" : "users-clear"} `} onClick={() => handleDeAllocateUser()} role="none">
                                  {t("userManagement.deAllocateUsersFromGroup")}
                                </p>
                              )}
                            </div>
                          </div>
                          {isEdit !== "true" ? (
                            <Table
                              className={`${listOfUsers.length === 0 ? "no-groups" : ""}`}
                              pagination={{
                                current: currentPage === 0 ? 1 : currentPage,
                                pageSize: pageSize,
                                total: userList?.data?.length > 0 ? userList?.meta?.totalRecords : 0,
                                showSizeChanger: true,
                                pageSizeOptions: prerequisitesTable.pageSizeOptions,
                                showPrevNextJumpers: true,
                                onChange: handlePagination,
                              }}
                              locale={{
                                triggerDesc: `${t("userManagement.triggerDesc")}`,
                                triggerAsc: `${t("userManagement.triggerAsc")}`,
                                cancelSort: `${t("userManagement.cancelSort")}`,
                              }}
                              rowSelection={rowSelection}
                              columns={addGroupColumns(t)}
                              dataSource={listOfUsers}
                            />
                          ) : (
                            <Table
                              className={`${listOfUsers.length === 0 ? "no-groups" : ""}`}
                              pagination={{
                                showSizeChanger: true,
                                pageSizeOptions: prerequisitesTable.pageSizeOptions,
                                showPrevNextJumpers: true,
                                defaultPageSize: sizeChanger,
                                pageSize: sizeChanger,
                                onChange: handlePageSizeChange,
                              }}
                              rowSelection={rowSelection}
                              columns={addGroupColumns(t)}
                              dataSource={listOfUsers}
                              locale={{
                                triggerDesc: `${t("userManagement.triggerDesc")}`,
                                triggerAsc: `${t("userManagement.triggerAsc")}`,
                                cancelSort: `${t("userManagement.cancelSort")}`,
                              }}
                            />
                          )}
                          <div className="w-full flex justify-end pt-10">
                            <CustomButtonBack text={t("common.cancel")} onClick={handleCancel} />
                            <SubmitButton text={isEdit === "true" ? `${t("common.update")}` : `${t("common.save")}`} disable={disableButton} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {deAllocateUserModal && (
                    <DeleteGroupModal
                      notificationOpen={deAllocateUserModal}
                      setDeAllocateUserModal={setDeAllocateUserModal}
                      selectedRow={selectedRow}
                      type={"deAllocate"}
                      setSelectedRow={setSelectedRow}
                      setUserDeleted={setUserDeleted}
                      usersSearchList={usersSearchList}
                      setDisableButton={setDisableButton}
                      setSelectedRowKeys={setSelectedRowKeys}
                      userDeleted={userDeleted}
                      setLoader={setLoader}
                      setListOfUsers={setListOfUsers}
                      listOfUsers={listOfUsers}
                      setDeAllocationList={setDeAllocationList}
                    />
                  )}
                  {permissionModal && <PermissionsModal open={permissionModal} close={closePermissionModal} />}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddGroupDetails;
