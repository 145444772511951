import CryptoJS from "crypto-js";
const secretKey = "key@123";
export function encryptData(data: any): string {
  const encrypted = CryptoJS.AES.encrypt(JSON?.stringify(data), secretKey);
  return encrypted?.toString();
}
export function decryptData(encryptedData: string): any {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedString = bytes?.toString(CryptoJS.enc.Utf8);

    // Check if decrypted string is valid before parsing
    if (decryptedString) {
      return JSON?.parse(decryptedString);
    } else {
      console.error("Decryption failed or returned an empty string");
    }
  } catch (error) {
    console.error("Error during decryption or JSON parsing", error);
    return null;
  }
}
export function storeData(storageKey: string, data: any, useSessionStorage: boolean = false): void {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  const encryptedData = encryptData(data);
  storage.setItem(storageKey, encryptedData);
}
export function retrieveData(storageKey: string, useSessionStorage: boolean = false): any {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  const encryptedData = storage?.getItem(storageKey);

  if (encryptedData) {
    try {
      const decryptedData = decryptData(encryptedData);
      return decryptedData;
    } catch (error) {
      return null;
    }
  } else {
    console.error("No data found in storage for key:", storageKey);
    return null;
  }
}

export function storeDeviceData(storageKey: string, data: any, useSessionStorage: boolean = false): void {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  storage.setItem(storageKey, data);
}
// Function to get device encrypt data from localStorage
export function retrieveDeviceData(storageKey: string, useSessionStorage: boolean = true): any {
  const storage = useSessionStorage ? sessionStorage : localStorage;
  const deviceEncryptedString = storage.getItem(storageKey);
  return deviceEncryptedString ?? null; // Return the item directly without JSON.parse
}
