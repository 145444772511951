import { Input, Select } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import CustomButtonBack from "../../../../../../../../layouts/component/CustomButtonBack";
import SubmitButton from "../../../../../../../../layouts/component/SubmitButton";
// import { validationSchema } from "../../../../adduser/form/validationSchema/basicFormValidationSchema";
import useGetApiRequests from "../../../../../../../../services/axios/useApiRequests";
import { retrieveData } from "../../../../../../../../services/storage/Storage";
import Loader from "../../../../../../../../layouts/component/Loader";
import { getRolesList } from "../../../../adduser/usertable/CustomTableUtils";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
const BasicDetailsForm = (props: any) => {
  const { setCurrentStep, initialValues, setInitialValues, handleCloseModal, isEdit } = props;
  const { t, i18n } = useTranslation();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("userManagement.errors.firstName.required"))
      .matches(/^[a-zA-Z\s]*$/, t("userManagement.errors.firstName.letterAndSpace"))
      .min(4, t("userManagement.errors.firstName.minLength"))
      .max(16, t("userManagement.errors.firstName.maxLength")),

    lastName: Yup.string()
      .required(t("userManagement.errors.lastName.required"))
      .matches(/^[a-zA-Z\s]*$/, t("userManagement.errors.lastName.letterAndSpace"))
      .min(1, t("userManagement.errors.lastName.minLength"))
      .max(16, t("userManagement.errors.lastName.maxLength")),
    email: Yup.string().email(t("userManagement.errors.email.invalid")).required(t("userManagement.errors.email.required")),
    userType: Yup.string().required(t("userManagement.errors.userType.required")),
  });
  const roleList = useGetApiRequests("getRolesList", "GET");
  const [isFormDirty, setFormDirty] = useState(false);
  const [loader, setLoader] = useState(false);
  const realmId = retrieveData("realmId", true);
  const [roleDropDownList, setRoleDropDownList] = useState<any>([]);
  const [selectedRole, setSelectedRole] = useState("User");
  const formikRef = useRef<any>(null);
  const getRoles = async () => {
    setLoader(true);
    const reformedRoleList: any = await getRolesList(realmId, roleList);
    const user = { label: "User", value: "User" };
    reformedRoleList?.push(user);
    setRoleDropDownList(reformedRoleList);
    setLoader(false);
    const rolesInInitialValue = reformedRoleList?.find((item: any) => item?.value === initialValues?.userType);
    if (rolesInInitialValue && Object.keys(rolesInInitialValue).length > 0) {
      setSelectedRole(rolesInInitialValue?.value);
    }
  };
  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef.current) {
      formikRef.current.validateForm();
    }

    // };
  }, [i18n.language]);
  const onSubmit = (values: any) => {
    setInitialValues({
      ...initialValues,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      userType: values.userType,
    });
    setCurrentStep(2);
  };
  const handleToBack = () => {
    handleCloseModal();
  };
  const onChange = (value: string, setFieldValue: any) => {
    setSelectedRole(value);
    setFieldValue("userType", value);
  };
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize={true} isInitialValid={isFormDirty} innerRef={formikRef}>
        {({ handleSubmit, errors, setFieldValue, dirty }) => (
          <Form
            onSubmit={(e) => {
              setFormDirty(true);
              handleSubmit(e);
            }}
          >
            <div className="w-full flex pt-[63px] flex-col edit-basic single-user pt-7 mb-6">
              <div className="w-[80%]  flex mx-auto justify-between flex-wrap _edit">
                <div className="mb-10 w-[40%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.firstName")}*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="firstName" maxLength={30} data-testid="fnameId" />
                  <ErrorMessage name="firstName" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[40%] -form input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.lastName")}*</p>
                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="lastName" maxLength={30} data-testid="lnameId" />
                  <ErrorMessage name="lastName" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[40%] input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.workEmailId")}*</p>
                  <Field as={Input} type="email" readOnly={isEdit || ""} className={`${isEdit ? "cursor-not-allowed" : ""} h-[56px] font-medium text-[18px]`} name="email" data-testid="emailId" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>
                <div className="mb-10 w-[40%] -form input-container relative">
                  <p className="text-[#1D1D24] text-[18px] font-Inter font-medium" data-testid="uTypeId">
                    {t("userManagement.userType")}*
                  </p>
                  <Field
                    value={selectedRole}
                    as={Select}
                    showSearch
                    onChange={(value: string) => onChange(value, setFieldValue)}
                    suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                    className=" w-[100%] h-[56px]"
                    name="userType"
                    filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {roleDropDownList?.map((option: any) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Field>
                  <ErrorMessage name="userType" component="div" className="error-message" />
                </div>
              </div>
            </div>

            <div className={` bg-[#F5F5FA] modal-footer mt-[5rem] h-[100px] flex items-center absolute bottom-0 pr-[30px] w-[100%] mx-auto`}>
              <div className="w-full flex justify-end">
                <CustomButtonBack onClick={handleToBack} text={t("common.cancel")} />
                <SubmitButton text={isEdit ? t("common.update") : t("common.next")} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {loader && <Loader />}
    </>
  );
};

export default BasicDetailsForm;
