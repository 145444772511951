import React, { useEffect, useState } from "react";
import { Button } from "antd";
import ConnectionSourceAndTarget from "./ConnectionSourceAndTarget";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep, setDeProvisioningBackButtonClicked } from "../../../../../redux/slice/provisioning/ProvisioningSlice";
import CustomButtonBack from "../../../../../layouts/component/CustomButtonBack";
import { useTranslation } from "react-i18next";
const ManageConnection = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const provisioning = useSelector((state: any) => state?.provisioning);
  const [triggerError, setTriggerError] = useState<boolean>(false);
  const handleBackToUser = () => {
    dispatch(setDeProvisioningBackButtonClicked(true));
    dispatch(setActiveStep(3));
  };
  const handleNext = () => {
    if (provisioning?.selectedSourceConnector === "") {
      setTriggerError(true);
    } else {
      setTriggerError(false);
      dispatch(setActiveStep(5));
    }
  };
  useEffect(() => {
    if (provisioning?.selectedSourceConnector !== "") {
      setTriggerError(false);
    }
  }, [provisioning?.selectedSourceConnector]);
  return (
    <div className="w-full relative manage-connection">
      <div className="bg-[#fff]  m-5 rounded-lg p-10 min-h-[calc(100vh-127px)]">
        <p className="app-header-title">
          {t("appManagement.provisioning.selectYourTargetApps")} {provisioning?.isDuplicate ? `${t("appManagement.copy")}` : null}
        </p>
        <div className="w-full connection-container">
          <div className="w-full">
            <ConnectionSourceAndTarget triggerError={triggerError} />
          </div>
          <div className="">
            <div className="footer-provision flex items-center absolute !bottom-[3px] right-0 w-full bg-[#fff] h-[100px]">
              <div className="modal-footer w-full mx-auto ">
                <div className="w-full flex justify-end pr-5">
                  <CustomButtonBack onClick={handleBackToUser} text={t("common.back")} />
                  <Button onClick={handleNext} type="primary" className="bg-[#5441DA] submit-btn">
                    {t("common.next")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageConnection;
