import { useCallback } from "react";
import axios from "axios";
import { storeData } from "../services/storage/Storage";

const useRoutingConfig = () => {
  const IPConfigUrl: string | undefined = process.env.REACT_APP_CLOUD_IP_ROUTING;

  const getLocation = window.location;
  const domain = getLocation.hostname;
  // Function to fetch initial routing config
  const fetchRoutingConfig = useCallback(async () => {
    const params = {
      domain,
    };

    if (!IPConfigUrl) {
      throw new Error("IPConfig connection URL is not defined");
    }

    try {
      const response = await axios.get(IPConfigUrl, { params });
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response?.status;
      if (status === 200) {
        const data = response?.data;
        storeData("routingConfig", data, true);
      }
    } catch (err) {
      if (axios?.isAxiosError(err)) {
        console.error("Failed to fetch routing config:", err);
      }
    }
  }, [IPConfigUrl]);

  // Return functions so they can be used outside of the hook
  return { fetchRoutingConfig };
};

export default useRoutingConfig;
