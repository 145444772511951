import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DeviceManagement {
  reloadDeviceManagement: boolean;
}

const initialState: DeviceManagement = {
  reloadDeviceManagement: false,
};

const deviceManagementSlice: any = createSlice({
  name: "deviceManagementSlice",
  initialState,
  reducers: {
    setReloadDeviceManagement: (state, action: PayloadAction<boolean>) => {
      state.reloadDeviceManagement = action.payload;
    },
  },
});

export const { setReloadDeviceManagement } = deviceManagementSlice.actions;
export default deviceManagementSlice.reducer;
