import React, { createContext, useContext, FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProvisionContextType, ProvisionProviderProps } from './ProvisionConfigureContextTypes';
import { useProvisioningDetails } from '../hooks/useProvisioningDetails.hook';

// Create the context with undefined initial value
const ProvisionContext = createContext<ProvisionContextType | undefined>(undefined);

// The provider component
export const ProvisionProvider: FunctionComponent<ProvisionProviderProps> = ({ children }) => {
  const provisioning = useSelector((state: any) => state?.provisioning);

  const { loader, provisionDetails, getProvisioningDetails } = useProvisioningDetails({ provisioning });

  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      loader,
      provisionDetails,
      getProvisioningDetails,
    }),
    [loader], // List dependencies for re-memoizing
  );

  return <ProvisionContext.Provider value={value}>{children}</ProvisionContext.Provider>;
};

// Custom hook to use the context
export const useProvisionContext = (): ProvisionContextType => {
  const context = useContext(ProvisionContext);
  if (!context) {
    throw new Error('useProvisionContext must be used within a ProvisionProvider');
  }
  return context;
};
