import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PermissionsState {
  attributes: { [moduleName: string]: { [action: string]: boolean } };
  admin: boolean;
}

const initialState: PermissionsState = {
  attributes: {},
  admin: false,
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions(state, action: any) {
      state.attributes = action.payload;
    },
    setRole(state, action: PayloadAction<boolean>) {
      state.admin = action.payload;
    },
    setClearPermission(state) {
      state.attributes = {};
      state.admin = false;
    },
  },
});

export const { setPermissions, setRole, setClearPermission } = permissionsSlice.actions;

export default permissionsSlice.reducer;
