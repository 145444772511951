import React from "react";
import { Modal } from "antd";
import { useAddUserContext } from "../../../add-user-context/AddUserContext";
import { useDispatch, useSelector } from "react-redux";

import SingleUploadDirectory from "../single-upload/directory/SingleUploadDirectory";
import { clearLDAPInitialValues, clearLDAPUpdateInitialValues } from "../../../../../../../redux/slice/LDAPSlice";

export default function AddUserModal() {
  const stepper = useSelector((state: any) => state.addUserSlice.steeper);
  const { isOpen, closeModal, addUserType, handleRedirect } = useAddUserContext();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(clearLDAPInitialValues());
    dispatch(clearLDAPUpdateInitialValues());
    closeModal();
  };

  return (
    <div>
      <Modal className="rounded-lg w-full modal-fullScreen clients" maskClosable={false} centered open={isOpen} footer={false} onCancel={handleClose}>
        <div className="flex h-full">
          <div className={`${stepper[4]?.active ? "mapper-responsive" : ""} modal-content flex rounded-lg`}>{addUserType === "singleUser" && handleRedirect ? <SingleUploadDirectory /> : <></>}</div>
        </div>
      </Modal>
    </div>
  );
}
