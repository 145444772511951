import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PopUpStatus {
  popUpBlockerStatus: boolean;
}

const initialState: PopUpStatus = {
  popUpBlockerStatus: false,
};

const popUpBlocker = createSlice({
  name: "popUpBlocker",
  initialState,
  reducers: {
    setPopUpBlockerStatus: (state, action: PayloadAction<boolean>) => {
      state.popUpBlockerStatus = action.payload;
    },
  },
});

export const { setPopUpBlockerStatus } = popUpBlocker.actions;
export default popUpBlocker.reducer;
