import React from "react";
import { useTranslation } from "react-i18next";

export default function CreateAccessManagerModalHeader() {
  const { t } = useTranslation();

  return (
    <div className="create-header flex items-center flex-wrap">
      <div className="accessManagerModalContainer">
        <h3 className="w-full"> {t("accessManager.controlAccessPrompt")}</h3>
        <p>{t("accessManager.selectRestrictions")}</p>
      </div>
    </div>
  );
}
