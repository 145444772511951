import React from "react";
import { useTranslation } from "react-i18next";

interface AttemptsIndividualScoreProps {
  successScore: number | undefined;
  failureScore: number | undefined;
}

const AttemptsIndividualScore: React.FC<AttemptsIndividualScoreProps> = ({ successScore, failureScore }) => {
  const formattedSuccessScore = successScore?.toString().padStart(2, "0") ?? "00";
  const formattedFailureScore = failureScore?.toString().padStart(2, "0") ?? "00";
  const { t } = useTranslation();

  return (
    <div className="w-[50%] flex justify-start items-center pl-10">
      <div className="flex justify-start flex-col items-start">
        <h3 className="font-Inter text-[38px] font-semibold  text-[#000] leading-[32px] text-rwd26">{formattedSuccessScore}</h3>
        <p className="font-Inter text-[20px] font-medium flex justify-center items-center text-[#12B369] pt-1 text-rwd16">
          <span className="status success mr-1"></span>
          {t("dashboard.successful")}
        </p>
      </div>
      <div className="pl-6">
        <h3 className="font-Inter text-[38px] font-semibold  text-[#000] leading-[32px]  text-rwd26">{formattedFailureScore}</h3>
        <p className="font-Inter flex justify-center items-center text-[20px] text-[#FB1A50]   pt-1 text-rwd16">
          <span className="status failure mr-1"></span>
          {t("dashboard.failure")}
        </p>
      </div>
    </div>
  );
};

export default AttemptsIndividualScore;
