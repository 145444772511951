import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { setIsAnnual } from "../../../redux/slice/AnnualPlanToggle";
import { useDispatch, useSelector } from "react-redux";
import "./PlansList.scss";
const OurPlans = (props: any) => {
  const { setViewCategory } = props;
  const toggleAnnual = useSelector((state: any) => state?.AnnualPlanToggle?.isAnnual);
  const dispatch = useDispatch();
  const [isToggle, setToggle] = useState<boolean>(false);
  const handelSubscription = (e: any) => {
    setViewCategory(e.target.checked);
  };
  useEffect(() => {
    setToggle(toggleAnnual);
  }, []);
  const handleToggle = (e: any) => {
    setToggle(e);
    dispatch(setIsAnnual(e));
  };

  return (

      <div className=" our-plans">
        <div className="btn-container ">
          <label className="switch btn-color-mode-switch relative">
            <input type="checkbox" name="color_mode" id="color_mode" onChange={(e) => handelSubscription(e)} data-testid='colorId'  />
            <label htmlFor="color_mode" data-on="Dark" data-off="Light" className="btn-color-mode-switch-inner"></label>
          </label>
          <p >No credit card required.</p>
        </div>
        <div className="custom-toogle ">
          <Switch checked={isToggle} onChange={(e) => handleToggle(e)} />
          <span className="font-Inter text-[22px] ml-2 mr-4 text-[#121D24] font-semibold ">Annual</span>
        </div>
      </div>
  
  );
};

export default OurPlans;
