import React, { useEffect, useRef, useState } from "react";
import { Input, Select, Tooltip } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CustomButtonBack from "../../../../../../layouts/component/CustomButtonBack";
import { validationSchema } from "./validationSchema/basicFormValidationSchema";
import ConnectedApps from "./ConnectedApps";
import SubmitButton from "../../../../../../layouts/component/SubmitButton";
import { ReactComponent as GreenCheckIcon } from "../../../../../../assets/images/icons/greencheck.svg";
import trashcan from "../.../../../../../../../assets/images/icons/trashcan.svg";
import useGetApiRequests from "../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../layouts/toast/ErrorNotificationMessage";
import Loader from "../../../../../../layouts/component/Loader";
import DeleteRestrictionModal from "./DeleteRestrictionModal";
import { useTranslation } from "react-i18next";
const BasicDetailsForm = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setCurrentStep, initialValues, setInitialValues, handleClose, isEdit, requestEditUser, handelModelClose, selectedRowObject } = props;
  const dropDownList: any = [{ label: "User", value: "user" }];
  const [isFormDirty, setFormDirty] = useState(false);
  const getUserInfo = useGetApiRequests("getUserProfile", "GET");
  const [deviceInfoId, setDeviceInfoId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    getUserInformation();
  }, []);
  const formikRef = useRef<any>(null);
  useEffect(() => {
    // Revalidate the form on language change
    if (formikRef?.current) {
      formikRef?.current?.validateForm();
    }
  }, [i18n.language]);
  const handleToBack = () => {
    if (isEdit) {
      handleClose();
    } else {
      handelModelClose();
    }
  };
  const getUserInformation = () => {
    const pathParams = {
      id: selectedRowObject?.key,
    };
    getUserInfo("", {}, pathParams)
      .then((response: any) => {
        setDeviceInfoId(response?.data?.data?.userDeviceInfoDto?.akkuUserDeviceInfoId);
        setLoader(false);
        const responseData = response?.data?.data;
        setInitialValues({
          ...initialValues,
          firstName: responseData?.userDetailDto?.firstName,
          lastName: responseData?.userDetailDto?.lastName,
        });
      })
      .catch((err: any) => {
        setLoader(false);
        handleRequestError(err);
      });
  };
  const onSubmit = (values: any) => {
    setInitialValues({
      ...initialValues,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      userType: values.userType,
    });
    //requestEditUser

    if (isEdit) {
      requestEditUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        userType: values.userType,
      });
    } else {
      setCurrentStep(1);
    }
  };
  const deleteRestriction = () => {
    setDeleteModal(true);
  };
  const handleModalClose = () => {
    setDeleteModal(false);
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema(t)} onSubmit={onSubmit} enableReinitialize={true} isInitialValid={isFormDirty} innerRef={formikRef}>
      {({ handleSubmit, errors, setFieldValue, dirty }) => (
        <Form
          onSubmit={(e) => {
            setFormDirty(true);
            handleSubmit(e);
          }}
        >
          <div className={`w-full flex pt-[63px] flex-col edit-basic  ${isEdit ? "h-[390px]" : ""}`}>
            <div className="w-[80%]  flex mx-auto justify-between flex-wrap _edit">
              <div className="mb-10 w-[40%] relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.firstName")}*</p>
                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="firstName" maxLength={30} />
                <ErrorMessage name="firstName" component="div" className="error-message" />
              </div>
              <div className="mb-10 w-[40%] -form relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.lastName")}*</p>
                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name="lastName" maxLength={30} />
                <ErrorMessage name="lastName" component="div" className="error-message" />
              </div>
              <div className="mb-10 w-[40%] relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.workEmailId")}*</p>
                <Field as={Input} type="email" readOnly={isEdit || ""} className={`${isEdit ? "cursor-not-allowed" : ""} h-[56px] font-medium text-[18px]`} name="email" />
                <ErrorMessage name="email" component="div" className="error-message" />
              </div>
              <div className="mb-10 w-[40%] -form relative">
                <p className="text-[#1D1D24] text-[18px] font-Inter font-medium">{t("userManagement.userType")}*</p>
                <Field
                  as={Select}
                  suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                  className=" w-[100%] h-[56px]"
                  name="userType"
                  disabled={true}
                  filterOption={(input: string, option: React.ReactElement) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {dropDownList?.map((option: any) => (
                    <Select.Option key={option.value} value={option.label}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Field>
                <ErrorMessage name="userType" component="div" className="error-message" />
              </div>
            </div>
            {selectedRowObject?.restrictions === "active" && (
              <div>
                <p className="text-[#171717] text-[24px] font-inter font-semibold">{t("userManagement.restrictions")}</p>
                <div className=" w-[40%] -form flex items-center justify-between mt-2 relative">
                  <Input value={"Device Restriction"} type="text" className="h-[46px] flex items-center font-medium text-[18px]" prefix={<GreenCheckIcon />} />
                  <Tooltip title={"Remove Restriction"}>
                    <span>
                      <img src={trashcan} alt="delete" className="cursor-pointer h-[33px]" onClick={deleteRestriction} role="none" />
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          <div className="hidden">
            <ConnectedApps />
          </div>
          <div className={` ${isEdit ? "pb-4 relative bottom-0 left-0 mt-0 " : "pb-0 "} modal-footer mt-[5rem] p-8 pr-[30px] w-[100%] mx-auto`}>
            <div className="w-full flex justify-end">
              <CustomButtonBack onClick={handleToBack} text={t("common.cancel")} />
              <SubmitButton text={isEdit ? `${t("common.update")}` : `${t("common.next")}`} />
            </div>
          </div>
          {loader && <Loader />}
          {deleteModal && (
            <DeleteRestrictionModal deviceInfoId={deviceInfoId} deleteModal={deleteModal} handleModalClose={handleModalClose} selectedRowObject={selectedRowObject} handleToBack={handleToBack} />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default BasicDetailsForm;
