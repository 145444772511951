import React, { createContext, useContext, useMemo } from "react";

interface AdaptiveMFAContextProps {
  values: any;
  setFieldValue: (field: string, value: any) => void;
  mfaFeatures: object;
  enableSwitchPrimary: boolean;
  setEnableSwitchPrimary: any;
  enableSwitchSecondary: boolean;
  setEnableSwitchSecondary: any;
}

// Create the context
const AdaptiveMFAContext = createContext<AdaptiveMFAContextProps | undefined>(undefined);

// Hook to use the context
export const useAdaptiveMFA = () => {
  const context = useContext(AdaptiveMFAContext);
  if (!context) {
    throw new Error("useAdaptiveMFA must be used within a AdaptiveMFAProvider");
  }
  return context;
};

// Define the provider component with PropsWithChildren to include 'children'
export const AdaptiveMFAProvider: React.FC<React.PropsWithChildren<AdaptiveMFAContextProps>> = ({
  children,
  values,
  setFieldValue,
  mfaFeatures,
  enableSwitchPrimary,
  setEnableSwitchPrimary,
  enableSwitchSecondary,
  setEnableSwitchSecondary,
}) => {
  // Memoize the context value
  const contextValue = useMemo(
    () => ({
      values,
      setFieldValue,
      mfaFeatures,
      enableSwitchPrimary,
      setEnableSwitchPrimary,
      enableSwitchSecondary,
      setEnableSwitchSecondary,
    }),
    [values, mfaFeatures, enableSwitchPrimary, enableSwitchSecondary],
  );

  return <AdaptiveMFAContext.Provider value={contextValue}>{children}</AdaptiveMFAContext.Provider>;
};
