export const UserStepper = [
  {
    title: "Channel",
    description: "",
    status: "check",
    active: true,
    icon: "check",
  },
  {
    title: "Directory Connection",
    description: "Enter your personal details",
    icon: "",
    status: "NotStarted",
    active: false,
  },
  {
    title: "Choose Connector",
    description: "Enter your personal details",
    icon: "",
    status: "NotStarted",
    active: true,
  },
  {
    title: "Configure",
    description: "Enter your security details",
    icon: "",
    status: "NotStarted",
    active: false,
    children: [
      {
        title: "Authentication",
        description: "Enter child step 1 details",
        active: false,
      },
      {
        title: "Search & Update",
        description: "Enter child step 2 details",
        active: false,
      },
    ],
  },
  {
    title: "Mapping",
    description: "Enter your account details",
    icon: "",
    status: "NotStarted",
    children: [
      {
        title: "Attributes",
        description: "Enter child step 1 details",
        active: false,
      },
      {
        title: "User Status",
        description: "Enter child step 2 details",
        active: false,
      },
      // {
      //   title: "Notifications 1",
      //   description: "Enter child step 2 details",
      //   active: false,
      // },
    ],
  },
];
