import React from "react";
import Banner from "../layouts/Banner";
import BrandLogo from "../layouts/BrandLogo";
import Footer from "../layouts/Footer";
import { staticThemeUrl, themeImages } from "../../../User/theme";
import { retrieveData } from "../../../services/storage/Storage";

const UserLoginTheme =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) => {
    const theme = retrieveData("themeImagesUrl", true);
    let backgroundUrl: string;
    if (theme?.themeImagesUrl) {
      backgroundUrl = `${theme?.themeImagesUrl}${themeImages.themeBackground}`;
    } else {
      backgroundUrl = `${staticThemeUrl.imagesUrl}${themeImages.themeBackground}`;
    }

    return (
      <div className="app-theme">
        <div className="layout section w-full" style={{ backgroundImage: `url(${backgroundUrl})` }}>
          <div className="grid grid-cols-2 gap-4 container mx-auto h-full theme-container">
            <Banner />
            <div className="login-container h-[100vh] flex items-center">
              <div className="login-auth">
                <BrandLogo />
                <WrappedComponent {...props} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  };

export default UserLoginTheme;
