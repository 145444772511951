import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  expanedTab: boolean;
}

const initialState: AuthState = {
    expanedTab: false,
};

const courseTabSlice = createSlice({
  name: 'navigateCourseTab',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<boolean>) => {
      state.expanedTab = action.payload;
    },
    clearCurrentTab: state => {
      state.expanedTab = false;
    },
  },
});

export const { setCurrentTab, clearCurrentTab } = courseTabSlice.actions;
export default courseTabSlice.reducer;
