import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Tooltip } from "antd";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { getCurrentDayDateMonthYear } from "../../../hooks/commonHooks";
import { retrieveDeviceData, storeData } from "../../../services/storage/Storage";
import UserHeaderModal from "./UserHeaderModal";
import { handleLogout } from "./LogoutHelperFile";
import Loader from "../../../layouts/component/Loader";
import LogoutModal from "../../../layouts/modal/logout/LogoutModal";
import { staticThemeUrl, themeImages } from "../../../User/theme";
import { setIsTenantAdmin, setProfileDetails, setTermsAndCondition } from "../../../redux/slice/profile/ProfileDetailsSlice";
import PasswordAlertModal from "../password-modal/PasswordAlertModal";
import ScreenLoader from "../../../layouts/loader/ScreenLoader";
import { setPopUpBlockerStatus } from "../../../redux/slice/PopUpBlockerStatus";
import { useTranslation } from "react-i18next";
import ImageView from "../../../layouts/component/ImageView";
import { images } from "../../../assets/images/ImagePaths";
import { getTermsAndDetails } from "../../../layouts/header/headerHelper";
import LanguageToggle from "../../../layouts/component/LanguageToggle";
const UserHeader = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const keyCloak = useSelector((state: any) => state?.keycloak);
  const role = useSelector((state: any) => state?.auth?.authentication?.role);
  const profileDetails = useGetApiRequests("userProfileDetails", "GET");
  const gettingLogoutUrls = useGetApiRequests("getLogoutUrls", "GET");
  const userSessionKilling = useGetApiRequests("logoutSessionKill", "POST");
  const userId: string = keyCloak?.KeycloakInfo?.subject || "";
  const [image, setImage] = useState("");
  const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
  const [open, isOpen] = useState<boolean>(false);
  const [openLogout, isOpenLogout] = useState<boolean>(false);
  const [logoutLoader, setLogoutLoader] = useState<boolean>(false);
  const [profileUpdate, setProfileUpdate] = useState<boolean>(false);
  const [userName, setUserName] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const deviceUrl = retrieveDeviceData("device");
  const [isPassword, setIsPassword] = useState<boolean>(false);
  const [forceSetPassword, setForceSetPassword] = useState<boolean>(false);
  const theme = useSelector((state: any) => state?.theme?.themeDetails);
  const userDashbordLogoUrl = theme?.themeImagesUrl
    ? `${theme.themeImagesUrl}${themeImages.userDashbordLogo}`
    : `${staticThemeUrl.imagesUrl}${themeImages.userDashbordLogo}`;

  useEffect(() => {
    userId && getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUpdate, keyCloak]);

  const checkPopupBlocker = () => {
    const popup = window.open("about:blank", "_blank");

    if (!popup || popup.closed || typeof popup.closed === "undefined") {
      dispatch(setPopUpBlockerStatus(true));
    } else {
      dispatch(setPopUpBlockerStatus(false));
      popup.close();
    }
  };
  useEffect(() => {
    checkPopupBlocker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetails = async () => {
    const payload = {
      id: `${userId}/profile`,
    };
    storeData("corsError", false, true);
    try {
      const response: any = await profileDetails("", {}, payload);
      const status = response.status;
      if (status === 200) {
        const data = response.data.data;
        setUserName(response.data.data);
        setIsPassword(!data.passwordStatus);
        setForceSetPassword(data?.passwordLogin);
        dispatch(setProfileDetails(response.data.data));
        const termsAndCondition = await getTermsAndDetails(data);
        dispatch(setTermsAndCondition(termsAndCondition));
        const isTenantAdmin = !(response?.data?.data?.customerType === "MASTER");
        dispatch(setIsTenantAdmin(isTenantAdmin));
        const profileImage = data.attributes.find((attribute: any) => attribute.name === "profileImage");
        setImage(profileImage?.value);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };
  const handleModalOpen = () => {
    isOpen(true);
  };
  const handleModalClose = () => {
    isOpen(false);
  };
  const handleLogoutModal = () => {
    isOpenLogout(true);
  };
  const logout = () => {
    handleLogoutModalClose();
    setLoader(true);
    handleLogout(dispatch, gettingLogoutUrls, userName, userSessionKilling, setLoader, deviceUrl, setLogoutLoader);
  };
  const handleLogoutModalClose = () => {
    isOpenLogout(false);
  };
  const redirectDashboard = () => {
    storeData("key", 1, false);
    const url = "/admin-dashboard";
    const newTab: any = window.open(url, "_blank");
    newTab.focus();
  };

  const getUserInitial = (name: any) => {
    return name?.[0];
  };
  return (
    <div className="akkuHeader-row">
      <div className="logo">
        <img src={userDashbordLogoUrl} alt="logo" />
      </div>
      <div className="userInfo">
        <div className="userInfo-text">
          <p className="name">
            {t("dashboard.welcome")} <strong>{(userName && userName?.firstName + " " + userName?.lastName) || "Guest"}</strong>
          </p>
          <p className="italic">
            {t("dashboard.lastLogin")} : <strong>{getCurrentDayDateMonthYear()}</strong>
          </p>
        </div>
        <div className="language-toggle-wrapper ml-8 select-none">
          <LanguageToggle />
        </div>
        <div className={`logout profile`}>
          <div className="header-profile clientHeader flex items-center">
            <div role="none" className="header-icon cursor-pointer" onClick={handleLogoutModal}>
              <Tooltip title={t("common.logout")}>
                <span className="material-symbols-outlined profile-hover">power_settings_new</span>
              </Tooltip>
            </div>
            {role === "admin" && (
              <div role="none" className="header-icon cursor-pointer  " onClick={redirectDashboard}>
                <Tooltip title={t("dashboard.adminDashboard")}>
                  <span className="material-symbols-outlined profile-hover">switch_account</span>
                </Tooltip>
              </div>
            )}

            <div className="header-user">
              <Tooltip title={t("common.profile")}>
                <Avatar
                  onClick={handleModalOpen}
                  className={`"bg-[#5441da] ${image ? "cursor-pointer " : "bg-[#5441da]"}`}
                  size="large"
                  src={
                    image ? (
                      imageBaseUrl + image
                    ) : (
                      <p className="text-[#fff] font-semibold font-Inter capitalize initial">{getUserInitial(userName?.firstName)}</p>
                    )
                  }
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {loader && <ScreenLoader />}
      {logoutLoader && <Loader />}
      {openLogout && <LogoutModal handleModalClose={handleLogoutModalClose} open={openLogout} logout={logout} isOpenLogout={isOpenLogout} />}
      <UserHeaderModal
        getDetails={getDetails}
        setFullPageLoader={setLoader}
        handleModalClose={handleModalClose}
        setProfileUpdate={setProfileUpdate}
        profileUpdate={profileUpdate}
        open={open}
        userHavePassword={isPassword}
      />
      {isPassword && forceSetPassword && (
        <PasswordAlertModal setFullPageLoader={setLoader} isPassword={isPassword} forceSetPassword={forceSetPassword} getDetails={getDetails} />
      )}
    </div>
  );
};
export default UserHeader;
