import { Form, Formik, FormikProps } from "formik";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonBack from "../../../../../../../layouts/component/CustomButtonBack";
import { buttonRootState, UpdateParams } from "../../types/provisioningTypes";
import { useEffect, useState } from "react";
import {
  setActiveStep,
  setAssignGroupProvisioningConnectorEndpointConfigId,
  setGetGroupProvisioningConnectorEndpointConfigId,
  setProvisioning,
} from "../../../../../../../redux/slice/provisioning/ProvisioningSlice";
import { getRoleProvisionErrorEvaluatorScroll, groupProvisionSchema, UseGetEndpointDescription } from "../../../helper/connectorHelper";
import useGetApiRequests from "../../../../../../../services/axios/useApiRequests";
import { handleRequestError } from "../../../../../../../layouts/toast/ErrorNotificationMessage";

import SuccessMessageModal from "../../../../../../access-manager/create/IpTimeSuccessModal";
import { useTranslation } from "react-i18next";
import { useHandleProvisionPrerequisites } from "../../../connector/helper/ProvisioningHelper";
import GetRoleGroupProvisionFormField from "../form/GetRoleGroupProvisionFormField";
import { useProvisionContext } from "../../../../../context/ProvisionConfigureContext";
import axios, { AxiosResponse } from "axios";
import { setButtonStatus } from "../../../../../../../redux/slice/provisioning/ProvisioningButtonSlice";
import { provisioningRootState } from "../../../../../../../redux/slice/provisioning/ProvisioningTypes";

interface GroupProvisionProps {
  formRef: React.RefObject<FormikProps<provisioningRootState>>;
  setActiveKey: (key: string) => void;
}

const GroupProvision = (props: GroupProvisionProps) => {
  const { setActiveKey, formRef } = props;
  const { provisionDetails } = useProvisionContext();
  const { getProvisionDetails, getGroupAssignGroupPayload } = useHandleProvisionPrerequisites();
  const { t, i18n } = useTranslation();

  const provisioningConnectorConfigure = useGetApiRequests("provisioningConnectorConfigure", "POST");
  const provisioningConnectorConfigureUpdate = useGetApiRequests("provisioningConnectorConfigureUpdate", "PUT");

  const dispatch = useDispatch();
  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const provisionDetail = useSelector((state: provisioningRootState) => state?.provisioning?.provisioning);
  const formInitialValues = useSelector((state: provisioningRootState) => state?.provisioning?.provisioning);

  const buttonStatus = useSelector((state: buttonRootState) => state?.buttonStatus?.buttonStatus);
  const groupProvisioning = useSelector((state: buttonRootState) => state?.buttonStatus?.buttonStatus?.groupProvisioning);

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [getResponseAttributes, setGetResponseAttributes] = useState(null);
  const [assignResponseAttributes, setAssignResponseAttributes] = useState(null);

  useEffect(() => {
    // Revalidate the form on language change
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [i18n.language]);

  useEffect(() => {
    if (provisioning?.isActiveEdit && !groupProvisioning?.isInitialized) {
      if (provisionDetails) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            groupProvisioning: {
              ...groupProvisioning,
              isInitialized: true,
            },
          }),
        );

        const getGrouprovisionData = getProvisionDetails(provisionDetails?.endPointResponseDtos, ["group_ou_list"], "getGroup");
        const getAssignProvisionData = getProvisionDetails(provisionDetails?.endPointResponseDtos, ["assign_group"], "assignGroup");

        const provisioningPayload = {
          ...provisionDetail,
          ...(getGrouprovisionData && { getGroup: getGrouprovisionData }),
          ...(getAssignProvisionData && { assignGroup: getAssignProvisionData }),
        };
        dispatch(setProvisioning(provisioningPayload));
      }
    }
  }, [provisionDetails]);

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };

  const handleSubmit = (values: provisioningRootState) => {
    if (isDirty || buttonStatus?.groupProvisioning?.apiError) {
      getGroupAssignGroupApiCall(values);
      dispatch(setProvisioning({ ...provisionDetail, getGroup: values?.getGroup, assignGroup: values?.assignGroup }));
    } else {
      dispatch(setActiveStep(3));
    }
  };

  const getGroupAssignGroupApiCall = async (values: provisioningRootState) => {
    try {
      const payload = getGroupAssignGroupPayload(values);
      const params: UpdateParams = {
        akkuProvisioningConnectorId: provisioning.akkuProvisioningConnectorId,
      };

      const isGetGroupCreated = UseGetEndpointDescription("group_ou_list", provisionDetails?.endPointResponseDtos);
      const isAssignGroupCreated = UseGetEndpointDescription("assign_group", provisionDetails?.endPointResponseDtos);

      const shouldUpdate = (provisioning?.isActiveEdit && isGetGroupCreated && isAssignGroupCreated) || buttonStatus?.groupProvisioning?.isCreated;

      let response: AxiosResponse<provisioningRootState>;
      if (shouldUpdate) {
        response = (await provisioningConnectorConfigureUpdate(payload, {}, params)) as AxiosResponse<provisioningRootState>;
      } else {
        response = (await provisioningConnectorConfigure(payload)) as AxiosResponse<provisioningRootState>;
      }

      if (response?.status === 200) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            groupProvisioning: {
              ...buttonStatus?.groupProvisioning,
              isCreated: true,
              apiError: false,
            },
          }),
        );

        const getGrouprovisionData = getProvisionDetails(response?.data?.data, ["group_ou_list"], "getGroup");
        const getAssignProvisionData = getProvisionDetails(response?.data?.data, ["assign_group"], "assignGroup");

        if (getGrouprovisionData) {
          dispatch(setGetGroupProvisioningConnectorEndpointConfigId(getGrouprovisionData?.akkuProvisioningConnectorEndpointConfigId));
        }
        if (getAssignProvisionData) {
          dispatch(setAssignGroupProvisioningConnectorEndpointConfigId(getAssignProvisionData?.akkuProvisioningConnectorEndpointConfigId));
        }
        dispatch(setActiveStep(3));
      }
    } catch (error) {
      if (axios?.isAxiosError(error)) {
        dispatch(
          setButtonStatus({
            ...buttonStatus,
            groupProvisioning: {
              ...buttonStatus?.groupProvisioning,
              apiError: true,
            },
          }),
        );
        handleRequestError(error);
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  const handleBack = () => {
    setActiveKey("2");
  };

  const handleSkip = () => {
    dispatch(setActiveStep(3));
  };

  return (
    <>
      <Formik
        initialValues={formInitialValues}
        values={formInitialValues}
        onSubmit={(value: provisioningRootState) => handleSubmit(value)}
        validationSchema={groupProvisionSchema(t, getResponseAttributes, assignResponseAttributes)}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({ values, setFieldValue, dirty, errors }) => {
          setIsDirty(dirty);

          return (
            <Form className="w-full">
              <div className=" w-[100%] flex flex-wrap flex-col gap-2 justify-center items-center ">
                <div className="w-full grid grid-cols-1 gap-x-[5%] 2xl:grid-cols-2  ">
                  <div className="">
                    <div className="pr-10">
                      <p className="font-medium text-[#000] font-Inter text-[22px] border-b-2 pb-1 border-[#CDCDCD]">
                        {t("appManagement.provisioning.getGroup")}
                      </p>
                      <GetRoleGroupProvisionFormField
                        key={"getGroup"}
                        values={values}
                        setFieldValue={setFieldValue}
                        formName="getGroup"
                        setGetResponseAttributes={setGetResponseAttributes}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="pr-10">
                      <p className="font-medium text-[#000] font-Inter text-[22px] border-b-2 pb-1 border-[#CDCDCD]  ">
                        {t("appManagement.provisioning.assignGroup")}
                      </p>
                      <GetRoleGroupProvisionFormField
                        key={"assignGroup"}
                        values={values}
                        setFieldValue={setFieldValue}
                        formName="assignGroup"
                        setAssignResponseAttributes={setAssignResponseAttributes}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-provision flex items-center absolute bottom-0 right-0 w-full bg-[#fff] h-[100px]">
                <div className="modal-footer w-full mx-auto ">
                  <div className="w-full flex justify-end pr-5">
                    <CustomButtonBack text={t("common.skip")} onClick={() => handleSkip()} />
                    <CustomButtonBack text={t("common.back")} onClick={() => handleBack()} />
                    <Button loading={loader} type="primary" htmlType="submit" className="bg-[#5441DA] submit-btn">
                      {t("common.next")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </>
  );
};

export default GroupProvision;
