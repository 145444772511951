import { Button } from "antd";
import React from "react";
import "./Common.scss";

// CustomButton component definition
const CustomButtonBack = ({ onClick, text }: any) => {
  return (
    <Button onClick={onClick} className="back-btn !text-[#545263] border-[#D0D5DD]" size="large">
      {text}
    </Button>
  );
};

export default CustomButtonBack;
