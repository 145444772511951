import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeData } from "../services/storage/Storage";
import { setThemeConfigure } from "../redux/slice/theme/themeSlice";
import { staticThemeUrl, themeCss } from "../User/theme";
import useGetApiRequests from "../services/axios/useApiRequests";
import { extractSubdomain } from "../hooks/commonHooks";
import Loader from "../layouts/component/Loader";

export default function Theme() {
  const localhostRealmName: string | undefined = process.env.REACT_APP_LOCALHOST_REALM_NAME;

  const getThemeDetails = useGetApiRequests("theme", "GET");
  const getLocation = window.location;
  const dispatch = useDispatch();
  const theme = useSelector((state: any) => state?.theme?.themeDetails);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      init();
    }, 2000);
  }, []);

  const dynamicTheme = (getThemePath: any) => {
    storeData(
      "themeImagesUrl",
      {
        themeImagesUrl: `${getThemePath}/img/`,
        themeCssUrl: `${getThemePath}/css/${themeCss.themePath}`,
      },
      true,
    );
    dispatch(
      setThemeConfigure({
        themeImagesUrl: `${getThemePath}/img/`,
        themeCssUrl: `${getThemePath}/css/${themeCss.themePath}`,
      }),
    );
  };
  const staticTheme = async () => {
    storeData(
      "themeImagesUrl",
      {
        themeImagesUrl: staticThemeUrl.imagesUrl,
        themeCssUrl: `${staticThemeUrl.cssUrl}${themeCss.themePath}`,
      },
      true,
    );

    dispatch(
      setThemeConfigure({
        themeImagesUrl: staticThemeUrl.imagesUrl,
        themeCssUrl: `${staticThemeUrl.cssUrl}${themeCss.themePath}`,
      }),
    );
  };
  const init = async () => {
    let realmName: string | undefined = "";
    if (getLocation.hostname === "localhost" || getLocation.hostname === "development" || getLocation.hostname === "sso-demo2") {
      realmName = localhostRealmName;
    } else {
      realmName = extractSubdomain(getLocation.href);
    }
    const urlParam = {
      realmName: realmName,
      key: "login-theme",
    };
    try {
      const response: any = await getThemeDetails("", "", urlParam);
      const status = response.status;
      if (status === 200) {
        const data = response?.data?.data;
        storeData("realmId", data?.id, true);
        storeData("realmName", data?.name, true);
        const getThemePath: string = data.loginTheme;
        dynamicTheme(getThemePath);
        setLoader(false);
      } else {
        staticTheme();
        setLoader(false);
      }
    } catch (err: any) {
      staticTheme();
      setLoader(false);
    }
  };
  return (
    <>
      {loader && <Loader />}
      {<link href={theme.themeCssUrl ? theme.themeCssUrl : staticThemeUrl.cssUrl} rel="stylesheet" />}
    </>
  );
}
