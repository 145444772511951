import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  data: any;
  setPassword: string;
}

const initialState: UserState = {
  data: null,
  setPassword: "",
};

const UserSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clearUserDetails: (state) => {
      state.data = null;
    },
    fromWhere: (state, action: PayloadAction<string>) => {
      state.setPassword = action.payload;
    },
  },
});

export const { setUserDetails, clearUserDetails, fromWhere } = UserSlice.actions;
export default UserSlice.reducer;
