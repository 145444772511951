import { Radio } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const RadioGroup = (props: any) => {
  const { t } = useTranslation();
  const { onChange, value } = props;

  return (
    <div className="w-full flex pt-[32px] app-configure ">
      <div className="w-full ">
        <p className="text-[#1D1D24] text-[20px] font-Inter pb-4 font-medium">{t("appManagement.applicationType")}</p>
        <Radio.Group onChange={onChange} value={value} className="pb-5">
          <Radio value={"saml"}>{t("appManagement.saml")}</Radio>
          <Radio value={"openid-connect"}>{t("appManagement.openId")}</Radio>
          <Radio value={"CAS"} disabled>
            {t("appManagement.cas")}
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default RadioGroup;
