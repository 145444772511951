import { Button, Table, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import generateTitle from "../../../layouts/Table/generateTitle";
import { SortOrder } from "antd/lib/table/interface";
import DeviceRestrictionDeleteModal from "../modal/DeviceRestrictionDeleteModal";
import DeviceManagementTableHeader from "./DeviceManagementTableHeader";
import AddDeviceRestrictionModal from "../modal/AddDeviceRestrictionModal";
import useGetApiRequests from "../../../services/axios/useApiRequests";
import { debounce } from "lodash";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../../services/storage/Storage";
import { getUsersCountForGroups } from "../../access-manager/layout/AccessManagerGetGroupCount";
import { prerequisitesTable } from "../../../constant/prerequisites/prerequisites";
import AssignUsersToDeviceModal from "../modal/AssignUsersToDeviceModal";
import { useDispatch, useSelector } from "react-redux";
import { setReloadDeviceManagement } from "../../../redux/slice/device-management/deviceManagementSlice";
import SuccessMessageModal from "../../access-manager/create/IpTimeSuccessModal";
import { hasPermission } from "../../../redux/slice/permissions/permissionUtils";
import { modulePermissions } from "../../../const/RolePermissions";
import PermissionsModal from "../../../layouts/permissionsModal/PermissionsModal";
import { useTranslation } from "react-i18next";
interface DataType {
  key: React.Key;
  role: string;
  description: string;
  action: string;
  isEdit: boolean;
}

const DeviceManagementTable = (props: any) => {
  const { search } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const realmId = retrieveData("realmId", true);
  const getDeviceManagementList = useGetApiRequests("getDeviceManagementList", "POST");
  const getUserCount = useGetApiRequests("userManagement", "POST");

  const deviceManagement = useSelector((state: any) => state?.deviceManagement);
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const deviceManagerUpdatePermission = permissions && hasPermission(permissions, modulePermissions.deviceManager, "update");
  const deviceManagerDeletePermission = permissions && hasPermission(permissions, modulePermissions.deviceManager, "delete");

  const [deviceManagementTableData, setDeviceManagementTableData] = useState<DataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [deviceRestrictionModalOpen, setDeviceRestrictionModalOpen] = useState(false);
  const [addDeviceRestrictionModalOpen, setAddDeviceRestrictionModalOpen] = useState<boolean>(false);
  const [assignUsersModalOpen, setAssignUsersModalOpen] = useState<boolean>(false);
  const [selectedRowForAssignUsers, setSelectedRowForAssignUsers] = useState<object>({});
  const [loader, setLoader] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editRecord, setEditRecord] = useState<object>({});
  const [sizeChanger, setSizeChanger] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true);
    debouncedDeviceManagementList(search);
    if (deviceManagement?.reloadDeviceManagement) {
      dispatch(setReloadDeviceManagement(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentPage, sizeChanger, deviceManagement?.reloadDeviceManagement]);

  useEffect(() => {
    getUsersCountForGroups(getUserCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const debouncedDeviceManagementList = useCallback(
    debounce((search) => {
      getDeviceDetailList(search);
    }, 800),
    [search, currentPage, sizeChanger],
  );
  const getDeviceDetailList = async (search: any) => {
    const payload: any = {
      realmId,
      startValue: currentPage,
      limitValue: sizeChanger,
    };

    if (search && search !== "") {
      payload.searchFilter = search;
    }
    try {
      const response: any = await getDeviceManagementList(payload);
      const status = response.status;
      if (status === 200) {
        const responseData = response?.data?.data;
        const reformedDeviceManagementList = responseData?.map((item: any) => ({
          key: item?.serialNumber,
          serialNumber: item?.serialNumber,
          deviceUUID: item?.deviceUuid,
          deviceType: item?.deviceType.toLowerCase(),
          assignedUsers: item?.userName,
        }));
        setTotalRecords(response?.data?.meta?.totalRecords ?? 0);
        setDeviceManagementTableData(reformedDeviceManagementList);
      }

      setLoader(false);
    } catch (err: any) {
      setLoader(false);
      handleRequestError(err);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const selectedRows: any = newSelectedRowKeys?.map((key: any) => deviceManagementTableData.find((row: any) => row.key === key));
    selectedRows.filter((row: any) => row && row.restrictions === "inactive");
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record?.status === "deleted", // Disable checkbox for records having deleted status
    }),
  };

  const groupCommonRender = (text: any) => {
    if (!text) {
      return <div className="text">-</div>;
    }
    const groups = text?.split(",");
    const firstGroup = groups?.[0];
    const remainingGroups = groups;

    return (
      <div>
        <p className={`${firstGroup?.toLowerCase()} cursor-default font-Inter text-[#3A3A3A] text-[18px]  font-normal`}>
          <span> {firstGroup || "-"}</span>
          {remainingGroups.length > 1 ? `... +${remainingGroups.length - 1}` : ""}
        </p>
      </div>
    );
  };
  const columns: any = [
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("deviceManagement.serialNumber"),
          sortColumns?.find(({ column }: any) => column.dataIndex === "serialNumber"),
        ),
      dataIndex: "serialNumber",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: any, b: any) => a?.serialNumber?.localeCompare(b?.serialNumber),
      render: (text: any, record: any) => (
        <span onClick={() => handleAddDeviceRestrictionOpenModal(record)} role="none" className="text-[#5441da] cursor-pointer text-[18px] font-normal font-Inter">
          {text}
        </span>
      ),
      width: "auto",
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("deviceManagement.deviceUUID"),
          sortColumns?.find(({ column }: any) => column.dataIndex === "deviceUUID"),
        ),
      dataIndex: "deviceUUID",
      sorter: (a: any, b: any) => a?.deviceUUID?.localeCompare(b?.deviceUUID),
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any) => <p className="font-Inter font-normal text-[18px] text-[#3A3A3A] table-data">{text}</p>,
      width: "auto",
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("deviceManagement.deviceType"),
          sortColumns?.find(({ column }: any) => column.dataIndex === "deviceType"),
        ),
      dataIndex: "deviceType",
      sorter: (a: any, b: any) => a?.deviceType?.localeCompare(b?.deviceType),
      defaultSortOrder: "ascend" as SortOrder,
      render: (text: any) => (
        <div className="flex items-center justify-center">
          <Tooltip title={t(text === "laptop" ? "deviceManagement.laptop" : "deviceManagement.desktop")}>
            <span className="cursor-pointer material-icons-outlined">
              {text === "laptop" ? <span className="material-symbols-outlined">computer</span> : text === "desktop" ? <span className="material-symbols-outlined">desktop_mac</span> : <span>-</span>}
            </span>
          </Tooltip>
        </div>
      ),
      width: "auto",
    },
    {
      title: ({ sortColumns }: any) => <p className="text-[18px] font-Inter text-[#3A3A3A] font-medium column-name">{t("deviceManagement.assignedUsers")}</p>,
      defaultSortOrder: "ascend" as SortOrder,
      dataIndex: "assignedUsers",
      render: (text: any, record: any) => (
        <div>
          {!text ? (
            <Button type="primary" className="bg-[#5441DA] text-[#fff] max-h-[32px] max-w-[32px] w-[32px] h-[32px] flex justify-center items-center" onClick={() => handleAssignUsers(record)}>
              <span className="material-symbols-outlined text-[22px]">add</span>
            </Button>
          ) : (
            <p className="text-[#3A3A3A] font-Inter text-[18px] font-normal table-data">{groupCommonRender(text)}</p>
          )}
        </div>
      ),
      width: "auto",
    },
  ];

  const handleAssignUsers = (record: any) => {
    setSelectedRowForAssignUsers(record);
    setAssignUsersModalOpen(true);
  };
  const handleAssignUsersModalClose = () => {
    setAssignUsersModalOpen(false);
  };
  const clearSelection = () => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  };
  const handleDeviceRestrictionModalOpen = () => {
    if (deviceManagerDeletePermission) {
      setDeviceRestrictionModalOpen(true);
    } else {
      setPermissionsModal(true);
    }
  };
  const handleDeviceRestrictionModalClose = () => {
    clearSelection();
    setDeviceRestrictionModalOpen(false);
  };
  const handleAddDeviceRestrictionCloseModal = () => {
    setAddDeviceRestrictionModalOpen(false);
  };
  const handleAddDeviceRestrictionOpenModal = (record: any) => {
    if (deviceManagerUpdatePermission) {
      setEditRecord(record);
      setIsEdit(true);
      setAddDeviceRestrictionModalOpen(true);
    } else {
      setPermissionsModal(true);
    }
  };
  const handelClose = () => {
    setPermissionsModal(false);
  };
  const handlePageChange = (page: any) => {
    setCurrentPage((page - 1) * sizeChanger);
  };
  const handlePageSizeChange = (current: any, newSize: any) => {
    setSizeChanger(newSize);
  };
  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
  };
  return (
    <div className="device-management-table ">
      <DeviceManagementTableHeader clearSelection={clearSelection} selectedRow={selectedRow} handleDeviceRestrictionModalOpen={handleDeviceRestrictionModalOpen} totalRecords={totalRecords} />
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        dataSource={deviceManagementTableData}
        pagination={{
          current: currentPage / sizeChanger + 1,
          total: totalRecords,
          showSizeChanger: true,
          pageSizeOptions: prerequisitesTable.pageSizeOptions,
          defaultPageSize: sizeChanger,
          pageSize: sizeChanger,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
        }}
        loading={loader}
        locale={{
          triggerDesc: `${t("common.triggerDesc")}`,
          triggerAsc: `${t("common.triggerAsc")}`,
          cancelSort: `${t("common.cancelSort")}`,
        }}
      />
      {deviceRestrictionModalOpen && (
        <DeviceRestrictionDeleteModal
          selectedRow={selectedRow}
          deviceRestrictionModalOpen={deviceRestrictionModalOpen}
          handelDeviceRestrictionModalClose={handleDeviceRestrictionModalClose}
          setCurrentPage={setCurrentPage}
          setSizeChanger={setSizeChanger}
          deviceManagementTableData={deviceManagementTableData}
        />
      )}
      {addDeviceRestrictionModalOpen && (
        <AddDeviceRestrictionModal editRecord={editRecord} modalOpen={addDeviceRestrictionModalOpen} handleCloseModal={handleAddDeviceRestrictionCloseModal} isEdit={isEdit} />
      )}
      {assignUsersModalOpen && (
        <AssignUsersToDeviceModal
          modalOpen={assignUsersModalOpen}
          handleCloseModal={handleAssignUsersModalClose}
          deviceData={selectedRowForAssignUsers}
          setResponseMessage={setResponseMessage}
          setSuccessModal={setSuccessModal}
        />
      )}
      {permissionsModal && <PermissionsModal open={permissionsModal} close={handelClose} />}

      {successModal && <SuccessMessageModal open={successModal} handleModalClose={handleCloseSuccessModal} responseMessage={responseMessage} />}
    </div>
  );
};

export default DeviceManagementTable;
