import React, { useCallback, useEffect, useState } from 'react';
import MyAppCard from '../card/MyAppCard';
import useGetApiRequests from '../../../../services/axios/useApiRequests';
import { useDispatch, useSelector } from 'react-redux';
import { Empty } from 'antd';
import { handleRequestError } from '../../../../layouts/toast/ErrorNotificationMessage';
import { retrieveData, storeData } from '../../../../services/storage/Storage';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { setPublishedAppsList } from '../../../../redux/slice/app-store/AppDetailsSlice';
import { hasPermission } from '../../../../redux/slice/permissions/permissionUtils';
import { modulePermissions } from '../../../../const/RolePermissions';

const AvailableApps = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const publishedAppsList = useGetApiRequests('publishedAppsList', 'GET');

  const searchApp = useSelector((state: any) => state?.appSearchSlice?.searchApps[0]);
  const reInitiateApps = useSelector((state: any) => state?.appsRenderSlice?.appRender);
  const realmId = retrieveData('realmId', true);

  //permissions
  const permissions = useSelector((state: any) => state?.permissionsSlice?.attributes);
  const provisioningViewPermission = permissions && hasPermission(permissions, modulePermissions.provisioning, 'view');

  const masterAvailableApps = useGetApiRequests('masterAvailableApps', 'POST');
  const [masterData, setMasterData] = useState<any>();
  const [publishedAppList, setPublishedAppList] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    debouncedSearchMyApps(searchApp);
  }, [reInitiateApps, searchApp]);

  // Debounced API call function
  const debouncedSearchMyApps = useCallback(
    debounce((searchApp) => {
      getFilterData(searchApp);
    }, 800),
    [],
  );

  const headers = {
    'Content-Type': 'application/json',
  };
  const sortAppsByNameList = (items: any) => {
    const sortedArrays = items?.sort((a: any, b: any) => {
      const firstElements = a?.name?.toUpperCase();
      const secondElements = b?.name?.toUpperCase();
      if (firstElements < secondElements) {
        return -1;
      }
      if (firstElements > secondElements) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return sortedArrays;
  };

  const checkAndAddIsActivePublished = (akkuMasterClientStore: any, publishedAppList: any) => {
    const updatedStore = akkuMasterClientStore.map((client: any) => {
      let matchedObject = {};
      const publishedApp = publishedAppList.find((pubApp: any) => {
        matchedObject = pubApp;
        return (pubApp.akkuMasterClientId || pubApp.akkuMasterClient?.akkuMasterClientId) === client.akkuMasterClientId;
      });
      return {
        ...client,
        isActivePublished: publishedApp ? publishedApp.publishStatus : false,
        publishedAppDto: matchedObject,
      };
    });

    // Return the updated store
    return updatedStore;
  };
  const getPublishedApps = async (sortedData: any) => {
    if (provisioningViewPermission) {
      try {
        const response: any = await publishedAppsList();
        const status = response.status;
        if (status === 200) {
          const data = response.data?.data || [];
          setPublishedAppList(data);
          dispatch(setPublishedAppsList(data));
          const processData = checkAndAddIsActivePublished(sortedData, data);
          setMasterData(processData);
        }
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } catch (err: any) {
        setLoader(false);
        const listNotFound = err?.response?.data?.message?.includes('provider list not found');
        if (!listNotFound) {
          handleRequestError(err);
        }
      }
    } else {
      setLoader(false);
      setPublishedAppList([]);
      dispatch(setPublishedAppsList([]));
      const processData = checkAndAddIsActivePublished(sortedData, []);
      setMasterData(processData);
    }
  };

  const getFilterData = async (searchApp: any) => {
    let payload = { realmId: realmId, ...searchApp };
    try {
      const response: any = await masterAvailableApps(payload, '', {}, headers);
      const status = response.status;
      if (status === 200) {
        const data = response.data?.data;
        const sortedData: any = sortAppsByNameList(data?.akkuMasterClient);
        getPublishedApps(sortedData);

        storeData('akkuCustometId', data.akkuCustomer.customerId, true);
      }
    } catch (err: any) {
      handleRequestError(err);
    }
  };

  return (
    <div className="pt-7">
      <div className="flex items-center gap-2  pb-6">
        <p className="text-[rgb(23,23,23)] text-[28px] font-Inter font-semibold pl-5 available-apps-title">{t('appManagement.appStore')}</p>
        <p className=" apps-count-number"> {masterData?.length}</p>
      </div>

      <div className="flex flex-wrap">
        {masterData?.length !== 0 && <MyAppCard masterData={masterData} available={true} />}
        {masterData?.length === 0 && (
          <div className="w-full flex justify-center">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default AvailableApps;
