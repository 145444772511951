import React from "react";

type UserBehaviorCardContentProps = {
  type?: string;
  dots?: boolean;
  isActiveArrow?: boolean;
  details?: any;
};

export default function UserBehaviorCardContent({ type, details }: UserBehaviorCardContentProps) {
  return (
    <div className={`${type}`}>
      <div className="flex gap-x-3  mt-1">
        <div className="date">{details?.formattedTime}</div>
        <div className="description">
          <p className="mb-1">{details?.actionType}</p>
          <p className={["fail", "exceed", "disable"].some((word) => details?.activityLog?.toLowerCase().includes(word)) ? "failure" : "success"}>
            {details?.activityLog}
          </p>
        </div>
      </div>
    </div>
  );
}
