import React from 'react';
import { FieldArray, Field, ErrorMessage } from 'formik';
import { Input } from 'antd';

interface DynamicFieldArrayProps {
  name: string;
  label: string;
  labelClassName: string;
  required?: boolean; // Optional prop to display a required label
  className?: string; // Optional prop to add custom class to the input field
  containerClassName?: string; // Optional prop to add custom class to the container
  values?: any;
  keyName: string; // Use dynamic `key` prop from passed props
  valueName: string; // Use dynamic `value` prop from passed props
}

const DynamicFieldArrayTwo: React.FC<DynamicFieldArrayProps> = ({
  name,
  label,
  labelClassName,
  required = false,
  className,
  containerClassName,
  values,
  keyName,
  valueName,
}) => {
  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <div className={`${containerClassName} !mb-3 `}>
          <p className={labelClassName}>
            {label} {required && <span className=""> *</span>}
          </p>
          {values?.map((param: any, paramIndex: number) => (
            <div className="relative flex justify-between gap-x-[5%]" key={param}>
              {/* Key field */}
              <div className="relative flex-1 mb-7">
                <Field
                  as={Input}
                  type="text"
                  className={`form-type-box ${className}`}
                  name={`${name}.${paramIndex}.${keyName}`}
                  placeholder="Key"
                  id={`key-${paramIndex}`}
                />
                <ErrorMessage name={`${name}.${paramIndex}.${keyName}`} component="div" className="error-message custom-error" />
              </div>

              {/* Value field */}
              <div className="relative flex-1 mb-7">
                <Field
                  as={Input}
                  type="text"
                  className={`form-type-box ${className}`}
                  name={`${name}.${paramIndex}.${valueName}`}
                  placeholder="Value"
                  id={`value-${paramIndex}`}
                />
                <ErrorMessage name={`${name}.${paramIndex}.${valueName}`} component="div" className="error-message custom-error" />
              </div>

              {/* Add/Remove buttons */}
              <div className="absolute provision-action right-[-100px] top-1">
                {values?.length > 1 && (
                  <button type="button" className="remove-btn" onClick={() => remove(paramIndex)}>
                    <span className="material-symbols-outlined provision-plus-btn">remove</span>
                  </button>
                )}

                {paramIndex === values?.length - 1 && (
                  <button type="button" onClick={() => push({ [keyName]: '', [valueName]: '' })} data-testid="add-btn">
                    <span className="material-symbols-outlined provision-plus-btn">add</span>
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  );
};

export default DynamicFieldArrayTwo;
