import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  ldapInitialData: any;
  ldapIsEnable:boolean
}

const initialState: AuthState = {
    ldapInitialData: {},
    ldapIsEnable:false
};

const LdapInitDataSlice:any = createSlice({
  name: 'LdapInitialDataSlice',
  initialState,
  reducers: {
    setLdapInitialData: (state, action: PayloadAction<any>) => {
      state.ldapInitialData = action.payload;
    },
   setLdapIsEnable: (state, action: PayloadAction<any>) => {
      state.ldapIsEnable = action.payload;
    },
 
    clearLdapInitialData: state => {
      state.ldapInitialData = {};
    },
  },
});

export const { setLdapInitialData, clearLdapInitialData,setLdapIsEnable } = LdapInitDataSlice.actions;
export default LdapInitDataSlice.reducer;
