import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeProvisioningType, duplicateProvisioningButtonsType, generalDetailsType, ProvisioningType } from "./ProvisioningTypes";

export interface provisioningTypes {
  isActive: boolean;
  generalDetails: generalDetailsType;
  authentication: {};
  getConnetorDetailsData: {};
  provisioning: ProvisioningType;
  deProvisioning: DeProvisioningType;
  activeStep: number;
  endPointResponseDtosLen: number;
  isActiveEdit: boolean;
  akkuProvisioningConnectorId: string;
  isBackButtonEnabled: boolean;
  connectorDetailDirty: boolean;
  generalDetailsEdit: {};
  isDuplicate: boolean;
  mappingAttributes: {};
  selectedSourceConnector: string;
  endPointResponseDtos: any;
  endPointResponseDtosData: any;
  mappingAttributesPayloadCreation: [];
  authenticationTestButtonClicked: false;
  provisioningBackButtonClicked: false;
  deProvisioningBackButtonClicked: false;
  akkuProvisioningConnectorEndpointConfigId: string;
  akkuDeProvisionConnectorEndpointConfigId: {};
  duplicateProvisioningButtons: duplicateProvisioningButtonsType;
  isConnectorApiCalled: boolean;
  isProvisioningApiCalled: {};
  isDeProvisioningApiCalled: {};
  isProvisioningApiError: {};
  isDeProvisioningApiError: {};
  isAuthenticationEdited: boolean; //
  isConnectionTested: boolean;
  mappingGroupAttributes: {};
  mappingGroupAttributesResponseData: {};
  mappingRolesAttributes: {};
  connectorAuthenticationEndpointConfigId: string;
  userProvisioningConnectorEndpointConfigId: string;
  getRoleProvisioningConnectorEndpointConfigId: string;
  assignRoleProvisioningConnectorEndpointConfigId: string;
  getGroupProvisioningConnectorEndpointConfigId: string;
  assignGroupProvisioningConnectorEndpointConfigId: string;
  isRoleProvisionConfigured: boolean;
  isGroupProvisionConfigured: boolean;
  isAkkuProvisioningConnectorAuthConfigId: boolean;
}
export const initialState: provisioningTypes = {
  isConnectionTested: false,
  isAuthenticationEdited: false,
  mappingAttributesPayloadCreation: [],
  activeStep: 0,
  endPointResponseDtosLen: 0,
  isActiveEdit: false,
  isDuplicate: false,
  akkuProvisioningConnectorId: "",
  generalDetailsEdit: {},
  isActive: false,
  isAkkuProvisioningConnectorAuthConfigId: false,
  generalDetails: {
    name: "",
    description: "",
    type: "RESTAPI",
    tokenType: "",
    isDefaultSourceConnector: "yes",
    authenticationType: "",
    spDomain: "",
  },
  authentication: {
    tokenApiEndpoint: "",
    userName: "",
    password: "",
    grantType: "",
    clientId: "",
    secret: "",
    apiToken: "",
    userNameEmail: "",
    tokenApiUrl: "",
    scope: [""],
    tokenMethodType: "",
  },
  getConnetorDetailsData: {},

  provisioning: {
    userProvision: {
      apiEndpointURL: "",
      methodType: "",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
      primaryKeyAttributeList: ["", ""],
    },

    roleProvision: {
      apiEndpointURL: "",
      methodType: "",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
    },

    groupProvision: {
      apiEndpointURL: "",
      methodType: "",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
    },

    getRole: {
      apiEndpointURL: "",
      methodType: "",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
      serviceProviderAttributesDto: { id: "", name: "" },
    },

    assignRole: {
      apiEndpointURL: "",
      methodType: "",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
      serviceProviderAttributesDto: { id: "", name: "" },
      primaryKeyAttributeList: [""],
    },

    getGroup: {
      apiEndpointURL: "",
      methodType: "",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
      // primaryKeyAttributeList: [""],
      serviceProviderAttributesDto: { id: "", name: "" },
    },
    assignGroup: {
      apiEndpointURL: "",
      methodType: "",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
      serviceProviderAttributesDto: { id: "", name: "" },
      primaryKeyAttributeList: [""],
    },
  },

  deProvisioning: {
    userDeProvision: {
      apiEndpointURL: "",
      methodType: "Delete",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
    },
    roleDeProvision: {
      apiEndpointURL: "",
      methodType: "Delete",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
    },
    groupDeProvision: {
      apiEndpointURL: "",
      methodType: "Delete",
      authType: "",
      token: "",
      userName: "",
      password: "",
      contentType: "",
      algorithm: "",
      secret: "",
      payload: "",
      apiKey: "",
      apiValue: "",
      apiAddTo: "",
      requestPayload: "",
      response: "",
      headerParam: [{ key: "", value: "" }],
      pathVariable: [{ key: "", value: "" }],
    },
  },
  isBackButtonEnabled: false,
  connectorDetailDirty: false,
  mappingAttributes: {
    createUserData: [{ source: "", target: "", userType: "", id: "" }],
    deleteUserData: [{ source: "", target: "", userType: "", id: "" }],
    roleUserData: [{ source: "", target: "", userType: "", id: "" }],
    groupUserData: [{ source: "", target: "", userType: "", id: "" }],
    deleteRoleUserData: [{ source: "", target: "", userType: "", id: "" }],
    deleteGroupUserData: [{ source: "", target: "", userType: "", id: "" }],
  },
  mappingGroupAttributes: { forms: [{ source: "", target: "", sourceId: "", targetId: "" }] },
  mappingRolesAttributes: { forms: [{ source: "", target: "", sourceId: "", targetId: "" }] },
  mappingGroupAttributesResponseData: {},
  selectedSourceConnector: "",
  endPointResponseDtos: [
    {
      akkuProvisioningConnectorEndpointConfigId: "",
      endpointDescription: "",
      attributeMappings: {},
      headerParameters: {},
    },
  ],
  endPointResponseDtosData: [],
  authenticationTestButtonClicked: false,
  provisioningBackButtonClicked: false,
  deProvisioningBackButtonClicked: false,
  duplicateProvisioningButtons: {
    connectorAuthentication: false,
    // Provisioning
    connectorUserProvisioning: false,
    connectorGetRoleProvisioning: false,
    connectorAssignRoleProvisioning: false,
    connectorGetGroupProvisioning: false,
    connectorAssignGroupProvisioning: false,
    // DeProvisioning
    connectorUserDeprovisioning: false,
    connectorRoleDeprovisioning: false,
    connectorGroupDeprovisioning: false,
    // Mapping Attribute
    connectorMappingAttributes: false,
    connectorMappingAttributesTarget: false,
  },
  isConnectorApiCalled: false,
  isProvisioningApiCalled: {
    userProvisionApiCalled: false,
    getRoleProvisionApiCalled: false,
    assignRoleProvisionApiCalled: false,
    getGroupProvisionApiCalled: false,
    assignGroupProvisionApiCalled: false,
    // roleProvisionApiCalled: false,
    // groupProvisionApiCalled: false,
  },
  isProvisioningApiError: {
    userProvisionApiError: false,
    getRoleProvisionApiError: false,
    assignRoleProvisionApiError: false,
    getGroupProvisionApiError: false,
    assignGroupProvisionApiError: false,
    // roleProvisionApiError: false,
    // groupProvisionApiError: false,
  },
  isDeProvisioningApiCalled: {
    userDeProvisionApiCalled: false,
    roleDeProvisionApiCalled: false,
    groupDeProvisionApiCalled: false,
  },
  isDeProvisioningApiError: {
    userDeProvisionApiError: false,
    roleDeProvisionApiError: false,
    groupDeProvisionApiError: false,
  },
  akkuProvisioningConnectorEndpointConfigId: "",
  // connector Authentication
  connectorAuthenticationEndpointConfigId: "",
  // connector ProvisioningConnectorEndpointConfig
  userProvisioningConnectorEndpointConfigId: "",
  getRoleProvisioningConnectorEndpointConfigId: "",
  assignRoleProvisioningConnectorEndpointConfigId: "",
  getGroupProvisioningConnectorEndpointConfigId: "",
  assignGroupProvisioningConnectorEndpointConfigId: "",
  // Connector DeProvisioningConnectorEndpointConfig
  akkuDeProvisionConnectorEndpointConfigId: {
    userDeProvision: "",
    roleDeProvision: "",
    groupDeProvision: "",
  },
  isRoleProvisionConfigured: false,
  isGroupProvisionConfigured: false,
};

const provisioningSlice: any = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setGeneralDetails: (state, action: PayloadAction<any>) => {
      state.generalDetails = action.payload;
    },
    setAuthentication: (state, action: PayloadAction<any>) => {
      state.authentication = action.payload;
    },
    setGetConnetorDetailsData: (state, action: PayloadAction<any>) => {
      state.getConnetorDetailsData = action.payload;
    },
    setProvisioning: (state, action: PayloadAction<any>) => {
      state.provisioning = action.payload;
    },
    setDeProvisioning: (state, action: PayloadAction<any>) => {
      state.deProvisioning = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setEndPointResponseDtosLen: (state, action: PayloadAction<number>) => {
      state.endPointResponseDtosLen = action.payload;
    },
    setEditStatusToggle: (state, action: PayloadAction<boolean>) => {
      state.isActiveEdit = action.payload;
    },
    setGeneralDetailsEdit: (state, action: PayloadAction<any>) => {
      state.generalDetailsEdit = action.payload;
    },
    setMappingGroupAttributesResponseData: (state, action: PayloadAction<any>) => {
      state.mappingGroupAttributesResponseData = action.payload;
    },
    setConnectorId: (state, action: PayloadAction<string>) => {
      state.akkuProvisioningConnectorId = action.payload;
    },
    setAuthenticationEdited: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticationEdited = action.payload;
    },
    setIsConnectionTested: (state, action: PayloadAction<boolean>) => {
      state.isConnectionTested = action.payload;
    },
    setIsDuplicate: (state, action: PayloadAction<boolean>) => {
      state.isDuplicate = action.payload;
    },
    setIsBackButtonPressed: (state, action: PayloadAction<any>) => {
      state.isBackButtonEnabled = action.payload;
    },
    setConnectorDetailDirty: (state, action: PayloadAction<boolean>) => {
      state.connectorDetailDirty = action.payload;
    },
    setMappingAttributes: (state, action: PayloadAction<any>) => {
      state.mappingAttributes = action.payload;
    },
    setMappingGroupAttributes: (state, action: PayloadAction<any>) => {
      state.mappingGroupAttributes = action.payload;
    },
    setMappingRolesAttributes: (state, action: PayloadAction<any>) => {
      state.mappingRolesAttributes = action.payload;
    },
    setSelectedSourceConnector: (state, action: PayloadAction<any>) => {
      state.selectedSourceConnector = action.payload;
    },
    setEndPointResponseDtos: (state, action: PayloadAction<any>) => {
      state.endPointResponseDtos = action.payload;
    },
    setMappingAttributesPayloadCreation: (state, action: PayloadAction<any>) => {
      state.mappingAttributesPayloadCreation = action.payload;
    },
    setAuthenticationNextButtonClicked: (state, action: PayloadAction<any>) => {
      state.authenticationTestButtonClicked = action.payload;
    },
    setProvisioningBackButtonClicked: (state, action: PayloadAction<any>) => {
      state.provisioningBackButtonClicked = action.payload;
    },
    setDeProvisioningBackButtonClicked: (state, action: PayloadAction<any>) => {
      state.deProvisioningBackButtonClicked = action.payload;
    },
    setProvisioningConnectorEndpointConfigId: (state, action: PayloadAction<any>) => {
      state.akkuProvisioningConnectorEndpointConfigId = action.payload;
    },
    setDeProvisionConnectorEndpointConfigId: (state, action: PayloadAction<any>) => {
      state.akkuDeProvisionConnectorEndpointConfigId = action.payload;
    },
    setProvisioningButtonStatus: (state, action: PayloadAction<any>) => {
      state.duplicateProvisioningButtons = action.payload;
    },
    setIsConnectorApiCalled: (state, action: PayloadAction<boolean>) => {
      state.isConnectorApiCalled = action.payload;
    },
    setIsProvisioningApiCalled: (state, action: PayloadAction<boolean>) => {
      state.isProvisioningApiCalled = action.payload;
    },
    setIsDeProvisioningApiCalled: (state, action: PayloadAction<boolean>) => {
      state.isDeProvisioningApiCalled = action.payload;
    },
    setIsProvisioningApiError: (state, action: PayloadAction<boolean>) => {
      state.isProvisioningApiError = action.payload;
    },
    setIsDeProvisioningApiError: (state, action: PayloadAction<boolean>) => {
      state.isDeProvisioningApiError = action.payload;
    },
    setEndPointResponseDtosData: (state, action: PayloadAction<any>) => {
      state.endPointResponseDtosData = action.payload;
    },
    // AkkuProvisioningConnectorEndpointConfigId starts
    setUserProvisioningConnectorEndpointConfigId: (state, action: PayloadAction<string>) => {
      state.userProvisioningConnectorEndpointConfigId = action.payload;
    },
    setConnectorAuthenticationEndpointConfigId: (state, action: PayloadAction<string>) => {
      state.connectorAuthenticationEndpointConfigId = action.payload;
    },
    setGetRoleProvisioningConnectorEndpointConfigId: (state, action: PayloadAction<string>) => {
      state.getRoleProvisioningConnectorEndpointConfigId = action.payload;
    },
    setAssignRoleProvisioningConnectorEndpointConfigId: (state, action: PayloadAction<string>) => {
      state.assignRoleProvisioningConnectorEndpointConfigId = action.payload;
    },
    setGetGroupProvisioningConnectorEndpointConfigId: (state, action: PayloadAction<string>) => {
      state.getGroupProvisioningConnectorEndpointConfigId = action.payload;
    },
    setAssignGroupProvisioningConnectorEndpointConfigId: (state, action: PayloadAction<string>) => {
      state.assignGroupProvisioningConnectorEndpointConfigId = action.payload;
    },
    setIsRoleProvisionConfigured: (state, action: PayloadAction<boolean>) => {
      state.isRoleProvisionConfigured = action.payload;
    },
    setIsGroupProvisionConfigured: (state, action: PayloadAction<boolean>) => {
      state.isGroupProvisionConfigured = action.payload;
    },
    setIsAkkuProvisioningConnectorAuthConfigId: (state, action: PayloadAction<boolean>) => {
      state.isAkkuProvisioningConnectorAuthConfigId = action.payload;
    },
    // End
    clearActiveStep: (state) => {
      state.activeStep = 0;
    },
    clearAllData: () => {
      return { ...initialState };
    },
  },
});

export const {
  setIsActive,
  setGeneralDetails,
  setAuthentication,
  setGetConnetorDetailsData,
  setProvisioning,
  setDeProvisioning,
  setActiveStep,
  clearActiveStep,
  clearAllData,
  setEditStatusToggle,
  setConnectorId,
  setGeneralDetailsEdit,
  setEndPointResponseDtosLen,
  setIsDuplicate,
  setIsBackButtonPressed,
  setConnectorDetailDirty,
  setMappingAttributes,
  setSelectedSourceConnector,
  setEndPointResponseDtos,
  setEndPointResponseDtosData,
  setAuthenticationNextButtonClicked,
  setProvisioningBackButtonClicked,
  setDeProvisioningBackButtonClicked,
  setProvisioningConnectorEndpointConfigId,
  setDeProvisionConnectorEndpointConfigId,
  setMappingAttributesPayloadCreation,
  setProvisioningButtonStatus,
  setIsConnectorApiCalled,
  setAuthenticationEdited,
  setIsConnectionTested,
  setMappingGroupAttributes,
  setIsProvisioningApiCalled,
  setIsProvisioningApiError,
  setIsDeProvisioningApiError,
  setIsDeProvisioningApiCalled,
  setMappingRolesAttributes,
  setMappingGroupAttributesResponseData,
  setConnectorAuthenticationEndpointConfigId,
  setUserProvisioningConnectorEndpointConfigId,
  setGetRoleProvisioningConnectorEndpointConfigId,
  setAssignRoleProvisioningConnectorEndpointConfigId,
  setGetGroupProvisioningConnectorEndpointConfigId,
  setAssignGroupProvisioningConnectorEndpointConfigId,
  setIsRoleProvisionConfigured,
  setIsGroupProvisionConfigured,
  setIsAkkuProvisioningConnectorAuthConfigId,
} = provisioningSlice.actions;
export default provisioningSlice.reducer;
