import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import "./ProductList.scss";
import SelectedProducts from "./SelectedProducts";
import useGetApiRequests from "../../../../services/axios/useApiRequests";
import Loader from "../../../../layouts/component/Loader";
import { images } from "../../../../assets/images/ImagePaths";
import ImageView from "../../../../layouts/component/ImageView";
import { handleRequestError } from "../../../../layouts/toast/ErrorNotificationMessage";
const OurProductsList = () => {
  const getPlanList = useGetApiRequests("getProducts", "GET");

  const [loader, setLoader] = useState(false);
  const [allPlans, setAllPlans] = useState([]);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [selectedList, setSelectedList] = useState<any>([]);
  const imagesList = [images.settingRoll, images.shield_lock, images.password, images.setting, images.cloud];

  useEffect(() => {
    getAvailablePlans();
  }, []);

  const getAvailablePlans = async () => {
    setLoader(true);

    try {
      const response: any = await getPlanList();
      const status = response.status;
      if (status === 200) {
        setLoader(false);
        setAllPlans(response?.data);
      }
    } catch (err: any) {
      setLoader(false);
     handleRequestError(err)

    }
  };

  const onChange = (event: any, featureId: any) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, featureId]);
      setSelectedList((prevSelectedList: number[]) => [...prevSelectedList, featureId]);
    } else {
      setCheckedItems((prevState: any) => prevState.filter((id: any) => id !== featureId));
      setSelectedList((prevSelectedList: []) => prevSelectedList.filter((id: any) => id !== featureId));
    }
  };

  return (
    <div className="w-[65%] mx-auto pb-10">
      {loader && <Loader />}
      <div className="w-full flex">
        <div className="w-[70%]">
          <div className=" flex flex-col w-full product-list">
            <div className=" flex flex-col w-full product-list">
              {allPlans?.map((elm: any, index: number) => (
                <div className="flex" key={elm.akkuFeatureId}>
                  <Checkbox onChange={(e) => onChange(e, elm)} checked={checkedItems.includes(elm)} />
                  <div className="product-list-price">
                    <p className="product-list-name">
                      <ImageView src={imagesList[index]} className='pr-5' /> {elm?.name}
                    </p>
                    <p className="text-[#939393] font-Inter text-[15px]">
                      <span className=" product-list-span">$ {elm?.price}</span> / Monthly-per user
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-[35%]">{<SelectedProducts checkedItems={checkedItems} selectedList={selectedList} />}</div>
      </div>
    </div>
  );
};

export default OurProductsList;
