import React, { useEffect, useState } from "react";
import DirectoryBreadcrumb from "../edit-ldap-configuration/breadcrumb/DirectoryBreadcrumb";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import DeleteLDAPConnection from "../edit-ldap-configuration/delete-connection/DeleteLDAPConnection";
import { Formik } from "formik";
import KerberosConnectionForm from "../connection-directory/kerberos-connection/KerberosConnectionForm";
import { kerberosValidationSchema, kerberosInitialValues } from "../dashboard/user/user-dashboard/add-user/layouts/choose-connector/kerberosHelper";
import { useSelector } from "react-redux";
import useGetApiRequests from "../../services/axios/useApiRequests";
import { triggerNotification } from "../../layouts/toast/ToastBar";
import { handleRequestError } from "../../layouts/toast/ErrorNotificationMessage";
import { retrieveData } from "../../services/storage/Storage";
import Loader from "../../layouts/component/Loader";
import DisableKerberos from "./DisableKerberos";
const EditKerberosDirectory = () => {
  const navigate = useNavigate();
  const enableTest = false;
  const [disableKerberos, setDisableKerberos] = useState<boolean>(false);
  const [openModal, setOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState(kerberosInitialValues);
  const responseData = useSelector((state: any) => state?.GroupSlice?.kerberosInitValue);
  const updateKerberosDirectory = useGetApiRequests("updateKerberosDirectory", "PUT");
  const realmName: string = retrieveData("realmName", true);
  const realmId = retrieveData("realmId", true);
  const [loader, setLoader] = useState<boolean>(false);
  const [isOpenPopup, isOpen] = useState<boolean>(false);
  useEffect(() => {
    const enabledValue = responseData[0]?.config?.enabled[0];
    const isEnabled = enabledValue === "true";
    setDisableKerberos(isEnabled);
    let kerberosInitialValues: any;
    responseData.forEach((target: any) => {
      kerberosInitialValues = {
        kerberosName: target?.name || "",
        kerberosRealmName: target?.config?.kerberosRealm[0] || "",
        serverPrincipalName: target?.config?.serverPrincipal[0] || "",
        keytabPath: target?.config?.keyTab[0] || "",
      };
    });
    setInitialValues(kerberosInitialValues);
  }, []);
  const handleNavigate = () => {
    navigate("/user");
  };
  const handleDeletePopup = () => {
    setOpen(true);
  };
  const onChange = (checked: boolean) => {
    if (checked === false) {
      isOpen(true);
    } else {
      handleDisable(checked);
    }
  };
  const handleClose = () => {
    isOpen(false);
  };
  const handleDisable = async (checked: boolean) => {
    setLoader(true);
    const query = responseData[0].id;
    let payload = {
      ...initialValues,
      realmName: realmName,
      enabled: checked,
      realmId: realmId,
    };
    try {
      const response: any = await updateKerberosDirectory(payload, {}, [query]);
      const status = response?.status;
      if (status === 200) {
        handleClose();
        triggerNotification("success", "", response?.data?.message, "topRight");
        setLoader(false);
        setDisableKerberos(checked);
      }
    } catch (err: any) {
      setLoader(false);
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
      handleRequestError(err);
    }
  };
  const handleSubmit = async (values: any) => {
    setLoader(true);
    const query = responseData[0].id;
    let payload = {
      ...values,
      enabled: disableKerberos,
      realmName: realmName,
      realmId: realmId,
    };
    try {
      const response: any = await updateKerberosDirectory(payload, {}, [query]);
      const status = response?.status;
      if (status === 200) {
        triggerNotification("success", "", response?.data?.message, "topRight");
        handleClose();
        navigate("/user");
        setLoader(false);
      }
    } catch (err: any) {
      handleRequestError(err);
      setLoader(false);
      triggerNotification("error", "", err?.response?.data?.message, "topRight");
    }
  };
  return (
    <div className="akku-container">
      {loader && <Loader />}
      <div className="main-container">
        <div className="dashboard-container h-full ">
          <DirectoryBreadcrumb onClick={handleNavigate} />
          <div className="w-full flex directory-ldap">
            <div className="w-1/2">
              <p className="title">Edit-Kerberos</p>
            </div>
            <div className="w-[50%] flex justify-end action items-center pt-3 ">
              <p className={`delete cursor-pointer `} onClick={handleDeletePopup}>
                Delete
              </p>
              <p>
                <span className="px-4"> {disableKerberos ? "Enabled" : "Disabled"} </span>
                <Switch checked={disableKerberos} onChange={onChange} disabled={enableTest} />
              </p>
            </div>
          </div>
          <div className="w-full relative">
            <Formik initialValues={initialValues} validationSchema={kerberosValidationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
              {({ values, dirty }) => {
                return (
                  <>
                    <KerberosConnectionForm dirty={dirty} active />
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      {openModal && <DeleteLDAPConnection openModal={openModal} handleClose={handleClose} />}
      {isOpenPopup && <DisableKerberos isOpenPopup={isOpenPopup} handleClose={handleClose} setDisableLDAP={setDisableKerberos} disableLDAP={disableKerberos} handleDisable={handleDisable} />}
    </div>
  );
};
export default EditKerberosDirectory;
