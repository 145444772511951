import { Tooltip } from "antd";
import React from "react";

interface PageTitleProps {
  title: any;
}

const ToolTipCustom: React.FC<PageTitleProps> = ({ title }: any) => {
  return (
    <Tooltip title={title}>
      <span className="material-symbols-outlined  cursor-pointer rotate-180 ml-2">error</span>
    </Tooltip>
  );
};

export default ToolTipCustom;
