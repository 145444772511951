import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FinalReviewUserDataAccordionGroups = ({ groupAttributeDetails }: any) => {
  const { t } = useTranslation();

  const provisioning = useSelector((state: any) => state?.provisioning);

  return (
    <div className="relative">
      <div className="flex w-full mx-auto flex-wrap  px-5 review ">
        <div className="w-full flex pt-8 pb-4 gap-x-10 justify-evenly ">
          <div className="w-[30%] text-[20px] flex  ">
            <p className="content-header">{t("appManagement.provisioning.sourceAkku")}</p>
          </div>
          <div className="w-[40%]">
            <p className="content-header">
              {t("appManagement.provisioning.target")}-{provisioning?.generalDetails?.name}
            </p>
          </div>
        </div>
        <div className="w-full modal-list ">
          {groupAttributeDetails?.map((elm: any, index: number) => (
            <div className="w-full flex border-b-2 py-3 justify-evenly gap-x-10  text-[#1D1D24]" key={elm.source}>
              <div className="w-[30%]  ">
                <p className=" font-Inter content-description text-[20px] font-medium">{elm.source}</p>
              </div>
              <div className="w-[40%]">
                <p className="font-Inter content-description text-[20px] font-medium">{elm.target}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinalReviewUserDataAccordionGroups;
