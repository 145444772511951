import COMMON_API_URL from './commonApiUrls';
import GET_API_URL from './getApiConfig';
import POST_API_URL from './postApiConfig';
import PUT_API_URL from './puttApiConfig';
import DELETE_API_URL from './deleteApiUrls';

export const API_CONFIG: any = {
    ...COMMON_API_URL,
    ...GET_API_URL,
    ...POST_API_URL,
    ...PUT_API_URL,
    ...DELETE_API_URL,
};
