import { SortOrder } from "antd/lib/table/interface";
import generateTitle from "../../../../../../layouts/Table/generateTitle";

export const resetPasswordHistoryTableColumns = (t: any) => {
  return [
    {
      title: ({ sortColumns }: any) => {
        return generateTitle(
          t("userManagement.lastUpdatedOn"),
          sortColumns?.find(({ column }: any) => column.key === "lastUpdatedOn"),
        );
      },
      dataIndex: "lastUpdatedOn",
      key: "lastUpdatedOn",
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      sorter: (a: any, b: any) => {
        return a?.lastUpdatedOn?.localeCompare(b?.lastUpdatedOn);
      },
      render: (text: any) => (
        <div>
          <p role="none" className={`${text?.toLowerCase()}  text-[#3A3A3A] font-Inter font-normal text-[18px]`}>
            <span>{text || "-"}</span>
          </p>
        </div>
      ),
    },
    {
      title: ({ sortColumns }: any) =>
        generateTitle(
          t("userManagement.resetPasswordInitiatedBy"),
          sortColumns?.find(({ column }: any) => column.key === "email"),
        ),
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a?.email?.localeCompare(b?.email),
      defaultSortOrder: "ascend" as SortOrder,
      width: "30%",
      render: (text: any) => {
        const splitString = text?.split("(");
        const adminName = splitString?.[0]?.trim();
        const email = splitString?.[1]?.trim();
        return (
          <div>
            <span className="font-normal text-[#3A3A3A] font-Inter text-[18px]">{adminName || "-"}</span>
            <span className="font-normal text-[#3A3A3A] font-Inter text-[18px]"> {email ? `(${email}` : "-"}</span>
          </div>
        );
      },
    },
  ];
};
