import React, { useEffect, useState } from "react";
import "./LicenseCard.scss";
import CardTitle from "../../component/CardTitle";
import batch from "../../../assets/images/icons/license.png";
import Chart from "react-apexcharts";
import ChartOptions from "./ChartOptions";
import { useSelector } from "react-redux";
interface ChatType {
  options: ChartOptions;
}
const data = [{ type: "MSSP" }, { type: "Clients " }];
const LicenseCard = () => {
  const userDetails = useSelector((state: any) => state.dashboardDetails?.totalLicenses);
  const [totalLicenses, setTotalLicenses] = useState<any>();
  const [chartSeries, setChartSeries] = useState<number[]>([10, 1]);
  useEffect(() => {
    const msspUsedLicensePercentage: number = totalLicenses?.msspUsedLicensePercentage || 0;
    const clientUsedLicensePercentage: number = totalLicenses?.clientUsedLicensePercentage || 0;
    const roundMSSP = Math.round(msspUsedLicensePercentage);
    const roundClient = Math.round(clientUsedLicensePercentage);
    setChartSeries([roundMSSP, roundClient]);
    setTotalLicenses(userDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const chartOptions: ChatType = {
    options: {
      labels: data.map((row) => row.type),
      colors: ["#A041DA", "#EBEBFF"],
      chart: {
        width: 300,
        type: "pie",
        events: {},
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: "100%",
            labels: {
              show: false,
            },
          },
          animate: false,
        },
      },
      stroke: {
        width: 0,
      },
      tooltip: {
        enabled: true,
        theme: "dark", // or 'light'
        // For more specific styling, custom CSS is often used.
      },
      dataLabels: {
        enabled: false,
        style: {},
      },
      legend: {
        show: false,
      },
      events: {},
      states: {
        hover: {
          filter: {
            type: "none", // Disables the hover effect
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none", // Optionally, disables the active (click) effect as well
          },
        },
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            chart: {
              width: 150,
            },
            legend: {},
          },
        },
      ],
    },
  };
  return (
    <div className="_card _card-modify w-full  min-h-[400px]  license-card">
      <div className="_card-header --no-border">
        <div className="title pt-[6px]">
          <CardTitle title="Total licenses " src={batch} />
        </div>
      </div>
      <div className="flex w-full pt-4">
        <div className="w-[50%] flex justify-center items-center">
          <Chart options={chartOptions.options} series={chartSeries} type="pie" width="225" />
        </div>
        <div className="license-card-title">
          <p className="pb-1">Used License</p>
          <h3>{totalLicenses?.usedLicenseCount?.toLocaleString()}</h3>
          <p className="pt-1">Out of {totalLicenses?.totalLicenseCount?.toLocaleString()}</p>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="contribution flex justify-center py-[10px]">
          <p>Contribution</p>
        </div>
        <div className="contribution-percentage w-full  flex">
          <div className="w-1/2 flex justify-start flex-col  py-3 _client">
            <h3>
              MSSP <span>({totalLicenses?.msspUsedLicensePercentage}% )</span>
            </h3>
            <p>{totalLicenses?.totalMsspUsedLicenseCount?.toLocaleString()} Licensed used</p>
          </div>
          <div className="w-1/2 flex justify-start flex-col py-3 ">
            <h3>
              Clients <span>( {totalLicenses?.clientUsedLicensePercentage}% )</span>
            </h3>
            <p>{totalLicenses?.totalClientUsedLicenseCount?.toLocaleString()} Licensed used</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseCard;
