import React from "react";
import { Select, Table } from "antd";
import { useLocation } from "react-router-dom";
import { columns, groupColumns } from "./TimeRestrictionTableData";
import UsersSearchDropdown from "../../../dashboard/user/user-dashboard/groups/UserSearchModal";
import { useTranslation } from "react-i18next";

export default function AssignToTimeParameter(props: any) {
  const {
    dropdownVisibleForTime,
    usersSearchListForTime,
    selectedRowKeys,
    groupDataForTime,
    listOfUsersForTime,
    unassignUserForTime,
    rowSelection,
    loader,
    handleChangeGroups,
    setDropdownVisibleForTime,
    handleAddUsersInTime,
    handleAddGroupsInTime,
    handleChangeUsersForTime,
    handleLabelClick,
    userSearchGroupDataForTime,
    selectedValues,
  } = props.props;
  const { t } = useTranslation();
  {
    t("accessManager.reEnterPassword");
  }
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const type = params.get("type");

  const { Option } = Select;

  const selectFilterProcessFunction = (input: string, option?: { label: string; value: string }) => (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  const renderingUserSearch = (menu: any) => {
    return (
      <div>
        <div>{menu}</div>
        <UsersSearchDropdown handleAddApps={type === "users" ? handleAddUsersInTime : handleAddGroupsInTime} setDropdownVisible={setDropdownVisibleForTime} />
      </div>
    );
  };
  const temporaryColumns = type === "groups" ? groupColumns(t) : columns(t);
  const finalColumns = Array.isArray(temporaryColumns) ? temporaryColumns : [];
  let translatedValues;
  if (type === "groups") {
    translatedValues = `${t("accessManager.groupsLowerCase")}`;
  } else if (type === "users") {
    translatedValues = `${t("accessManager.usersLowerCase")}`;
  }
  return (
    <>
      {type !== "organization" && (
        <div className="assignedTable-container">
          <h3> {t("accessManager.assignedTo")}</h3>
          <div className="table-search">
            <div className="w-[35%]">
              {type === "groups" ? (
                <Select
                  className="h-14 mb-5 w-full custom-dropdown"
                  placeholder={t("accessManager.searchGroups")}
                  tokenSeparators={[","]}
                  onChange={handleChangeGroups}
                  onDropdownVisibleChange={(canVisible) => setDropdownVisibleForTime(canVisible)}
                  open={dropdownVisibleForTime}
                  optionLabelProp="label"
                  filterOption={selectFilterProcessFunction}
                  dropdownRender={renderingUserSearch}
                  mode="tags"
                >
                  {userSearchGroupDataForTime?.map((element: any) => (
                    <Option key={element?.value} value={element?.value} label={element?.name}>
                      <input
                        name={element?.name}
                        id={element?.value}
                        checked={selectedValues.includes(element?.value)}
                        onChange={(e) => handleLabelClick(e, element?.value)}
                        type="checkbox"
                        className="w-5 dropdown-list"
                      />
                      <label htmlFor={element?.value} className="flex flex-col h-14 justify-center pointer-events-none">
                        {element?.name} <span className="absolute text-[#747577]  top-[32px]">{element?.description}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select
                  className="h-14 w-full mb-5 custom-dropdown"
                  mode="tags"
                  placeholder={t("accessManager.searchUsers")}
                  tokenSeparators={[","]}
                  optionLabelProp="label"
                  open={dropdownVisibleForTime}
                  dropdownRender={renderingUserSearch}
                  onDropdownVisibleChange={(visible) => setDropdownVisibleForTime(visible)}
                  onChange={handleChangeUsersForTime}
                  filterOption={selectFilterProcessFunction}
                >
                  {usersSearchListForTime?.map((item: any) => (
                    <Option key={item?.value} value={item?.value} label={item?.name}>
                      <input
                        name={item?.name}
                        id={item?.value}
                        checked={selectedValues?.includes(item?.value)}
                        onChange={(e) => handleLabelClick(e, item?.value)}
                        type="checkbox"
                        className="dropdown-list w-5"
                      />
                      <label htmlFor={item?.name} className="h-14  flex flex-col justify-center pointer-events-none">
                        {item?.name} <span className="top-[32px] absolute text-[#747577]">{item?.email}</span>
                      </label>
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
          <div className="assignedTable ">
            <div className="device-restriction flex">
              <p className="assignedTable-totalGroup">{`${t("accessManager.totalNoOf")} ${translatedValues} ${type === "groups" ? groupDataForTime?.length : listOfUsersForTime?.length} `}</p>
              {selectedRowKeys?.length > 0 && (
                <p className={`ml-[55px] users-clear `} onClick={unassignUserForTime} role="none">
                  {type === "groups" ? `${t("accessManager.unassignGroups")}` : `${t("accessManager.unassignUsers")}`}
                </p>
              )}
            </div>
            <Table loading={loader} rowSelection={rowSelection} columns={finalColumns} dataSource={type === "groups" ? groupDataForTime : listOfUsersForTime} pagination={false} />
          </div>
        </div>
      )}
    </>
  );
}
