import { useSelector } from "react-redux";
import { convertObjectToArray } from "./../helper/ConnectorDetailsHelper";
import { groupEndPointDescriptionPayload, roleEndPointDescriptionPayload, transformHeaderParameters } from "../../helper/connectorHelper";
import { retrieveData } from "../../../../../../services/storage/Storage";
import { provisioningRootState } from "../../../../../../redux/slice/provisioning/ProvisioningTypes";
import { profileDetailsType } from "../../../../../../redux/slice/profile/ProfileDetailsSlice";

export const useHandleProvisionPrerequisites = () => {
  const realmId = retrieveData("realmId", true);
  const provisioning = useSelector((state: provisioningRootState) => state?.provisioning);
  const generalDetails = provisioning?.generalDetails;
  const isTenantAdmin = useSelector((state: profileDetailsType) => state?.ProfileDetailsSlice?.isTenantAdmin);

  const getRoleAkkuProvisioningConnectorEndpointConfigId = useSelector(
    (state: provisioningRootState) => state.provisioning?.getRoleProvisioningConnectorEndpointConfigId,
  );
  const assignRoleAkkuProvisioningConnectorEndpointConfigId = useSelector(
    (state: provisioningRootState) => state.provisioning?.assignRoleProvisioningConnectorEndpointConfigId,
  );

  const getGroupAkkuProvisioningConnectorEndpointConfigId = useSelector(
    (state: provisioningRootState) => state.provisioning?.getGroupProvisioningConnectorEndpointConfigId,
  );
  const assignAkkuProvisioningConnectorEndpointConfigId = useSelector(
    (state: provisioningRootState) => state.provisioning?.assignGroupProvisioningConnectorEndpointConfigId,
  );

  // Provisioning and Deprovisioning getDetails for TextFields
  const getProvisionDetails = (data: provisioningRootState, provisionTypes: string[], type: string) => {
    if (data?.length > 0) {
      const result = data?.find((item: provisioningRootState) => provisionTypes.includes(item?.endpointDescription));

      if (result) {
        const headerParameters = convertObjectToArray(result.headerParameters);
        const pathVariable = convertObjectToArray(result.pathVariable);

        // common properties
        const datas: any = {
          akkuProvisioningConnectorEndpointConfigId: result?.akkuProvisioningConnectorEndpointConfigId,
          methodType: result?.methodType,
          apiEndpointURL: result?.apiEndpointUrl,
          tokenApiUrl: result?.authToken,
          apiToken: result?.tokenApiEndpoint,
          headerParam: headerParameters,
          pathVariable: pathVariable,
          requestPayload: result?.requestPayload || "",
          response: result?.sampleResponse || "",
          primaryKeyAttributeList: result?.primaryKeyAttributeList?.length > 0 ? result.primaryKeyAttributeList : ["", ""],
          serviceProviderAttributesDto:
            result?.serviceProviderAttributesDto && Object.keys(result.serviceProviderAttributesDto).length > 0
              ? result.serviceProviderAttributesDto
              : { id: "", name: "" },
        };

        return datas || null;
      } else {
        console.warn(`No result found ${provisionTypes}`);
      }
    } else {
      console.warn("response data");
    }
  };

  const getRoleAssignRolePayload = (values: provisioningRootState) => {
    const { getRole, assignRole } = values;
    const payload = [
      {
        apiEndpointUrl: getRole?.apiEndpointURL,
        methodType: getRole?.methodType,
        endpointDescription: roleEndPointDescriptionPayload(getRole?.methodType),
        requestPayload: getRole?.requestPayload || null,
        sampleResponse: getRole?.response,
        primaryKeyAttributeList: [""],
        serviceProviderAttributesDto:
          getRole?.serviceProviderAttributesDto?.id && getRole?.serviceProviderAttributesDto?.name ? getRole?.serviceProviderAttributesDto : null,
        headerParameters: transformHeaderParameters(getRole?.headerParam),
        pathVariable: transformHeaderParameters(getRole?.pathVariable),
        realmId: realmId,
        name: generalDetails?.name,
        type: "request",
        endpointType: "GENERAL",
        isRoleProvisioningConfig: true,
        akkuProvisioningConnectorEndpointConfigId:
          getRole?.akkuProvisioningConnectorEndpointConfigId || getRoleAkkuProvisioningConnectorEndpointConfigId,
        isActive: provisioning?.isActive,
        isTenantAdmin: isTenantAdmin,
      },
      {
        apiEndpointUrl: assignRole?.apiEndpointURL,
        methodType: assignRole?.methodType,
        endpointDescription: roleEndPointDescriptionPayload(assignRole?.methodType),
        requestPayload: assignRole?.requestPayload || null,
        sampleResponse: assignRole?.response,
        primaryKeyAttributeList: assignRole?.primaryKeyAttributeList,
        serviceProviderAttributesDto: null,
        headerParameters: transformHeaderParameters(assignRole?.headerParam),
        pathVariable: transformHeaderParameters(assignRole?.pathVariable),
        realmId: realmId,
        name: generalDetails?.name,
        type: "request",
        endpointType: "PROVISIONING",
        isRoleProvisioningConfig: true,
        akkuProvisioningConnectorEndpointConfigId:
          assignRole?.akkuProvisioningConnectorEndpointConfigId || assignRoleAkkuProvisioningConnectorEndpointConfigId,
        isActive: provisioning?.isActiveEdit ? provisioning?.isActive : undefined,
        isTenantAdmin: isTenantAdmin,
      },
    ];

    return payload;
  };

  const getGroupAssignGroupPayload = (values: provisioningRootState) => {
    const { getGroup, assignGroup } = values;
    const payload = [
      {
        apiEndpointUrl: getGroup?.apiEndpointURL,
        methodType: getGroup?.methodType,
        endpointDescription: groupEndPointDescriptionPayload(getGroup?.methodType),
        requestPayload: getGroup?.requestPayload || null,
        sampleResponse: getGroup?.response,
        primaryKeyAttributeList: [""],
        serviceProviderAttributesDto:
          getGroup?.serviceProviderAttributesDto?.id && getGroup?.serviceProviderAttributesDto?.name ? getGroup?.serviceProviderAttributesDto : null,
        headerParameters: transformHeaderParameters(getGroup?.headerParam),
        pathVariable: transformHeaderParameters(getGroup?.pathVariable),
        realmId: realmId,
        name: generalDetails?.name,
        type: "request",
        endpointType: "GENERAL",
        isGroupProvisioningConfig: true,
        akkuProvisioningConnectorEndpointConfigId:
          getGroupAkkuProvisioningConnectorEndpointConfigId || getGroup?.akkuProvisioningConnectorEndpointConfigId,
        isActive: provisioning?.isActiveEdit ? provisioning?.isActive : undefined,
        isTenantAdmin: isTenantAdmin,
      },
      {
        apiEndpointUrl: assignGroup?.apiEndpointURL,
        methodType: assignGroup?.methodType,
        endpointDescription: groupEndPointDescriptionPayload(assignGroup?.methodType),
        requestPayload: assignGroup?.requestPayload || null,
        sampleResponse: assignGroup?.response,
        primaryKeyAttributeList: assignGroup?.primaryKeyAttributeList,
        serviceProviderAttributesDto: null,
        headerParameters: transformHeaderParameters(assignGroup?.headerParam),
        pathVariable: transformHeaderParameters(assignGroup?.pathVariable),
        realmId: realmId,
        name: generalDetails?.name,
        type: "request",
        endpointType: "PROVISIONING",
        isGroupProvisioningConfig: true,
        akkuProvisioningConnectorEndpointConfigId:
          assignGroup?.akkuProvisioningConnectorEndpointConfigId || assignAkkuProvisioningConnectorEndpointConfigId,
        isActive: provisioning?.isActiveEdit ? provisioning?.isActive : undefined,
        isTenantAdmin: isTenantAdmin,
      },
    ];

    return payload;
  };

  return { getProvisionDetails, getRoleAssignRolePayload, getGroupAssignGroupPayload };
};
