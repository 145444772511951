import React, { useState, useEffect } from "react";
import { notification, Upload } from "antd";
import { UploadFile, RcFile } from "antd/lib/upload/interface";

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ImageUploadProfile = ({
  imageUrl,
  onImageUpload,
  onImageDelete,
  maxFileSize, // Maximum file size in bytes
  allowedTypes, // An array of allowed file types, e.g., ['image/jpeg', 'image/png', 'application/pdf']
  multiple = false,
  className,
  initial,
  reset,
}: {
  imageUrl?: string;
  onImageUpload: (file: File) => void;
  onImageDelete: (file: File) => void;
  maxFileSize: number;
  allowedTypes: string[];
  multiple?: boolean;
  className: any;
  initial: string;
  reset: boolean;
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      const parts = imageUrl?.split("/"); // This splits the string into an array of parts
      const fileName = parts[parts?.length - 1]; // This selects the last part of the array

      setFileList([
        {
          uid: "-1",
          name: fileName,
          status: "done",
          url: imageUrl,
        },
      ]);
    }
    setFileSizeExceeded(false);
  }, [imageUrl, reset]);

  const clearError = () => setFileSizeExceeded(false);

  const handleRemove = (file: UploadFile) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
    onImageDelete(file.originFileObj as File);
  };

  const customRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
      onImageUpload(file);
    }, 1000);
  };

  return (
    <>
      <div className="relative upload-file">
        <Upload
          customRequest={customRequest}
          listType="picture-circle"
          fileList={fileList}
          className={className}
          onChange={(e) => handleChange(e, maxFileSize, setFileSizeExceeded, allowedTypes, setFileList, onImageUpload, multiple)}
          onRemove={handleRemove}
          multiple={multiple}
        >
          {!multiple && fileList.length >= 1 ? null : (
            <div className="image-upload justify-center items-center flex-wrap p-4">
              <div className="upload-document-image">
                <p className="flex items-center flex-col">
                  <p className="text-3xl text-[#fff]">{initial}</p>
                </p>
              </div>
            </div>
          )}
        </Upload>
        <p className="absolute top-0 profileEdit-icon right-0 w-8 h-8 border rounded-[100%] flex justify-center items-center bg-[#fff]">
          <span className="material-symbols-outlined text-[18px] text-[#000]">edit</span>
        </p>
      </div>

      {fileSizeExceeded && (
        <div className="file-size-warning">
          <p className="text-red-500 mt-5 ">File size should be within {maxFileSize / (1024 * 1024)} MB.</p>
          <p className="text-[#5441DA] underline text-[14px] " onClick={clearError}>
            Clear
          </p>
        </div>
      )}
    </>
  );
};
export const handleChange = (
  { fileList: newFileList }: { fileList: UploadFile[] },
  maxFileSize: any,
  setFileSizeExceeded: any,
  allowedType: any,
  setFileList: any,
  onImageUpload: any,
  multiple: any,
) => {

  const filteredFileList: any = newFileList.filter((file: any) => {
    const fileSize = file?.originFileObj?.size;
    if (fileSize === null) return false;
    if (fileSize > maxFileSize) {
      setFileSizeExceeded(true);
      return false;
    } else setFileSizeExceeded(false);

    if (!allowedType.includes(file?.type)) {
      let allowedFileTypesString;
      if (allowedType[0] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        allowedFileTypesString = `Only xlsx files are allowed.`;
      } else {
        const typeNames = allowedType.map((type: any) => type.split("/")[1].toUpperCase());
        allowedFileTypesString = `Only ${typeNames.join(", ")} files are allowed.`;
      }
      notification.error({
        message: "Invalid File Type",
        description: allowedFileTypesString,
      });
      return false;
    }
    return true;
  });
  setFileList(filteredFileList);
  if (filteredFileList.length > 0) {
    if (multiple) onImageUpload(filteredFileList as File);
    else onImageUpload(filteredFileList[0]?.originFileObj as File);
  }
};
export default ImageUploadProfile;
