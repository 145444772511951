import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  appRender: boolean;
  reloadAppStore: boolean;
}

const initialState: AuthState = {
  appRender: false,
  reloadAppStore: false,
};

const courseTabSlice = createSlice({
  name: "navigateCourseTab",
  initialState,
  reducers: {
    setAppRender: (state, action: PayloadAction<boolean>) => {
      state.appRender = action.payload;
    },
    setReloadAppStore: (state, action: PayloadAction<boolean>) => {
      state.reloadAppStore = action.payload;
    },
    clearSetAppRender: (state) => {
      state.appRender = false;
    },
  },
});

export const { setAppRender, clearSetAppRender, setReloadAppStore } = courseTabSlice.actions;
export default courseTabSlice.reducer;
