import { useSelector } from "react-redux";
import { staticThemeUrl, themeImages } from "../../../User/theme";

export default function Banner() {
  const theme = useSelector((state: any) => state?.theme?.themeDetails);

  let bannerUrl: string = "";
  if (theme?.themeImagesUrl) {
    bannerUrl = `${theme?.themeImagesUrl}${themeImages.banner}`;
  } else {
    bannerUrl = `${staticThemeUrl.imagesUrl}${themeImages.banner}`;
  }

  return (
    <div className="flex items-center h-[100vh] banner-container">
      <div className="login-banner">
        <img src={bannerUrl} className="bannerImage" alt="banner" />
      </div>
    </div>
  );
}
