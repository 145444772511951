import React, { useEffect, useState } from "react";
import { Collapse, CollapseProps, Input } from "antd";
import { ErrorMessage, Field, FieldArray } from "formik";
import { staticMessage } from "../../../../../constant/StaticContent";
import ToolTipCustom from "../../../../../layouts/component/ToolTipCustom";
import { useTranslation } from "react-i18next";

const OpenIdLogInSetting = (props: any) => {
  const { t } = useTranslation();
  const { values, isError } = props;
  const [activeKey, setActiveKey] = useState<string>("");

  useEffect(() => {
    if (isError) {
      setActiveKey("1");
    }
  }, [isError]);
  const handleCollapseChange = (key: any) => {
    setActiveKey(key);
  };
  const item: CollapseProps["items"] = [
    {
      key: "1",
      label: <p className="text-[#000] font-Inter text-[18px] font-normal">3 . {t("appManagement.loginSettings")}</p>,
      children: (
        <FieldArray name="openId">
          {({ insert, remove, push }) => (
            <div>
              {values?.openId?.length > 0 &&
                values.openId.map((samlConfig: any, index: any) => (
                  <div className="flex w-full justify-between flex-col" key={samlConfig?.clientId}>
                    {/*  */}
                    <div className="w-full flex justify-between flex-wrap">
                      <div className="mb-10 2xl:w-[45%] w-[100%] ">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium flex items-center">
                          {t("appManagement.rootUrl")}
                          <ToolTipCustom title={t("appManagement.rootUrlDescription")} />
                        </p>
                        <Field as={Input} type="text" name={`openId.${index}.rootUrl`} className="h-[56px] font-medium text-[18px]" />
                        <ErrorMessage name={`openId.${index}.rootUrl`} component="div" className="error-message custom-error" />
                      </div>
                      {/*  */}
                      <div className=" 2xl:w-[45%] w-[100%]">
                        <p className="text-[#1D1D24] text-[18px] font-Inter font-medium flex items-center">
                          {t("appManagement.homeUrl")}*
                          <ToolTipCustom title={t("appManagement.homeUrlDescription")} />
                        </p>
                        <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.baseUrl`} />
                        <ErrorMessage name={`openId.${index}.baseUrl`} component="div" className="error-message custom-error" />
                      </div>
                    </div>
                    <div className="mt-5">
                      <FieldArray name={`openId.${index}.validRedirectUrl`}>
                        {({ push, remove }) => (
                          <div>
                            {samlConfig.validRedirectUrl.map((url: any, urlIndex: any) => (
                              <div key={samlConfig?.clientId} className="relative ">
                                <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium ">
                                  {t("appManagement.validRedirectUrl")}* <ToolTipCustom title={t("appManagement.validRedirectUrlDescription")} />{" "}
                                </p>
                                <div className="relative">
                                  <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.validRedirectUrl.${urlIndex}`} />
                                  <ErrorMessage name={`openId.${index}.validRedirectUrl.${urlIndex}`} component="div" className="error-message custom-error" />
                                  <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-[30%]" onClick={() => remove(urlIndex)}>
                                    <span className="material-symbols-outlined">do_not_disturb_on</span>
                                  </button>
                                </div>
                              </div>
                            ))}
                            <button type="button" onClick={() => push("")}>
                              <p className="text-[#444] text-[18px] font-Inter flex pt-3">
                                <span className="material-symbols-outlined">add_circle</span>
                                {t("appManagement.addValidRedirectUrls")}
                              </p>
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                    <FieldArray name={`openId.${index}.validRedirectLogoutUrl`}>
                      {({ push, remove }) => (
                        <div>
                          {samlConfig.validRedirectLogoutUrl.map((url: any, urlIndex: any) => (
                            <div key={samlConfig?.clientId} className="relative mt-8">
                              <p className="text-[#1D1D24] text-[18px] flex items-center font-Inter font-medium">
                                {t("appManagement.validPostLogoutRedirectUrl")}
                                <ToolTipCustom title={t("appManagement.validRedirectLogoutUrlDescription")} />
                              </p>
                              <div className="relative">
                                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.validRedirectLogoutUrl.${urlIndex}`} />
                                <ErrorMessage name={`openId.${index}.validRedirectLogoutUrl.${urlIndex}`} component="div" className="error-message custom-error" />
                                <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-[30%]" onClick={() => remove(urlIndex)}>
                                  <span className="material-symbols-outlined">do_not_disturb_on</span>
                                </button>
                              </div>
                            </div>
                          ))}
                          <button type="button" onClick={() => push("")}>
                            <p className="text-[#444] text-[18px] font-Inter  flex pt-3">
                              <span className="material-symbols-outlined" role="none">
                                add_circle
                              </span>
                              {t("appManagement.addValidPostLogoutRedirectUrl")}
                            </p>
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <FieldArray name={`openId.${index}.webOrigins`}>
                      {({ push, remove }) => (
                        <div className="mb-8">
                          {samlConfig.webOrigins.map((url: any, urlIndex: any) => (
                            <div key={samlConfig?.clientId} className="relative mt-8">
                              <p className="text-[#1D1D24] flex items-center text-[18px] font-Inter font-medium">
                                {t("appManagement.webOrigin")}
                                <ToolTipCustom title={t("appManagement.webOriginDescription")} />
                              </p>
                              <div className="relative">
                                <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.webOrigins.${urlIndex}`} />
                                <ErrorMessage name={`openId.${index}.webOrigins.${urlIndex}`} component="div" className="error-message custom-error" />
                                <button type="button" disabled={urlIndex === 0} className="absolute right-4 top-[30%]" onClick={() => remove(urlIndex)}>
                                  <span className="material-symbols-outlined">do_not_disturb_on</span>
                                </button>
                              </div>
                            </div>
                          ))}
                          <button type="button" onClick={() => push("")}>
                            <p className="text-[#444] text-[18px] font-Inter flex pt-3">
                              <span className="material-symbols-outlined">add_circle</span>
                              {t("appManagement.webOrigin")}
                            </p>
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <div className=" w-full">
                      <p className="text-[#1D1D24] text-[18px] font-Inter font-medium flex items-center"> {t("appManagement.frontChannelLogoutUrl")}*</p>
                      <Field as={Input} type="text" className="h-[56px] font-medium text-[18px]" name={`openId.${index}.frontChannelLogoutUrl`} />
                      <ErrorMessage name={`openId.${index}.frontChannelLogoutUrl`} component="div" className="error-message custom-error" />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </FieldArray>
      ),
    },
  ];
  return (
    <div className={` w-full pt-5`}>
      <Collapse activeKey={activeKey} onChange={handleCollapseChange} accordion items={item} className="w-[80%]  mt-6 custom-header" expandIconPosition={"end"} />
    </div>
  );
};

export default OpenIdLogInSetting;
