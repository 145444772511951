import * as Yup from "yup";
import { validationErrorMessages } from "../../../../constant/validationMessage";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required(validationErrorMessages.firstName.required)
    .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.firstName.letterAndSpace)
    .min(4, validationErrorMessages.minLength).max(16,validationErrorMessages.firstName.maxLength),
  lastName: Yup.string()
    .required(validationErrorMessages.lastName.required)
    .matches(/^[a-zA-Z\s]*$/, validationErrorMessages.lastName.letterAndSpace)
    .min(1,validationErrorMessages.lastName.minLength)
    .max(16,validationErrorMessages.lastName.maxLength),
  primaryEmail: Yup.string().email("Invalid email format").required("Email is required"),
   customerName: Yup.string()
    .matches(/^[A-Za-z0-9-]+$/, validationErrorMessages.domainName)
    .required(validationErrorMessages.register.required),
  primaryMobileNumber:Yup.string().required(validationErrorMessages.phone),
   
});



export const initialValue={

    firstName:"",
    lastName:'',
    primaryEmail:'',
    customerName:'',
    primaryMobileNumber:'',
    customerType: "CLIENT",
    discountAmount:0,
    parentCustomer:'akku'
}

