import React from "react";
import { useNavigate } from "react-router-dom";

export default function ReturnToHome({ title }: any) {
  const navigate = useNavigate();
  return (
    <div className="tileWithBack flex items-center">
      <span className="material-icons-outlined  text-[#1C1B1F] cursor-pointer" onClick={() => navigate(-1)} data-testid="returnButton">
        {" "}
        arrow_upward{" "}
      </span>{" "}
      <h2>{title}</h2>
    </div>
  );
}
